import * as React from 'react';

function SvgHonor(props: any) {
  const { isDark } = props;
  return isDark ? (
    <svg
      width={275}
      height={190}
      viewBox="0 0 275 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.806 190S-.954 169.784.016 134.014c.972-35.77 39.945-41.365 66.611-19.311 26.774 22.054 40.481 11.265 54.3-7.388 13.819-18.653 35.09-60.86 73.847-45.06 28.933 11.74 48.258 43.231 54.519 71.649 4.319 19.531-1.943 40.159-16.949 52.996-.54.439-2.361 2.771-2.901 3.1H40.806z"
        fill="url(#SvgHonor0_linear)"
      />
      <path
        d="M274.319 141.348c.21-.105 0-.315-.211-.315h-.21c.421-.105.631-.105.736-.21.21-.105 0-.315-.105-.42.105 0 .21-.105.315-.105.315-.105.105-.315-.21-.42h-.421c.106 0 .211 0 .211-.105.21-.104.105-.314-.105-.314h-.421c-.21 0-.525 0-.735.105-.211 0-1.262.105-1.472-.42-.315-.735 1.051-.63 1.156-1.155 0-.21-.946-.105-1.576.105-.526.105-.841.21-1.156.42-.315.105-.736.42-.946.525h-.841l-.21 1.889s1.156 0 1.471.21c.736.42 1.472.63 2.312.63.526 0 1.051-.105 1.577-.21.105 0 .735-.21.841-.21z"
        fill="#FFE1BD"
      />
      <path
        d="M244.577 181.658s1.156 1.574 1.682 2.099c.525.525 2.206-1.05 2.206-1.47-.105-.42-2.206-2.939-2.206-2.939"
        fill="#FFE5C2"
      />
      <path
        d="M249.307 182.392c.526.525 1.366 1.68 1.366 1.68l-1.471 1.784-.525-.105s-.106.84-.631 1.365c-.315.42-1.156 1.154-1.576 1.469-1.577 1.05-2.943 1.365-3.153 1.155-.946-.735 1.156-2.204 1.471-2.519 1.051-.945 1.156-2.415.946-3.254-.21-.525-.315-.63-.105-.735 0 0 .21-.105.63 0 .526.21 1.156.105 1.472-.42.21-.315.315-.63.315-.945.21.105.736 0 1.261.525z"
        fill="#112B46"
      />
      <path
        d="M215.781 183.232s-.525 1.889-.525 2.624c0 .735 2.207 1.05 2.522.735.315-.315.841-3.569.841-3.569"
        fill="#FFE5C2"
      />
      <path
        d="M218.305 187.326c-.105.734-.42 2.099-.42 2.099l-2.312.105-.211-.42s-.735.42-1.471.42a14.27 14.27 0 01-2.102-.21c-1.786-.525-2.942-1.365-2.942-1.679 0-1.26 2.417-.525 2.837-.42 1.471.21 2.627-.63 3.048-1.365.315-.525.21-.63.525-.525 0 0 .21.105.421.42.21.525.735.945 1.261.945.315 0 .63-.105.946-.315 0-.105.525.21.42.945z"
        fill="#112B46"
      />
      <path
        d="M226.082 142.188s-6.621 16.691-7.357 20.365c-.84 3.569-3.888 21.729-3.888 21.729s2.837.84 3.993.42c.736-2.939 8.197-25.928 13.032-32.122 4.729-6.193-5.78-10.392-5.78-10.392z"
        fill="url(#SvgHonor1_linear)"
      />
      <path
        d="M230.706 151.426s-1.472 8.083 3.573 16.375c5.044 8.293 10.193 14.382 10.193 14.382s2.207-1.05 2.943-2.205c-1.576-2.099-8.407-13.856-8.407-18.475 0-4.619 1.786-15.536 1.786-15.536l-7.566-1.784-2.522 7.243z"
        fill="url(#SvgHonor2_linear)"
      />
      <path
        d="M268.328 140.928s-8.618.42-13.137-.84c-4.413-1.259-12.085-6.718-12.085-6.718l-8.933-6.823c-2.312-2.834-5.254-11.022-5.675-12.492-.42-1.469-2.102-11.652-2.102-11.652l1.892-.21s2.207 7.138 3.363 11.232c1.051 3.779 6.936 9.028 8.302 10.288.105.105.21.105.21.21.841.734.211 2.204.211 2.204l1.576.525s1.681-.84 2.627.105l.315.315c1.367 1.469 4.835 6.928 11.771 9.657 5.044 1.26 10.614 2.1 11.98 2.205-.21 1.469-.315 1.994-.315 1.994z"
        fill="#FFE5C2"
      />
      <path
        d="M244.892 127.176c-.315.525-2.101 1.785-3.678 1.155-3.152-1.469-1.366-4.304-1.261-4.514.105.105.21.105.21.21.841.735 1.577-1.049 1.577-1.049l3.152 1.784s-1.366 1.26-.315 2.1l.315.314z"
        fill="#FFE5C2"
      />
      <path
        d="M245.208 137.044s-2.837 6.509-3.888 10.918c-7.777 1.889-12.926-1.68-15.238-3.989-.946-.945-1.472-1.68-1.472-1.68s2.102-3.779 3.363-6.613c1.261-2.834 3.153-6.509 4.519-9.028.105-.42-1.156-2.309-2.522-5.143 1.787-.735 4.519-3.57 4.519-3.57l5.36 5.984s-1.577 2.624.42 3.989c2.102 1.364 3.468.21 4.519-.63.84 1.155 5.254 5.144 7.987 7.033l-3.468 5.879c.105-.105-3.048-1.89-4.099-3.15z"
        fill="url(#SvgHonor3_linear)"
      />
      <path
        d="M243.841 114.895c-.421-.105-.946.105-1.261.42-.316.315-.421.84-.316 1.26-.315-.21-.84 0-.945.42-.211.315-.105.735 0 1.155.105.419.21.944.63 1.154.315.21.631.21.946.105.21 0 .42-.105.631-.315.21-.105.42-.42.525-.629.525-.735 1.156-1.47 1.681-2.31a.798.798 0 00.211-.525"
        fill="url(#SvgHonor4_linear)"
      />
      <path
        d="M248.046 123.609c-.42.419-.945.839-1.471 1.259-.736.525-1.576.735-2.417.21-.946-.525-1.997-.945-2.837-1.679-.946-.84-1.051-1.575-.526-2.625.315-.735.631-1.364.946-2.099.63-1.47 1.471-2.834 2.312-4.199.21-.315.42-.735.841-.525a29.712 29.712 0 013.993 2.204c.946.63 1.787 1.365 2.207 2.31.42 1.05.105 2.099-.105 3.254 0 .21-.105.42-.315.42"
        fill="#FFE5C2"
      />
      <path
        d="M247.94 121.299c.316-.21.736 0 .841.419.105.315-.105.735-.315 1.05-.21.42-.631.63-1.051.84-.42.21-.841.21-.946-.21-.42.525-.21 1.26.21 1.575.421.314.946.21 1.366-.105 1.367 1.679 3.468-.42 4.309-1.68.736-.945 1.156-2.309 1.156-3.464 0-.63-.21-1.26-.63-1.785.315-.629.21-1.469-.105-2.204-.316-.63-.736-1.155-1.367-1.68-.315-.314-1.576-.734-1.996-.419-.421-.84-1.367-.84-1.997-1.155-.631-.315-1.577-.21-1.682.525-.735-.84-2.417-.42-2.732.63-.315 1.049.841 2.309 1.892 2.099-.105.735.315 1.575 1.051 1.994.63.42 1.471.525 2.207.525-.421.21-.736.63-.841 1.155-.105.525-.105.945.525 1.155 0 0-.63.84-.315.944.105.105.42-.209.42-.209z"
        fill="url(#SvgHonor5_linear)"
      />
      <path
        d="M225.241 96.84c.105-.21.316-.105.421.105v.21c0-.42.105-.735.105-.735.105-.21.315-.105.42.105v-.315c.105-.315.315-.105.42.105v.21c.106-.21.316-.105.316.105v1.154c0 .21.105 1.26.63 1.47.736.315.421-1.155 1.051-1.365.21-.105.21.945.105 1.575s-.105.84-.21 1.155c-.105.315-.315.84-.315 1.049 0 .21.105.84.105.84l-1.892.42s-.21-1.155-.42-1.365a4.167 4.167 0 01-.946-2.204c-.105-.525 0-1.05 0-1.575.105-.105.21-.84.21-.944z"
        fill="#FFE5C2"
      />
      <path
        d="M241.32 147.961c-7.777 1.89-12.926-1.679-15.238-3.988 8.092 6.193 13.557 3.359 14.397 3.149.211-1.89 3.994-10.812 3.994-10.812s.63.734.736.839c0-.105-2.838 6.404-3.889 10.812z"
        fill="#5F52DD"
      />
      <path
        d="M118.046 0c1.051 1.47 2.417 1.785 2.417 1.785-.84.63-.84 1.05-.84 1.994-.841-.315-1.682-.945-2.207-1.785-.316-.84 0-1.47.63-1.994zM178.16 1.05c1.051 1.47 2.418 1.784 2.418 1.784-.841.63-.841 1.05-.841 1.995-.841-.315-1.682-.945-2.207-1.785-.315-.84 0-1.47.63-1.994z"
        fill="#F9D04E"
      />
      <path
        d="M78.428 16.795c1.472 1.05 2.943.735 2.943.735-.526.84-.316 1.26-.105 2.1-.946 0-1.892-.315-2.733-.84-.525-.63-.525-1.365-.105-1.995z"
        fill="#B0AFFD"
      />
      <path
        d="M96.607 32.436c1.577-.84 1.997-2.204 1.997-2.204.526.84.946.945 1.892 1.05a4.584 4.584 0 01-1.997 1.994c-.946.21-1.471-.105-1.892-.84z"
        fill="#F9D04E"
      />
      <path
        d="M126.454 20.89c1.787.42 2.943-.42 2.943-.42-.211 1.05.105 1.26.735 1.994-.946.315-1.891.42-2.837.21-.736-.315-.946-.945-.841-1.784zM176.373 21.205c.946 1.574 2.312 1.89 2.312 1.89-.841.524-.841.944-.946 1.889a5.19 5.19 0 01-2.102-1.89c-.315-.84 0-1.47.736-1.89zM234.698 56.685c.946 1.575 2.312 1.89 2.312 1.89-.841.524-.841.944-.946 1.89a5.19 5.19 0 01-2.102-1.89c-.21-.735.105-1.365.736-1.89z"
        fill="#B0AFFD"
      />
      <path
        d="M225.871 36.11c.946 1.575 2.312 1.89 2.312 1.89-.841.525-.841.945-.946 1.89a5.19 5.19 0 01-2.102-1.89c-.315-.84 0-1.47.736-1.89zM203.906 37.475c1.787.21 2.943-.63 2.943-.63-.105 1.05.21 1.26.946 1.89-.841.42-1.787.63-2.838.525-.841-.42-1.156-.945-1.051-1.785zM190.875 79.57c1.681-.526 2.417-1.785 2.417-1.785.315.944.736 1.05 1.576 1.364-.63.735-1.366 1.26-2.312 1.575-.84.105-1.366-.315-1.681-1.155z"
        fill="#F9D04E"
      />
      <path
        d="M208.005 8.818c1.682.63 3.048.105 3.048.105-.315.945-.105 1.26.42 2.1-.946.21-1.891.104-2.837-.21-.841-.526-.946-1.26-.631-1.995z"
        fill="#B0AFFD"
      />
      <path
        d="M149.68 8.818c1.682.735 3.048.105 3.048.105-.316.945-.105 1.26.42 2.1-.946.21-1.892.104-2.837-.21-.841-.526-.946-1.26-.631-1.995zM56.674 62.248c1.681.735 3.047.105 3.047.105-.315.945-.105 1.26.42 2.1-.945.21-1.89.105-2.837-.21-.735-.525-.84-1.26-.63-1.994zM78.007 89.541c.105 1.785 1.156 2.73 1.156 2.73-1.05.104-1.261.524-1.681 1.259-.631-.735-.946-1.68-1.051-2.624.105-.84.735-1.26 1.576-1.365z"
        fill="#F9D04E"
      />
      <path
        d="M72.332 47.867c1.681-.734 2.207-1.994 2.207-1.994.42.945.84.945 1.787 1.155-.526.84-1.262 1.47-2.207 1.89-.841.104-1.367-.316-1.787-1.05zM218.62 85.658c1.787-.315 2.627-1.47 2.627-1.47.211.945.631 1.155 1.367 1.575a5.734 5.734 0 01-2.523 1.26c-.84 0-1.261-.526-1.471-1.365z"
        fill="#B0AFFD"
      />
      <path
        d="M76.642 89.942c0-.21-.316-.105-.42.105v-.63c0-.21-.316-.105-.421 0v-.315c0-.315-.315-.21-.42.105 0 .105 0 .21-.105.42v-.21c0-.21-.316-.21-.42 0 0 .105 0 .21-.106.525 0 .21-.105.524-.105.734 0 .21-.315 1.26-.84 1.365-.841.21-.316-1.26-.841-1.575-.21-.105-.42 1.05-.42 1.575v1.26c0 .315.21.84.21 1.154 0 .315-.421 1.05-.421 1.05l2.102.42s.525-1.155.736-1.365a4.67 4.67 0 001.26-2.204c.106-.525.21-1.155.21-1.68v-.734z"
        fill="#FFE5C2"
      />
      <path
        d="M57.2 107.157s6.516-.105 10.089-3.989c2.837-3.989 5.57-8.398 5.78-8.712.525.21 1.786.734 1.786.734s-3.783 8.398-5.15 10.288c-1.47 1.889-4.308 5.038-10.403 6.613-2.943-3.464-2.102-4.934-2.102-4.934zM61.508 185.152s.42.84.315 1.47c0 .525-1.892.735-2.102.42-.21-.315-.42-2.939-.42-2.939"
        fill="#FFE5C2"
      />
      <path
        d="M59.302 187.462c0 .629.21 1.574.21 1.574l2.102-.105.105-.315s.736.315 1.26.315l1.787-.105c1.262-.105 1.997-.21 2.102-.525.105-.944-1.471-.839-1.891-.839-1.156 0-2.417-.42-2.733-1.05-.21-.42-.105-.525-.42-.42 0 0-.21.105-.315.315-.21.42-.63.735-1.051.63-.316 0-.526-.105-.736-.315-.105.105-.42.315-.42.84z"
        fill="#112B46"
      />
      <path
        d="M40.909 183.998s-.315 1.574-.525 2.099c-.21.525-1.997 0-2.102-.42-.105-.315.63-2.834.63-2.834"
        fill="#FFE5C2"
      />
      <path
        d="M37.653 185.992c-.21.525-.525 1.68-.525 1.68l1.681.84.316-.21s.42.524.84.839c.42.21 1.156.525 1.577.63 1.471.315 2.627.105 2.732-.105.42-.84-1.471-1.259-1.892-1.364-1.05-.42-1.681-1.365-1.681-2.1 0-.42.105-.525-.21-.525 0 0-.21 0-.42.21-.316.315-.841.42-1.262.21a1.266 1.266 0 01-.525-.525c0-.105-.526-.105-.63.42z"
        fill="#112B46"
      />
      <path
        d="M46.587 142.638s-3.152-6.718 1.682-11.967c0 0 4.309 1.68 7.776 1.785 2.733.105 4.099-.315 4.099-.315s.84 4.934.84 12.807l-14.397-2.31z"
        fill="#233659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.26 145.788c-.105 0-.105-.105-.105-.21-1.156-4.933 3.573-9.342 7.146-8.293.63.21.946 2.1 1.156 3.465.105 1.364 1.261 15.116 1.577 17.635.105.42.105.84.105 1.155 0 .387-.038.735-.106 1.047-.005 1.076-.032 2.63-.064 4.477-.098 5.699-.245 14.181.065 19.984v.525s-.946.209-2.733.314h-.21c-.195-1.339-.495-2.959-.825-4.747-.913-4.941-2.061-11.16-1.907-16.247 0-1.05.105-1.994.315-2.939.019-.131.04-.262.066-.392-1.217-3.4-3.672-12.346-4.48-15.774z"
        fill="url(#SvgHonor6_linear)"
      />
      <path
        d="M56.045 159.645s.736 2.729.42 5.353c-.315 2.624 1.156-2.414 1.156-2.414l-.946-1.785-.63-1.154z"
        fill="#594A5F"
      />
      <path
        d="M59.407 185.887s-.736-7.978-1.682-13.541c-.736-4.304.105-7.978.526-9.448.105-.419.21-.629.21-.629l-.946-2.415c-1.051-3.149-1.471-10.497-1.471-10.497 2.101-5.249 2.207-11.757 2.207-11.757l-1.997.315-3.468 4.409-.63 3.359c.63 2.834 2.521 9.657 3.782 13.856 0 0 .736 2.73.42 5.354v.735c-.104 6.718 1.998 15.326 2.733 20.259h.316z"
        fill="url(#SvgHonor7_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.25 137.6c.21-5.564-6.516-1.575-9.774.42-1.366.839-1.786 2.414-1.891 3.884-.08 1.106-.754 9.823-1.217 15.232l-.043-.012s-.105 2.939-2.417 6.613l.015-.007c-.197.34-.378.693-.54 1.058-1.615 3.856-2.432 8.836-3.142 13.157-.352 2.148-.678 4.134-1.062 5.738 1.786.735 2.942.84 2.942.84l.21-.525c1.713-5.28 4.585-12.087 6.638-16.952.845-2.002 1.55-3.675 1.98-4.777.194-.456.303-.868.343-1.235.597-1.912 1.787-5.122 3.069-8.579 2.269-6.12 4.825-13.016 4.889-14.855z"
        fill="url(#SvgHonor8_linear)"
      />
      <path
        d="M57.307 102.748c0 .42 0 2.1-.841 2.415-.84.42-1.471.525-1.366.735 0 .105.105.315.21.629.21.63.525.735.525.735l.105 2.1-1.576 1.574-.525.525-.736-.735-2.522-2.309s1.05-1.26.315-2.624c-.105-.21-1.261-1.47-2.102-2.73-.525-.734-.946-1.469-1.05-1.994-.316-1.47-.631-2.94.21-3.884.84-.945 6.515-4.619 7.671-.21 0 0 1.261 2.414 1.787 2.834.42.42.315.945-.105 1.155-.42.105 0 1.365 0 1.784z"
        fill="#FFE5C2"
      />
      <path
        d="M55.835 109.362l-1.577 1.574-.525.21-.84-.42-2.523-2.309s1.051-1.26.315-2.624c-.105-.21-1.26-1.47-2.101-2.73l-.106-.839s1.787-.525 1.892-.525c.105 0 .84.84.84.944 0 .105-.104 1.05-.104 1.05s.21 1.365.525 2.31c.105.524.63.944 1.261.944h1.051c.63-.105.946-.42 1.156-.629.21.629.526.734.526.734l.21 2.31z"
        fill="#FED2A4"
      />
      <path
        d="M50.58 101.489s-.104 2.939 1.998 4.198c2.101 1.26 4.939-.105 5.36-.524.314-.42 1.26-3.045-.316-3.884-1.577-.84-3.048.629-3.048.629s-.84.945-1.26.945c-.316 0-1.367-.84-1.472-2.729 0-.105.105-.21.21-.21.42-.105 1.261-.63.84-2.414 0-.105.106-.315.211-.315.63-.105 1.786-.21 2.102-.21h.42c.105 0 .105 0 .105.105.105.21.316.525.316.63.105.105.525.944.525.944s.315-.21.21-.525c-.105-.315-.315-1.05-.315-1.154 0-.21.525-.42.525-.735 0-.84-.315-4.199-2.942-2.204-.316.314-.63.63-.946.734-.42.105-.84-.105-1.681-.105-1.262.105-6.411 1.26-4.73 5.984 1.682 4.619 4.414 5.353 4.414 5.353s-1.05-3.989-.525-4.513z"
        fill="url(#SvgHonor9_linear)"
      />
      <path
        d="M50.58 101.489s-.21-.84-.945-.525c-.946.315-.84 2.52 1.156 2.31 0-.105-.315-1.05-.21-1.785z"
        fill="#FED2A4"
      />
      <path
        d="M61.719 133.505s-7.041 1.784-15.974.105c.42-2.729.63-7.138.736-10.287v-.315c.105-1.995.105-3.464.105-3.464.105-.105-.21-6.509.315-9.028.105-.42.21-.84.42-.945 1.051-.84 2.313-1.784 3.679-2.414 0 0 3.152 3.464 4.834-.315 1.786-.21 2.732.21 3.258 1.994 2.732 9.973 2.627 24.669 2.627 24.669z"
        fill="url(#SvgHonor10_linear)"
      />
      <path
        d="M57.096 106.843s2.942 0 5.99-1.26c1.681 1.994 2.732 5.564 2.732 5.564s-4.098 2.624-7.04 2.834c-.631-4.304-1.682-7.138-1.682-7.138z"
        fill="url(#SvgHonor11_linear)"
      />
      <path
        opacity={0.5}
        d="M57.726 107.472s1.786 2.309 2.417 6.298c2.102-.42 5.254-2.204 5.675-2.624-.946.315-4.73 1.679-5.255.525-1.05-2.205-2.837-4.199-2.837-4.199z"
        fill="#343F9B"
      />
      <path
        d="M56.885 109.677l13.03 1.364-2.416 5.249-11.245-5.249c-.315-.105-.42-.524-.315-.734l.21-.21c.105-.315.42-.42.736-.42z"
        fill="#FED2A4"
      />
      <path
        d="M56.885 109.677l13.03 1.364-2.416 5.249-11.245-5.249c-.315-.105-.42-.524-.315-.734l.21-.21c.105-.315.42-.42.736-.42z"
        fill="url(#SvgHonor12_linear)"
      />
      <path
        d="M69.705 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.525-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="#FED2A4"
      />
      <path
        opacity={0.5}
        d="M69.705 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.525-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="#FED2A4"
      />
      <path
        d="M69.705 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.525-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="url(#SvgHonor13_linear)"
      />
      <path
        d="M45.324 119.543s1.366 4.724 4.94 6.928c1.05.63 2.311.315 3.152-.629 2.417-2.73 4.834-8.818 5.675-11.232-1.471-.735-1.892-.63-1.892-.63s-3.468 4.829-5.464 7.978l-1.471-2.625"
        fill="#FFE5C2"
      />
      <path
        d="M57.096 114.295s.63-1.05.63-1.785c0-.734-.315-2.099.42-2.834.316-.21 1.262-.63 2.418-.525 1.681.315 2.102.735 1.996 1.155-.105.315-1.26.42-1.576 1.05-.315.63-.105.944-.21 1.574-.105.945-1.471 1.365-1.787 1.89-.105.315-1.891-.525-1.891-.525z"
        fill="#FFE5C2"
      />
      <path
        opacity={0.5}
        d="M47.111 131.406c.42-1.47.63-6.404.63-7.663 1.157-.105 3.259-.945 4.52-2.94-.42-.839-1.577-3.569-3.784-5.878l-1.26-4.619-.316.315c-.525 2.519-.21 8.923-.315 9.028 0 .105-.105 1.469-.105 3.464v.315c-.105 3.149-.316 7.453-.736 10.287 8.933 1.68 15.974-.105 15.974-.105-8.933.735-15.028-.525-14.608-2.204z"
        fill="#343F9B"
      />
      <path
        d="M48.162 109.046s-3.678 2.415-3.783 6.509c-.105 3.149 1.997 7.768 1.997 7.768s3.678.42 5.57-2.52c-.526-1.469-1.052-2.729-2.102-4.409"
        fill="url(#SvgHonor14_linear)"
      />
      <path
        d="M158.298 139.508l.21-41.674-9.353-.105-.21 37.79v3.254c-.21 5.354-1.576 15.536-8.722 15.536l26.588.105c-6.936.105-8.303-9.342-8.513-14.906z"
        fill="url(#SvgHonor15_linear)"
      />
      <path
        d="M113.528 55.215c2.102 1.05 3.889 2.625 5.465 4.41 3.889 4.723 4.624 10.601 1.892 15.955-1.051 2.1-2.733 3.569-4.834 4.409-.946.42-1.997.63-3.048.63-3.573.105-7.146-1.785-8.828-4.514-1.366-2.415-1.366-5.144.105-7.663.946-1.68 3.048-2.204 4.73-1.26 1.681.945 2.206 3.044 1.261 4.724-.211.42-.211.525-.105.735.315.63 1.576 1.26 2.732 1.154 1.261 0 1.786-.734 1.997-1.26 0-.104.105-.21.105-.21 1.576-3.253.315-5.878-.841-7.557-.105-.21-.315-.42-.42-.525-2.838-3.36-7.672-5.039-11.56-3.884-4.414 1.365-7.357 6.193-8.197 13.751-1.682 14.487 8.512 24.354 14.817 28.868 6.726 4.933 13.452 7.558 18.076 8.503 2.627.524 4.519.629 5.254.209 1.787-.734 3.784 0 4.519 1.785.526 1.05.421 2.204-.21 3.149-.315.63-.946 1.155-1.576 1.47-.841.315-1.787.63-2.838.63-6.83.734-18.18-3.36-27.428-10.183-12.821-9.447-19.022-21.939-17.55-35.165 1.576-14.067 8.932-18.37 13.136-19.525 4.309-1.575 9.248-.84 13.346 1.364z"
        fill="url(#SvgHonor16_linear)"
      />
      <path
        opacity={0.5}
        d="M136.544 113.265c.525 1.05.42 2.204-.211 3.149-.315.63-.945 1.155-1.576 1.47-.841.315-1.787.629-2.837.629-1.997-2.204-3.679-4.618-5.255-7.243 2.627.525 4.519.63 5.255.21 1.891-.735 3.888.105 4.624 1.785z"
        fill="#EDBF5A"
      />
      <path
        d="M113.529 55.215c2.102 1.05 3.889 2.624 5.465 4.409 3.888 4.723 4.624 10.602 1.892 15.955-1.051 2.1-2.733 3.57-4.835 4.41-.42-2.73-.84-5.354-1.156-7.769 1.577-3.254.316-5.878-.84-7.558-.21-3.779-.421-7.033-.526-9.447z"
        fill="url(#SvgHonor17_linear)"
      />
      <path
        d="M192.873 55.215c-2.102 1.05-3.994 2.52-5.57 4.199-3.993 4.619-4.939 10.497-2.417 15.85a8.655 8.655 0 004.729 4.514c.946.42 1.997.63 3.048.735 3.573.21 7.251-1.47 9.037-4.304 1.472-2.309 1.577-5.143.211-7.663-.946-1.68-3.048-2.309-4.729-1.364-1.682.945-2.312 3.044-1.367 4.724.211.42.211.524.105.734-.42.525-1.576 1.155-2.732 1.05-1.261-.105-1.681-.84-1.997-1.364 0-.105-.105-.21-.105-.21-1.471-3.255-.21-5.984 1.051-7.454.21-.21.315-.42.421-.524 2.942-3.255 7.776-4.724 11.665-3.465 4.413 1.47 7.251 6.404 7.776 13.962 1.156 14.486-9.248 24.038-15.658 28.447-6.936 4.724-13.662 7.138-18.286 7.873-2.627.525-4.519.42-5.254.105-1.682-.84-3.784-.105-4.624 1.68-.526 1.05-.421 2.204.105 3.149.315.63.84 1.155 1.576 1.47.736.419 1.681.629 2.732.734 6.831.945 18.286-2.729 27.744-9.342 13.137-9.028 19.652-21.31 18.601-34.641-1.156-14.171-8.407-18.58-12.505-19.945-4.414-1.68-9.354-1.154-13.557 1.05z"
        fill="url(#SvgHonor18_linear)"
      />
      <path
        opacity={0.5}
        d="M168.176 112.53c-.526 1.05-.421 2.204.105 3.149.315.63.84 1.155 1.576 1.47.736.42 1.681.63 2.732.734 1.997-2.099 3.784-4.513 5.465-7.033-2.627.525-4.519.42-5.254.105-1.787-.944-3.889-.105-4.624 1.575z"
        fill="#EDBF5A"
      />
      <path
        d="M192.873 55.215c-2.102 1.05-3.994 2.52-5.57 4.199-3.993 4.618-4.939 10.497-2.417 15.85a8.655 8.655 0 004.729 4.514c.525-2.624.946-5.248 1.366-7.768-1.471-3.254-.21-5.983 1.051-7.453.42-3.779.736-6.928.841-9.342z"
        fill="url(#SvgHonor19_linear)"
      />
      <path
        d="M188.773 50.177s0 2.52-.21 6.613c-.841 16.166-5.255 57.525-29.005 69.282l-1.156.945a7.462 7.462 0 01-5.255 1.784h-1.156c-1.681-.105-3.257-.63-4.519-1.679l-1.366-1.155c-23.33-11.862-27.428-52.381-28.164-68.967-.21-4.409-.105-7.243-.105-7.243 0-3.989 4.729-5.039 4.729-5.039l61.688.315c-.21.105 4.519 1.155 4.519 5.144z"
        fill="url(#SvgHonor20_linear)"
      />
      <path
        d="M185.096 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="#F8BD36"
      />
      <path
        opacity={0.4}
        d="M188.773 50.177s0 2.52-.21 6.613c-1.156.735-2.523 1.26-3.994 1.26l-63.79-.315a7.06 7.06 0 01-2.943-.63c-.21-4.409-.105-7.243-.105-7.243 0-3.989 4.729-5.039 4.729-5.039l61.689.315c-.105 0 4.624 1.05 4.624 5.039z"
        fill="#FFD454"
      />
      <path
        d="M185.096 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="#FFB127"
      />
      <path
        d="M185.096 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="url(#SvgHonor21_linear)"
      />
      <path
        d="M183.099 189.999l-59.586-.314.105-24.669c0-3.884 3.153-6.928 7.041-6.928l45.609.21c3.889 0 6.936 3.149 6.936 7.033l-.105 24.668z"
        fill="url(#SvgHonor22_linear)"
      />
      <path
        d="M186.042 190l-65.366-.42a1.165 1.165 0 01-1.156-1.155v-2.834c0-.63.525-1.154 1.156-1.154l65.366.419c.631 0 1.156.525 1.156 1.155v2.834c-.105.63-.525 1.155-1.156 1.155z"
        fill="#FFB127"
      />
      <path
        d="M186.042 190l-65.366-.42a1.165 1.165 0 01-1.156-1.155v-2.834c0-.63.525-1.154 1.156-1.154l65.366.419c.631 0 1.156.525 1.156 1.155v2.834c-.105.63-.525 1.155-1.156 1.155z"
        fill="url(#SvgHonor23_linear)"
      />
      <path
        d="M168.702 180.237l-30.582-.105c-1.786 0-3.258-1.469-3.258-3.359v-8.608c0-1.784 1.472-3.254 3.363-3.254l30.582.21c1.786 0 3.257 1.47 3.257 3.359v8.608c0 1.679-1.576 3.149-3.362 3.149z"
        fill="url(#SvgHonor24_linear)"
      />
      <path
        d="M167.651 158.193l-28.165-.21a.828.828 0 01-.84-.84v-2.099c0-.42.315-.84.84-.84l28.165.21c.42 0 .84.315.84.84v2.099c-.105.525-.42.84-.84.84z"
        fill="#FFB127"
      />
      <path
        d="M167.651 158.193l-28.165-.21a.828.828 0 01-.84-.84v-2.099c0-.42.315-.84.84-.84l28.165.21c.42 0 .84.315.84.84v2.099c-.105.525-.42.84-.84.84z"
        fill="url(#SvgHonor25_linear)"
      />
      <path
        d="M133.181 64.663c-1.051 12.386-1.366 39.889 13.767 57.629.735.945-.421 2.205-1.367 1.365-8.302-6.718-19.652-19.735-22.909-42.619 0 0-1.156-9.132-.526-17.215.21-2.73 2.417-4.829 5.15-5.039 3.363-.315 6.2 2.52 5.885 5.879z"
        fill="url(#SvgHonor26_linear)"
      />
      <path
        d="M171.119 64.138c.42 12.386-.736 39.889-16.814 56.79-.841.839.315 2.204 1.261 1.469 8.722-6.193 20.597-18.685 25.116-41.359 0 0 1.577-9.028 1.367-17.215-.106-2.73-2.102-4.934-4.835-5.249-3.257-.42-6.2 2.204-6.095 5.564z"
        fill="url(#SvgHonor27_linear)"
      />
      <path
        d="M143.9 171.211l2.943.525-2.207 1.994.42 2.939-2.522-1.469-2.732 1.259.63-2.939-1.996-2.099 2.837-.315 1.471-2.52 1.156 2.625z"
        fill="url(#SvgHonor28_linear)"
      />
      <path
        d="M154.723 171.21l2.943.525-2.207 1.994.42 2.939-2.522-1.469-2.732 1.259.631-2.939-1.997-2.099 2.942-.315 1.366-2.52 1.156 2.625z"
        fill="url(#SvgHonor29_linear)"
      />
      <path
        d="M165.549 171.21l2.942.525-2.207 1.994.421 2.939-2.522-1.469-2.733 1.259.631-2.939-1.997-2.099 2.943-.315 1.366-2.52 1.156 2.625z"
        fill="url(#SvgHonor30_linear)"
      />
      <path
        d="M143.341 78.741c-.116-.14-.174-.325-.174-.557 0-.325.128-.58.383-.766l7.485-5.744c.047-.046.163-.14.348-.279.209-.139.418-.255.627-.348.209-.093.418-.139.627-.139h4.247c.255 0 .476.093.661.279a.903.903 0 01.279.661v22.49a.903.903 0 01-.279.66.902.902 0 01-.661.28h-4.561a.902.902 0 01-.661-.28.903.903 0 01-.279-.66V79.123l-4.142 3.133a.97.97 0 01-.592.21.862.862 0 01-.731-.384l-2.577-3.342z"
        fill="#fff"
      />
      <path
        d="M104.07 115.89s.42-3.359 1.892-4.619c1.891-1.575 5.78-1.155 7.356.315 1.681 1.68 2.522 5.249 1.156 7.768 2.837 1.679 2.627 5.249.315 5.563l.841 4.304c.525 2.73-1.366 5.249-4.309 5.879l-5.149 1.049s-8.933-.839-9.563-5.248c-.631-4.409 3.362-6.403 3.362-6.403s-1.891-3.674.106-6.299c1.996-2.519 3.993-2.309 3.993-2.309z"
        fill="url(#SvgHonor31_linear)"
      />
      <path
        d="M116.786 128.801s7.987 1.26 10.194 1.155c1.682.105 4.519-9.237 4.729-9.762 1.366-.84 1.682-1.995 1.682-2.625 0-.629-1.787-1.154-1.892-.839.315-.84.315-1.155.42-1.365.211-.63-.315-1.05-.945 1.26-.631 2.204-.631 1.889-.736 2.624-1.051 2.099-3.888 6.823-3.993 7.558-3.574-.105-9.669-1.47-9.669-1.47l.21 3.464zM104.491 128.906s-6.2 5.144-8.092 6.194c-1.261 1.049-9.458-5.144-10.089-5.459-1.681.105-2.312-.525-2.732-1.05-.42-.524.63-1.889.946-1.784-.736-.525-1.051-.735-1.261-.945-.526-.42-.42-1.05 1.576.42 1.997 1.47 1.681 1.155 2.312 1.785 2.207 1.049 7.461 3.464 8.092 3.884 2.837-1.995 6.831-6.509 6.831-6.509l2.417 3.464zM114.055 184.437s.315 1.47.315 2.729c-.21.84 0 1.05 0 1.05l3.363-.315s-1.471-.63-1.366-3.884c.105-3.254-2.312.42-2.312.42z"
        fill="#FFE5C2"
      />
      <path
        d="M114.369 187.376s1.471.524 2.942.105c1.682.734 2.943 1.049 4.519 1.154 1.577.21 1.261 1.365-1.366 1.155-2.627-.21-4.519 0-5.149 0-.631.105-1.577.105-1.577-.21-.105-.315.105-2.309.631-2.204z"
        fill="#112B46"
      />
      <path
        d="M115.315 142.972s2.207 19.63 2.312 22.15c.105 2.519.105 3.464 0 5.038-.105 1.575.946 14.696.946 14.696s-5.045 1.155-6.411.105c0 0 .21-14.276-.42-16.69-.631-2.415-3.153-14.801-3.153-14.801l2.942-12.702 3.784 2.204z"
        fill="url(#SvgHonor32_linear)"
      />
      <path
        d="M104.491 161.763s-.421 4.199-1.261 4.934c-.526 1.259-.526 1.259.315 1.784 1.261.735 3.783 2.204 4.309 1.575 2.312-2.31 2.417-3.15 2.942-6.089.21-.735 3.468-20.469 3.468-20.469s-10.299-1.47-10.404 1.364c0 2.834 1.156 13.647.631 16.901z"
        fill="url(#SvgHonor33_linear)"
      />
      <path
        d="M91.773 179.398s-1.05 1.155-2.102 1.89c-.84.42-.945.629-.945.629l2.312 2.31s-.316-1.47 2.522-3.359c2.837-1.89-1.787-1.47-1.787-1.47z"
        fill="#FFE5C2"
      />
      <path
        d="M89.461 181.393s.42 1.365 1.682 2.205c.42 1.679.945 2.729 1.681 3.988.84 1.26-.42 1.785-1.891-.314-1.472-2.1-2.733-3.36-3.153-3.779-.42-.42-1.051-1.05-.736-1.26.316-.21 2.207-1.26 2.417-.84z"
        fill="#112B46"
      />
      <path
        d="M103.649 166.067c-4.519 5.563-12.085 9.867-14.292 13.121-.42 1.05 4.624 3.674 4.624 3.674s10.404-9.342 12.506-11.442c.84-.839 1.681-1.469 2.312-2.309-1.997-.63-3.889-4.619-5.15-3.044z"
        fill="url(#SvgHonor34_linear)"
      />
      <path
        d="M113.318 131.531c-.315.735 1.997 10.602 2.418 12.806h-.106c-1.05-.105-7.146-.944-12.19 1.155l-.42-13.856v-.525-1.365c.105-1.679.42-4.409 1.471-6.193.525-.945 1.261-1.575 2.312-1.68 3.047-.315 3.047.945 5.99 1.68l4.624 1.155s-.525 3.569 0 4.723h-3.258c0-.21-.525 1.365-.841 2.1z"
        fill="url(#SvgHonor35_linear)"
      />
      <path
        d="M112.477 112.951s1.996 4.513.525 6.613c-1.156 1.575-3.048 1.575-3.048 1.575v2.204s-.21.21-.63.21c-.631 0-2.522-1.05-2.522-1.05v-8.818l5.675-.734z"
        fill="#FFE5C2"
      />
      <path
        d="M105.226 129.116l-2.312 2.31v-.525s0-4.934 1.576-7.558c.211-.105.526-.105.736-.105 2.522.21 2.312 3.569 0 5.878z"
        fill="url(#SvgHonor36_linear)"
      />
      <path
        d="M117.417 129.221s-2.207-.105-2.417-.524c-.21-.42-1.156-2.31-2.417-2.94 0 0 1.892 1.785 1.681 3.359h3.153v.105z"
        fill="url(#SvgHonor37_linear)"
      />
      <path
        d="M115.525 144.233c-1.051-.105-7.146-.945-12.191 1.154l-.42-13.856v-.525-1.364l1.156.315s0 12.176.631 12.911c.63.735 5.885.21 7.356.42 1.156.21 2.732.735 3.468.945z"
        fill="url(#SvgHonor38_linear)"
      />
      <path
        d="M98.71 127.752s3.993-5.144 6.516-5.039c2.522.21 2.312 3.464 0 5.878-2.312 2.31-3.153 3.255-3.153 3.255s-2.838-1.365-3.363-4.094z"
        fill="url(#SvgHonor39_linear)"
      />
      <path
        d="M112.687 112.426s-.946 4.094-3.048 5.143c-2.101 1.05-.525 3.674-2.522 5.669-.63.63-1.471.525-2.207.315-.945-.315-2.101-.105-2.837.63l-.21.21c-2.627-1.785 4.098-11.442 4.098-11.442l6.726-.525z"
        fill="url(#SvgHonor40_linear)"
      />
      <defs>
        <linearGradient
          id="SvgHonor0_linear"
          x1={125.316}
          y1={58.785}
          x2={125.316}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="SvgHonor1_linear"
          x1={214.85}
          y1={163.524}
          x2={233.1}
          y2={163.524}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor2_linear"
          x1={230.518}
          y1={163.201}
          x2={247.429}
          y2={163.201}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor3_linear"
          x1={223.294}
          y1={122.599}
          x2={231.864}
          y2={153.033}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor4_linear"
          x1={243.577}
          y1={114.879}
          x2={243.577}
          y2={119.458}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor5_linear"
          x1={248.246}
          y1={112.346}
          x2={248.246}
          y2={125.435}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor6_linear"
          x1={51.985}
          y1={161.532}
          x2={62.148}
          y2={161.532}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor7_linear"
          x1={52.16}
          y1={161.767}
          x2={59.413}
          y2={161.767}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor8_linear"
          x1={38.193}
          y1={159.666}
          x2={58.272}
          y2={159.666}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor9_linear"
          x1={52.452}
          y1={93.444}
          x2={52.452}
          y2={106.153}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor10_linear"
          x1={44.998}
          y1={110.992}
          x2={56.036}
          y2={135.657}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor11_linear"
          x1={56.688}
          y1={106.864}
          x2={58.812}
          y2={115.361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor12_linear"
          x1={62.908}
          y1={109.677}
          x2={62.908}
          y2={116.29}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgHonor13_linear"
          x1={68.693}
          y1={111.033}
          x2={68.693}
          y2={116.298}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#C5DCF4" />
        </linearGradient>
        <linearGradient
          id="SvgHonor14_linear"
          x1={44.021}
          y1={111.226}
          x2={50.096}
          y2={123.619}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor15_linear"
          x1={153.517}
          y1={97.729}
          x2={153.517}
          y2={154.415}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor16_linear"
          x1={111.909}
          y1={53.009}
          x2={111.909}
          y2={118.808}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor17_linear"
          x1={118.025}
          y1={55.215}
          x2={118.025}
          y2={79.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor18_linear"
          x1={193.438}
          y1={53.174}
          x2={193.438}
          y2={118.24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor19_linear"
          x1={188.181}
          y1={55.215}
          x2={188.181}
          y2={79.778}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor20_linear"
          x1={153.292}
          y1={44.718}
          x2={153.292}
          y2={128.812}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1D8" />
          <stop offset={1} stopColor="#FFDE8C" />
        </linearGradient>
        <linearGradient
          id="SvgHonor21_linear"
          x1={153.253}
          y1={40.939}
          x2={153.253}
          y2={55.428}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor22_linear"
          x1={153.358}
          y1={158.088}
          x2={153.358}
          y2={189.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor23_linear"
          x1={153.359}
          y1={184.437}
          x2={153.359}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor24_linear"
          x1={153.463}
          y1={164.911}
          x2={153.778}
          y2={192.204}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDF0D8" />
          <stop offset={1} stopColor="#F9D478" />
          <stop offset={1} stopColor="#FFF3D6" />
        </linearGradient>
        <linearGradient
          id="SvgHonor25_linear"
          x1={153.568}
          y1={154.204}
          x2={153.568}
          y2={158.193}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor26_linear"
          x1={134.569}
          y1={58.76}
          x2={134.569}
          y2={123.933}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor27_linear"
          x1={168.041}
          y1={58.53}
          x2={168.041}
          y2={122.607}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor28_linear"
          x1={142.639}
          y1={168.586}
          x2={142.639}
          y2={176.669}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor29_linear"
          x1={153.462}
          y1={168.585}
          x2={153.462}
          y2={176.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor30_linear"
          x1={164.288}
          y1={168.585}
          x2={164.288}
          y2={176.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor31_linear"
          x1={106.584}
          y1={110.334}
          x2={106.584}
          y2={136.037}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor32_linear"
          x1={108.596}
          y1={163.106}
          x2={118.581}
          y2={163.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor33_linear"
          x1={102.876}
          y1={156.676}
          x2={114.274}
          y2={156.676}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor34_linear"
          x1={108.589}
          y1={162.183}
          x2={91.796}
          y2={181.097}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor35_linear"
          x1={102.346}
          y1={125.433}
          x2={111.532}
          y2={146.958}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor36_linear"
          x1={102.721}
          y1={124.486}
          x2={106.305}
          y2={131.443}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor37_linear"
          x1={112.357}
          y1={126.286}
          x2={113.027}
          y2={129.885}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor38_linear"
          x1={102.325}
          y1={132.043}
          x2={107.289}
          y2={147.361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor39_linear"
          x1={98.321}
          y1={124.104}
          x2={100.906}
          y2={133.188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor40_linear"
          x1={106.989}
          y1={112.455}
          x2={106.989}
          y2={124.341}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={276}
      height={190}
      viewBox="0 0 276 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.268 190S-.493 169.784.478 134.014c.972-35.77 39.945-41.365 66.611-19.311 26.774 22.054 40.481 11.265 54.3-7.388 13.819-18.653 35.09-60.86 73.847-45.06 28.933 11.74 48.258 43.231 54.519 71.649 4.319 19.531-1.943 40.159-16.949 52.996-.54.439-2.361 2.771-2.901 3.1H41.268z"
        fill="url(#SvgHonor0_linear)"
      />
      <path
        d="M274.78 141.348c.211-.105 0-.315-.21-.315h-.21c.42-.105.631-.105.736-.21.21-.105 0-.315-.105-.42.105 0 .21-.105.315-.105.315-.105.105-.315-.21-.42h-.421c.105 0 .211 0 .211-.105.21-.104.105-.314-.106-.314h-.42c-.21 0-.525 0-.736.105-.21 0-1.261.105-1.471-.42-.315-.735 1.051-.63 1.156-1.155 0-.21-.946-.105-1.576.105-.526.105-.841.21-1.156.42-.315.105-.736.42-.946.525h-.841l-.21 1.889s1.156 0 1.471.21c.736.42 1.472.63 2.312.63.526 0 1.051-.105 1.577-.21.105 0 .735-.21.84-.21z"
        fill="#FFE1BD"
      />
      <path
        d="M245.039 181.658s1.156 1.574 1.681 2.099c.526.525 2.207-1.05 2.207-1.47-.105-.42-2.207-2.939-2.207-2.939"
        fill="#FFE5C2"
      />
      <path
        d="M249.769 182.392c.525.525 1.366 1.68 1.366 1.68l-1.471 1.784-.526-.105s-.105.84-.63 1.365c-.315.42-1.156 1.154-1.576 1.469-1.577 1.05-2.943 1.365-3.153 1.155-.946-.735 1.156-2.204 1.471-2.519 1.051-.945 1.156-2.415.946-3.254-.21-.525-.315-.63-.105-.735 0 0 .21-.105.63 0 .526.21 1.156.105 1.472-.42.21-.315.315-.63.315-.945.21.105.736 0 1.261.525z"
        fill="#112B46"
      />
      <path
        d="M216.243 183.232s-.525 1.889-.525 2.624c0 .735 2.207 1.05 2.522.735.315-.315.841-3.569.841-3.569"
        fill="#FFE5C2"
      />
      <path
        d="M218.767 187.326c-.105.734-.421 2.099-.421 2.099l-2.312.105-.21-.42s-.735.42-1.471.42a14.27 14.27 0 01-2.102-.21c-1.786-.525-2.942-1.365-2.942-1.679 0-1.26 2.417-.525 2.837-.42 1.471.21 2.627-.63 3.048-1.365.315-.525.21-.63.525-.525 0 0 .21.105.421.42.21.525.735.945 1.261.945.315 0 .63-.105.945-.315 0-.105.526.21.421.945z"
        fill="#112B46"
      />
      <path
        d="M226.544 142.188s-6.621 16.691-7.357 20.365c-.841 3.569-3.888 21.729-3.888 21.729s2.837.84 3.993.42c.736-2.939 8.197-25.928 13.032-32.122 4.729-6.193-5.78-10.392-5.78-10.392z"
        fill="url(#SvgHonor1_linear)"
      />
      <path
        d="M231.167 151.426s-1.471 8.083 3.574 16.375c5.044 8.293 10.193 14.382 10.193 14.382s2.207-1.05 2.943-2.205c-1.576-2.099-8.407-13.856-8.407-18.475 0-4.619 1.786-15.536 1.786-15.536l-7.566-1.784-2.523 7.243z"
        fill="url(#SvgHonor2_linear)"
      />
      <path
        d="M268.79 140.928s-8.618.42-13.137-.84c-4.414-1.259-12.085-6.718-12.085-6.718l-8.933-6.823c-2.312-2.834-5.254-11.022-5.675-12.492-.42-1.469-2.102-11.652-2.102-11.652l1.892-.21s2.207 7.138 3.363 11.232c1.051 3.779 6.936 9.028 8.302 10.288.105.105.21.105.21.21.841.734.21 2.204.21 2.204l1.577.525s1.681-.84 2.627.105l.315.315c1.367 1.469 4.835 6.928 11.771 9.657 5.044 1.26 10.614 2.1 11.98 2.205-.21 1.469-.315 1.994-.315 1.994z"
        fill="#FFE5C2"
      />
      <path
        d="M245.354 127.176c-.315.525-2.101 1.785-3.678 1.155-3.153-1.469-1.366-4.304-1.261-4.514.105.105.21.105.21.21.841.735 1.577-1.049 1.577-1.049l3.152 1.784s-1.366 1.26-.315 2.1l.315.314z"
        fill="#FFE5C2"
      />
      <path
        d="M245.67 137.044s-2.837 6.509-3.888 10.918c-7.777 1.889-12.926-1.68-15.238-3.989-.946-.945-1.472-1.68-1.472-1.68s2.102-3.779 3.363-6.613c1.261-2.834 3.153-6.509 4.519-9.028.105-.42-1.156-2.309-2.522-5.143 1.786-.735 4.519-3.57 4.519-3.57l5.359 5.984s-1.576 2.624.421 3.989c2.102 1.364 3.468.21 4.519-.63.84 1.155 5.254 5.144 7.987 7.033l-3.468 5.879c.105-.105-3.048-1.89-4.099-3.15z"
        fill="url(#SvgHonor3_linear)"
      />
      <path
        d="M244.303 114.895c-.421-.105-.946.105-1.261.42-.316.315-.421.84-.316 1.26-.315-.21-.84 0-.945.42-.211.315-.106.735 0 1.155.105.419.21.944.63 1.154.315.21.631.21.946.105.21 0 .42-.105.63-.315.211-.105.421-.42.526-.629.525-.735 1.156-1.47 1.681-2.31a.803.803 0 00.211-.525"
        fill="url(#SvgHonor4_linear)"
      />
      <path
        d="M248.508 123.609c-.42.419-.946.839-1.471 1.259-.736.525-1.576.735-2.417.21-.946-.525-1.997-.945-2.838-1.679-.945-.84-1.05-1.575-.525-2.625.315-.735.631-1.364.946-2.099.63-1.47 1.471-2.834 2.312-4.199.21-.315.42-.735.841-.525a29.712 29.712 0 013.993 2.204c.946.63 1.787 1.365 2.207 2.31.42 1.05.105 2.099-.105 3.254 0 .21-.105.42-.315.42"
        fill="#FFE5C2"
      />
      <path
        d="M248.402 121.299c.316-.21.736 0 .841.419.105.315-.105.735-.315 1.05-.21.42-.631.63-1.051.84-.421.21-.841.21-.946-.21-.42.525-.21 1.26.21 1.575.421.314.946.21 1.366-.105 1.367 1.679 3.468-.42 4.309-1.68.736-.945 1.156-2.309 1.156-3.464 0-.63-.21-1.26-.63-1.785.315-.629.21-1.469-.106-2.204-.315-.63-.735-1.155-1.366-1.68-.315-.314-1.576-.734-1.996-.419-.421-.84-1.367-.84-1.997-1.155-.631-.315-1.577-.21-1.682.525-.735-.84-2.417-.42-2.732.63-.315 1.049.841 2.309 1.892 2.099-.105.735.315 1.575 1.051 1.994.63.42 1.471.525 2.206.525-.42.21-.735.63-.84 1.155-.105.525-.105.945.525 1.155 0 0-.63.84-.315.944.105.105.42-.209.42-.209z"
        fill="url(#SvgHonor5_linear)"
      />
      <path
        d="M225.703 96.84c.105-.21.315-.105.421.105v.21c0-.42.105-.735.105-.735.105-.21.315-.105.42.105v-.315c.105-.315.315-.105.42.105v.21c.105-.21.316-.105.316.105v1.154c0 .21.105 1.26.63 1.47.736.315.421-1.155 1.051-1.365.21-.105.21.945.105 1.575s-.105.84-.21 1.155c-.105.315-.315.84-.315 1.049 0 .21.105.84.105.84l-1.892.42s-.21-1.155-.42-1.365a4.167 4.167 0 01-.946-2.204c-.105-.525 0-1.05 0-1.575.105-.105.21-.84.21-.944z"
        fill="#FFE5C2"
      />
      <path
        d="M241.782 147.961c-7.777 1.89-12.926-1.679-15.238-3.988 8.092 6.193 13.557 3.359 14.397 3.149.211-1.89 3.994-10.812 3.994-10.812s.63.734.735.839c0-.105-2.837 6.404-3.888 10.812z"
        fill="#5F52DD"
      />
      <path
        d="M118.508 0c1.051 1.47 2.417 1.785 2.417 1.785-.84.63-.84 1.05-.84 1.994-.841-.315-1.682-.945-2.207-1.785-.316-.84 0-1.47.63-1.994zM178.622 1.05c1.051 1.47 2.417 1.784 2.417 1.784-.84.63-.84 1.05-.84 1.995-.841-.315-1.682-.945-2.207-1.785-.315-.84 0-1.47.63-1.994z"
        fill="#F9D04E"
      />
      <path
        d="M78.89 16.795c1.471 1.05 2.943.735 2.943.735-.526.84-.316 1.26-.105 2.1-.946 0-1.892-.315-2.733-.84-.525-.63-.525-1.365-.105-1.995z"
        fill="#B0AFFD"
      />
      <path
        d="M97.07 32.436c1.576-.84 1.996-2.204 1.996-2.204.525.84.946.945 1.892 1.05a4.584 4.584 0 01-1.997 1.994c-.946.21-1.471-.105-1.892-.84z"
        fill="#F9D04E"
      />
      <path
        d="M126.916 20.89c1.787.42 2.943-.42 2.943-.42-.211 1.05.105 1.26.735 1.994-.946.315-1.891.42-2.837.21-.736-.315-.946-.945-.841-1.784zM176.835 21.205c.946 1.574 2.312 1.89 2.312 1.89-.841.524-.841.944-.946 1.889a5.19 5.19 0 01-2.102-1.89c-.315-.84 0-1.47.736-1.89zM235.16 56.685c.946 1.575 2.312 1.89 2.312 1.89-.841.524-.841.944-.946 1.89a5.19 5.19 0 01-2.102-1.89c-.21-.735.105-1.365.736-1.89z"
        fill="#B0AFFD"
      />
      <path
        d="M226.333 36.11c.946 1.575 2.312 1.89 2.312 1.89-.841.525-.841.945-.946 1.89a5.19 5.19 0 01-2.102-1.89c-.315-.84 0-1.47.736-1.89zM204.368 37.475c1.787.21 2.943-.63 2.943-.63-.105 1.05.21 1.26.945 1.89-.84.42-1.786.63-2.837.525-.841-.42-1.156-.945-1.051-1.785zM191.337 79.57c1.681-.526 2.417-1.785 2.417-1.785.315.944.736 1.05 1.576 1.364-.63.735-1.366 1.26-2.312 1.575-.84.105-1.366-.315-1.681-1.155z"
        fill="#F9D04E"
      />
      <path
        d="M208.467 8.818c1.682.63 3.048.105 3.048.105-.315.945-.105 1.26.42 2.1-.946.21-1.891.104-2.837-.21-.841-.526-.946-1.26-.631-1.995z"
        fill="#B0AFFD"
      />
      <path
        d="M150.142 8.818c1.681.735 3.048.105 3.048.105-.316.945-.105 1.26.42 2.1-.946.21-1.892.104-2.837-.21-.841-.526-.946-1.26-.631-1.995zM57.136 62.248c1.681.735 3.047.105 3.047.105-.315.945-.105 1.26.42 2.1-.945.21-1.891.105-2.837-.21-.735-.525-.84-1.26-.63-1.994zM78.469 89.541c.105 1.785 1.156 2.73 1.156 2.73-1.051.104-1.261.524-1.681 1.259-.631-.735-.946-1.68-1.051-2.624.105-.84.735-1.26 1.576-1.365z"
        fill="#F9D04E"
      />
      <path
        d="M72.794 47.867C74.475 47.133 75 45.873 75 45.873c.42.945.84.945 1.786 1.155-.525.84-1.26 1.47-2.207 1.89-.84.104-1.366-.316-1.786-1.05zM219.082 85.658c1.787-.315 2.627-1.47 2.627-1.47.21.945.631 1.155 1.366 1.575a5.725 5.725 0 01-2.522 1.26c-.84 0-1.261-.526-1.471-1.365z"
        fill="#B0AFFD"
      />
      <path
        d="M77.104 89.942c0-.21-.316-.105-.42.105v-.63c0-.21-.316-.105-.421 0v-.315c0-.315-.315-.21-.42.105 0 .105 0 .21-.106.42v-.21c0-.21-.315-.21-.42 0 0 .105 0 .21-.105.525 0 .21-.105.524-.105.734 0 .21-.315 1.26-.84 1.365-.841.21-.316-1.26-.841-1.575-.21-.105-.42 1.05-.42 1.575v1.26c0 .315.21.84.21 1.154 0 .315-.421 1.05-.421 1.05l2.102.42s.525-1.155.735-1.365a4.67 4.67 0 001.261-2.204c.106-.525.21-1.155.21-1.68v-.734z"
        fill="#FFE5C2"
      />
      <path
        d="M57.662 107.157s6.516-.105 10.089-3.989c2.837-3.989 5.57-8.398 5.78-8.712.525.21 1.786.734 1.786.734s-3.783 8.398-5.15 10.288c-1.47 1.889-4.308 5.038-10.403 6.613-2.943-3.464-2.102-4.934-2.102-4.934zM61.97 185.152s.42.84.315 1.47c0 .525-1.892.735-2.102.42-.21-.315-.42-2.939-.42-2.939"
        fill="#FFE5C2"
      />
      <path
        d="M59.764 187.462c0 .629.21 1.574.21 1.574l2.102-.105.105-.315s.735.315 1.26.315l1.787-.105c1.262-.105 1.997-.21 2.102-.525.105-.944-1.471-.839-1.891-.839-1.156 0-2.417-.42-2.733-1.05-.21-.42-.105-.525-.42-.42 0 0-.21.105-.315.315-.21.42-.631.735-1.051.63-.316 0-.526-.105-.736-.315-.105.105-.42.315-.42.84z"
        fill="#112B46"
      />
      <path
        d="M41.37 183.998s-.314 1.574-.524 2.099c-.21.525-1.997 0-2.102-.42-.105-.315.63-2.834.63-2.834"
        fill="#FFE5C2"
      />
      <path
        d="M38.115 185.992c-.21.525-.525 1.68-.525 1.68l1.681.84.316-.21s.42.524.84.839c.42.21 1.156.525 1.577.63 1.47.315 2.627.105 2.732-.105.42-.84-1.471-1.259-1.892-1.364-1.05-.42-1.681-1.365-1.681-2.1 0-.42.105-.525-.21-.525 0 0-.21 0-.42.21-.316.315-.841.42-1.262.21a1.266 1.266 0 01-.525-.525c0-.105-.526-.105-.63.42z"
        fill="#112B46"
      />
      <path
        d="M47.05 142.638s-3.153-6.718 1.68-11.967c0 0 4.31 1.68 7.777 1.785 2.733.105 4.099-.315 4.099-.315s.84 4.934.84 12.807l-14.397-2.31z"
        fill="#233659"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.722 145.788c-.105 0-.105-.105-.105-.21-1.156-4.933 3.573-9.342 7.146-8.293.63.21.946 2.1 1.156 3.465.105 1.364 1.261 15.116 1.577 17.635.105.42.105.84.105 1.155 0 .387-.038.735-.106 1.047-.005 1.076-.032 2.63-.064 4.477-.098 5.699-.245 14.181.065 19.984v.525s-.946.209-2.733.314h-.21c-.195-1.339-.495-2.959-.825-4.747-.913-4.941-2.062-11.16-1.907-16.247 0-1.05.105-1.994.315-2.939.019-.131.04-.262.066-.392-1.217-3.4-3.672-12.346-4.48-15.774z"
        fill="url(#SvgHonor6_linear)"
      />
      <path
        d="M56.507 159.645s.736 2.729.42 5.353c-.315 2.624 1.156-2.414 1.156-2.414l-.946-1.785-.63-1.154z"
        fill="#594A5F"
      />
      <path
        d="M59.868 185.887s-.735-7.978-1.681-13.541c-.736-4.304.105-7.978.525-9.448.105-.419.21-.629.21-.629l-.945-2.415c-1.051-3.149-1.471-10.497-1.471-10.497 2.101-5.249 2.206-11.757 2.206-11.757l-1.996.315-3.468 4.409-.63 3.359c.63 2.834 2.521 9.657 3.782 13.856 0 0 .736 2.73.42 5.354v.735c-.104 6.718 1.998 15.326 2.733 20.259h.315z"
        fill="url(#SvgHonor7_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.712 137.6c.21-5.564-6.516-1.575-9.774.42-1.366.839-1.786 2.414-1.892 3.884-.079 1.106-.753 9.823-1.216 15.232a2.278 2.278 0 00-.043-.012s-.105 2.939-2.417 6.613l.015-.007c-.197.34-.378.693-.54 1.058-1.615 3.856-2.432 8.836-3.142 13.157-.353 2.148-.678 4.134-1.062 5.738 1.786.735 2.942.84 2.942.84l.21-.525c1.713-5.28 4.585-12.087 6.638-16.952.845-2.002 1.55-3.675 1.98-4.777.193-.456.303-.868.343-1.235.597-1.912 1.787-5.122 3.069-8.579 2.269-6.12 4.825-13.016 4.889-14.855z"
        fill="url(#SvgHonor8_linear)"
      />
      <path
        d="M57.769 102.748c0 .42 0 2.1-.841 2.415-.84.42-1.471.525-1.366.735 0 .105.105.315.21.629.21.63.525.735.525.735l.105 2.1-1.576 1.574-.526.525-.735-.735-2.522-2.309s1.05-1.26.315-2.624c-.105-.21-1.261-1.47-2.102-2.73-.525-.734-.946-1.469-1.05-1.994-.316-1.47-.631-2.94.21-3.884.84-.945 6.515-4.619 7.671-.21 0 0 1.261 2.414 1.787 2.834.42.42.315.945-.105 1.155-.42.105 0 1.365 0 1.784z"
        fill="#FFE5C2"
      />
      <path
        d="M56.297 109.362l-1.577 1.574-.525.21-.84-.42-2.523-2.309s1.051-1.26.315-2.624c-.105-.21-1.26-1.47-2.102-2.73l-.105-.839s1.787-.525 1.892-.525c.105 0 .84.84.84.944 0 .105-.104 1.05-.104 1.05s.21 1.365.525 2.31c.105.524.63.944 1.261.944h1.051c.63-.105.946-.42 1.156-.629.21.629.526.734.526.734l.21 2.31z"
        fill="#FED2A4"
      />
      <path
        d="M51.043 101.489s-.105 2.939 1.996 4.198c2.102 1.26 4.94-.105 5.36-.524.315-.42 1.261-3.045-.315-3.884-1.577-.84-3.048.629-3.048.629s-.84.945-1.26.945c-.316 0-1.367-.84-1.472-2.729 0-.105.105-.21.21-.21.42-.105 1.261-.63.84-2.414 0-.105.106-.315.21-.315.631-.105 1.787-.21 2.103-.21h.42c.105 0 .105 0 .105.105.105.21.315.525.315.63.105.105.526.944.526.944s.315-.21.21-.525c-.105-.315-.315-1.05-.315-1.154 0-.21.525-.42.525-.735 0-.84-.315-4.199-2.942-2.204-.316.314-.63.63-.946.734-.42.105-.84-.105-1.682-.105-1.26.105-6.41 1.26-4.729 5.984 1.682 4.619 4.414 5.353 4.414 5.353s-1.05-3.989-.525-4.513z"
        fill="url(#SvgHonor9_linear)"
      />
      <path
        d="M51.043 101.489s-.21-.84-.946-.525c-.946.315-.841 2.52 1.156 2.31 0-.105-.316-1.05-.21-1.785z"
        fill="#FED2A4"
      />
      <path
        d="M62.18 133.505s-7.04 1.784-15.973.105c.42-2.729.63-7.138.736-10.287v-.315c.105-1.995.105-3.464.105-3.464.105-.105-.21-6.509.315-9.028.105-.42.21-.84.42-.945 1.051-.84 2.312-1.784 3.679-2.414 0 0 3.152 3.464 4.834-.315 1.786-.21 2.732.21 3.258 1.994 2.732 9.973 2.627 24.669 2.627 24.669z"
        fill="url(#SvgHonor10_linear)"
      />
      <path
        d="M57.558 106.843s2.942 0 5.99-1.26c1.681 1.994 2.732 5.564 2.732 5.564s-4.098 2.624-7.04 2.834c-.632-4.304-1.682-7.138-1.682-7.138z"
        fill="url(#SvgHonor11_linear)"
      />
      <path
        opacity={0.5}
        d="M58.188 107.472s1.786 2.309 2.417 6.298c2.101-.42 5.254-2.204 5.675-2.624-.946.315-4.73 1.679-5.255.525-1.051-2.205-2.837-4.199-2.837-4.199z"
        fill="#343F9B"
      />
      <path
        d="M57.346 109.677l13.032 1.364-2.417 5.249-11.245-5.249c-.315-.105-.42-.524-.315-.734l.21-.21c.105-.315.42-.42.736-.42z"
        fill="#FED2A4"
      />
      <path
        d="M57.346 109.677l13.032 1.364-2.417 5.249-11.245-5.249c-.315-.105-.42-.524-.315-.734l.21-.21c.105-.315.42-.42.736-.42z"
        fill="url(#SvgHonor12_linear)"
      />
      <path
        d="M70.167 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.526-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="#FED2A4"
      />
      <path
        opacity={0.5}
        d="M70.167 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.526-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="#FED2A4"
      />
      <path
        d="M70.167 113.98c-.736 1.47-1.681 2.415-2.312 2.31-.526-.21-.42-1.47.21-2.939.736-1.47 1.682-2.415 2.312-2.31.63.21.526 1.575-.21 2.939z"
        fill="url(#SvgHonor13_linear)"
      />
      <path
        d="M45.786 119.543s1.366 4.724 4.94 6.928c1.05.63 2.311.315 3.152-.629 2.417-2.73 4.834-8.818 5.675-11.232-1.471-.735-1.892-.63-1.892-.63s-3.468 4.829-5.464 7.978l-1.472-2.625"
        fill="#FFE5C2"
      />
      <path
        d="M57.558 114.295s.63-1.05.63-1.785c0-.734-.315-2.099.42-2.834.316-.21 1.262-.63 2.418-.525 1.681.315 2.101.735 1.996 1.155-.105.315-1.26.42-1.576 1.05-.315.63-.105.944-.21 1.574-.105.945-1.471 1.365-1.787 1.89-.105.315-1.891-.525-1.891-.525z"
        fill="#FFE5C2"
      />
      <path
        opacity={0.5}
        d="M47.573 131.406c.42-1.47.63-6.404.63-7.663 1.157-.105 3.259-.945 4.52-2.94-.42-.839-1.577-3.569-3.784-5.878l-1.26-4.619-.316.315c-.525 2.519-.21 8.923-.315 9.028 0 .105-.105 1.469-.105 3.464v.315c-.105 3.149-.316 7.453-.736 10.287 8.933 1.68 15.974-.105 15.974-.105-8.933.735-15.028-.525-14.608-2.204z"
        fill="#343F9B"
      />
      <path
        d="M48.624 109.046s-3.678 2.415-3.783 6.509c-.105 3.149 1.996 7.768 1.996 7.768s3.679.42 5.57-2.52c-.525-1.469-1.05-2.729-2.102-4.409"
        fill="url(#SvgHonor14_linear)"
      />
      <path
        d="M158.76 139.508l.21-41.674-9.353-.105-.21 37.79v3.254c-.21 5.354-1.576 15.536-8.722 15.536l26.588.105c-6.936.105-8.303-9.342-8.513-14.906z"
        fill="url(#SvgHonor15_linear)"
      />
      <path
        d="M113.99 55.215c2.102 1.05 3.889 2.625 5.465 4.41 3.888 4.723 4.624 10.601 1.892 15.955-1.051 2.1-2.733 3.569-4.834 4.409-.946.42-1.997.63-3.048.63-3.573.105-7.146-1.785-8.828-4.514-1.366-2.415-1.366-5.144.105-7.663.946-1.68 3.048-2.204 4.729-1.26 1.682.945 2.207 3.044 1.262 4.724-.211.42-.211.525-.106.735.316.63 1.577 1.26 2.733 1.154 1.261 0 1.786-.734 1.997-1.26 0-.104.105-.21.105-.21 1.576-3.253.315-5.878-.841-7.557-.105-.21-.315-.42-.42-.525-2.838-3.36-7.672-5.039-11.56-3.884-4.414 1.365-7.357 6.193-8.198 13.751-1.681 14.487 8.513 24.354 14.818 28.868 6.726 4.933 13.452 7.558 18.076 8.503 2.627.524 4.519.629 5.254.209 1.787-.734 3.784 0 4.519 1.785.526 1.05.421 2.204-.21 3.149-.315.63-.946 1.155-1.576 1.47-.841.315-1.787.63-2.838.63-6.831.734-18.18-3.36-27.428-10.183-12.821-9.447-19.022-21.939-17.55-35.165 1.576-14.067 8.932-18.37 13.136-19.525 4.309-1.575 9.248-.84 13.346 1.364z"
        fill="url(#SvgHonor16_linear)"
      />
      <path
        opacity={0.5}
        d="M137.005 113.265c.526 1.05.421 2.204-.21 3.149-.315.63-.946 1.155-1.576 1.47-.841.315-1.787.629-2.838.629-1.996-2.204-3.678-4.618-5.254-7.243 2.627.525 4.519.63 5.254.21 1.892-.735 3.889.105 4.624 1.785z"
        fill="#EDBF5A"
      />
      <path
        d="M113.991 55.215c2.102 1.05 3.889 2.624 5.465 4.409 3.888 4.723 4.624 10.602 1.892 15.955-1.051 2.1-2.733 3.57-4.835 4.41-.42-2.73-.84-5.354-1.156-7.769 1.577-3.254.316-5.878-.84-7.558-.211-3.779-.421-7.033-.526-9.447z"
        fill="url(#SvgHonor17_linear)"
      />
      <path
        d="M193.335 55.215c-2.102 1.05-3.994 2.52-5.57 4.199-3.993 4.619-4.939 10.497-2.417 15.85a8.655 8.655 0 004.729 4.514c.946.42 1.997.63 3.048.735 3.573.21 7.251-1.47 9.037-4.304 1.472-2.309 1.577-5.143.211-7.663-.946-1.68-3.048-2.309-4.73-1.364-1.681.945-2.312 3.044-1.366 4.724.21.42.21.524.105.734-.42.525-1.576 1.155-2.732 1.05-1.261-.105-1.681-.84-1.997-1.364 0-.105-.105-.21-.105-.21-1.471-3.255-.21-5.984 1.051-7.454.21-.21.315-.42.42-.524 2.943-3.255 7.777-4.724 11.666-3.465 4.413 1.47 7.251 6.404 7.776 13.962 1.156 14.486-9.248 24.038-15.658 28.447-6.936 4.724-13.662 7.138-18.286 7.873-2.627.525-4.519.42-5.255.105-1.681-.84-3.783-.105-4.624 1.68-.525 1.05-.42 2.204.106 3.149.315.63.84 1.155 1.576 1.47.736.419 1.681.629 2.732.734 6.831.945 18.286-2.729 27.744-9.342 13.137-9.028 19.652-21.31 18.601-34.641-1.156-14.171-8.407-18.58-12.506-19.945-4.413-1.68-9.353-1.154-13.556 1.05z"
        fill="url(#SvgHonor18_linear)"
      />
      <path
        opacity={0.5}
        d="M168.637 112.53c-.525 1.05-.42 2.204.106 3.149.315.63.84 1.155 1.576 1.47.736.42 1.681.63 2.732.734 1.997-2.099 3.784-4.513 5.465-7.033-2.627.525-4.519.42-5.255.105-1.786-.944-3.888-.105-4.624 1.575z"
        fill="#EDBF5A"
      />
      <path
        d="M193.335 55.215c-2.102 1.05-3.994 2.52-5.57 4.199-3.994 4.618-4.939 10.497-2.417 15.85a8.655 8.655 0 004.729 4.514c.525-2.624.946-5.248 1.366-7.768-1.471-3.254-.21-5.983 1.051-7.453.42-3.779.736-6.928.841-9.342z"
        fill="url(#SvgHonor19_linear)"
      />
      <path
        d="M189.235 50.177s0 2.52-.21 6.613c-.841 16.166-5.255 57.525-29.005 69.282l-1.156.945a7.462 7.462 0 01-5.255 1.784h-1.156c-1.681-.105-3.258-.63-4.519-1.679l-1.366-1.155c-23.33-11.862-27.429-52.381-28.164-68.967-.21-4.409-.105-7.243-.105-7.243 0-3.989 4.729-5.039 4.729-5.039l61.688.315c-.21.105 4.519 1.155 4.519 5.144z"
        fill="url(#SvgHonor20_linear)"
      />
      <path
        d="M185.558 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="#F8BD36"
      />
      <path
        opacity={0.4}
        d="M189.235 50.177s0 2.52-.211 6.613c-1.156.735-2.522 1.26-3.993 1.26l-63.79-.315a7.06 7.06 0 01-2.943-.63c-.21-4.409-.105-7.243-.105-7.243 0-3.989 4.729-5.039 4.729-5.039l61.689.315c-.105 0 4.624 1.05 4.624 5.039z"
        fill="#FFD454"
      />
      <path
        d="M185.558 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="#FFB127"
      />
      <path
        d="M185.558 55.425l-63.79-.315c-3.889 0-7.041-3.254-7.041-7.138s3.257-7.033 7.146-7.033l63.79.315c3.888 0 7.041 3.254 7.041 7.138s-3.258 7.138-7.146 7.033z"
        fill="url(#SvgHonor21_linear)"
      />
      <path
        d="M183.561 189.999l-59.586-.314.105-24.669c0-3.884 3.152-6.928 7.041-6.928l45.609.21c3.889 0 6.936 3.149 6.936 7.033l-.105 24.668z"
        fill="url(#SvgHonor22_linear)"
      />
      <path
        d="M186.504 190l-65.367-.42c-.63 0-1.156-.525-1.156-1.155v-2.834c0-.63.526-1.154 1.156-1.154l65.367.419c.63 0 1.156.525 1.156 1.155v2.834c-.105.63-.526 1.155-1.156 1.155z"
        fill="#FFB127"
      />
      <path
        d="M186.504 190l-65.367-.42c-.63 0-1.156-.525-1.156-1.155v-2.834c0-.63.526-1.154 1.156-1.154l65.367.419c.63 0 1.156.525 1.156 1.155v2.834c-.105.63-.526 1.155-1.156 1.155z"
        fill="url(#SvgHonor23_linear)"
      />
      <path
        d="M169.163 180.237l-30.581-.105c-1.787 0-3.258-1.469-3.258-3.359v-8.608c0-1.784 1.472-3.254 3.363-3.254l30.582.21c1.786 0 3.257 1.47 3.257 3.359v8.608c0 1.679-1.576 3.149-3.363 3.149z"
        fill="url(#SvgHonor24_linear)"
      />
      <path
        d="M168.112 158.193l-28.164-.21a.829.829 0 01-.841-.84v-2.099c0-.42.316-.84.841-.84l28.164.21c.421 0 .841.315.841.84v2.099c-.105.525-.42.84-.841.84z"
        fill="#FFB127"
      />
      <path
        d="M168.112 158.193l-28.164-.21a.829.829 0 01-.841-.84v-2.099c0-.42.316-.84.841-.84l28.164.21c.421 0 .841.315.841.84v2.099c-.105.525-.42.84-.841.84z"
        fill="url(#SvgHonor25_linear)"
      />
      <path
        d="M133.643 64.663c-1.051 12.386-1.367 39.889 13.767 57.629.735.945-.421 2.205-1.367 1.365-8.302-6.718-19.652-19.735-22.909-42.619 0 0-1.156-9.132-.526-17.215.21-2.73 2.417-4.829 5.15-5.039 3.362-.315 6.2 2.52 5.885 5.879z"
        fill="url(#SvgHonor26_linear)"
      />
      <path
        d="M171.581 64.138c.42 12.386-.736 39.889-16.815 56.79-.84.839.316 2.204 1.262 1.469 8.722-6.193 20.597-18.685 25.116-41.359 0 0 1.577-9.028 1.366-17.215-.105-2.73-2.101-4.934-4.834-5.249-3.258-.42-6.2 2.204-6.095 5.564z"
        fill="url(#SvgHonor27_linear)"
      />
      <path
        d="M144.362 171.211l2.943.525-2.207 1.994.42 2.939-2.522-1.469-2.732 1.259.63-2.939-1.997-2.099 2.838-.315 1.471-2.52 1.156 2.625z"
        fill="url(#SvgHonor28_linear)"
      />
      <path
        d="M155.185 171.21l2.943.525-2.207 1.994.42 2.939-2.522-1.469-2.732 1.259.63-2.939-1.996-2.099 2.942-.315 1.366-2.52 1.156 2.625z"
        fill="url(#SvgHonor29_linear)"
      />
      <path
        d="M166.011 171.21l2.942.525-2.207 1.994.421 2.939-2.523-1.469-2.732 1.259.631-2.939-1.997-2.099 2.942-.315 1.367-2.52 1.156 2.625z"
        fill="url(#SvgHonor30_linear)"
      />
      <path
        d="M143.803 78.741c-.116-.14-.174-.325-.174-.557 0-.325.128-.58.383-.766l7.485-5.744c.046-.046.163-.14.348-.279.209-.139.418-.255.627-.348.209-.093.418-.139.626-.139h4.248c.255 0 .475.093.661.279a.903.903 0 01.279.661v22.49a.903.903 0 01-.279.66.904.904 0 01-.661.28h-4.561a.902.902 0 01-.661-.28.903.903 0 01-.279-.66V79.123l-4.143 3.133a.968.968 0 01-.591.21.862.862 0 01-.731-.384l-2.577-3.342z"
        fill="#fff"
      />
      <path
        d="M104.532 115.89s.42-3.359 1.892-4.619c1.891-1.575 5.78-1.155 7.356.315 1.681 1.68 2.522 5.249 1.156 7.768 2.837 1.679 2.627 5.249.315 5.563l.841 4.304c.525 2.73-1.366 5.249-4.309 5.879l-5.149 1.049s-8.933-.839-9.564-5.248c-.63-4.409 3.363-6.403 3.363-6.403s-1.891-3.674.105-6.299c1.997-2.519 3.994-2.309 3.994-2.309z"
        fill="url(#SvgHonor31_linear)"
      />
      <path
        d="M117.248 128.801s7.987 1.26 10.194 1.155c1.682.105 4.519-9.237 4.729-9.762 1.366-.84 1.682-1.995 1.682-2.625 0-.629-1.787-1.154-1.892-.839.315-.84.315-1.155.42-1.365.211-.63-.315-1.05-.945 1.26-.631 2.204-.631 1.889-.736 2.624-1.051 2.099-3.888 6.823-3.994 7.558-3.573-.105-9.668-1.47-9.668-1.47l.21 3.464zM104.953 128.906s-6.2 5.144-8.092 6.194c-1.261 1.049-9.458-5.144-10.089-5.459-1.681.105-2.312-.525-2.732-1.05-.42-.524.63-1.889.946-1.784-.736-.525-1.051-.735-1.261-.945-.526-.42-.42-1.05 1.576.42 1.997 1.47 1.681 1.155 2.312 1.785 2.207 1.049 7.461 3.464 8.092 3.884 2.837-1.995 6.831-6.509 6.831-6.509l2.417 3.464zM114.517 184.437s.315 1.47.315 2.729c-.21.84 0 1.05 0 1.05l3.363-.315s-1.471-.63-1.366-3.884c.105-3.254-2.312.42-2.312.42z"
        fill="#FFE5C2"
      />
      <path
        d="M114.831 187.376s1.471.524 2.942.105c1.682.734 2.943 1.049 4.519 1.154 1.576.21 1.261 1.365-1.366 1.155-2.627-.21-4.519 0-5.15 0-.63.105-1.576.105-1.576-.21-.105-.315.105-2.309.631-2.204z"
        fill="#112B46"
      />
      <path
        d="M115.777 142.972s2.207 19.63 2.312 22.15c.105 2.519.105 3.464 0 5.038-.105 1.575.945 14.696.945 14.696s-5.044 1.155-6.41.105c0 0 .21-14.276-.42-16.69-.631-2.415-3.153-14.801-3.153-14.801l2.942-12.702 3.784 2.204z"
        fill="url(#SvgHonor32_linear)"
      />
      <path
        d="M104.953 161.763s-.421 4.199-1.261 4.934c-.526 1.259-.526 1.259.315 1.784 1.261.735 3.783 2.204 4.309 1.575 2.312-2.31 2.417-3.15 2.942-6.089.21-.735 3.468-20.469 3.468-20.469s-10.299-1.47-10.404 1.364c0 2.834 1.156 13.647.631 16.901z"
        fill="url(#SvgHonor33_linear)"
      />
      <path
        d="M92.235 179.398s-1.05 1.155-2.102 1.89c-.84.42-.945.629-.945.629l2.311 2.31s-.315-1.47 2.523-3.359c2.837-1.89-1.787-1.47-1.787-1.47z"
        fill="#FFE5C2"
      />
      <path
        d="M89.923 181.393s.42 1.365 1.682 2.205c.42 1.679.945 2.729 1.681 3.988.84 1.26-.42 1.785-1.892-.314-1.47-2.1-2.732-3.36-3.152-3.779-.42-.42-1.051-1.05-.736-1.26.315-.21 2.207-1.26 2.417-.84z"
        fill="#112B46"
      />
      <path
        d="M104.111 166.067c-4.519 5.563-12.085 9.867-14.292 13.121-.42 1.05 4.624 3.674 4.624 3.674s10.404-9.342 12.506-11.442c.84-.839 1.681-1.469 2.312-2.309-1.997-.63-3.889-4.619-5.15-3.044z"
        fill="url(#SvgHonor34_linear)"
      />
      <path
        d="M113.78 131.531c-.315.735 1.997 10.602 2.417 12.806h-.105c-1.051-.105-7.146-.944-12.19 1.155l-.421-13.856v-.525-1.365c.106-1.679.421-4.409 1.472-6.193.525-.945 1.261-1.575 2.312-1.68 3.047-.315 3.047.945 5.99 1.68l4.624 1.155s-.526 3.569 0 4.723h-3.258c0-.21-.525 1.365-.841 2.1z"
        fill="url(#SvgHonor35_linear)"
      />
      <path
        d="M112.939 112.951s1.996 4.513.525 6.613c-1.156 1.575-3.048 1.575-3.048 1.575v2.204s-.21.21-.63.21c-.631 0-2.522-1.05-2.522-1.05v-8.818l5.675-.734z"
        fill="#FFE5C2"
      />
      <path
        d="M105.688 129.116l-2.312 2.31v-.525s0-4.934 1.576-7.558c.211-.105.526-.105.736-.105 2.522.21 2.312 3.569 0 5.878z"
        fill="url(#SvgHonor36_linear)"
      />
      <path
        d="M117.879 129.221s-2.207-.105-2.417-.524c-.21-.42-1.156-2.31-2.417-2.94 0 0 1.892 1.785 1.681 3.359h3.153v.105z"
        fill="url(#SvgHonor37_linear)"
      />
      <path
        d="M115.987 144.233c-1.051-.105-7.146-.945-12.191 1.154l-.42-13.856v-.525-1.364l1.156.315s0 12.176.631 12.911c.63.735 5.885.21 7.356.42 1.156.21 2.732.735 3.468.945z"
        fill="url(#SvgHonor38_linear)"
      />
      <path
        d="M99.172 127.752s3.993-5.144 6.515-5.039c2.523.21 2.312 3.464 0 5.878-2.311 2.31-3.152 3.255-3.152 3.255s-2.838-1.365-3.363-4.094z"
        fill="url(#SvgHonor39_linear)"
      />
      <path
        d="M113.149 112.426s-.946 4.094-3.048 5.143c-2.101 1.05-.525 3.674-2.522 5.669-.63.63-1.471.525-2.207.315-.945-.315-2.102-.105-2.837.63l-.21.21c-2.628-1.785 4.098-11.442 4.098-11.442l6.726-.525z"
        fill="url(#SvgHonor40_linear)"
      />
      <defs>
        <linearGradient
          id="SvgHonor0_linear"
          x1={125.778}
          y1={59.036}
          x2={125.778}
          y2={188.107}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="SvgHonor1_linear"
          x1={215.311}
          y1={163.524}
          x2={233.562}
          y2={163.524}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor2_linear"
          x1={230.979}
          y1={163.201}
          x2={247.891}
          y2={163.201}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor3_linear"
          x1={223.756}
          y1={122.599}
          x2={232.326}
          y2={153.033}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor4_linear"
          x1={244.039}
          y1={114.879}
          x2={244.039}
          y2={119.458}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor5_linear"
          x1={248.708}
          y1={112.346}
          x2={248.708}
          y2={125.435}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor6_linear"
          x1={52.447}
          y1={161.532}
          x2={62.609}
          y2={161.532}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor7_linear"
          x1={52.622}
          y1={161.767}
          x2={59.875}
          y2={161.767}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor8_linear"
          x1={38.654}
          y1={159.666}
          x2={58.733}
          y2={159.666}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor9_linear"
          x1={52.914}
          y1={93.444}
          x2={52.914}
          y2={106.153}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor10_linear"
          x1={45.46}
          y1={110.992}
          x2={56.498}
          y2={135.657}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor11_linear"
          x1={57.15}
          y1={106.864}
          x2={59.273}
          y2={115.361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor12_linear"
          x1={63.37}
          y1={109.677}
          x2={63.37}
          y2={116.29}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgHonor13_linear"
          x1={69.155}
          y1={111.033}
          x2={69.155}
          y2={116.298}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#C5DCF4" />
        </linearGradient>
        <linearGradient
          id="SvgHonor14_linear"
          x1={44.483}
          y1={111.226}
          x2={50.558}
          y2={123.619}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor15_linear"
          x1={153.979}
          y1={97.729}
          x2={153.979}
          y2={154.415}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor16_linear"
          x1={112.371}
          y1={53.009}
          x2={112.371}
          y2={118.808}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor17_linear"
          x1={118.487}
          y1={55.215}
          x2={118.487}
          y2={79.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor18_linear"
          x1={193.9}
          y1={53.174}
          x2={193.9}
          y2={118.24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor19_linear"
          x1={188.643}
          y1={55.215}
          x2={188.643}
          y2={79.778}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor20_linear"
          x1={153.754}
          y1={44.718}
          x2={153.754}
          y2={128.812}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1D8" />
          <stop offset={1} stopColor="#FFDE8C" />
        </linearGradient>
        <linearGradient
          id="SvgHonor21_linear"
          x1={153.715}
          y1={40.939}
          x2={153.715}
          y2={55.428}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor22_linear"
          x1={153.82}
          y1={158.088}
          x2={153.82}
          y2={189.999}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor23_linear"
          x1={153.821}
          y1={184.437}
          x2={153.821}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor24_linear"
          x1={153.925}
          y1={164.911}
          x2={154.24}
          y2={192.204}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDF0D8" />
          <stop offset={1} stopColor="#F9D478" />
          <stop offset={1} stopColor="#FFF3D6" />
        </linearGradient>
        <linearGradient
          id="SvgHonor25_linear"
          x1={154.03}
          y1={154.204}
          x2={154.03}
          y2={158.193}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor26_linear"
          x1={135.031}
          y1={58.76}
          x2={135.031}
          y2={123.933}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor27_linear"
          x1={168.503}
          y1={58.53}
          x2={168.503}
          y2={122.607}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE7BB" />
          <stop offset={1} stopColor="#F9D478" />
        </linearGradient>
        <linearGradient
          id="SvgHonor28_linear"
          x1={143.101}
          y1={168.586}
          x2={143.101}
          y2={176.669}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor29_linear"
          x1={153.924}
          y1={168.585}
          x2={153.924}
          y2={176.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor30_linear"
          x1={164.75}
          y1={168.585}
          x2={164.75}
          y2={176.668}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#FFF3D5" />
        </linearGradient>
        <linearGradient
          id="SvgHonor31_linear"
          x1={107.046}
          y1={110.334}
          x2={107.046}
          y2={136.037}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor32_linear"
          x1={109.058}
          y1={163.106}
          x2={119.043}
          y2={163.106}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor33_linear"
          x1={103.338}
          y1={156.676}
          x2={114.736}
          y2={156.676}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor34_linear"
          x1={109.051}
          y1={162.183}
          x2={92.258}
          y2={181.097}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgHonor35_linear"
          x1={102.808}
          y1={125.433}
          x2={111.994}
          y2={146.958}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor36_linear"
          x1={103.183}
          y1={124.486}
          x2={106.767}
          y2={131.443}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor37_linear"
          x1={112.819}
          y1={126.286}
          x2={113.489}
          y2={129.885}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor38_linear"
          x1={102.786}
          y1={132.043}
          x2={107.751}
          y2={147.361}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor39_linear"
          x1={98.782}
          y1={124.104}
          x2={101.368}
          y2={133.188}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgHonor40_linear"
          x1={107.451}
          y1={112.455}
          x2={107.451}
          y2={124.341}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgHonor;
