import React from 'react';

const GetPromoted = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M22.4922 6.99316H10.6608C10.2958 6.99316 10 7.28892 10 7.65393V10.3582C10 10.7232 10.2958 11.019 10.6608 11.019H12.4301V12.7799C12.4301 13.1449 12.7259 13.4406 13.0908 13.4406H15.2971C15.662 13.4406 15.9578 13.1449 15.9578 12.7799V11.019H16.4491V12.7799C16.4491 13.1449 16.7449 13.4406 17.1098 13.4406H19.316C19.6809 13.4406 19.9767 13.1449 19.9767 12.7799V11.019H22.4921C22.857 11.019 23.1528 10.7232 23.1528 10.3582V7.65393C23.1529 7.28892 22.8571 6.99316 22.4922 6.99316Z"
        fill="#5384EE"
      />
      <path
        d="M13.9756 13.4413H15.2971C15.662 13.4413 15.9578 13.1455 15.9578 12.7805V11.0195H13.9756V13.4413Z"
        fill="#3F73E3"
      />
      <path
        d="M17.9946 13.4413H19.3161C19.681 13.4413 19.9769 13.1455 19.9769 12.7805V11.0195H17.9946V13.4413Z"
        fill="#3F73E3"
      />
      <path
        d="M22.4924 6.99316H20.7303V11.0191H22.4924C22.8573 11.0191 23.1532 10.7233 23.1532 10.3583V7.65393C23.1532 7.28892 22.8573 6.99316 22.4924 6.99316Z"
        fill="#3F73E3"
      />
      <path
        d="M37.8392 10.2115C37.4743 10.2115 37.1784 10.5072 37.1784 10.8722H26.0642V8.83359C26.0642 6.86414 24.4618 5.26172 22.4922 5.26172C22.1273 5.26172 21.8315 5.55747 21.8315 5.92248V15.4842C21.8315 16.7525 22.3659 17.9736 23.2978 18.834L24.1211 19.5944V22.459C24.1211 22.824 24.417 23.1198 24.7819 23.1198H25.7629H30.0309H36.1358V22.459V20.7224V19.539L37.1472 18.2897C38.0195 17.2121 38.4999 15.8553 38.4999 14.4689V10.8722C38.4999 10.5072 38.2042 10.2115 37.8392 10.2115Z"
        fill="#FED2A4"
      />
      <path
        d="M36.7935 12.1715C35.8444 12.1715 35.0751 11.4022 35.0751 10.4531C35.0751 11.4022 34.3058 12.1715 33.3568 12.1715C32.4077 12.1715 31.6384 11.4022 31.6384 10.4531C31.6384 11.4022 30.8691 12.1715 29.9201 12.1715C28.971 12.1715 28.2017 11.4022 28.2017 10.4531C28.2017 11.4022 27.4324 12.1715 26.4834 12.1715C25.5344 12.1715 24.765 11.4022 24.765 10.4531V12.4026C24.765 13.3517 25.5344 14.121 26.4834 14.121C27.4324 14.121 28.2017 13.3517 28.2017 12.4026C28.2017 13.3517 28.971 14.121 29.9201 14.121C30.8691 14.121 31.6384 13.3517 31.6384 12.4026C31.6384 13.3517 32.4077 14.121 33.3568 14.121C34.3058 14.121 35.0751 13.3517 35.0751 12.4026C35.0751 13.3517 35.8444 14.121 36.7935 14.121C37.7425 14.121 38.5118 13.3517 38.5118 12.4026V10.4531C38.5118 11.4022 37.7425 12.1715 36.7935 12.1715Z"
        fill="#DD9A76"
      />
      <path
        d="M47.2132 1.21973C43.6699 1.21973 40.5895 3.61686 39.6898 6.99353H37.839C37.4741 6.99353 37.1783 7.28928 37.1783 7.65429V10.3586C37.1783 10.7236 37.4741 11.0194 37.839 11.0194H39.6898C40.5895 14.396 43.6699 16.7932 47.2132 16.7932C51.5068 16.7932 54.9999 13.3001 54.9999 9.0064C54.9999 4.71268 51.5068 1.21973 47.2132 1.21973ZM51.015 9.00648C51.015 11.1029 49.3095 12.8084 47.2132 12.8084C45.1168 12.8084 43.4113 11.1029 43.4113 9.00648C43.4113 6.91012 45.1168 5.2046 47.2132 5.2046C49.3095 5.2046 51.015 6.91012 51.015 9.00648Z"
        fill="#5384EE"
      />
      <path
        d="M47.2133 1.21973C46.8734 1.21973 46.5376 1.24231 46.2076 1.2852C50.0258 1.78187 52.9845 5.0551 52.9845 9.00657C52.9845 12.958 50.0259 16.2313 46.2076 16.7279C46.5375 16.7708 46.8732 16.7933 47.2133 16.7933C51.5069 16.7933 55 13.3003 55 9.00657C55 4.71285 51.5069 1.21973 47.2133 1.21973Z"
        fill="#3F73E3"
      />
      <path
        d="M47.5655 5.2041H47.2131C47.2723 5.2041 47.3309 5.20586 47.3893 5.20858C47.4478 5.20595 47.5065 5.2041 47.5655 5.2041Z"
        fill="#3F73E3"
      />
      <path
        d="M47.3893 12.8037C47.3309 12.8064 47.2723 12.8082 47.2131 12.8082H47.5655C47.5065 12.8082 47.4478 12.8063 47.3893 12.8037Z"
        fill="#3F73E3"
      />
      <path
        d="M43.4114 9.00599C43.4114 6.90962 45.1169 5.2041 47.2133 5.2041H45.6382C43.5419 5.2041 41.8364 6.90962 41.8364 9.00599C41.8364 11.1024 43.5419 12.8079 45.6382 12.8079H47.2133C45.1168 12.808 43.4114 11.1024 43.4114 9.00599Z"
        fill="#3F73E3"
      />
      <path
        d="M36.3419 21.7979H23.7194C23.3545 21.7979 23.0587 22.0936 23.0587 22.4586V27.0453C23.0587 27.4103 23.3545 27.706 23.7194 27.706H36.342C36.7069 27.706 37.0027 27.4103 37.0027 27.0453V22.4586C37.0026 22.0937 36.7068 21.7979 36.3419 21.7979Z"
        fill="white"
      />
      <path
        d="M36.3419 21.7979H34.5798V27.7061H36.3419C36.7068 27.7061 37.0027 27.4104 37.0027 27.0454V22.4587C37.0027 22.0937 36.7068 21.7979 36.3419 21.7979Z"
        fill="#ECECEC"
      />
      <path
        d="M37.5691 26.3848H22.4921C22.1272 26.3848 21.8314 26.6805 21.8314 27.0455V42.3074C21.8314 42.6724 22.1272 42.9681 22.4921 42.9681H37.5692C37.9342 42.9681 38.23 42.6724 38.23 42.3074V27.0455C38.2299 26.6806 37.9342 26.3848 37.5691 26.3848Z"
        fill="#5384EE"
      />
      <path
        d="M37.5692 26.3848H35.8071V42.9682H37.5692C37.9342 42.9682 38.23 42.6725 38.23 42.3075V27.0455C38.23 26.6806 37.9343 26.3848 37.5692 26.3848Z"
        fill="#3F73E3"
      />
      <path
        d="M34.5351 31.1191C35.0542 31.1191 35.475 30.6983 35.475 30.1792C35.475 29.6601 35.0542 29.2393 34.5351 29.2393C34.0161 29.2393 33.5952 29.6601 33.5952 30.1792C33.5952 30.6983 34.0161 31.1191 34.5351 31.1191Z"
        fill="#072252"
      />
      <path
        d="M26.9788 2.5957C25.758 2.5957 24.7648 3.58878 24.7648 4.80967V10.6286C24.7648 11.8494 25.758 12.8425 26.9788 12.8425C28.1996 12.8425 29.1927 11.8494 29.1927 10.6286V4.80967C29.1927 3.58887 28.1996 2.5957 26.9788 2.5957Z"
        fill="#FFE5C2"
      />
      <path
        d="M26.9787 2.5957C26.6225 2.5957 26.2859 2.68069 25.9875 2.83081C26.7119 3.19512 27.2104 3.94509 27.2104 4.80958V10.6285C27.2104 11.493 26.7119 12.2429 25.9875 12.6072C26.2859 12.7574 26.6225 12.8423 26.9787 12.8423C28.1995 12.8423 29.1927 11.8492 29.1927 10.6284V4.80967C29.1927 3.58887 28.1995 2.5957 26.9787 2.5957Z"
        fill="#FED2A4"
      />
      <path
        d="M30.0853 1.87891C28.8645 1.87891 27.8713 2.87198 27.8713 4.09287V10.6296C27.8713 11.8504 28.8645 12.8435 30.0853 12.8435C31.3061 12.8435 32.2993 11.8504 32.2993 10.6296V4.09287C32.2993 2.87198 31.3061 1.87891 30.0853 1.87891Z"
        fill="#FFE5C2"
      />
      <path
        d="M30.0851 1.87891C29.7333 1.87891 29.4006 1.96179 29.105 2.10839C29.8354 2.47059 30.3388 3.2239 30.3388 4.09287V10.6296C30.3388 11.4985 29.8354 12.2518 29.105 12.614C29.4006 12.7606 29.7333 12.8435 30.0851 12.8435C31.3059 12.8435 32.2991 11.8504 32.2991 10.6296V4.09287C32.2991 2.87198 31.3059 1.87891 30.0851 1.87891Z"
        fill="#FED2A4"
      />
      <path
        d="M33.1915 2.5957C31.9707 2.5957 30.9775 3.58878 30.9775 4.80967V10.6286C30.9775 11.8494 31.9707 12.8425 33.1915 12.8425C34.4123 12.8425 35.4054 11.8494 35.4054 10.6286V4.80967C35.4054 3.58887 34.4122 2.5957 33.1915 2.5957Z"
        fill="#FFE5C2"
      />
      <path
        d="M33.1915 2.5957C32.832 2.5957 32.4926 2.68236 32.1921 2.83512C32.9121 3.20092 33.4067 3.94843 33.4067 4.80967V10.6286C33.4067 11.4899 32.912 12.2373 32.1921 12.6031C32.4925 12.7558 32.8321 12.8425 33.1915 12.8425C34.4123 12.8425 35.4054 11.8494 35.4054 10.6286V4.80967C35.4053 3.58887 34.4122 2.5957 33.1915 2.5957Z"
        fill="#FED2A4"
      />
      <path
        d="M36.2979 4.19727C35.0771 4.19727 34.084 5.19034 34.084 6.41123V10.6283C34.084 11.8491 35.0771 12.8423 36.2979 12.8423C37.5187 12.8423 38.5119 11.8491 38.5119 10.6283V6.41123C38.5119 5.19034 37.5187 4.19727 36.2979 4.19727Z"
        fill="#FFE5C2"
      />
      <path
        d="M36.2979 4.19727C35.9426 4.19727 35.6069 4.28182 35.3091 4.43123C36.0347 4.7951 36.5342 5.54577 36.5342 6.41123V10.6283C36.5342 11.4939 36.0346 12.2444 35.3091 12.6083C35.6069 12.7576 35.9426 12.8423 36.2979 12.8423C37.5187 12.8423 38.5119 11.8491 38.5119 10.6283V6.41123C38.5119 5.19034 37.5187 4.19727 36.2979 4.19727Z"
        fill="#FED2A4"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GetPromoted;
