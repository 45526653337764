import { collectionToObjectByKey, db, formatDate } from '@lobox/utils';
import type { Education } from '@lobox/utils';

const degrees = collectionToObjectByKey(db.DEGREE_OPTIONS);

export const convertEducationApiToForm = (curr: Education) => {
  const {
    schoolPageId,
    schoolName,
    majorName,
    majorLookupId,
    degree,
    startDate,
    endDate,
    id,
    description,
    currentlyStudying,
    location,
  } = curr;

  const degreeLabel = degrees[degree]?.label;
  return {
    school: { label: schoolName, value: schoolPageId },
    location: location
      ? {
          ...location,
          label: location.title,
          value: location.externalId,
        }
      : undefined,
    major: { label: majorName, value: majorLookupId },
    degree: { value: degree, label: degreeLabel },
    id,
    endDate,
    startDate,
    description,
    currentlyStudying,
  };
};

export const educationNormalizer = (item: Education, t: Function) => {
  const {
    schoolPageId,
    schoolName,
    majorName,
    degree,
    startDate,
    endDate,
    id,
    description,
    pageCroppedImageUrl,
    location,
  } = item;
  const degreeLabel = t(degrees[degree]?.label);
  const realData = convertEducationApiToForm(item);

  const secondText = [majorName, degreeLabel].filter(Boolean).join(', ');
  const formattedEndDate = endDate ? formatDate(endDate, 'y') : 'Present';
  const formattedStartDate = formatDate(startDate, 'y');

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: schoolName,
    firstTextAdditionalProps: { objectId: schoolPageId },
    thirdText:
      secondText?.length > 0
        ? location
          ? `${secondText} . ${location?.title}`
          : secondText
        : undefined,
    secondText: `${formattedStartDate} - ${formattedEndDate}`,
    realData,
    longText: description,
    objectId: schoolPageId,
  };
};
