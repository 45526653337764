// @ts-nocheck

export const calculateDatesSum = (items: any) => {
  const numberOfDays = items.reduce((prev, cur) => {
    const days =
      cur?.durationObj?.years * 365 +
      cur?.durationObj?.months * 30 +
      cur?.durationObj?.days;
    return days + prev;
  }, 0);
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  return { years, months, days };
};

export const displayDuration = (durationObj: any) => {
  const yearSuffix = durationObj?.years > 1 ? 'yrs' : 'yr';
  const monthSuffix = durationObj?.months > 1 ? 'mos' : 'mo';
  const daySuffix = durationObj?.days > 1 ? 'days' : 'day';

  const year = durationObj?.years ? `${durationObj?.years} ${yearSuffix}` : 0;
  const month = durationObj?.months
    ? `${durationObj?.months} ${monthSuffix}`
    : 0;
  const days = durationObj?.days ? `${durationObj.days} ${daySuffix}` : 0;

  return [year, month, days]
    .filter((i) => i !== 0)
    .slice(0, 2)
    .join(' ');
};
