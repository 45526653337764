import { formatDate, datesDuration, db } from '@lobox/utils';
import type { Experience } from '@lobox/utils';
import { calculateDatesSum, displayDuration } from '../../utils';

export const addDisplayDuration = (item) =>
  item.steps
    ? { ...item, secondText: displayDuration(calculateDatesSum(item.steps)) }
    : item;

export const findEmploymentLabelByType = (value) => {
  const employment = db.EMPLOYMENT_TYPES.find((item) => item.value === value);
  return employment?.label;
};

export const convertExperienceApiToForm = (curr: Experience) => {
  const {
    id,
    companyName,
    occupationName,
    occupationLookupId,
    companyPageId,
    description,
    volunteer,
    currentlyWorking,
    startDate,
    endDate,
    employmentType,
    location,
  } = curr;

  return {
    location: location
      ? {
          ...location,
          label: location.title,
          value: location.externalId,
        }
      : undefined,
    id,
    job: {
      label: occupationName,
      value: occupationLookupId,
      public: false,
    },
    company: {
      label: companyName,
      value: companyPageId,
      public: false,
    },
    employmentType: {
      value: employmentType,
      label: findEmploymentLabelByType(employmentType),
    },
    description,
    volunteer,
    currentlyWorking,
    startDate,
    endDate,
  };
};

export const experienceNormalizer = (
  prev: Array<Experience>,
  curr: Experience
) => {
  const {
    id,
    companyName,
    occupationName,
    companyPageId,
    description,
    startDate,
    endDate,
    pageCroppedImageUrl,
    location,
  } = curr;
  const realData = convertExperienceApiToForm(curr);

  const formattedEndDate = endDate ? formatDate(endDate, 'MMM y') : 'Present';
  const formattedStartDate = formatDate(startDate, 'MMM y');
  const durationObj = datesDuration({
    startDate,
    endDate: endDate || new Date().toISOString(),
  });
  const duration = durationObj ? displayDuration(durationObj) : '';
  const company = prev.find((i) => i.companyPageId === companyPageId);
  const objectId = !companyPageId?.includes('_temp')
    ? companyPageId
    : undefined;

  if (company) {
    return prev.map((i) => {
      if (i.companyPageId === companyPageId) {
        return {
          id: companyPageId,
          companyPageId,
          image: pageCroppedImageUrl,
          firstText: companyName,
          firstTextAdditionalProps: { objectId },
          objectId,
          steps: [
            ...(company?.steps || [
              {
                id: i.id,
                firstText: i?.thirdText,
                secondText: i.secondText,
                secondTextHelper: i.secondTextHelper,
                longText: i.longText,
                realData: i.realData,
                durationObj: i.durationObj,
              },
            ]),
            {
              id,
              firstText: location
                ? `${occupationName} · ${location?.title}`
                : occupationName,
              secondText: `${formattedStartDate} - ${formattedEndDate}`,
              secondTextHelper: duration,
              longText: description,
              realData,
              durationObj,
            },
          ],
        };
      }
      return i;
    });
  }
  return [
    ...prev,
    {
      id,
      companyPageId,
      image: pageCroppedImageUrl,
      firstText: companyName,
      firstTextAdditionalProps: { objectId },
      secondText: `${formattedStartDate} - ${formattedEndDate}`,
      secondTextHelper: duration,
      thirdText: location
        ? `${occupationName} · ${location?.title}`
        : occupationName,

      longText: description,
      realData,
      durationObj,
      objectId,
    },
  ];
};
