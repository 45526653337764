import classes from './HighlightCard.banner.module.scss';

import React from 'react';
import HighlightImage from '@shared/svg/Highlights';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import useTheme from '@lobox/uikit/utils/useTheme';
import HighlightPattern from '@shared/svg/HighlightPattern.svg';
import type { FC } from 'react';
import type { HighlightCardProps } from './types';
import Image from '@lobox/uikit/Image';

const HighlightCardBanner: FC<HighlightCardProps> = (props) => {
  const { type, title, className } = props;

  const { isDark } = useTheme();

  return (
    <div
      className={cnj(
        classes.root,
        title && classes.withTitle,
        isDark && classes.darkRoot,
        className
      )}
    >
      <div className={classes.content}>
        <div className={classes.imageContainer}>
          <HighlightImage type={type} className={classes.image} />
        </div>

        {!!title && (
          <Typography
            className={classes.title}
            font="700"
            size={20}
            color="brand"
          >
            {title}
          </Typography>
        )}
      </div>
      <Image className={classes.pattern} src={HighlightPattern} />
    </div>
  );
};

export default HighlightCardBanner;
