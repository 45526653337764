import classes from '../../../../../constants/highlights.user.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  db,
  Endpoints,
  formValidator,
  geoApi,
  hereApiResponseNormalizer,
  isEmptyObjectValues,
  lookupResponseNormalizer,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { VOL_EXPERIENCE } from '@shared/constants/profileModalsKeys';
import { useProfileModals, useProfile } from '@shared/hooks';
import ExperienceSvg from '@shared/svg/SvgExperience';
import { DESC_LEN_VALIDATION } from '@shared/constants/highlights.user';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import useShowSuccessToast from './useShowSuccessToast';
import type { Experience } from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';

interface ValuesTypes {
  [name: string]: any;
}

type InitialValue = {
  job: { value: string; label: string };
  company: { value: string; label: string };
  employmentType: { value: string; label: string };
  currentlyWorking: boolean;
  volunteer: boolean;
  leftItem: string;
  endDate: string;
  description: string;
  share: boolean;
};

export const useExperienceFormGroups = ({
  isVolunteer,
  isEdit,
  isHighlight,
}: {
  isVolunteer: boolean;
  isEdit?: boolean;
  isHighlight?: boolean;
}) => {
  const { t } = useTranslation();

  const groups = ({ values }: ValuesTypes) =>
    [
      {
        name: 'job',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('job_title'),
        url: Endpoints.App.Common.getOccupations,
        normalizer: lookupResponseNormalizer,
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
      },
      {
        name: 'company',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('company'),
        wrapStyle: cnj(classes.halfWidth, classes.leftItem, classes.formItem),
        url: `${Endpoints.App.Common.suggestPlace}`,
        normalizer: hereApiResponseNormalizer,
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        renderItem: ({ item }: any) => (
          <AvatarCard
            data={{
              title: item.label,
              image: item.image,
            }}
            avatarProps={{ isCompany: true, size: 'sm' }}
          />
        ),
      },
      {
        name: 'location',
        apiFunc: geoApi.suggestCity,
        label: t('location'),
        wrapStyle: cnj(classes.halfWidth, classes.rightItem, classes.formItem),
        rightIconProps: {
          name: 'search',
          size: 'md18',
        },
        cp: 'asyncAutoComplete',
        privateable: false,
      },
      isVolunteer
        ? {
            name: 'cause',
            cp: 'dropdownSelect',
            label: t('cause'),
            wrapStyle: 'responsive-margin-top',
            options: db.CAUSE_OPTIONS,
          }
        : {
            name: 'employmentType',
            cp: 'dropdownSelect',
            label: t('contract_type'),
            wrapStyle: classes.formItem,
            options: db.EMPLOYMENT_TYPES,
          },
      {
        name: 'currentlyWorking',
        cp: 'checkBox',
        label: t('currently_working_at_this_position'),
        wrapStyle: classes.marginTop,
        visibleOptionalLabel: false,
      },
      {
        name: 'startDate',
        cp: 'datePicker',
        wrapStyle: cnj(classes.halfWidth, classes.leftItem, classes.formItem),
        variant: 'input',
        required: true,
        picker: 'month',
        label: t('start_date'),
      },
      {
        name: 'endDate',
        cp: 'datePicker',
        wrapStyle: cnj(classes.halfWidth, classes.rightItem, classes.formItem),
        variant: 'input',
        picker: 'month',
        minDate: values?.startDate ? new Date(values?.startDate) : undefined,
        label: t('end_date'),
        visibleOptionalLabel: false,
        required: false,
        textInputProps: {
          disabled: values.currentlyWorking,
        },
      },
      {
        name: 'description',
        visibleOptionalLabel: false,
        cp: 'richtext',
        label: t('tell_more_about_experience'),
        wrapStyle: classes.formItem,
        maxLength: DESCRIPTION_MAX_LENGTH,
      },
    ].reduce((prev: any, curr) => {
      if (isHighlight && curr.name === 'description') {
        return prev;
      }
      if (curr.name === 'share' && isEdit) {
        return prev;
      }
      return [...prev, curr];
    }, []);

  return React.useCallback(groups, []);
};

export const experienceValidationSchema = () =>
  formValidator.object().shape({
    location: formValidator
      .object()
      .test(
        'value',
        'select_one_of_sug_locns',
        (val: any) => isEmptyObjectValues(val) || val?.value
      ),
    endDate: formValidator
      .date()
      .when(
        ['startDate', 'currentlyWorking'],
        (startDate, currentlyWorking, schema) => {
          if (currentlyWorking) {
            return schema.nullable();
          }
          return startDate
            ? schema.min(startDate, 'date_e_b_s').required()
            : schema.nullable();
        }
      ),
    description: DESC_LEN_VALIDATION,
  });

const useExperience = () => {
  const { t } = useTranslation();
  const { modals, close } = useProfileModals();
  const EXPERIENCE = modals?.active;
  const { reFetchAboutSectionQuery } = useProfile();
  const { openConfirmDialog } = useOpenConfirm();
  const showSuccessToast = useShowSuccessToast();
  const isVolunteer = EXPERIENCE === VOL_EXPERIENCE;
  const experience = modals?.params?.[EXPERIENCE]?.realData || {};
  const isEdit = !!experience?.id;
  const editUrl = `${Endpoints.App.User.Experiences.get}/${experience?.id}`;
  const groups = useExperienceFormGroups({ isVolunteer, isEdit });
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const { mutate } = useReactMutation({
    url: editUrl,
    headers: {},
    method: 'DELETE',
  });

  const onSuccess = (data: Experience, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          ...data,
          profileEntityId: data.id,
          subTitle: data.companyName,
          title: data.occupationName,
          type: isVolunteer
            ? HIGHLIGHT_TYPES.VOLUNTEERING
            : HIGHLIGHT_TYPES.JOB,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isEdit,
      isDelete: !values,
      shared: values?.share,
      variant: EXPERIENCE,
    });
  };

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_experience_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };

  return {
    title: isEdit ? t('edit_experience') : t('add_experience'),
    method: isEdit ? 'PUT' : 'POST',
    image: <ExperienceSvg />,
    visibleHeaderBorderBottom: false,
    groups,
    onSuccess,
    initialValues: {
      share: true,
      volunteer: isVolunteer,
      ...experience,
    } as InitialValue,
    url: isEdit ? editUrl : Endpoints.App.User.Experiences.get,
    transform: ({
      job,
      employmentType,
      company,
      endDate,
      currentlyWorking,
      ...rest
    }: InitialValue) => ({
      ...rest,
      currentlyWorking,
      endDate: currentlyWorking ? undefined : endDate,
      occupationLookupId: job?.value,
      occupationName: job?.label,
      employmentType: employmentType?.value,
      companyPageId: `${company?.value}`?.includes('_temp')
        ? null
        : company?.value,
      companyName: company?.label,
    }),
    secondaryAction: isEdit
      ? {
          label: t('delete_experience'),
          onClick: openConfirm,
        }
      : undefined,
    validationSchema: experienceValidationSchema,
  };
};

export default useExperience;
