import React from 'react';

const SchoolIcon = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56} height={50} fill="none">
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#072252"
          d="M27.159 3.072a.728.728 0 0 0 .189-.025c1.672-.45 3.92-.699 6.328-.699 2.409 0 4.656.248 6.33.7a.724.724 0 1 0 .376-1.4C38.59 1.167 36.21.9 33.676.9c-2.532 0-4.913.266-6.705.749a.724.724 0 0 0 .188 1.423Z"
        />
        <path
          fill="#072252"
          d="M40.194 1.624c.4 0 .724.324.724.724v3.62H39.47v-3.62c0-.4.324-.724.724-.724ZM27.159 1.624c.4 0 .724.324.724.724v3.62h-1.448v-3.62c0-.4.324-.724.724-.724Z"
        />
        <path
          fill="#3F73E3"
          d="M17.02 37.1h33.31c1.6 0 2.897-1.297 2.897-2.896V20.452H14.123v13.752c0 1.6 1.297 2.896 2.897 2.896Z"
        />
        <path
          fill="#5384EE"
          d="M34.4 19.003h17.38v15.2c0 .8-.65 1.449-1.449 1.449H35.124a.724.724 0 0 1-.724-.724V19.003ZM32.951 19.003v15.925c0 .4-.324.724-.724.724H17.02c-.8 0-1.448-.648-1.448-1.448v-15.2h17.38Z"
        />
        <path
          fill="#5384EE"
          d="M17.02 22.624h33.31c2.4 0 4.346-1.945 4.346-4.345V6.686c0-.8-.649-1.448-1.449-1.448H14.124c-.8 0-1.448.648-1.448 1.448V18.28c0 2.4 1.945 4.345 4.345 4.345Z"
        />
        <path
          fill="#3F73E3"
          d="M17.02 24.072a5.75 5.75 0 0 1-2.897-.784v-1.777a4.324 4.324 0 0 0 2.897 1.113h33.31a4.324 4.324 0 0 0 2.897-1.113v1.777a5.75 5.75 0 0 1-2.897.784H17.02Z"
        />
        <path
          fill="#fff"
          d="M32.227 16.83h2.897c.4 0 .724.325.724.725v5.069c0 .4-.325.724-.724.724h-2.897a.724.724 0 0 1-.724-.724v-5.069c0-.4.324-.724.724-.724Z"
        />
        <path
          fill="#fff"
          d="M31.503 25.52h4.345c.4 0 .724-.324.724-.724v-3.62c0-.4-.324-.725-.724-.725h-4.345c-.4 0-.724.325-.724.725v3.62c0 .4.324.724.724.724Z"
        />
        <path
          fill="#ECECEC"
          d="M32.228 25.52h2.897v-2.896c0-.4-.324-.724-.724-.724h-1.448c-.4 0-.725.324-.725.724v2.897Z"
        />
        <path
          fill="#fff"
          d="M33.677 14.659a1.448 1.448 0 1 1 0-2.897 1.448 1.448 0 0 1 0 2.897Z"
        />
      </g>
      <g clipPath="url(#c)">
        <path
          fill="#3F73E3"
          d="M.676 36c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 14-14 14-14-6.268-14-14Z"
        />
        <path
          fill="#fff"
          d="M2.676 36c0-6.627 5.372-12 12-12 6.627 0 12 5.373 12 12s-5.373 12-12 12c-6.628 0-12-5.373-12-12Z"
        />
        <path
          fill="#3F73E3"
          d="M9.05 34.969a.834.834 0 0 0-.644.293.834.834 0 0 0-.293.644v.938c0 .254.098.478.293.674.176.175.39.263.645.263h4.219V42c0 .254.097.469.293.645.175.195.39.292.644.292h.938a.926.926 0 0 0 .673-.292.876.876 0 0 0 .264-.645v-4.219h4.219a.978.978 0 0 0 .674-.263.978.978 0 0 0 .263-.674v-.938a.877.877 0 0 0-.263-.644.926.926 0 0 0-.674-.293h-4.219V30.75a.876.876 0 0 0-.264-.645.926.926 0 0 0-.673-.293h-.938a.834.834 0 0 0-.645.293.834.834 0 0 0-.292.645v4.219H9.05Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.676 0h55v50h-55z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M54.676-2h-42v42h42z" />
      </clipPath>
      <clipPath id="c">
        <path fill="#fff" d="M.676 21h29v29h-29z" />
      </clipPath>
    </defs>
  </svg>
);

export default SchoolIcon;
