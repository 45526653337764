import classes from './highlights.user.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import cnj from '@lobox/uikit/utils/cnj';
import omit from 'lodash/omit';
import {
  useTranslation,
  db,
  profileEndpoints,
  Endpoints,
  lookupResponseNormalizer,
  hereApiResponseNormalizer,
  skillsResponseNormalizer,
  formValidator,
  geoApi,
  ProfileType,
  getAboutSectionsData,
  useReactQuery,
  QueryKeys,
  formatDate,
  Education,
  languageNormalizer,
  Language,
  linkValidation,
} from '@lobox/utils';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import Work from '@shared/svg/Work';
import EducationHome from '@shared/svg/EducationHome';
import Achievement from '@shared/svg/AchievementIcon';
import SelfDevelopment from '@shared/svg/SelfDevelopment';
import Entrepreneurship from '@shared/svg/Entrepreneurship';
import SchoolHome from '@shared/svg/School';
import CourseHome from '@shared/svg/Course';
import GraduationHome from '@shared/svg/Graduation';
import Study from '@shared/svg/Study';
import GetPromoted from '@shared/svg/GetPromoted';
import Fund from '@shared/svg/Fund';
import Conference from '@shared/svg/Conference';
import Instrument from '@shared/svg/Instrument';
import Hobby from '@shared/svg/Hobby';
import Sport from '@shared/svg/Sport';
import LicenceHome from '@shared/svg/Licence';
import PatentHome from '@shared/svg/Patent';
import PublicationHome from '@shared/svg/Publication';
import LanguageHome from '@shared/svg/Language';
import Award from '@shared/svg/Award';
import Skill from '@shared/svg/Skill';
import Adventure from '@shared/svg/Adventure';
import EmptyHighlight from '@shared/svg/EmptyHighlight';
import LifeStyleHome from '@shared/svg/LifeStyle';
import JobHome from '@shared/svg/Job';
import Travel from '@shared/svg/Travel';
import HighlightImage from '@shared/svg/Highlights';
import VolunteerIcon from '@shared/svg/VolunteerIcon';
import HighlightCard from '@shared/components/molecules/HighlightCard';
import type { SkillType } from '@lobox/utils';
import HIGHLIGHT_TYPES from './highlightTypes';
import useGetAppObject from '@shared/hooks/useGetAppObject';
import { convertExperienceApiToForm } from '@app/(profile)/[username]/(about)/partials/sections/Experience/experience.utils';
import { convertEducationApiToForm } from '@app/(profile)/[username]/(about)/partials/sections/Education/education.utils';
import { convertCourseApiToForm } from '@app/(profile)/[username]/(about)/partials/sections/Course/course.utils';
import { convertLicenceApiToForm } from '@app/(profile)/[username]/(about)/partials/sections/Licence/licence.utils';
import {
  convertAwardApiToForm,
  convertPatentApiToForm,
  convertPublicationApiToForm,
} from '@app/(profile)/[username]/(about)/partials/sections/UserAccomplishment/userAccomplishment.utils';
import skillNormalizer from '@lobox/utils/utils/normalizers/skillNormalizer';
import {
  experienceValidationSchema,
  useExperienceFormGroups,
} from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/useExperience';
import {
  schoolValidationSchema,
  useSchoolFormGroups,
} from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/useSchool';
import {
  courseValidationSchema,
  useCourseFormGroups,
} from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/useCourse';
import { useLicenceFormGroups } from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/useLicence';
import { usePublicationFormGroups } from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/usePublication';
import { useHonorFormGroups } from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/useHonor';
import { usePatentFormGroups } from '@shared/components/Organism/ObjectFormContainer/ObjectFormBuilder/hooks/usePatent';

const EXPERIENCE_LINK = profileEndpoints.experience?.post;
const EDUCATION_LINK = profileEndpoints.education?.post;
const COURSE_LINK = profileEndpoints.course?.post;
const LICENCE_LINK = profileEndpoints.licence.post;
const PATENT_LINK = profileEndpoints.patent.post;
const PUBLICATION_LINK = profileEndpoints.publication.post;
const LANGUAGE_LINK = profileEndpoints.language.post;
const AWARD_LINK = profileEndpoints.award.post;
const SKILL_LINK = profileEndpoints.skill.post;

export const DESC_LEN_VALIDATION = formValidator
  .mixed()
  .notRequired()
  .test(
    'len',
    'must_be_less_max_len',
    (val = '') => (val?.length || 0) <= DESCRIPTION_MAX_LENGTH
  );

const useHighlights = (): any => {
  const { t } = useTranslation();
  const { authUser } = useGetAppObject();
  const { refetch: fetchAuthUserProfile } = useReactQuery<ProfileType>({
    action: {
      key: [QueryKeys.aboutSections, authUser?.username],
      apiFunc: () =>
        getAboutSectionsData({ params: { username: authUser?.username } }),
    },
  });

  const experienceFormGroups = useExperienceFormGroups({
    isVolunteer: false,
    isHighlight: true,
  });
  const volunteerFormGroups = useExperienceFormGroups({
    isVolunteer: true,
    isHighlight: true,
  });
  const schoolFormGroups = useSchoolFormGroups({ isHighlight: true });
  const courseFormGroups = useCourseFormGroups({ isHighlight: true });
  const licenceFormGroups = useLicenceFormGroups({ isHighlight: true });
  const publicationFormGroups = usePublicationFormGroups({ isHighlight: true });
  const honorFormGroups = useHonorFormGroups({ isHighlight: true });
  const patentFormGroups = usePatentFormGroups({ isHighlight: true });

  const fetchExperienceList = async (
    filterFunc: (item: any) => boolean,
    title: string
  ) => {
    const authUserProfile = await fetchAuthUserProfile();
    const experiences = authUserProfile?.data?.experiences?.reduce(
      (prev, curr) => {
        if (filterFunc(curr)) return prev;
        const item = convertExperienceApiToForm(curr);
        const formattedEndDate = curr.endDate
          ? formatDate(curr.endDate, 'MMM y')
          : t('present');
        const formattedStartDate = formatDate(curr.startDate, 'MMM y');

        return [
          ...prev,
          {
            ...item,
            advancedCardData: {
              image: curr.pageCroppedImageUrl,
              firstText: curr.occupationName,
              secondText: curr.companyName,
              thirdText: `${formattedStartDate} - ${formattedEndDate}`,
            },
          },
        ];
      },
      []
    );
    return {
      title,
      items: experiences,
    };
  };

  const JOB_FIELD = {
    name: 'job',
    cp: 'asyncAutoComplete',
    label: t('job_title'),
    wrapStyle: '',
    visibleRightIcon: true,
    rightIconProps: { name: 'search' },
    url: Endpoints.App.Common.getOccupations,
    normalizer: lookupResponseNormalizer,
    required: true,
    maxLength: 100,
  };
  const COMPANY_FIELD = {
    name: 'company',
    params: {
      pageCategories: 'COMPANY,EDUCATION_INSTITUTION',
    },
    cp: 'asyncAutoComplete',
    label: t('company'),
    wrapStyle: 'responsive-margin-top',
    url: `${Endpoints.App.Common.suggestPlace}`,
    required: true,
    maxLength: 100,
    visibleRightIcon: true,
    rightIconProps: { name: 'search' },
    normalizer: hereApiResponseNormalizer,
    renderItem: ({ item }: any) => (
      <AvatarCard
        data={{
          title: item.label,
          image: item.image,
        }}
        avatarProps={{ isCompany: true, size: 'sm' }}
      />
    ),
  };

  const START_DATE_FIELD = {
    name: 'startDate',
    cp: 'datePicker',
    wrapStyle: 'responsive-margin-top',
    variant: 'input',
    picker: 'month',
    required: true,
    label: t('start_date'),
  };
  const LOCATION_FIELD = {
    name: 'location',
    apiFunc: geoApi.suggestCity,
    label: t('location'),
    wrapStyle: 'responsive-margin-top',
    rightIconProps: {
      name: 'search',
      size: 'md18',
    },
    visibleRightIcon: true,
    cp: 'asyncAutoComplete',
    privateable: false,
  };
  const SCHOOL_FIELD = {
    name: 'school',
    cp: 'asyncAutoComplete',
    label: t('school'),
    url: `${Endpoints.App.Common.suggestPlace}`,
    normalizer: hereApiResponseNormalizer,
    maxLength: 100,
    required: true,
    visibleRightIcon: true,
    rightIconProps: { name: 'search' },
    renderItem: ({ item }: any) => (
      <AvatarCard
        data={{
          title: item.label,
          image: item.image,
        }}
        avatarProps={{ isCompany: true, size: 'sm' }}
      />
    ),
  };
  const MAJOR_FIELD = {
    name: 'major',
    cp: 'asyncAutoComplete',
    label: t('major'),
    required: true,
    visibleRightIcon: true,
    rightIconProps: { name: 'search' },
    normalizer: lookupResponseNormalizer,
    url: Endpoints.App.Common.getMajors,
    wrapStyle: 'responsive-margin-top',
    maxLength: 100,
  };
  const DEGREE_FIELD = {
    name: 'degree',
    cp: 'dropdownSelect',
    label: t('degree'),
    wrapStyle: 'responsive-margin-top',
    options: db.DEGREE_OPTIONS,
  };
  const DATE_FIELD = {
    ...START_DATE_FIELD,
    // picker: undefined,
  };
  const VERIFICATION_CODE = {
    name: 'verificationCode',
    cp: 'input',
    label: t('verification_code'),
    wrapStyle: 'responsive-margin-top',
    type: 'text',
    maxLength: 100,
  };
  const CERTIFICATION_LINK = {
    name: 'link',
    cp: 'input',
    label: t('link'),
    wrapStyle: 'responsive-margin-top',
    type: 'text',
  };

  const highlights: any = React.useMemo(
    () => ({
      list: {
        id: 'list',
        title: t('create_highlight'),
        image: () => (
          <HighlightImage className={classes.highlightImage} type="SIMPLE" />
        ),
        items: [
          {
            id: 'work',
            title: t('work'),
            image: () => <Work />,
          },
          {
            id: 'education',
            title: t('education'),
            image: () => <EducationHome />,
          },
          {
            id: 'lifeStyle',
            title: t('life_style'),
            image: () => <LifeStyleHome />,
          },
          {
            id: 'achievement',
            title: t('achievements'),
            image: () => <Achievement />,
          },
          {
            id: 'selfDevelopment',
            title: t('self_development'),
            image: () => <SelfDevelopment />,
          },
          {
            id: 'simple',
            title: t('create'),
            image: () => <EmptyHighlight />,
            form: {
              logo: HIGHLIGHT_TYPES.SIMPLE,
              title: t('new_highlight'),
              initialValues: {},
              highlightTransform: ({ ...rest }: any) => ({
                ...rest,
                type: HIGHLIGHT_TYPES.SIMPLE,
              }),
              groups: [
                {
                  name: 'title',
                  cp: 'input',
                  label: t('title'),
                  type: 'text',
                  maxLength: 100,
                  required: true,
                },

                DATE_FIELD,
              ],
            },
          },
        ],
      },
      work: {
        id: 'work',
        title: t('work'),
        image: () => (
          <HighlightCard className={classes.highlightCard} type="JOB" />
        ),
        items: [
          {
            parentId: 'work',
            id: 'newJob',
            title: t('new_experience'),
            subTitle: t('new_job_desc'),
            fetchSelectionList: () =>
              fetchExperienceList(
                (item) => item.volunteer,
                t('select_experience')
              ),
            image: () => <JobHome />,
            form: {
              title: t('new_experience'),
              logo: HIGHLIGHT_TYPES.JOB,
              initialValues: {
                isPublishedProfile: true,
              },
              url: EXPERIENCE_LINK,
              transform: ({ job, company, employmentType, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    occupationLookupId: job?.value,
                    occupationName: job?.label,
                    employmentType: employmentType?.value,
                    companyPageId: `${company?.value}`?.includes('_temp')
                      ? null
                      : company?.value,
                    companyName: company?.label,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({
                job,
                company,
                employmentType,
                ...rest
              }: any) =>
                omit(
                  {
                    type: HIGHLIGHT_TYPES.JOB,
                    title: job?.label,
                    subTitle: company?.label,
                    employmentType: employmentType?.value,
                    ...rest,
                  },
                  ['job', 'company', 'volunteer', 'isPublishedProfile']
                ),
              validationSchema: experienceValidationSchema,
              groups: experienceFormGroups,
            },
          },
          {
            parentId: 'work',
            id: 'newVolunteer',
            title: t('new_volunteering'),
            subTitle: t('new_volunteering_desc'),
            image: () => <VolunteerIcon />,
            fetchSelectionList: () =>
              fetchExperienceList(
                (item) => !item.volunteer,
                t('select_experience')
              ),
            form: {
              title: t('new_volunteering'),
              logo: HIGHLIGHT_TYPES.VOLUNTEERING,
              initialValues: {
                isPublishedProfile: true,
              },
              url: EXPERIENCE_LINK,
              validationSchema: experienceValidationSchema,
              transform: ({
                job,
                company,
                employmentType,
                volunteerCause,
                ...rest
              }: any) =>
                omit(
                  {
                    ...rest,
                    occupationLookupId: job?.value,
                    occupationName: job?.label,
                    employmentType: employmentType?.value,
                    companyPageId: `${company?.value}`?.includes('_temp')
                      ? null
                      : company?.value,
                    companyName: company?.label,
                    volunteerCause: volunteerCause?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({
                job,
                company,
                employmentType,
                volunteerCause,
                ...rest
              }: any) =>
                omit(
                  {
                    type: HIGHLIGHT_TYPES.VOLUNTEERING,
                    title: job?.label,
                    subTitle: company?.label,
                    employmentType: employmentType?.value,
                    volunteerCause: volunteerCause?.label,
                    ...rest,
                  },
                  ['job', 'company', 'volunteer', 'isPublishedProfile']
                ),
              groups: volunteerFormGroups,
            },
          },
          {
            parentId: 'work',
            id: 'getPromoted',
            title: t('get_promoted'),
            subTitle: t('get_promoted_desc'),
            image: () => <GetPromoted />,
            fetchSelectionList: () =>
              fetchExperienceList(
                (item) => item.endDate,
                t('select_experience')
              ),
            form: {
              logo: HIGHLIGHT_TYPES.GET_PROMOTED,
              title: t('get_promoted'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: EXPERIENCE_LINK,
              transform: ({
                job,
                employmentType,
                experience,
                startDate,
                description,
              }: any) =>
                omit(
                  {
                    ...experience,
                    occupationLookupId: job?.value,
                    occupationName: job?.label,
                    employmentType: employmentType?.value,
                    companyPageId: experience?.companyPageId,
                    companyName: experience?.companyName,
                    startDate,
                    description,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({
                job,
                location,
                experience,
                employmentType,
                company,
                ...rest
              }: any) =>
                omit(
                  {
                    type: HIGHLIGHT_TYPES.GET_PROMOTED,
                    title: job?.label,
                    subTitle: company?.label,
                    employmentType: employmentType?.value,
                    ...rest,
                  },
                  ['job', 'experience', 'isPublishedProfile']
                ),
              groups: [JOB_FIELD, COMPANY_FIELD, START_DATE_FIELD],
            },
          },
          {
            parentId: 'work',
            id: 'entrepreneurship',
            title: t('entrepreneurship'),
            subTitle: t('entrepreneurship_desc'),
            image: () => <Entrepreneurship />,
            form: {
              logo: HIGHLIGHT_TYPES.ENTREPRENEURSHIP,
              title: t('entrepreneurship'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: EXPERIENCE_LINK,
              transform: ({ job, company, ...rest }: any) => ({
                ...rest,
                currentlyWorking: false,
                endDate: undefined,
                occupationLookupId: job?.value,
                occupationName: job?.label,
                employmentType: undefined,
                companyPageId: `${company?.value}`?.includes('_temp')
                  ? null
                  : company?.value,
                companyName: company?.label,
              }),
              highlightTransform: ({ job, company, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.ENTREPRENEURSHIP,
                    title: job?.label,
                    subTitle: company?.label,
                  },
                  ['job', 'company', 'isPublishedProfile']
                ),
              groups: [
                JOB_FIELD,
                {
                  ...COMPANY_FIELD,
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.leftItem,
                    classes.formItem
                  ),
                },
                {
                  ...LOCATION_FIELD,
                  label: t('location'),
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.rightItem,
                    classes.formItem
                  ),
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
        ],
      },
      education: {
        id: 'education',
        title: t('education'),
        image: () => (
          <HighlightCard className={classes.highlightCard} type="SCHOOL" />
        ),
        items: [
          {
            parentId: 'education',
            id: 'newSchool',
            title: t('new_school'),
            subTitle: t('new_school_desc'),
            image: () => <SchoolHome />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const schools = authUserProfile?.data?.educations?.reduce<
                Education[]
              >((prev, curr) => {
                const item = convertEducationApiToForm(curr);
                return [
                  ...prev,
                  {
                    ...item,
                    advancedCardData: {
                      image: curr.pageCroppedImageUrl,
                      firstText: curr.schoolName,
                      secondText: curr.majorName,
                      thirdText: t(item.degree.label),
                    },
                  },
                ];
              }, []);

              return {
                title: t('select_school'),
                items: schools,
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.SCHOOL,
              title: t('new_school'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: EDUCATION_LINK,
              transform: ({ school, degree, major, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    endDate: undefined,
                    schoolName: school?.label,
                    schoolPageId: `${school?.value}`?.includes('_temp')
                      ? null
                      : school?.value,
                    degree: degree?.value,
                    majorLookupId: major?.value,
                    majorName: major?.label,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ school, degree, major, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.SCHOOL,
                    title: school?.label,
                    subTitle: major?.label,
                    degree: degree?.value,
                  },
                  ['school', 'degree', 'major', 'isPublishedProfile']
                ),
              groups: schoolFormGroups,
              validationSchema: schoolValidationSchema,
            },
          },
          {
            parentId: 'education',
            id: 'newCourse',
            title: t('new_course'),
            subTitle: t('new_course_hint'),
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const courses = authUserProfile?.data?.courses?.reduce<
                Education[]
              >((prev, curr) => {
                const item = convertCourseApiToForm(curr);

                const formattedEndDate = curr.endDate
                  ? formatDate(curr.endDate, 'MMM y')
                  : undefined;
                const formattedStartDate = formatDate(curr.startDate, 'MMM y');
                const thirdText = [formattedStartDate, formattedEndDate]
                  .filter(Boolean)
                  .join(' - ');

                return [
                  ...prev,
                  {
                    ...item,
                    advancedCardData: {
                      image: curr.pageCroppedImageUrl,
                      firstText: curr.title,
                      secondText: curr.institutionName,
                      thirdText,
                    },
                  },
                ];
              }, []);

              return {
                title: t('select_course'),
                items: courses,
              };
            },
            image: () => <CourseHome />,
            form: {
              logo: HIGHLIGHT_TYPES.COURSE,
              title: t('new_course'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: COURSE_LINK,
              transform: ({ title, institution, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    title: title?.label,
                    titleLookupId: title?.value,
                    institutionName: institution?.label,
                    institutionPageId: `${institution?.value}`?.includes(
                      '_temp'
                    )
                      ? null
                      : institution?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ title, institution, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.COURSE,
                    title: title?.label,
                    subTitle: institution?.label,
                  },
                  ['institution', 'isPublishedProfile']
                ),
              groups: courseFormGroups,
              validationSchema: courseValidationSchema,
            },
          },
          {
            parentId: 'education',
            id: 'newLicence',
            title: t('new_licence_certificate'),
            subTitle: t('new_certification_desc'),
            image: () => <LicenceHome />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const certifications =
                authUserProfile?.data?.certifications?.reduce<Education[]>(
                  (prev, curr) => {
                    const item = convertLicenceApiToForm(curr);

                    const formattedEndDate = curr.endDate
                      ? formatDate(curr.endDate, 'MMM y')
                      : t('present');
                    const formattedStartDate = formatDate(
                      curr.startDate,
                      'MMM y'
                    );

                    return [
                      ...prev,
                      {
                        ...item,
                        advancedCardData: {
                          image: curr.pageCroppedImageUrl,
                          firstText: curr.title,
                          secondText: curr.institutionName,
                          thirdText: `${formattedStartDate} - ${formattedEndDate}`,
                        },
                      },
                    ];
                  },
                  []
                );

              return {
                title: t('select_certification'),
                items: certifications,
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.LICENSE,
              title: t('new_licence_certificate'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: LICENCE_LINK,
              transform: ({ institution, title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    certificationName: title?.label,
                    certificationLookupId: title?.value,
                    institutionName: institution?.label,
                    institutionLookupId: `${institution?.value}`?.includes(
                      '_temp'
                    )
                      ? null
                      : institution?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ institution, title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    title: title?.label,
                    type: HIGHLIGHT_TYPES.LICENSE,
                    subTitle: institution?.label,
                  },
                  ['institution', 'isPublishedProfile']
                ),
              validationSchema: () =>
                formValidator.object().shape({
                  certificateLink: linkValidation,
                }),
              groups: licenceFormGroups,
            },
          },

          {
            parentId: 'education',
            id: 'newGraduation',
            title: t('new_graduation'),
            subTitle: t('new_graduation_desc'),
            image: () => <GraduationHome />,
            form: {
              logo: HIGHLIGHT_TYPES.GRADUATION,
              title: t('new_graduation'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: EDUCATION_LINK,
              transform: ({ school, degree, major, endDate, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    currentlyStudying: undefined,
                    endDate,
                    schoolName: school?.label,
                    schoolPageId: `${school?.value}`?.includes('_temp')
                      ? null
                      : school?.value,
                    degree: degree?.value,
                    majorLookupId: major?.value,
                    majorName: major?.label,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ school, major, degree, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    degree: degree?.value,
                    type: HIGHLIGHT_TYPES.GRADUATION,
                    title: school?.label,
                    subTitle: major?.label,
                  },
                  ['school', 'isPublishedProfile']
                ),
              groups: [
                SCHOOL_FIELD,
                {
                  ...MAJOR_FIELD,
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.leftItem,
                    classes.formItem
                  ),
                },
                {
                  ...LOCATION_FIELD,
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.rightItem,
                    classes.formItem
                  ),
                },
                DEGREE_FIELD,
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                  name: 'endDate',
                },
              ],
            },
          },
          {
            parentId: 'education',
            id: 'studyAbroad',
            title: t('study_abroad'),
            subTitle: t('study_abroad_desc'),
            image: () => <Study />,
            form: {
              logo: HIGHLIGHT_TYPES.STUDY_ABROAD,
              title: t('study_abroad'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: EDUCATION_LINK,
              transform: ({ school, degree, major, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    currentlyStudying: undefined,
                    endDate: undefined,
                    schoolName: school?.label,
                    schoolPageId: `${school?.value}`?.includes('_temp')
                      ? null
                      : school?.value,
                    degree: degree?.value,
                    majorLookupId: major?.value,
                    majorName: major?.label,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ school, degree, major, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    degree: degree?.value,
                    type: HIGHLIGHT_TYPES.STUDY_ABROAD,
                    title: school?.label,
                    subTitle: major?.label,
                  },
                  ['school', 'major', 'isPublishedProfile']
                ),
              groups: [
                SCHOOL_FIELD,
                {
                  ...MAJOR_FIELD,
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.leftItem,
                    classes.formItem
                  ),
                },
                {
                  ...LOCATION_FIELD,
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.rightItem,
                    classes.formItem
                  ),
                },

                DEGREE_FIELD,
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
        ],
      },
      lifeStyle: {
        id: 'lifeStyle',
        title: t('life_style'),
        image: () => (
          <HighlightCard className={classes.highlightCard} type="TRAVEL" />
        ),
        items: [
          {
            parentId: 'lifeStyle',
            id: 'newTravel',
            title: t('new_travel'),
            subTitle: t('new_travel_desc'),
            image: () => <Travel />,
            form: {
              logo: HIGHLIGHT_TYPES.TRAVEL,
              title: t('new_travel'),
              initialValues: {},
              highlightTransform: ({ to, from, startDate, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.TRAVEL,
                    title: to?.label,
                    subTitle: [from?.label, to?.label]
                      .filter(Boolean)
                      .join(', '),
                    startDate: startDate,
                  },
                  ['to', 'from', 'isPublishedProfile']
                ),
              groups: [
                {
                  ...LOCATION_FIELD,
                  name: 'to',
                  label: t('to_where'),
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.leftItem,
                    classes.formItem
                  ),
                  required: true,
                },
                {
                  ...LOCATION_FIELD,
                  name: 'from',
                  label: t('from_where'),
                  wrapStyle: cnj(
                    classes.halfWidth,
                    classes.rightItem,
                    classes.formItem
                  ),
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
          {
            parentId: 'lifeStyle',
            id: 'newInstrument',
            title: t('new_instrument'),
            subTitle: t('new_instrument_desc'),
            image: () => <Instrument />,
            form: {
              logo: HIGHLIGHT_TYPES.INSTRUMENT,
              title: t('new_instrument'),
              initialValues: {},
              transform: ({ title, ...rest }) => ({
                ...rest,
                instrumentLookupId: title.value,
                instrumentName: title.label,
              }),
              highlightTransform: ({ title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    title: title?.label,
                    instrumentLookupId: title?.value,
                    type: HIGHLIGHT_TYPES.INSTRUMENT,
                  },
                  ['isPublishedProfile']
                ),
              groups: [
                {
                  name: 'title',
                  cp: 'asyncAutoComplete',
                  label: t('which_instrument'),
                  type: 'text',
                  maxLength: 100,
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  required: true,
                  url: Endpoints.App.Common.searchInstrument,
                  normalizer: (data: any) =>
                    data?.map(({ id: value, title }: any) => ({
                      label: title,
                      value,
                    })),
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
          {
            parentId: 'lifeStyle',
            id: 'newHobby',
            title: t('new_hobby'),
            subTitle: t('new_hobby_desc'),
            image: () => <Hobby />,
            form: {
              logo: HIGHLIGHT_TYPES.HOBBY,
              title: t('new_hobby'),
              initialValues: {},
              transform: ({ title, ...rest }) => ({
                ...rest,
                hobbyLookupId: title.value,
                hobbyName: title.label,
              }),
              highlightTransform: ({ title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    title: title?.label,
                    hobbyLookupId: title.value,
                    type: HIGHLIGHT_TYPES.HOBBY,
                  },
                  ['isPublishedProfile']
                ),
              groups: [
                {
                  name: 'title',
                  cp: 'asyncAutoComplete',
                  label: t('title'),
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  url: Endpoints.App.Common.searchHobby,
                  normalizer: (data: any) =>
                    data.map(({ id: value, title: label }: any) => ({
                      label,
                      value,
                    })),
                  required: true,
                  maxLength: 100,
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
          {
            parentId: 'lifeStyle',
            id: 'newSport',
            title: t('new_sport'),
            subTitle: t('new_sport_desc'),
            image: () => <Sport />,
            form: {
              logo: HIGHLIGHT_TYPES.SPORT,
              title: t('new_sport'),
              initialValues: {},
              transform: ({ title, ...rest }) => ({
                ...rest,
                hobbyLookupId: title.value,
                sportLookupId: title.value,
                sportName: title.label,
              }),
              highlightTransform: ({ title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    title: title?.label,
                    type: HIGHLIGHT_TYPES.SPORT,
                  },
                  ['isPublishedProfile']
                ),
              groups: [
                {
                  name: 'title',
                  cp: 'asyncAutoComplete',
                  label: t('sport_title'),
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  url: Endpoints.App.Common.searchSport,
                  normalizer: (data: any) =>
                    data.map(({ id: value, title: label }: any) => ({
                      label,
                      value,
                    })),
                  required: true,
                  maxLength: 100,
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
        ],
      },
      achievement: {
        id: 'achievement',
        title: t('achievements'),
        image: () => (
          <HighlightCard className={classes.highlightCard} type="LICENSE" />
        ),
        items: [
          {
            parentId: 'achievement',
            id: 'newPublication',
            title: t('publication'),
            subTitle: t('new_publication_desc'),
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const publications = authUserProfile?.data?.publications?.reduce<
                Education[]
              >((prev, curr) => {
                const item = convertPublicationApiToForm(curr);

                return [
                  ...prev,
                  {
                    ...item,
                    advancedCardData: {
                      image: curr.pageCroppedImageUrl,
                      firstText: curr.title,
                      secondText: curr.publisherName,
                      thirdText: formatDate(curr.date, 'MMM y'),
                    },
                  },
                ];
              }, []);

              return {
                title: t('select_publication'),
                items: publications,
              };
            },
            image: () => <PublicationHome />,
            form: {
              logo: HIGHLIGHT_TYPES.PUBLICATION,
              title: t('publication'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: PUBLICATION_LINK,
              transform: ({ publisher, ...rest }: any) => ({
                ...rest,
                publisherName: publisher?.label,
                publisherPageId: `${publisher?.value}`?.includes('_temp')
                  ? null
                  : publisher?.value,
              }),
              validationSchema: () =>
                formValidator.object().shape({
                  link: linkValidation,
                  description: DESC_LEN_VALIDATION,
                }),
              highlightTransform: ({ publisher, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.PUBLICATION,
                    subTitle: publisher?.label,
                  },
                  ['publisher', 'isPublishedProfile']
                ),
              groups: publicationFormGroups,
            },
          },
          {
            parentId: 'achievement',
            id: 'newAward',
            title: t('honor_aw'),
            subTitle: t('get_funded_desc'),
            image: () => <Award />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const awards = authUserProfile?.data?.awards?.reduce<Education[]>(
                (prev, curr) => {
                  const item = convertAwardApiToForm(curr);

                  return [
                    ...prev,
                    {
                      ...item,
                      advancedCardData: {
                        image: curr.pageCroppedImageUrl,
                        firstText: curr.title,
                        secondText: curr.institutionName,
                        thirdText: formatDate(curr.date, 'MMM y'),
                      },
                    },
                  ];
                },
                []
              );

              return {
                title: t('select_honor_award'),
                items: awards,
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.AWARD,
              title: t('honor_aw'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: AWARD_LINK,
              transform: ({ institution, title, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    awardLookupId: title?.id,
                    awardName: title?.label,
                    institutionName: institution?.label,
                    institutionPageId: `${institution?.value}`?.includes(
                      '_temp'
                    )
                      ? null
                      : institution?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({
                title,
                institution,
                date,
                ...rest
              }: any) =>
                omit(
                  {
                    ...rest,
                    awardLookupId: title?.id,
                    awardName: title?.label,
                    institutionName: institution?.label,
                    institutionPageId: institution?.value,
                    startDate: date,
                    title: title?.label,
                    subTitle: institution?.label,
                    type: HIGHLIGHT_TYPES.AWARD,
                  },
                  ['isPublishedProfile']
                ),
              groups: honorFormGroups,
              validationSchema: () =>
                formValidator.object().shape({
                  link: linkValidation,
                  description: DESC_LEN_VALIDATION,
                }),
            },
          },
          {
            parentId: 'achievement',
            id: 'newPatent',
            title: t('new_patent'),
            subTitle: t('new_patent_desc'),
            image: () => <PatentHome />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const patents = authUserProfile?.data?.patents?.reduce<
                Education[]
              >((prev, curr) => {
                const item = convertPatentApiToForm(curr);

                return [
                  ...prev,
                  {
                    ...item,
                    advancedCardData: {
                      image: curr.pageCroppedImageUrl,
                      firstText: curr.title,
                      secondText: curr.institutionName,
                      thirdText: formatDate(curr.date, 'MMM y'),
                    },
                  },
                ];
              }, []);

              return {
                title: t('select_patent'),
                items: patents,
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.PATENT,
              title: t('new_patent'),
              url: PATENT_LINK,
              transform: ({ institution, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    institutionName: institution?.label,
                    institutionPageId: `${institution?.value}`?.includes(
                      '_temp'
                    )
                      ? null
                      : institution?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ institution, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.PATENT,
                    subTitle: institution?.label,
                  },
                  ['institution', 'isPublishedProfile']
                ),
              groups: patentFormGroups,
            },
          },
          {
            parentId: 'achievement',
            id: 'getFunded',
            title: t('get_funded'),
            subTitle: t('get_funded_desc'),
            image: () => <Fund />,
            form: {
              logo: HIGHLIGHT_TYPES.GET_FUNDED,
              title: t('get_funded'),
              initialValues: {},
              highlightTransform: ({ amount, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.GET_FUNDED,
                    subTitle: amount,
                  },
                  ['institution', 'isPublishedProfile', 'amount']
                ),
              groups: [
                {
                  name: 'title',
                  cp: 'input',
                  label: t('title'),
                  type: 'text',
                  maxLength: 100,
                  required: true,
                  helperText: t('exmp_ang_f_sec'),
                },
                {
                  name: 'institution',
                  cp: 'asyncAutoComplete',
                  label: t('provider'),
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  required: true,
                  maxLength: 100,
                  normalizer: hereApiResponseNormalizer,
                  url: `${Endpoints.App.Common.suggestPlace}`,
                  wrapStyle: 'responsive-margin-top',
                  renderItem: ({ item }: any) => (
                    <AvatarCard
                      data={{
                        title: item.label,
                        image: item.image,
                      }}
                      avatarProps={{ isCompany: true, size: 'sm' }}
                    />
                  ),
                },
                {
                  name: 'currency',
                  cp: 'asyncAutoComplete',
                  label: t('currency'),
                  wrapStyle: cnj(
                    classes.leftItem,
                    classes.halfWidth,
                    classes.formItem
                  ),
                  type: 'text',
                  required: true,
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  url: Endpoints.App.Common.searchCurrency,
                  normalizer: (data: any) =>
                    data?.map(({ id: value, name, symbol, code }: any) => ({
                      label: `${name} (${symbol})`,
                      name,
                      symbol,
                      value,
                      code,
                    })),
                },
                {
                  name: 'amount',
                  cp: 'input',
                  label: t('amount'),
                  wrapStyle: cnj(
                    classes.rightItem,
                    classes.halfWidth,
                    classes.formItem
                  ),
                  type: 'number',
                  maxLength: 100,
                  required: true,
                },
                {
                  ...START_DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
        ],
      },
      selfDevelopment: {
        id: 'selfDevelopment',
        image: () => (
          <HighlightCard className={classes.highlightCard} type="LANGUAGE" />
        ),
        title: t('self_development'),
        items: [
          {
            parentId: 'selfDevelopment',
            id: 'newLanguage',
            title: t('new_language'),
            subTitle: t('new_language_desc'),
            image: () => <LanguageHome />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const languages = authUserProfile?.data?.languages?.reduce<
                Language[]
              >((prev, curr) => {
                const item = languageNormalizer(curr);
                return [...prev, item.realData];
              }, []);

              return {
                title: t('select_language'),
                cp: 'progressBar',
                items: languages?.sort((a, b) =>
                  a.progress < b.progress ? 1 : -1
                ),
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.LANGUAGE,
              title: t('new_language'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: LANGUAGE_LINK,
              transform: ({ name, level }: any) =>
                omit(
                  {
                    name: name?.label,
                    languageLookupId: name?.value,
                    level: level?.value,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ name, level, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.LANGUAGE,
                    title: name?.label,
                    subTitle: level?.label,
                  },
                  ['name', 'isPublishedProfile']
                ),
              validationSchema: () =>
                formValidator.object().shape({
                  name: formValidator
                    .object()
                    .test(
                      'value',
                      'select_one_of_sug_langs',
                      (val) => val?.value
                    ),
                  level: formValidator
                    .object()
                    .when('name', (name: any, schema: any) =>
                      name?.value ? schema.required('required_level') : schema
                    ),
                }),
              groups: () => [
                {
                  name: 'name',
                  cp: 'asyncAutoComplete',
                  label: t('language'),
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  url: Endpoints.App.Common.getLanguages,
                  normalizer: lookupResponseNormalizer,
                  required: true,
                  maxLength: 100,
                  wrapStyle: classes.formItem,
                },
                {
                  name: 'level',
                  cp: 'dropdownSelect',
                  label: t('level'),
                  wrapStyle: classes.formItem,
                  options: db.LANGUAGE_LEVELS,
                  required: true,
                },
              ],
            },
          },

          {
            parentId: 'selfDevelopment',
            id: 'newSkill',
            title: t('new_skill'),
            subTitle: t('new_skill_desc'),
            image: () => <Skill />,
            fetchSelectionList: async () => {
              const authUserProfile = await fetchAuthUserProfile();
              const skills = authUserProfile?.data?.skills?.reduce<any[]>(
                (prev, curr) => {
                  const item = skillNormalizer(curr);
                  return [...prev, item.realData];
                },
                []
              );

              return {
                title: t('select_skill'),
                cp: 'progressBar',
                items: skills?.sort((a, b) =>
                  a.progress < b.progress ? 1 : -1
                ),
              };
            },
            form: {
              logo: HIGHLIGHT_TYPES.SKILL,
              title: t('new_skill'),
              initialValues: {
                isPublishedProfile: true,
              },
              url: SKILL_LINK,
              transform: ({ name, level }: SkillType) =>
                omit(
                  {
                    name: name?.label,
                    skillLookupId: name?.value,
                    level: level?.value,
                    type: name?.type,
                  },
                  ['isPublishedProfile']
                ),
              highlightTransform: ({ name, level, startDate, ...rest }: any) =>
                omit(
                  {
                    ...rest,
                    type: HIGHLIGHT_TYPES.SKILL,
                    title: name?.label,
                    subTitle: level?.label,
                  },
                  ['name', 'level', 'isPublishedProfile']
                ),
              validationSchema: () =>
                formValidator.object().shape({
                  level: formValidator
                    .object()
                    .test('value', 'required_level', (val) => val?.value),
                }),
              groups: () => [
                {
                  name: 'name',
                  cp: 'asyncAutoComplete',
                  label: t('skill'),
                  visibleRightIcon: true,
                  rightIconProps: { name: 'search' },
                  url: Endpoints.App.Common.getSkills,
                  normalizer: skillsResponseNormalizer,
                  required: true,
                  maxLength: 100,
                  wrapStyle: classes.formItem,
                },
                {
                  name: 'level',
                  cp: 'dropdownSelect',
                  label: t('level'),
                  wrapStyle: classes.formItem,
                  options: db.SKILL_LEVELS,
                  required: true,
                },
              ],
            },
          },
          {
            parentId: 'selfDevelopment',
            id: 'newAdventure',
            title: t('new_adventure'),
            subTitle: t('new_adventure_desc'),
            image: () => <Adventure />,
            form: {
              logo: HIGHLIGHT_TYPES.ADVENTURE,
              title: t('new_adventure'),
              initialValues: {},
              highlightTransform: ({ ...rest }: any) => ({
                ...rest,
                type: HIGHLIGHT_TYPES.ADVENTURE,
              }),
              groups: [
                {
                  name: 'title',
                  helperText: t('exp_da_aro_th_wor'),
                  cp: 'input',
                  label: t('title'),
                  type: 'text',
                  maxLength: 100,
                  required: true,
                },
                {
                  ...DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
          {
            parentId: 'selfDevelopment',
            id: 'conferencePartipation',
            title: t('conference_participation'),
            subTitle: t('conference_participation_desc'),
            image: () => <Conference />,
            form: {
              logo: HIGHLIGHT_TYPES.CONFERENCE_PARTICIPATION,
              title: t('conference_participation'),
              initialValues: {},
              highlightTransform: ({ ...rest }: any) => ({
                ...rest,
                type: HIGHLIGHT_TYPES.CONFERENCE_PARTICIPATION,
              }),
              groups: [
                {
                  name: 'title',
                  cp: 'input',
                  label: t('title'),
                  type: 'text',
                  maxLength: 100,
                  required: true,
                  helperText: t('exmp_inter_conf_con'),
                },
                {
                  ...DATE_FIELD,
                  label: t('date'),
                },
              ],
            },
          },
        ],
      },
    }),
    []
  );
  return { highlights };
};

export default useHighlights;
