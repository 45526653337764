import React from 'react';

const EntrepreneurshipIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M37.6824 22.0175C37.3376 22.0175 36.9999 21.8992 36.7282 21.6772C36.3778 21.3908 36.1744 20.9621 36.1744 20.5096V4.73348C36.1744 3.9007 36.8496 3.22559 37.6823 3.22559H52.4558C53.2484 3.22559 53.9812 3.58505 54.4662 4.21193C54.9513 4.83873 55.1155 5.63829 54.9166 6.40553L53.1744 13.1272C52.2495 16.695 49.4556 19.4739 45.883 20.3796C45.8597 20.3855 45.8363 20.3908 45.8128 20.3955L37.9827 21.9873C37.8832 22.0075 37.7825 22.0175 37.6824 22.0175ZM39.1904 6.24138V18.6643L45.1752 17.4477C47.6647 16.8039 49.6094 14.8613 50.2551 12.3704L51.8438 6.24146H39.1904V6.24138Z"
          fill="#64A2FF"
        />
        <path
          d="M30.3163 22.0175C30.2162 22.0175 30.1157 22.0075 30.0159 21.9873L22.1858 20.3955C22.1624 20.3908 22.139 20.3854 22.1158 20.3796C18.5431 19.4738 15.7491 16.695 14.8244 13.1271L13.0821 6.40553C12.8831 5.63837 13.0472 4.83881 13.5323 4.21201C14.0174 3.58513 14.7502 3.22559 15.5428 3.22559H30.3163C31.149 3.22559 31.8242 3.9007 31.8242 4.73348V20.5096C31.8242 20.9621 31.6209 21.3908 31.2704 21.6772C30.9988 21.8992 30.661 22.0175 30.3163 22.0175ZM22.8236 17.4477L28.8084 18.6643V6.24138H16.155L17.7437 12.3703C18.3893 14.8613 20.334 16.8038 22.8236 17.4477Z"
          fill="#64A2FF"
        />
        <path
          d="M39.163 33.5174C35.2494 32.2255 32.5217 28.2017 32.377 23.5716C32.5347 22.84 32.6173 22.0835 32.6173 21.3121V16.1943H31.5174V21.3121C31.5174 26.0839 28.3603 30.28 23.7754 31.6022V34.703H40.1129V33.831L39.163 33.5174Z"
          fill="#5384EE"
        />
        <path
          d="M43.2737 31.3283C39.2514 30.1684 36.4817 26.4871 36.4817 22.3008V16.1943H31.517V21.3121C31.517 22.0836 31.4345 22.84 31.2767 23.5716C31.4214 28.2017 34.1491 32.2255 38.0627 33.5174L39.0127 33.831V34.703H44.2237V31.6022L43.2737 31.3283Z"
          fill="#3F73E3"
        />
        <path
          d="M41.2658 31.5693H21.9956C21.2441 31.5693 20.6349 32.1785 20.6349 32.9301V39.7624H42.6265V32.9301C42.6265 32.1785 42.0173 31.5693 41.2658 31.5693Z"
          fill="#5384EE"
        />
        <path
          d="M46.0044 31.5693H40.56C41.3115 31.5693 41.9208 32.1786 41.9208 32.9301V39.7624H47.3652V32.9301C47.3651 32.1786 46.7559 31.5693 46.0044 31.5693Z"
          fill="#5384EE"
        />
        <path
          d="M40.8413 9.1262V1.49023H22.6757V8.86197C22.6757 14.9097 25.9498 20.4836 31.232 23.4285C31.6249 23.6476 32.0375 23.8166 32.4605 23.9356C37.6485 20.8207 40.8413 15.2045 40.8413 9.1262Z"
          fill="#5384EE"
        />
        <path
          d="M39.8672 1.49023V8.86197C39.8672 14.9097 36.5932 20.4836 31.3108 23.4285C31.2976 23.4358 31.2845 23.4431 31.2713 23.4503C32.9844 24.3877 35.0601 24.3804 36.7676 23.4285C42.0498 20.4835 45.3239 14.9097 45.3239 8.86197V1.49023H39.8672Z"
          fill="#5384EE"
        />
        <path
          d="M45.3426 40.8901V40.0381C45.3426 39.2051 44.6676 38.5297 43.8346 38.5293H19.4128C18.5795 38.5293 17.904 39.2048 17.904 40.0381C17.904 40.8714 18.5795 41.5469 19.4128 41.5469H44.6363L45.3426 40.8901Z"
          fill="#5384EE"
        />
        <path
          d="M48.5868 38.5293H43.1274C43.9607 38.5293 44.6362 39.2048 44.6362 40.0381V41.5469H48.5868C49.4201 41.5469 50.0956 40.8714 50.0956 40.0381C50.0956 39.2048 49.4201 38.5293 48.5868 38.5293Z"
          fill="#5384EE"
        />
        <path
          d="M43.8211 1.43301V0.229691L42.6518 -0.359375H21.6841C20.6942 -0.359375 19.8918 0.443054 19.8918 1.43293C19.8918 2.4228 20.6942 3.22523 21.6841 3.22523H42.0288C43.0186 3.22539 43.8211 2.42288 43.8211 1.43301Z"
          fill="#5384EE"
        />
        <path
          d="M46.316 -0.359375H42.6516V1.43293C42.6516 2.4228 41.8491 3.22523 40.8593 3.22523H46.3161C47.3059 3.22523 48.1084 2.4228 48.1084 1.43293C48.1084 0.443054 47.3059 -0.359375 46.316 -0.359375Z"
          fill="#64A2FF"
        />
        <path
          d="M41.9199 35.8417H26.079C25.7244 35.8417 25.437 35.5543 25.437 35.1997C25.437 34.8451 25.7244 34.5576 26.079 34.5576H41.9199C42.2745 34.5576 42.562 34.8451 42.562 35.1997C42.562 35.5543 42.2745 35.8417 41.9199 35.8417Z"
          fill="white"
        />
        <path
          d="M34.4901 7.62046L35.4371 10.2147C35.51 10.4143 35.6966 10.5499 35.909 10.5575L38.6689 10.6565C39.1556 10.674 39.3556 11.2894 38.9721 11.5895L36.7975 13.2919C36.6301 13.4229 36.5588 13.6422 36.6172 13.8465L37.376 16.5019C37.5097 16.9702 36.9863 17.3505 36.5822 17.0786L34.2912 15.5365C34.1149 15.4178 33.8843 15.4178 33.708 15.5365L31.417 17.0786C31.0129 17.3505 30.4894 16.9702 30.6232 16.5019L31.3819 13.8465C31.4403 13.6421 31.369 13.4228 31.2017 13.2919L29.0271 11.5895C28.6436 11.2893 28.8435 10.674 29.3302 10.6565L32.0902 10.5575C32.3026 10.5499 32.4891 10.4144 32.562 10.2147L33.5091 7.62046C33.676 7.16297 34.3231 7.16297 34.4901 7.62046Z"
          fill="white"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="42"
          height="42"
          fill="white"
          transform="translate(13 -0.40625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EntrepreneurshipIcon;
