import React from 'react';

const FundIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.4217 20.1292C49.9802 20.1292 54.4863 15.6231 54.4863 10.0646C54.4863 4.50607 49.9802 0 44.4217 0C38.8632 0 34.3571 4.50607 34.3571 10.0646C34.3571 15.6231 38.8632 20.1292 44.4217 20.1292Z"
      fill="#5384EE"
    />
    <path
      d="M46.6232 17.9278C41.0647 17.9278 36.5587 13.4218 36.5587 7.86332C36.5587 5.64627 37.2841 3.60349 38.4987 1.94043C35.9922 3.77099 34.3571 6.72349 34.3571 10.0649C34.3571 15.6234 38.8631 20.1294 44.4216 20.1294C47.763 20.1294 50.7156 18.4943 52.5461 15.9878C50.883 17.2024 48.8403 17.9278 46.6232 17.9278Z"
      fill="#3F73E3"
    />
    <path
      d="M40.0009 23.5582L35.5668 21.8528C34.838 21.5725 34.3571 20.8723 34.3571 20.0915V16.9844H26.1797V20.0915C26.1797 20.8723 25.6988 21.5725 24.97 21.8528L20.536 23.5582C19.0223 24.1404 18.0497 25.6245 18.1202 27.2448L18.579 37.797C18.6082 38.47 19.1623 39.0005 19.8358 39.0005H40.7009C41.3745 39.0005 41.9285 38.47 41.9578 37.797L42.4166 27.2448C42.487 25.6245 41.5146 24.1404 40.0009 23.5582Z"
      fill="#FFE5C2"
    />
    <path
      d="M40.0009 23.5575L36.025 22.0283C35.3296 24.5581 33.0192 26.4192 30.2685 26.4192C27.5177 26.4192 25.2073 24.5582 24.5119 22.0283L20.536 23.5575C19.0223 24.1397 18.0497 25.6239 18.1202 27.2441L18.579 37.7963C18.6083 38.4693 19.1624 38.9998 19.8359 38.9998H40.701C41.3745 38.9998 41.9286 38.4693 41.9578 37.7963L42.4166 27.2441C42.4871 25.6239 41.5146 24.1397 40.0009 23.5575Z"
      fill="#5384EE"
    />
    <path
      d="M26.1798 19.9509C27.3148 20.7241 28.6798 21.1736 30.1373 21.1736C31.7089 21.1736 33.1737 20.6522 34.3572 19.7641V16.9834H26.1798V19.9509Z"
      fill="#FED2A4"
    />
    <path
      d="M30.1377 3.16369C27.2504 3.31977 25.6076 4.89858 25.6076 4.89858C25.6076 4.89858 23.198 4.89858 23.198 7.79007C23.198 10.1033 23.7763 12.3983 23.7763 12.3983H37.0773C38.2339 6.03705 35.487 2.87454 30.1377 3.16369Z"
      fill="#485363"
    />
    <path
      d="M27.4383 9.43569C24.9222 7.54861 25.6077 4.89844 25.6077 4.89844C25.6077 4.89844 23.198 4.89844 23.198 7.78992C23.198 10.1031 23.7763 12.3982 23.7763 12.3982H26.6678L27.4383 9.43569Z"
      fill="#072252"
    />
    <path
      d="M36.4983 11.8195H35.92L35.8202 11.3105C35.7545 10.9753 35.6079 10.6556 35.3705 10.41C32.5653 7.50682 28.3182 7.93132 27.1283 8.11932C26.9286 8.15085 26.759 8.27592 26.6693 8.45706C26.0691 9.6701 25.3754 11.1276 24.9584 11.5114C24.7434 11.7094 24.4643 11.8194 24.172 11.8194H23.7559C23.7556 11.8194 23.7554 11.8196 23.755 11.8196C23.6919 11.8207 23.6276 11.8274 23.562 11.84C22.9987 11.9483 22.6189 12.4866 22.6189 13.0603V13.4794C22.6189 14.0326 22.9732 14.5544 23.5119 14.6802C23.6332 14.7085 23.7512 14.7165 23.8642 14.7074C24.1517 14.6845 24.4027 14.8937 24.4545 15.1775C24.9461 17.872 27.3003 19.9157 30.1368 19.9157C32.9734 19.9157 35.3275 17.872 35.8192 15.1775C35.8691 14.9042 36.105 14.7051 36.379 14.7109H36.4982C37.137 14.7109 37.6548 14.1931 37.6548 13.5543V12.976C37.6549 12.3373 37.1371 11.8195 36.4983 11.8195Z"
      fill="#FFE5C2"
    />
    <path
      d="M45.208 9.47915V6.52657C46.1835 6.7135 46.7806 7.21532 46.7806 7.58803C46.7806 8.02236 47.1325 8.37435 47.5669 8.37435C48.0012 8.37435 48.3532 8.02236 48.3532 7.58803C48.3532 6.25311 47.0234 5.17269 45.2081 4.92788V4.40351C45.2081 3.96918 44.8561 3.61719 44.4217 3.61719C43.9874 3.61719 43.6354 3.96918 43.6354 4.40351V4.92788C41.8201 5.17269 40.4903 6.25311 40.4903 7.58803C40.4903 9.47633 42.2144 10.2082 43.6354 10.6506V13.6032C42.6599 13.4162 42.0628 12.9144 42.0628 12.5417C42.0628 12.1074 41.7109 11.7554 41.2765 11.7554C40.8422 11.7554 40.4902 12.1074 40.4902 12.5417C40.4902 13.8766 41.82 14.957 43.6353 15.2019V15.7262C43.6353 16.1606 43.9873 16.5126 44.4217 16.5126C44.856 16.5126 45.208 16.1606 45.208 15.7262V15.2019C47.0234 14.957 48.3531 13.8766 48.3531 12.5417C48.3531 10.6533 46.629 9.92148 45.208 9.47915ZM42.0628 7.58803C42.0628 7.21532 42.6599 6.7135 43.6354 6.52657V8.98266C42.4768 8.56074 42.0628 8.17501 42.0628 7.58803ZM45.208 13.6031V11.147C46.3666 11.5689 46.7806 11.9547 46.7806 12.5416C46.7806 12.9143 46.1835 13.4162 45.208 13.6031Z"
      fill="white"
    />
    <path
      d="M26.6671 14.1331V8.46191C26.0673 9.67411 25.3748 11.1284 24.9584 11.5118C24.7433 11.7097 24.4643 11.8198 24.172 11.8198H23.7559C23.7556 11.8198 23.7554 11.82 23.755 11.82C23.6919 11.8212 23.6276 11.8278 23.562 11.8404C22.9987 11.9488 22.6189 12.4871 22.6189 13.0607V13.4798C22.6189 14.0331 22.9732 14.5548 23.5119 14.6806C23.6332 14.7088 23.7512 14.7168 23.8642 14.7079C24.1517 14.6849 24.4027 14.8942 24.4545 15.1779C24.9462 17.8724 27.3003 19.9161 30.1368 19.9161C30.53 19.9161 30.9125 19.8732 31.2834 19.7984C31.2835 19.7984 31.2835 19.7983 31.2835 19.7983C28.6488 19.2587 26.6671 16.9274 26.6671 14.1331Z"
      fill="#FED2A4"
    />
    <path
      d="M22.1727 30.046C22.1727 29.1258 21.8071 28.2432 21.1564 27.5925L18.6781 25.1143C18.2951 25.7402 18.0873 26.4751 18.1207 27.2444L18.5795 37.7966C18.6087 38.4695 19.1628 39.0001 19.8363 39.0001H22.1726L22.1727 30.046Z"
      fill="#3F73E3"
    />
    <path
      d="M38.365 30.046C38.365 29.1258 38.7305 28.2432 39.3813 27.5925L41.8595 25.1143C42.2426 25.7402 42.4504 26.4751 42.417 27.2444L41.9582 37.7966C41.9288 38.4695 41.3748 39.0001 40.7013 39.0001H38.365V30.046Z"
      fill="#3F73E3"
    />
    <path
      d="M23.3308 22.4825C24.2119 25.4839 26.9819 27.6772 30.2682 27.6772C33.5546 27.6772 36.3247 25.4839 37.2057 22.4825L36.0247 22.0283C35.3293 24.5581 33.0189 26.4192 30.2682 26.4192C27.5174 26.4192 25.207 24.5582 24.5116 22.0283L23.3308 22.4825Z"
      fill="#3F73E3"
    />
    <path
      d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
      fill="#3F73E3"
    />
    <path
      d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
      fill="white"
    />
    <path
      d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
      fill="#3F73E3"
    />
  </svg>
);

export default FundIcon;
