import React from 'react';

import type { FC } from 'react';
import type { HighlightCardProps } from './types';
import HighlightCardWithData from './HighlightCard.info';
import HighlightCardBanner from './HighlightCard.banner';

const HighlightCard: FC<HighlightCardProps> = (props) => {
  const { data } = props;

  if (data) {
    return <HighlightCardWithData {...props} />;
  }

  return <HighlightCardBanner {...props} />;
};

export default HighlightCard;
