import classes from './useLicence.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  Endpoints,
  formValidator,
  hereApiResponseNormalizer,
  linkValidation,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfileModals, useProfile } from '@shared/hooks';
import { LICENCE } from '@shared/constants/profileModalsKeys';
import LicencSvg from '@shared/svg/SvgLicence';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import type { Licence } from '@lobox/utils';
import useShowSuccessToast from './useShowSuccessToast';

export const useLicenceFormGroups = ({
  isEdit,
  isHighlight,
}: {
  isEdit?: boolean;
  isHighlight?: boolean;
} = {}) => {
  const { t } = useTranslation();
  const groups = () =>
    [
      {
        name: 'title',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('title'),
        wrapStyle: classes.formItem,
        required: true,
        type: 'text',
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        url: Endpoints.App.Common.searchCertification,
        normalizer: (data: any) =>
          data.map(({ id: value, title: label }: any) => ({
            label,
            value,
          })),
      },
      {
        name: 'institution',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        label: t('provider'),
        normalizer: hereApiResponseNormalizer,
        url: `${Endpoints.App.Common.suggestPlace}`,
        wrapStyle: classes.formItem,
        renderItem: ({ item }: any) => (
          <AvatarCard
            data={{
              title: item.label,
              image: item.image,
            }}
            avatarProps={{ isCompany: true, size: 'sm' }}
          />
        ),
      },
      {
        name: 'certificateLink',
        cp: 'input',
        label: t('link'),
        wrapStyle: classes.formItem,
      },
      {
        name: 'verificationCode',
        cp: 'input',
        maxLength: 100,
        label: t('verification_code'),
        wrapStyle: classes.formItem,
      },
      {
        name: 'startDate',
        cp: 'datePicker',
        wrapStyle: 'responsive-margin-top',
        variant: 'input',
        picker: 'month',
        required: true,
        label: t('start_date'),
      },
      {
        name: 'description',
        visibleOptionalLabel: false,
        cp: 'richtext',
        label: t('tell_more_about_licence'),
        wrapStyle: classes.formItem,
        maxLength: DESCRIPTION_MAX_LENGTH,
      },
    ].reduce((prev: any, curr) => {
      if (curr.name === 'description' && isHighlight) {
        return prev;
      }
      return [...prev, curr];
    }, []);
  return React.useCallback(groups, []);
};
const useLicence = () => {
  const { t } = useTranslation();
  const { close, modals } = useProfileModals();
  const { reFetchAboutSectionQuery } = useProfile();
  const { openConfirmDialog } = useOpenConfirm();
  const showSuccessToast = useShowSuccessToast();

  const licence = modals?.params?.[LICENCE]?.realData || {};
  const isEdit = !!licence?.id;
  const editUrl = `${Endpoints.App.User.Licence.get}/${licence?.id}`;
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const onSuccess = (data: Licence, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          ...data,
          profileEntityId: data.id,
          subTitle: data.institutionName,
          certificationName: data.title?.label,
          certificationLookupId: data.title?.value,
          type: HIGHLIGHT_TYPES.LICENSE,
          verificationCode: data.verificationCode,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isEdit,
      isDelete: !values,
      shared: values?.share,
      variant: LICENCE,
    });
  };

  const { mutate } = useReactMutation({
    url: editUrl,
    method: 'DELETE',
  });

  const groups = useLicenceFormGroups();

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_licence_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };

  return {
    title: isEdit ? t('edit_licence') : t('add_licence'),
    onSuccess,
    method: isEdit ? 'PUT' : 'POST',
    image: <LicencSvg />,
    url: isEdit ? editUrl : Endpoints.App.User.Licence.get,
    initialValues: { ...licence, share: true } as Licence,
    visibleRequiredHint: true,
    visibleHeaderBorderBottom: false,
    transform: ({ institution, title, ...rest }: Licence) => ({
      ...rest,
      certificationName: title?.label,
      certificationLookupId: title?.value,
      institutionName: institution?.label,
      institutionPageId: `${institution?.value}`?.includes('_temp')
        ? null
        : institution?.value,
    }),
    validationSchema: () =>
      formValidator.object().shape({
        certificateLink: linkValidation,
      }),
    secondaryAction: isEdit
      ? {
          label: t('delete_licence'),
          onClick: openConfirm,
        }
      : undefined,
    groups,
  };
};

export default useLicence;
