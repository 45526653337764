import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const EmptyHighlightIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='highlight_create_11223'>
        <path
          fill='#fff'
          d='M30.796 64H1.634C1.013 64 .51 63.504.51 62.89v-33.99c0-.614.503-1.112 1.124-1.112h29.162c.62 0 1.124.498 1.124 1.112v33.99c0 .614-.504 1.112-1.124 1.112Z'
        />
        <path
          fill='#3F73E3'
          d='m7.694 27.787-.981 2.562a.7.7 0 0 0 .704.696.7.7 0 0 0 .705-.697l.981-2.56H7.694Z'
        />
        <path
          fill='#CDCDCD'
          d='m11.258 27.787-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.982-2.56h-1.41Z'
        />
        <path
          fill='#3F73E3'
          d='m14.822 27.787-.981 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .705-.697l.981-2.56h-1.409Z'
        />
        <path
          fill='#072252'
          d='m4.13 27.787-.982 2.562a.7.7 0 0 0 .705.696.7.7 0 0 0 .704-.697l.982-2.56h-1.41Z'
        />
        <path
          fill='#ECECEC'
          d='m29.077 27.787-.981 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .704-.697l.982-2.56h-1.409Z'
        />
        <path
          fill='#fff'
          d='m25.515 27.787-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.981-2.56h-1.408Z'
        />
        <path
          fill='#8493A8'
          d='m18.387 27.787-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.982-2.56h-1.41Z'
        />
        <path
          fill='#CDCDCD'
          d='m21.95 27.787-.982 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .705-.697l.981-2.56h-1.409Z'
        />
        <path
          fill='#5384EE'
          fillOpacity={0.2}
          d='M10.88 61.099H4.203a.347.347 0 0 1-.349-.345V55.56c0-.19.157-.345.349-.345h6.677c.192 0 .348.154.348.345v5.194c0 .19-.156.345-.348.345Z'
        />
        <path
          fill='#3F73E3'
          d='M3.852 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 3.852 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM7.416 31.044a.7.7 0 0 1-.704-.696v-4.651A.7.7 0 0 1 7.416 25a.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM10.98 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 10.98 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM14.544 31.044a.7.7 0 0 1-.704-.696v-4.651a.7.7 0 0 1 .704-.697.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM18.109 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 18.11 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM21.671 31.044a.7.7 0 0 1-.704-.696v-4.651A.7.7 0 0 1 21.67 25a.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM25.237 31.044a.7.7 0 0 1-.705-.696v-4.651a.7.7 0 0 1 .705-.697.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM28.8 31.044a.7.7 0 0 1-.705-.696v-4.651a.7.7 0 0 1 .704-.697.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697Z'
        />
        <path
          fill='#5384EE'
          fillOpacity={0.2}
          d='M28.8 38.153H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.697.7.7 0 0 1-.704.696ZM28.8 41.321H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.697.7.7 0 0 1-.704.696ZM28.8 44.49H3.852a.7.7 0 0 1-.705-.698.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697ZM28.8 47.657H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697ZM28.8 50.825H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697Z'
        />
      </g>
      <g clipPath='url(#b)'>
        <path
          fill='#4D719A'
          d='M50.182 32.638 48.79 31.26l-8.938 9.53 5.223-.345 5.107-5.052c.77-.76.77-1.995 0-2.755Z'
        />
        <path
          fill='#072252'
          d='m48.789 31.26-.697-.69a1.985 1.985 0 0 0-2.785 0l-5.108 5.053.697 6.2 7.893-7.808c.77-.76.77-1.995 0-2.756Z'
        />
        <path
          fill='#F7FCFF'
          d='m36.022 41.133 6.964 1.378 2.09-2.067-1.393-1.378-7.661 2.067Z'
        />
        <path
          fill='#E1F5FE'
          d='m43.683 39.068-3.482-3.445-2.09 2.067.697 6.2 4.875-4.822Z'
        />
        <path
          fill='#072252'
          d='m22.304 58.147-3.284 1.87-.485 1.226c-.156.396.238.786.638.631l2.957-1.144.174-2.583Z'
        />
        <path
          fill='#485363'
          d='m22.303 58.147-2.612.172-.671 1.697 3.241-1.254.042-.615Z'
        />
        <path
          fill='#FED2A4'
          d='m26.482 54.014-5.746 5.339 1.393 1.377 4.005-1.55.348-5.166Z'
        />
        <path
          fill='#FFE5C2'
          d='m24.74 57.803 1.742-3.79-5.223.345-1.568 3.961 1.045 1.034 4.005-1.55Z'
        />
        <path
          fill='#5384EE'
          d='m24.741 57.803 1.393 1.378 16.852-16.668-1.393-1.378-11.212 5.578-5.64 11.09Z'
        />
        <path
          fill='#3F73E3'
          d='M21.258 54.355 38.107 37.69l3.482 3.444-16.85 16.665-3.481-3.444Z'
        />
      </g>
      <defs>
        <clipPath id='highlight_create_11223a'>
          <path fill='#fff' d='M.5 25h32v39H.5z' />
        </clipPath>
        <clipPath id='highlight_create_11223b'>
          <path fill='#fff' d='M18.5 30h32.26v31.909H18.5z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='highlight_create_11223'>
        <path
          fill='#fff'
          d='M30.796 64H1.634C1.013 64 .51 63.504.51 62.89v-33.99c0-.614.503-1.112 1.124-1.112h29.162c.62 0 1.124.498 1.124 1.112v33.99c0 .614-.504 1.112-1.124 1.112Z'
        />
        <path
          fill='#CDCDCD'
          d='m7.694 27.787-.981 2.562a.7.7 0 0 0 .704.696.7.7 0 0 0 .705-.697l.981-2.56H7.694ZM11.258 27.787l-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.982-2.56h-1.41ZM14.822 27.787l-.981 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .705-.697l.981-2.56h-1.409ZM4.13 27.787l-.982 2.562a.7.7 0 0 0 .705.696.7.7 0 0 0 .704-.697l.982-2.56h-1.41ZM29.077 27.787l-.981 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .704-.697l.982-2.56h-1.409ZM25.515 27.787l-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.981-2.56h-1.408ZM18.387 27.787l-.982 2.561a.7.7 0 0 0 .705.697.7.7 0 0 0 .704-.697l.982-2.56h-1.41ZM21.95 27.787l-.982 2.561a.7.7 0 0 0 .704.697.7.7 0 0 0 .705-.697l.981-2.56h-1.409Z'
        />
        <path
          fill='#5384EE'
          fillOpacity={0.2}
          d='M10.88 61.099H4.203a.347.347 0 0 1-.349-.345V55.56c0-.19.157-.345.349-.345h6.677c.192 0 .348.154.348.345v5.194c0 .19-.156.345-.348.345Z'
        />
        <path
          fill='#3F73E3'
          d='M3.852 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 3.852 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM7.416 31.044a.7.7 0 0 1-.704-.696v-4.651A.7.7 0 0 1 7.416 25a.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM10.98 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 10.98 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM14.544 31.044a.7.7 0 0 1-.704-.696v-4.651a.7.7 0 0 1 .704-.697.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM18.109 31.044a.7.7 0 0 1-.705-.696v-4.651A.7.7 0 0 1 18.11 25a.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM21.671 31.044a.7.7 0 0 1-.704-.696v-4.651A.7.7 0 0 1 21.67 25a.7.7 0 0 1 .705.697v4.65a.7.7 0 0 1-.705.697ZM25.237 31.044a.7.7 0 0 1-.705-.696v-4.651a.7.7 0 0 1 .705-.697.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697ZM28.8 31.044a.7.7 0 0 1-.705-.696v-4.651a.7.7 0 0 1 .704-.697.7.7 0 0 1 .704.697v4.65a.7.7 0 0 1-.704.697Z'
        />
        <path
          fill='#5384EE'
          fillOpacity={0.2}
          d='M28.8 38.153H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.697.7.7 0 0 1-.704.696ZM28.8 41.321H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.697.7.7 0 0 1-.704.696ZM28.8 44.49H3.852a.7.7 0 0 1-.705-.698.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697ZM28.8 47.657H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697ZM28.8 50.825H3.852a.7.7 0 0 1-.705-.697.7.7 0 0 1 .705-.696H28.8a.7.7 0 0 1 .704.696.7.7 0 0 1-.704.697Z'
        />
      </g>
      <g clipPath='url(#b)'>
        <path
          fill='#4D719A'
          d='m49.69 32.364-1.394-1.378-8.938 9.53 5.224-.344 5.107-5.052c.77-.761.77-1.995 0-2.756Z'
        />
        <path
          fill='#072252'
          d='m48.296 30.986-.697-.689a1.985 1.985 0 0 0-2.785 0l-5.108 5.052.697 6.2 7.893-7.807c.77-.761.77-1.995 0-2.756Z'
        />
        <path
          fill='#F7FCFF'
          d='m35.528 40.86 6.965 1.377 2.09-2.066-1.393-1.378-7.662 2.067Z'
        />
        <path
          fill='#E1F5FE'
          d='m43.19 38.794-3.482-3.444-2.09 2.066.697 6.2 4.875-4.822Z'
        />
        <path
          fill='#072252'
          d='m21.81 57.873-3.283 1.87-.485 1.227c-.157.395.238.785.638.63l2.956-1.144.175-2.583Z'
        />
        <path
          fill='#485363'
          d='m21.81 57.873-2.612.172-.672 1.698 3.242-1.255.042-.615Z'
        />
        <path
          fill='#FED2A4'
          d='m25.99 53.74-5.747 5.34 1.393 1.377 4.005-1.55.348-5.167Z'
        />
        <path
          fill='#FFE5C2'
          d='m24.248 57.53 1.74-3.79-5.223.345-1.567 3.96 1.045 1.034 4.005-1.55Z'
        />
        <path
          fill='#5384EE'
          d='m24.248 57.53 1.393 1.378 16.852-16.669-1.393-1.378-11.212 5.579-5.64 11.09Z'
        />
        <path
          fill='#3F73E3'
          d='m20.765 54.082 16.849-16.666 3.482 3.444-16.85 16.666-3.481-3.444Z'
        />
      </g>
      <defs>
        <clipPath id='highlight_create_11223a'>
          <path fill='#fff' d='M.5 25h32v39H.5z' />
        </clipPath>
        <clipPath id='highlight_create_11223b'>
          <path fill='#fff' d='M18.007 29.727h32.26v31.909h-32.26z' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EmptyHighlightIcon;
