import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgPublication(props: any) {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      width={284}
      height={190}
      viewBox="0 0 284 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.702 190S-.452 167.199.645 126.855C1.742 86.511 45.748 80.2 75.858 105.074c30.232 24.875 45.709 12.706 61.313-8.332 15.603-21.038 39.621-68.642 83.384-50.822 32.67 13.242 54.49 48.76 61.56 80.811 4.876 22.028-2.194 45.294-19.138 59.773-.61.495-4.446 3.125-5.055 3.496H46.702z"
        fill="url(#SvgPublication0_linear)"
      />
      <path
        d="M211.729 151.199H75.044c-5.218 0-9.472-3.85-9.472-8.576v-5.075c0-4.725 4.254-8.575 9.472-8.575H211.73c-6.271 6.169-6.359 15.619-.263 21.963l.263.263z"
        fill="url(#SvgPublication1_linear)"
      />
      <path
        d="M209.844 129.289h-68.803c-4.736 0-8.595 3.85-8.595 8.576 0 4.725 3.859 8.575 8.595 8.575h68.803c-3.245-5.25-3.201-11.901 0-17.151z"
        fill="url(#SvgPublication2_linear)"
      />
      <path
        d="M267.946 151.156H64.826c-6.314 0-11.401 5.119-11.401 11.375v9.582c0 6.301 5.13 11.376 11.401 11.376h203.12l-.088-.131c-8.726-8.97-8.683-23.233.088-32.202z"
        fill="url(#SvgPublication3_linear)"
      />
      <path
        d="M264.349 155.925H155.773c-6.314 0-11.401 5.119-11.401 11.376 0 6.3 5.131 11.376 11.401 11.376h108.576c-3.99-7.001-3.99-15.708 0-22.752z"
        fill="url(#SvgPublication4_linear)"
      />
      <path
        d="M18.826 177.217l4.298-1.006s3.464.218 6.709-.438c0 0 .307.875.482 2.625.044.307-.395 1.838-.701 1.882-1.097.175-3.026-.044-6.49.043 0 0-5.394.657-8.332-.306-2.938-.962 4.034-2.8 4.034-2.8z"
        fill="#112B46"
      />
      <path
        d="M66.099 107.301c.219 2.494 2.324 3.369 4.253 4.9 1.316 1.05 1.096 3.894.088 5.644-2.543 4.463-.395 5.032 1.666 8.313 4.912 7.919-10.305 9.669-12.103 10.238-1.798.569-3.026 1.313-4.078 1.181-3.201-.35-6.14-4.9-7.805-9.144-1.623-4.2 2.324-4.419 2.543-6.869.22-2.406-4.43-2.931-3.07-7.525 1.491-4.944 3.596-9.145 6.359-10.632 4.604-2.494 10.217.131 11.489 2.1.394.7.614 1.269.658 1.794z"
        fill="url(#SvgPublication5_linear)"
      />
      <path
        d="M66.448 161.51c.395 1.006 2.588 4.507 2.588 4.507l-30.872.306-.438-6.344c8.463-.088 16.839-3.807 24.644-6.476.175.482 3.903 7.526 4.078 8.007z"
        fill="#FFE5C2"
      />
      <path
        d="M44.567 137.271s1.666-11.682 4.341-12.863c2.675-1.225 17.102.481 19.251 1.794 2.149 1.312-1.447 15.007-1.973 18.857-.044.219-.088.437-.088.7a31.874 31.874 0 001.579 16.451c-7.894 2.406-9.955 3.719-18.418 3.456l-3.201-18.507s-2.5-8.925-1.491-9.888z"
        fill="url(#SvgPublication6_linear)"
      />
      <path
        d="M60.353 116.707s.657 6.432 1.228 8.007c.57 1.575-1.535 4.769-4.956 5.688-3.42.875-.92-3.413-1.008-5.075-.088-1.663-.395-9.538-.395-9.538l5.13.918z"
        fill="#FFE5C2"
      />
      <path
        d="M60.442 117.451c-.439.481-.921.919-1.579 1.269-1.271.744-2.456.919-3.508.7-.088-1.969-.175-3.675-.175-3.675l5.13.962c.044 0 .088.263.132.744z"
        fill="#FED2A4"
      />
      <path
        d="M59.17 104.981s2.017.526 3.2 2.494c1.185 1.969 1.843 7.613-2.367 10.107-4.21 2.494-7.323-1.356-7.236-4.988.044-3.631 1.009-8.444 6.403-7.613z"
        fill="#FFE5C2"
      />
      <path
        d="M51.451 111.063c.044.963.526 1.706 1.097 1.663.57-.044.964-.832.92-1.751-.043-.962-.526-1.706-1.096-1.662-.526.044-.965.831-.92 1.75zM62.24 112.508c-.35.918-.088 1.881.526 2.1.614.218 1.404-.35 1.754-1.269.351-.919.088-1.882-.526-2.1-.614-.219-1.403.35-1.754 1.269z"
        fill="#FED2A4"
      />
      <path
        d="M68.159 126.158s-2.456 1.269-3.991 4.9c-1.491 3.588 3.64 21.92 4.736 23.101 1.096 1.182 4.122 1.357 4.648-1.531.526-2.844-1.228-25.551-5.394-26.47z"
        fill="url(#SvgPublication7_linear)"
      />
      <path
        d="M53.424 123.708l4.254 25.42-16.532 2.669-5.262-23.801c.044 0 13.638-5.469 17.54-4.288z"
        fill="#9BC1F9"
      />
      <path
        d="M21.545 124.495c1.579-.612 3.289-.787 4.955-.437 3.07.612 8.025 1.837 9.998 3.981 2.982 3.15 5.745 24.283 5.745 24.283l-12.849-2.888-7.85-24.939z"
        fill="#9BC1F9"
      />
      <path
        d="M65.747 167.985c-2.148-5.994-26.617-27.782-33.107-26.076l3.464 13.301s7.28 21.613 14.383 24.413c7.104 2.801 13.813-.35 13.813-.35s3.596-5.294 1.447-11.288z"
        fill="url(#SvgPublication8_linear)"
      />
      <path
        d="M56.932 133.071c-.307.612 1.623 1.706 2.193 2.1.57.394 2.894 0 2.894 0s-4.736-2.8-5.087-2.1z"
        fill="#FED2A4"
      />
      <path
        d="M36.016 161.511s-6.227 12.906-6.227 14.219c-.044 1.925-8.99 1.4-7.06.569.438-.175 1.841-.919 1.973-1.006.57-.482 5.744-16.495 5.744-16.495l5.57 2.713z"
        fill="#FFE5C2"
      />
      <path
        d="M38.034 146.109c-.307-.35-3.464-1.575-5.876 1.444-2.412 3.019-7.06 26.47-7.06 26.47l5.7.569s14.208-20.782 7.236-28.483z"
        fill="url(#SvgPublication9_linear)"
      />
      <path
        d="M19.527 128.477l7.367 21.395c.307.875 1.009 1.488 1.886 1.619 6.885.962 10.48 1.487 10.48 1.487s1.36.088 2.763.088l15.786-1.663c1.36-.131 2.325-1.487 2.062-2.887l-3.86-21.002a2.384 2.384 0 00-2.674-1.968l-15.173 2.537c-1.36-.043-2.631-.087-3.99-.087l-11.928-2.669c-1.798-.438-3.333 1.356-2.719 3.15z"
        fill="url(#SvgPublication10_linear)"
      />
      <path
        d="M51.67 138.89s4.254.788 5.701.744c1.447-.044 3.333.394 3.333.394s4.736 11.113 5.394 12.338c2.017 3.675 7.63 3.238 7.542.175-.087-2.45-9.647-17.807-12.234-17.72-2.544.088-10.525-1.575-10.788-.569-.131.525 7.367.788 6.183 1.269-.745.306-6.577-.35-6.577.306 0 .7 5.306.744 5.876 1.269.57.525-5.613-.656-5.57.525 0 .569 5.438.175 6.052 1.05.22.394-5.394-.918-4.911.219z"
        fill="#FFE5C2"
      />
      <path
        d="M23.081 137.096c-.35-.131-.702.044-.79.394-.087.35-.087.788.176 1.094.526.569 4.254 1.094 4.254.569 0-.438-2.5-1.575-3.64-2.057z"
        fill="#FED2A4"
      />
      <path
        d="M22.379 138.54s-.351 1.4-.044 1.4 4.341.394 4.517-.088c.131-.481-3.99-1.618-4.473-1.312z"
        fill="#FED2A4"
      />
      <path
        d="M22.202 139.94s-.132.613.22.963c.35.35 4.077.131 4.209-.482.131-.569-4.297-.919-4.43-.481z"
        fill="#FED2A4"
      />
      <path
        d="M22.771 141.253s0 .481.176.656c.175.219 2.938-.087 3.201-.656.307-.569-3.07-.613-3.377 0zM32.114 173.805s-1.315 4.769-2.631 7.919c-.877 2.056-1.096 4.113-1.666 5.119-1.448 2.581-3.816 2.713-5.701 1.138-1.886-1.576.658-8.138.658-8.138l5.92-8.357 3.42 2.319z"
        fill="#FED2A4"
      />
      <path
        d="M27.817 186.843c.526-.919.745-2.625 1.403-4.463-.921.525-2.412.919-3.245.7-3.026-.831-3.201-3.238-3.201-3.238s-3.158 6.738-1.272 8.313c1.842 1.532 4.867 1.269 6.315-1.312z"
        fill="#112B46"
      />
      <path
        d="M38.21 128.083l4.823 25.114-2.894-.175-.877-.087-5.087-24.983 4.034.131z"
        fill="#ECECEC"
      />
      <path
        d="M55.136 178.136c1.666 3.063 4.999 3.544 7.98 3.282 3.29-.263 6.93-2.101 7.938-5.863 1.052-4.025-.35-9.057-2.719-12.207-12.322-16.232-12.41-17.851-17.321-19.995-5.306-2.319-24.952 31.152-24.952 31.152l5.394 2.756s8.113-6.738 13.287-12.688c.614-.7 2.324-4.288 2.324-4.288s6.973 15.839 8.069 17.851z"
        fill="url(#SvgPublication11_linear)"
      />
      <path
        d="M212.869 105.041s10.261 4.462 11.182 4.637c.57.132 7.104-6.606 10.656-9.231.175-.132.789-1.007.965-1.094 2.104-1.05-1.448 5.031-1.448 5.031s-7.542 9.582-9.296 10.413c-2.149 1.05-12.761-4.2-12.717-4.506l.658-5.25z"
        fill="#FED2A4"
      />
      <path
        d="M211.247 95.459c.438-3.02 2.543-9.451.613-11.989-2.718-3.631-8.463-3.456-11.182 0-1.227 1.576-1.929 3.544-2.587 5.47-.438 1.225-.833 2.45-1.447 3.63-.526 1.05-1.14 2.32-2.149 2.976-4.516 2.8-9.559 4.594-9.91 10.938-.395 7.482 7.849 16.889 17.935 12.338 3.859-1.75 8.946-7.35 10.612-11.244.746-1.75.395-1.575-.044-4.244-.351-2.406-2.192-5.425-1.841-7.876z"
        fill="url(#SvgPublication12_linear)"
      />
      <path
        d="M205.765 99.965l13.725 7.525-3.245 4.9s-10.349-3.937-12.41-4.506c-2.105-.525-.482-8.138 1.93-7.92z"
        fill="url(#SvgPublication13_linear)"
      />
      <path
        d="M205.501 93.402s-.351 5.207-.614 6.694c-.263 1.532-.438 7.788 2.719 8.576 3.157.787 1.973-7.744 2.017-9.276.044-1.531.351-6.475.351-6.475l-4.473.481z"
        fill="#FFE5C2"
      />
      <path
        d="M205.457 94.058c.219.35.526.7.877 1.007 1.14 1.094 2.368 1.444 3.464 1.312.088-1.75.22-3.456.22-3.456l-4.473.525c-.044-.044-.044.219-.088.612z"
        fill="#FED2A4"
      />
      <path
        d="M208.79 83.427s-1.929.043-3.376 1.575c-1.447 1.531-3.202 6.431.043 9.538 3.289 3.106 6.534.437 7.192-2.844.658-3.238 1.14-7.92-3.859-8.27z"
        fill="#FFE5C2"
      />
      <path
        d="M210.589 83.602s-2.017 5.163-6.709 4.725c-2.149-.219-2.631 2.713-2.631 2.713s1.754-6.913 3.947-7.963c2.192-1.05 5.393.525 5.393.525z"
        fill="url(#SvgPublication14_linear)"
      />
      <path
        d="M204.45 89.552c.087.919-.307 1.706-.921 1.75-.614.088-1.184-.612-1.316-1.487-.087-.92.307-1.707.921-1.75.614-.088 1.228.568 1.316 1.487z"
        fill="#FFE5C2"
      />
      <path
        d="M239.531 91.215l-11.62 23.713c-.263.569-.176 1.225.175 1.444l8.507 4.944 10.612-21.001s-4.604-6.913-6.358-9.494c-.307-.438-.965-.263-1.316.394z"
        fill="url(#SvgPublication15_linear)"
      />
      <path
        d="M229.4 114.141l10.13-20.607c.351-.657 1.008-.875 1.315-.394 1.404 2.1 3.552 5.294 5.087 7.569l-9.56 18.857-6.797-3.981c-.35-.219-.438-.875-.175-1.444z"
        fill="#fff"
      />
      <path
        d="M234.926 98.915c.132-.219.658-.656.921-.7.483-.131 1.097-.131 1.184.219.132.437-1.315.962-1.315.962l-.79 1.138c0-.044-.35-.963 0-1.619z"
        fill="#FED2A4"
      />
      <path
        d="M205.106 98.696s3.596 2.932 4.605 3.982c1.008 1.006 2.806 2.275 2.806 2.275l-2.894-3.982-1.842-1.706-2.675-.569z"
        fill="#FFE5C2"
      />
      <path
        d="M249.573 145.993c-.57-.482-1.053-1.794-1.272-2.363-1.184-6.213-6.885-23.014-13.068-23.014-5.393 0-22.057 13.038-29.862 20.564-2.237 2.187-2.105.35-.702.831.833.306 6.402 9.538 7.279 9.582 2.719.131 5.219-1.706 7.499-2.975 3.464-1.925 5.876-4.113 7.893-7.132 5.087-7.613 7.016-12.076 8.551-14.963 0 0 1.447 5.994 2.149 7.438 1.622 3.237 6.402 11.288 8.288 14.394l7.367-.219c-.088.044-3.421-1.575-4.122-2.143z"
        fill="#FED2A4"
      />
      <path
        d="M235.672 124.073s-24.689 11.506-26.267 15.444c-1.579 3.938-.351 9.801 3.245 11.026 3.595 1.181 25.916-17.851 25.916-17.851s2.894 5.25 6.972 8.838c2.193 1.925 5.832 3.456 7.937 4.856 1.842 1.225 2.237 2.407 2.237 2.407l5.174.218s-.877-.306-2.412-2.406c-5.306-7-17.54-23.451-22.802-22.532z"
        fill="#FFE5C2"
      />
      <path
        d="M226.156 122.628s7.937 8.532 9.516 19.776c0 0-12.454 5.338-18.549 8.576-6.096 3.238-12.936-.131-15.129-3.719-2.149-3.588 0-13.694 0-13.694l12.717-1.969 11.445-8.97z"
        fill="url(#SvgPublication16_linear)"
      />
      <path
        d="M244.968 146.255c-.614-.219-.833 2.931-.965 3.675-.131.788.263 1.356.351 1.4.263.175 10.437.394 12.41-.131.131-.044.395-.219.438-.263 1.447-1.225-5.174-4.55-6.533-4.287-1.141.262-2.851.656-5.701-.394z"
        fill="#112B46"
      />
      <path
        d="M204.933 100.096s-1.93 0-3.991 4.419c-2.543 5.469 1.097 26.383.834 29.358 0 0 .877 1.05 6.139 1.794 4.648.656 7.235-2.625 7.235-2.625s-1.184-8.051.614-15.664c.965-4.112 1.491-8.094-2.368-11.813-3.859-3.675-8.463-5.469-8.463-5.469z"
        fill="url(#SvgPublication17_linear)"
      />
      <path
        d="M253.519 146.43c-.613-.219-1.184 2.844-1.14 3.588.044.787.263 1.4.351 1.444.263.175 10.481.35 12.41-.175.132-.044.395-.219.439-.263 1.447-1.225-5.92-4.375-7.28-4.069-1.14.263-1.929.569-4.78-.525z"
        fill="#112B46"
      />
      <path
        d="M246.721 90.252l-19.25 28.001c-.439.656-.351 1.444.219 1.619l13.024 4.857 15.392-22.052-7.674-12.732c-.351-.525-1.228-.393-1.711.307z"
        fill="url(#SvgPublication18_linear)"
      />
      <path
        d="M242.117 111.472c-.263-.481-2.236.569-2.236.569s-1.798.306-1.886.262c-.131-.087 1.184-.175 1.184-.175s2.193-.744 2.149-1.006c-.088-1.006-1.491.262-2.543.306-.833.044-1.71 0-2.5-.131-.307-.175.483-.569.483-.569s1.052-.481.877-.744c-.395-.656-2.851.613-3.377 1.094-.351.306-.789.919-1.403 1.269-2.631 1.488-5.789 4.025-9.516 5.819-1.228-1.05-13.112-11.682-13.418-11.944l-6.447 5.119s18.637 14.132 19.646 14.132c1.315-.044 9.033-8.182 11.138-10.151.307-.306 2.324-.525 2.85-.437 1.228.175 3.509-.788 3.509-.788.701-.744-2.588 0-2.281-.175.132-.087.965-.175 1.754-.262.833-.132 1.535-.569 1.667-.788.175-.437-1.842.175-1.842.175l-1.71.088 1.622-.35c0-.044 2.544-.832 2.28-1.313z"
        fill="#FFE5C2"
      />
      <path
        d="M208.133 102.983c-2.105-2.144-6.139-1.837-7.323 1.225-.307.832-.307 3.282.044 4.201 1.491 3.587 10.173 9.45 10.173 9.45l4.78-6.519-7.674-8.357z"
        fill="#86B1F2"
      />
      <path
        d="M55.31 81.73c-5.217.787-6.84 7.569-2.543 10.675.79.569 1.316 1.4 1.491 2.319l.746 3.194c.22.875 1.052 1.444 1.93 1.313l1.973-.307a1.698 1.698 0 001.447-1.837c-.22-3.282-.57-4.463.526-5.907 3.201-4.244-.35-10.238-5.57-9.45z"
        fill="#F7CC7F"
      />
      <path
        d="M56.45 96.736c-.262.044-.525-.131-.525-.394l-.965-6.912-1.623-1.4a.44.44 0 01-.044-.657.442.442 0 01.658-.044l1.886 1.62 1.008 7.262c.044.219-.131.481-.394.525zM58.862 96.649a.494.494 0 00.395-.57l-1.14-6.912 1.14-1.794c.132-.219.088-.525-.131-.656-.22-.131-.527-.088-.658.131l-1.316 2.1 1.184 7.307c.044.262.263.437.526.394z"
        fill="#FAE0B2"
      />
      <path
        d="M54.259 94.636c0 .044 0 .044.044.088l.745 3.194c.22.875 1.052 1.444 1.93 1.312l1.973-.306a1.698 1.698 0 001.447-1.838c-.088-1.443-.22-2.493-.22-3.325l-5.92.875z"
        fill="#96D9C9"
      />
      <path
        d="M96.134 59.04l-2.061-2.975h-3.64c-.395 0-.658-.481-.395-.788l2.193-2.887-1.14-3.457c-.132-.394.263-.744.658-.612l3.42 1.181 2.938-2.144c.351-.262.79 0 .79.394l-.088 3.719 2.938 2.1c.351.263.263.744-.132.875l-3.464 1.05-1.096 3.456c-.176.35-.702.438-.921.088z"
        fill="#F47458"
      />
      <path
        d="M245.275 36.62l-1.535-2.187h-2.675c-.307 0-.482-.35-.307-.569l1.623-2.1-.834-2.537c-.087-.307.176-.57.483-.438l2.499.875 2.149-1.575c.263-.175.57 0 .57.306l-.044 2.67 2.149 1.574c.263.175.175.57-.132.657l-2.543.743-.833 2.538c0 .219-.395.263-.57.044z"
        fill="#9BC1F9"
      />
      <path
        d="M188.071 34.915l-3.332-.525-1.974 2.843-3.727-.918-1.14 3.675-4.254-.831-1.447 3.281-4.21-.131.044-1.619 3.114.131 1.578-3.456 4.078.787 1.184-3.719 4.079.963 1.929-2.757 3.113.526.834-2.1 1.491.612-1.36 3.238z"
        fill="#F48C7F"
      />
      <path
        d="M107.757 14.832l-2.456.744-1.622-3.806-3.728.656-1.71-2.625-1.36.875 2.281 3.5 3.552-.613 1.71 3.982 3.684-1.138-.351-1.575z"
        fill="#50BFA5"
      />
      <path
        d="M35.534 55.128l-3.683 2.232 3.815 17.063c.35 1.488 2.017 2.188 3.289 1.4a2.144 2.144 0 001.008-2.362l-.92-4.025 3.858-2.32 3.201 2.67c.746.612 1.754.7 2.588.174l.087-.043a2.191 2.191 0 00.263-3.588l-13.506-11.2zm2.5 10.282l-.702-3.15 2.456 2.056-1.754 1.094z"
        fill="#F48C7F"
      />
      <path
        d="M256.983 71.272c.176-.481.132-2.231 2.544-3.894 1.008-.656 1.798-1.269 2.017-2.056.395-1.356-1.053-2.013-2.5-2.013-.745 0-1.359-.569-1.359-1.312-.044-.744.57-1.4 1.316-1.4a9.33 9.33 0 012.806.306c3.07.831 3.991 2.931 3.464 4.857-.482 1.75-1.929 2.625-3.069 3.368-1.93 1.225-1.93 2.626-2.105 2.976l-3.114-.832zm-1.491 3.02c.351-1.226 1.448-1.882 2.675-1.532 1.272.35 1.842 1.444 1.491 2.712-.351 1.226-1.447 1.882-2.675 1.532-1.271-.35-1.841-1.488-1.491-2.713zM149.307 10.982c-4.78 0-7.192 5.863-3.772 9.232.614.613.965 1.4 1.053 2.275l.219 2.976c.044.83.746 1.443 1.579 1.443h1.798c.833 0 1.491-.612 1.578-1.444.22-2.975.132-4.069 1.272-5.25 3.464-3.369 1.052-9.232-3.727-9.232z"
        fill="#F7CC7F"
      />
      <path
        d="M148.299 24.59a.44.44 0 01-.439-.438l.088-6.344-1.272-1.488c-.175-.175-.131-.437.044-.612s.438-.132.614.043l1.491 1.707-.088 6.694c0 .262-.175.437-.438.437zM150.49 24.852a.44.44 0 00.439-.438l-.088-6.344 1.272-1.488c.175-.175.131-.437-.044-.612-.176-.175-.439-.131-.614.044l-1.491 1.706.088 6.694c0 .262.175.438.438.438z"
        fill="#FAE0B2"
      />
      <path
        d="M146.588 22.401v.088l.219 2.975c.044.831.746 1.444 1.579 1.444h1.798c.833 0 1.491-.613 1.578-1.444.088-1.313.132-2.275.263-3.019h-5.437v-.044z"
        fill="#96D9C9"
      />
      <path
        d="M158.517 2.888l-4.122 7 7.016-5.206-2.894-1.794zM141.722 1.05l4.209 7.22L145.23 0l-3.508 1.05zM134.353 11.376l6.71 2.625-6.841.393.131-3.018zM155.096 20.827l2.718 3.412 1.974-2.362-4.692-1.05zM157.813 14.526l6.096-2.494.219 2.67-6.315-.176zM141.809 21.351l-5.745 2.713 1.798 2.144 3.947-4.857z"
        fill="#FAE0B2"
      />
      <path
        d="M120.253 57.115c.921.088 2.762.438 4.473.919 2.061.569 3.288 1.138 4.166 2.1.877.875 1.359 2.1.964 3.457-.394 1.312-1.535 2.362-3.376 2.494v.043c1.578.963 2.499 2.625 1.929 4.55-.395 1.357-1.315 2.232-2.456 2.713-1.359.569-3.288.525-6.27-.306-1.667-.481-2.894-.963-3.596-1.269l4.166-14.7zm1.71 6.694l1.096.307c1.798.525 2.938.043 3.245-1.05.307-1.138-.351-1.97-1.885-2.407-.746-.219-1.184-.306-1.491-.306l-.965 3.456zm-1.886 6.607c.307.131.702.263 1.316.438 1.535.437 3.07.262 3.508-1.313.439-1.531-.745-2.494-2.456-2.975l-1.14-.35-1.228 4.2z"
        fill="#96D9C9"
      />
      <path
        d="M223.701 35.745c.175.832-.307 1.663-1.14 1.882-.79.175-1.755.35-2.895.35-5.92.219-9.077-3.37-9.252-8.226-.176-5.819 3.859-9.188 9.033-9.363 1.009-.043 1.93.044 2.675.175.877.132 1.447 1.007 1.272 1.882v.043a1.572 1.572 0 01-1.798 1.226 11.357 11.357 0 00-1.842-.132c-3.07.088-5.394 2.013-5.262 5.82.131 3.412 2.236 5.512 5.657 5.38.57 0 1.184-.087 1.71-.218.833-.175 1.666.35 1.842 1.181z"
        fill="#F7CC7F"
      />
      <path
        d="M32.025 115.156a2.434 2.434 0 011.49-3.019c1.273-.438 2.456.219 2.939 1.488.482 1.268-.044 2.537-1.403 3.019-1.36.481-2.588-.175-3.026-1.488zm-.439-3.719l-3.99-9.494c-.35-.788.088-1.707.877-1.97l.965-.35c.789-.305 1.666.132 1.93.963l3.025 9.845-2.807 1.006z"
        fill="#466EB6"
      />
      <defs>
        <linearGradient
          id="SvgPublication0_linear"
          x1={142.126}
          y1={42.006}
          x2={142.126}
          y2={190}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="SvgPublication1_linear"
          x1={138.651}
          y1={128.973}
          x2={138.651}
          y2={151.199}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="SvgPublication2_linear"
          x1={171.145}
          y1={129.289}
          x2={171.145}
          y2={146.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1D6FB" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SvgPublication3_linear"
          x1={160.685}
          y1={151.156}
          x2={160.685}
          y2={183.489}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication4_linear"
          x1={204.361}
          y1={155.925}
          x2={204.361}
          y2={178.677}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#789EE2" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication5_linear"
          x1={60.252}
          y1={102.473}
          x2={60.252}
          y2={137.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication6_linear"
          x1={43.181}
          y1={130.347}
          x2={59.757}
          y2={167.869}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication7_linear"
          x1={63.443}
          y1={130.55}
          x2={78.399}
          y2={150.041}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication8_linear"
          x1={32.663}
          y1={161.317}
          x2={66.461}
          y2={161.317}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#1F2349" />
          <stop offset={0.504} stopColor="#172441" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication9_linear"
          x1={25.108}
          y1={160.093}
          x2={39.972}
          y2={160.093}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication10_linear"
          x1={39.652}
          y1={125.26}
          x2={39.652}
          y2={153.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication11_linear"
          x1={26.094}
          y1={162.377}
          x2={71.447}
          y2={162.377}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication12_linear"
          x1={199.115}
          y1={80.908}
          x2={199.115}
          y2={119.845}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication13_linear"
          x1={202.086}
          y1={101.855}
          x2={204.585}
          y2={114.734}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication14_linear"
          x1={205.934}
          y1={82.736}
          x2={205.934}
          y2={91.004}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication15_linear"
          x1={237.482}
          y1={90.58}
          x2={237.482}
          y2={121.316}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication16_linear"
          x1={201.063}
          y1={137.471}
          x2={235.701}
          y2={137.471}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication17_linear"
          x1={199.267}
          y1={105.533}
          x2={215.664}
          y2={134.627}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication18_linear"
          x1={241.649}
          y1={89.624}
          x2={241.649}
          y2={124.729}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={284}
      height={190}
      viewBox="0 0 284 190"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.538 190S-.616 167.199.48 126.855C1.578 86.511 45.584 80.2 75.694 105.074c30.231 24.875 45.709 12.706 61.313-8.332C152.61 75.704 176.628 28.1 220.391 45.92c32.67 13.242 54.49 48.76 61.56 80.811 4.876 22.028-2.194 45.294-19.138 59.773-.61.495-4.446 3.125-5.055 3.496H46.538z"
        fill="url(#SvgPublication0_linear)"
      />
      <path
        d="M211.565 151.199H74.88c-5.218 0-9.472-3.85-9.472-8.576v-5.075c0-4.725 4.254-8.575 9.472-8.575h136.685c-6.271 6.169-6.359 15.619-.263 21.963l.263.263z"
        fill="url(#SvgPublication1_linear)"
      />
      <path
        d="M209.68 129.289h-68.803c-4.736 0-8.595 3.85-8.595 8.576 0 4.725 3.859 8.575 8.595 8.575h68.803c-3.245-5.25-3.201-11.901 0-17.151z"
        fill="url(#SvgPublication2_linear)"
      />
      <path
        d="M267.782 151.156H64.662c-6.315 0-11.401 5.119-11.401 11.375v9.582c0 6.301 5.13 11.376 11.401 11.376h203.12l-.088-.131c-8.727-8.97-8.683-23.233.088-32.202z"
        fill="url(#SvgPublication3_linear)"
      />
      <path
        d="M264.185 155.925H155.609c-6.314 0-11.401 5.119-11.401 11.376 0 6.3 5.131 11.376 11.401 11.376h108.576c-3.99-7.001-3.99-15.708 0-22.752z"
        fill="url(#SvgPublication4_linear)"
      />
      <path
        d="M18.662 177.217l4.297-1.006s3.465.218 6.71-.438c0 0 .307.875.482 2.625.044.307-.395 1.838-.701 1.882-1.097.175-3.026-.044-6.49.043 0 0-5.394.657-8.332-.306-2.938-.962 4.034-2.8 4.034-2.8z"
        fill="#112B46"
      />
      <path
        d="M65.934 107.301c.22 2.494 2.325 3.369 4.254 4.9 1.316 1.05 1.096 3.894.088 5.644-2.544 4.463-.395 5.032 1.666 8.313 4.912 7.919-10.305 9.669-12.103 10.238-1.798.569-3.026 1.313-4.078 1.181-3.201-.35-6.14-4.9-7.806-9.144-1.622-4.2 2.325-4.419 2.544-6.869.22-2.406-4.43-2.931-3.07-7.525 1.491-4.944 3.596-9.145 6.359-10.632 4.604-2.494 10.217.131 11.489 2.1.394.7.614 1.269.657 1.794z"
        fill="url(#SvgPublication5_linear)"
      />
      <path
        d="M66.284 161.51c.395 1.006 2.587 4.507 2.587 4.507L38 166.323l-.438-6.344c8.463-.088 16.838-3.807 24.644-6.476.175.482 3.903 7.526 4.078 8.007z"
        fill="#FFE5C2"
      />
      <path
        d="M44.403 137.271s1.666-11.682 4.341-12.863c2.675-1.225 17.102.481 19.25 1.794 2.15 1.312-1.446 15.007-1.972 18.857-.044.219-.088.437-.088.7a31.873 31.873 0 001.579 16.451c-7.894 2.406-9.955 3.719-18.418 3.456l-3.201-18.507s-2.5-8.925-1.491-9.888z"
        fill="url(#SvgPublication6_linear)"
      />
      <path
        d="M60.189 116.707s.657 6.432 1.227 8.007c.57 1.575-1.534 4.769-4.955 5.688-3.42.875-.92-3.413-1.008-5.075-.088-1.663-.395-9.538-.395-9.538l5.13.918z"
        fill="#FFE5C2"
      />
      <path
        d="M60.278 117.451c-.439.481-.921.919-1.579 1.269-1.272.744-2.456.919-3.508.7-.088-1.969-.175-3.675-.175-3.675l5.13.962c.044 0 .088.263.132.744z"
        fill="#FED2A4"
      />
      <path
        d="M59.006 104.981s2.017.526 3.2 2.494c1.185 1.969 1.843 7.613-2.367 10.107-4.21 2.494-7.323-1.356-7.236-4.988.044-3.631 1.009-8.444 6.403-7.613z"
        fill="#FFE5C2"
      />
      <path
        d="M51.287 111.063c.044.963.526 1.706 1.096 1.663.57-.044.965-.832.921-1.751-.044-.962-.526-1.706-1.096-1.662-.526.044-.965.831-.92 1.75zM62.076 112.508c-.35.918-.088 1.881.526 2.1.614.218 1.404-.35 1.754-1.269.351-.919.088-1.882-.526-2.1-.614-.219-1.403.35-1.754 1.269z"
        fill="#FED2A4"
      />
      <path
        d="M67.994 126.158s-2.455 1.269-3.99 4.9c-1.491 3.588 3.64 21.92 4.736 23.101 1.096 1.182 4.122 1.357 4.648-1.531.526-2.844-1.228-25.551-5.394-26.47z"
        fill="url(#SvgPublication7_linear)"
      />
      <path
        d="M53.26 123.708l4.254 25.42-16.532 2.669-5.262-23.801c.044 0 13.638-5.469 17.54-4.288z"
        fill="#9BC1F9"
      />
      <path
        d="M21.38 124.495c1.58-.612 3.29-.787 4.956-.437 3.07.612 8.025 1.837 9.998 3.981 2.982 3.15 5.745 24.283 5.745 24.283l-12.849-2.888-7.85-24.939z"
        fill="#9BC1F9"
      />
      <path
        d="M65.583 167.985c-2.148-5.994-26.617-27.782-33.107-26.076l3.464 13.301s7.28 21.613 14.383 24.413c7.104 2.801 13.813-.35 13.813-.35s3.596-5.294 1.447-11.288z"
        fill="url(#SvgPublication8_linear)"
      />
      <path
        d="M56.768 133.071c-.307.612 1.623 1.706 2.193 2.1.57.394 2.894 0 2.894 0s-4.736-2.8-5.087-2.1z"
        fill="#FED2A4"
      />
      <path
        d="M35.852 161.511s-6.227 12.906-6.227 14.219c-.044 1.925-8.99 1.4-7.06.569.438-.175 1.841-.919 1.973-1.006.57-.482 5.744-16.495 5.744-16.495l5.57 2.713z"
        fill="#FFE5C2"
      />
      <path
        d="M37.87 146.109c-.307-.35-3.464-1.575-5.876 1.444-2.412 3.019-7.06 26.47-7.06 26.47l5.7.569s14.208-20.782 7.236-28.483z"
        fill="url(#SvgPublication9_linear)"
      />
      <path
        d="M19.363 128.477l7.367 21.395c.307.875 1.009 1.488 1.886 1.619 6.885.962 10.48 1.487 10.48 1.487s1.36.088 2.763.088l15.786-1.663c1.36-.131 2.325-1.487 2.061-2.887l-3.858-21.002a2.384 2.384 0 00-2.675-1.968L38 128.083c-1.36-.043-2.631-.087-3.99-.087l-11.928-2.669c-1.798-.438-3.333 1.356-2.719 3.15z"
        fill="url(#SvgPublication10_linear)"
      />
      <path
        d="M51.507 138.89s4.253.788 5.7.744c1.447-.044 3.333.394 3.333.394s4.736 11.113 5.394 12.338c2.017 3.675 7.63 3.238 7.542.175-.088-2.45-9.647-17.807-12.234-17.72-2.544.088-10.525-1.575-10.788-.569-.131.525 7.367.788 6.183 1.269-.745.306-6.577-.35-6.577.306 0 .7 5.306.744 5.876 1.269.57.525-5.613-.656-5.57.525 0 .569 5.438.175 6.052 1.05.22.394-5.394-.918-4.911.219z"
        fill="#FFE5C2"
      />
      <path
        d="M22.917 137.096c-.35-.131-.702.044-.79.394-.087.35-.087.788.176 1.094.526.569 4.254 1.094 4.254.569 0-.438-2.5-1.575-3.64-2.057z"
        fill="#FED2A4"
      />
      <path
        d="M22.215 138.54s-.351 1.4-.044 1.4 4.341.394 4.517-.088c.131-.481-3.99-1.618-4.473-1.312z"
        fill="#FED2A4"
      />
      <path
        d="M22.038 139.94s-.132.613.22.963c.35.35 4.077.131 4.209-.482.131-.569-4.298-.919-4.43-.481z"
        fill="#FED2A4"
      />
      <path
        d="M22.607 141.253s0 .481.176.656c.175.219 2.938-.087 3.201-.656.307-.569-3.07-.613-3.377 0zM31.95 173.805s-1.316 4.769-2.631 7.919c-.877 2.056-1.096 4.113-1.666 5.119-1.448 2.581-3.816 2.713-5.701 1.138-1.886-1.576.658-8.138.658-8.138l5.92-8.357 3.42 2.319z"
        fill="#FED2A4"
      />
      <path
        d="M27.652 186.843c.527-.919.746-2.625 1.404-4.463-.921.525-2.412.919-3.245.7-3.026-.831-3.201-3.238-3.201-3.238s-3.158 6.738-1.272 8.313c1.842 1.532 4.867 1.269 6.314-1.312z"
        fill="#112B46"
      />
      <path
        d="M38.045 128.083l4.824 25.114-2.895-.175-.877-.087-5.086-24.983 4.034.131z"
        fill="#ECECEC"
      />
      <path
        d="M54.972 178.136c1.666 3.063 4.999 3.544 7.98 3.282 3.29-.263 6.93-2.101 7.938-5.863 1.052-4.025-.35-9.057-2.719-12.207-12.322-16.232-12.41-17.851-17.321-19.995-5.306-2.319-24.952 31.152-24.952 31.152l5.394 2.756s8.113-6.738 13.287-12.688c.614-.7 2.324-4.288 2.324-4.288s6.973 15.839 8.069 17.851z"
        fill="url(#SvgPublication11_linear)"
      />
      <path
        d="M212.705 105.041s10.261 4.462 11.182 4.637c.57.132 7.104-6.606 10.656-9.231.175-.132.789-1.007.965-1.094 2.104-1.05-1.448 5.031-1.448 5.031s-7.542 9.582-9.296 10.413c-2.149 1.05-12.761-4.2-12.717-4.506l.658-5.25z"
        fill="#FED2A4"
      />
      <path
        d="M211.082 95.459c.439-3.02 2.544-9.451.614-11.989-2.718-3.631-8.463-3.456-11.182 0-1.228 1.576-1.929 3.544-2.587 5.47-.438 1.225-.833 2.45-1.447 3.63-.526 1.05-1.14 2.32-2.149 2.976-4.516 2.8-9.559 4.594-9.91 10.938-.395 7.482 7.849 16.889 17.935 12.338 3.859-1.75 8.946-7.35 10.612-11.244.746-1.75.395-1.575-.044-4.244-.351-2.406-2.192-5.425-1.842-7.876z"
        fill="url(#SvgPublication12_linear)"
      />
      <path
        d="M205.601 99.965l13.725 7.525-3.245 4.9s-10.349-3.937-12.41-4.506c-2.105-.525-.482-8.138 1.93-7.92z"
        fill="url(#SvgPublication13_linear)"
      />
      <path
        d="M205.337 93.402s-.351 5.207-.614 6.694c-.263 1.532-.438 7.788 2.719 8.576 3.157.787 1.973-7.744 2.017-9.276.044-1.531.351-6.475.351-6.475l-4.473.481z"
        fill="#FFE5C2"
      />
      <path
        d="M205.293 94.058c.219.35.526.7.877 1.007 1.14 1.094 2.368 1.444 3.464 1.312.088-1.75.22-3.456.22-3.456l-4.473.525c-.044-.044-.044.219-.088.612z"
        fill="#FED2A4"
      />
      <path
        d="M208.626 83.427s-1.929.043-3.376 1.575c-1.448 1.531-3.202 6.431.043 9.538 3.289 3.106 6.534.437 7.192-2.844.658-3.238 1.14-7.92-3.859-8.27z"
        fill="#FFE5C2"
      />
      <path
        d="M210.425 83.602s-2.017 5.163-6.709 4.725c-2.149-.219-2.631 2.713-2.631 2.713s1.754-6.913 3.947-7.963c2.192-1.05 5.393.525 5.393.525z"
        fill="url(#SvgPublication14_linear)"
      />
      <path
        d="M204.286 89.552c.087.919-.307 1.706-.921 1.75-.614.088-1.184-.612-1.316-1.487-.087-.92.307-1.707.921-1.75.614-.088 1.228.568 1.316 1.487z"
        fill="#FFE5C2"
      />
      <path
        d="M239.367 91.215l-11.62 23.713c-.263.569-.176 1.225.175 1.444l8.507 4.944 10.612-21.001s-4.604-6.913-6.358-9.494c-.307-.438-.965-.263-1.316.394z"
        fill="url(#SvgPublication15_linear)"
      />
      <path
        d="M229.236 114.141l10.13-20.607c.35-.657 1.008-.875 1.315-.394 1.403 2.1 3.552 5.294 5.087 7.569l-9.56 18.857-6.797-3.981c-.35-.219-.438-.875-.175-1.444z"
        fill="#fff"
      />
      <path
        d="M234.762 98.915c.132-.219.658-.656.921-.7.483-.131 1.097-.131 1.184.219.132.437-1.315.962-1.315.962l-.79 1.138c0-.044-.35-.963 0-1.619z"
        fill="#FED2A4"
      />
      <path
        d="M204.942 98.696s3.596 2.932 4.605 3.982c1.008 1.006 2.806 2.275 2.806 2.275l-2.894-3.982-1.842-1.706-2.675-.569z"
        fill="#FFE5C2"
      />
      <path
        d="M249.409 145.993c-.57-.482-1.053-1.794-1.272-2.363-1.184-6.213-6.885-23.014-13.068-23.014-5.393 0-22.057 13.038-29.862 20.564-2.237 2.187-2.105.35-.702.831.833.306 6.402 9.538 7.279 9.582 2.719.131 5.219-1.706 7.499-2.975 3.464-1.925 5.876-4.113 7.893-7.132 5.087-7.613 7.016-12.076 8.551-14.963 0 0 1.447 5.994 2.149 7.438 1.622 3.237 6.402 11.288 8.288 14.394l7.367-.219c-.088.044-3.421-1.575-4.122-2.143z"
        fill="#FED2A4"
      />
      <path
        d="M235.507 124.073s-24.688 11.506-26.266 15.444c-1.579 3.938-.351 9.801 3.245 11.026 3.595 1.181 25.916-17.851 25.916-17.851s2.894 5.25 6.972 8.838c2.193 1.925 5.832 3.456 7.937 4.856 1.842 1.225 2.237 2.407 2.237 2.407l5.174.218s-.877-.306-2.412-2.406c-5.306-7-17.54-23.451-22.803-22.532z"
        fill="#FFE5C2"
      />
      <path
        d="M225.992 122.628s7.937 8.532 9.516 19.776c0 0-12.454 5.338-18.549 8.576-6.096 3.238-12.936-.131-15.129-3.719-2.149-3.588 0-13.694 0-13.694l12.717-1.969 11.445-8.97z"
        fill="url(#SvgPublication16_linear)"
      />
      <path
        d="M244.804 146.255c-.614-.219-.833 2.931-.965 3.675-.131.788.263 1.356.351 1.4.263.175 10.436.394 12.41-.131.131-.044.394-.219.438-.263 1.447-1.225-5.174-4.55-6.534-4.287-1.14.262-2.85.656-5.7-.394z"
        fill="#112B46"
      />
      <path
        d="M204.769 100.096s-1.93 0-3.991 4.419c-2.543 5.469 1.097 26.383.833 29.358 0 0 .878 1.05 6.14 1.794 4.648.656 7.235-2.625 7.235-2.625s-1.184-8.051.614-15.664c.965-4.112 1.491-8.094-2.368-11.813-3.859-3.675-8.463-5.469-8.463-5.469z"
        fill="url(#SvgPublication17_linear)"
      />
      <path
        d="M253.355 146.43c-.614-.219-1.184 2.844-1.14 3.588.044.787.263 1.4.351 1.444.263.175 10.481.35 12.41-.175.132-.044.395-.219.439-.263 1.447-1.225-5.92-4.375-7.28-4.069-1.14.263-1.929.569-4.78-.525z"
        fill="#112B46"
      />
      <path
        d="M246.557 90.252l-19.25 28.001c-.439.656-.351 1.444.219 1.619l13.024 4.857 15.392-22.052-7.674-12.732c-.351-.525-1.228-.393-1.711.307z"
        fill="url(#SvgPublication18_linear)"
      />
      <path
        d="M241.953 111.472c-.263-.481-2.236.569-2.236.569s-1.798.306-1.886.262c-.131-.087 1.184-.175 1.184-.175s2.193-.744 2.149-1.006c-.088-1.006-1.491.262-2.543.306-.833.044-1.711 0-2.5-.131-.307-.175.483-.569.483-.569s1.052-.481.877-.744c-.395-.656-2.851.613-3.377 1.094-.351.306-.789.919-1.403 1.269-2.631 1.488-5.789 4.025-9.516 5.819-1.228-1.05-13.112-11.682-13.419-11.944l-6.446 5.119s18.637 14.132 19.646 14.132c1.315-.044 9.033-8.182 11.138-10.151.307-.306 2.324-.525 2.85-.437 1.228.175 3.508-.788 3.508-.788.702-.744-2.587 0-2.28-.175.132-.087.965-.175 1.754-.262.833-.132 1.535-.569 1.667-.788.175-.437-1.842.175-1.842.175l-1.71.088 1.622-.35c0-.044 2.543-.832 2.28-1.313z"
        fill="#FFE5C2"
      />
      <path
        d="M207.969 102.983c-2.105-2.144-6.139-1.837-7.323 1.225-.307.832-.307 3.282.044 4.201 1.491 3.587 10.173 9.45 10.173 9.45l4.78-6.519-7.674-8.357z"
        fill="#86B1F2"
      />
      <path
        d="M55.147 81.73c-5.218.787-6.841 7.569-2.544 10.675.79.569 1.316 1.4 1.491 2.319l.746 3.194c.22.875 1.052 1.444 1.93 1.313l1.973-.307a1.698 1.698 0 001.447-1.837c-.22-3.282-.57-4.463.526-5.907 3.201-4.244-.35-10.238-5.57-9.45z"
        fill="#F7CC7F"
      />
      <path
        d="M56.287 96.736c-.263.044-.526-.131-.526-.394l-.965-6.912-1.623-1.4a.44.44 0 01-.044-.657.442.442 0 01.658-.044l1.886 1.62 1.008 7.262c.044.219-.131.481-.394.525zM58.699 96.649a.495.495 0 00.394-.57l-1.14-6.912 1.14-1.794c.132-.219.088-.525-.131-.656-.22-.131-.527-.088-.658.131l-1.316 2.1 1.184 7.307c.044.262.263.437.527.394z"
        fill="#FAE0B2"
      />
      <path
        d="M54.095 94.636c0 .044 0 .044.044.088l.745 3.194c.22.875 1.053 1.444 1.93 1.312l1.973-.306a1.698 1.698 0 001.447-1.838c-.088-1.443-.22-2.493-.22-3.325l-5.92.875z"
        fill="#96D9C9"
      />
      <path
        d="M95.97 59.04l-2.061-2.975h-3.64c-.395 0-.658-.481-.395-.788l2.193-2.887-1.14-3.457c-.132-.394.263-.744.658-.612l3.42 1.181 2.938-2.144c.35-.262.79 0 .79.394l-.088 3.719 2.938 2.1c.351.263.263.744-.132.875l-3.464 1.05-1.096 3.456c-.176.35-.702.438-.921.088z"
        fill="#F47458"
      />
      <path
        d="M245.111 36.62l-1.535-2.187h-2.675c-.307 0-.482-.35-.307-.569l1.623-2.1-.834-2.537c-.087-.307.176-.57.483-.438l2.499.875 2.149-1.575c.263-.175.57 0 .57.306l-.044 2.67 2.149 1.574c.263.175.175.57-.132.657l-2.543.743-.833 2.538c0 .219-.395.263-.57.044z"
        fill="#9BC1F9"
      />
      <path
        d="M187.907 34.915l-3.333-.525-1.973 2.843-3.727-.918-1.14 3.675-4.254-.831-1.447 3.281-4.21-.131.044-1.619 3.114.131 1.578-3.456 4.078.787 1.184-3.719 4.079.963 1.929-2.757 3.113.526.834-2.1 1.491.612-1.36 3.238z"
        fill="#F48C7F"
      />
      <path
        d="M107.593 14.832l-2.456.744-1.622-3.806-3.728.656-1.71-2.625-1.36.875 2.281 3.5 3.552-.613 1.71 3.982 3.684-1.138-.351-1.575z"
        fill="#50BFA5"
      />
      <path
        d="M35.37 55.128l-3.683 2.232 3.815 17.063c.35 1.488 2.017 2.188 3.288 1.4a2.144 2.144 0 001.009-2.362l-.92-4.025 3.858-2.32 3.201 2.67c.746.612 1.754.7 2.587.174l.088-.043a2.191 2.191 0 00.263-3.588l-13.506-11.2zm2.5 10.282l-.702-3.15 2.456 2.056-1.754 1.094z"
        fill="#F48C7F"
      />
      <path
        d="M256.819 71.272c.176-.481.132-2.231 2.544-3.894 1.008-.656 1.798-1.269 2.017-2.056.395-1.356-1.053-2.013-2.5-2.013-.745 0-1.359-.569-1.359-1.312-.044-.744.57-1.4 1.315-1.4a9.334 9.334 0 012.807.306c3.07.831 3.99 2.931 3.464 4.857-.482 1.75-1.929 2.625-3.069 3.368-1.93 1.225-1.93 2.626-2.105 2.976l-3.114-.832zm-1.491 3.02c.351-1.226 1.447-1.882 2.675-1.532 1.272.35 1.842 1.444 1.491 2.712-.351 1.226-1.447 1.882-2.675 1.532-1.271-.35-1.841-1.488-1.491-2.713zM149.143 10.982c-4.78 0-7.192 5.863-3.772 9.232.614.613.965 1.4 1.053 2.275l.219 2.976c.044.83.746 1.443 1.579 1.443h1.798c.833 0 1.491-.612 1.578-1.444.22-2.975.132-4.069 1.272-5.25 3.464-3.369 1.052-9.232-3.727-9.232z"
        fill="#F7CC7F"
      />
      <path
        d="M148.135 24.59a.439.439 0 01-.439-.438l.088-6.344-1.272-1.488c-.175-.175-.131-.437.044-.612s.438-.132.614.043l1.491 1.707-.088 6.694c0 .262-.175.437-.438.437zM150.326 24.852a.44.44 0 00.439-.438l-.088-6.344 1.272-1.488c.175-.175.131-.437-.044-.612-.176-.175-.439-.131-.614.044L149.8 17.72l.088 6.694c0 .262.175.438.438.438z"
        fill="#FAE0B2"
      />
      <path
        d="M146.424 22.401v.088l.219 2.975c.044.831.746 1.444 1.579 1.444h1.798c.833 0 1.491-.613 1.578-1.444.088-1.313.132-2.275.263-3.019h-5.437v-.044z"
        fill="#96D9C9"
      />
      <path
        d="M158.352 2.888l-4.122 7 7.017-5.206-2.895-1.794zM141.558 1.05l4.209 7.22-.701-8.27-3.508 1.05zM134.189 11.376L140.898 14l-6.84.393.131-3.018zM154.932 20.827l2.718 3.412 1.974-2.362-4.692-1.05zM157.649 14.526l6.096-2.494.219 2.67-6.315-.176zM141.645 21.351l-5.745 2.713 1.798 2.144 3.947-4.857z"
        fill="#FAE0B2"
      />
      <path
        d="M120.089 57.115c.921.088 2.762.438 4.473.919 2.061.569 3.288 1.138 4.165 2.1.877.875 1.36 2.1.965 3.457-.394 1.312-1.535 2.362-3.376 2.494v.043c1.578.963 2.499 2.625 1.929 4.55-.395 1.357-1.315 2.232-2.456 2.713-1.359.569-3.288.525-6.27-.306-1.667-.481-2.895-.963-3.596-1.269l4.166-14.7zm1.71 6.694l1.096.307c1.798.525 2.938.043 3.245-1.05.307-1.138-.351-1.97-1.885-2.407-.746-.219-1.184-.306-1.491-.306l-.965 3.456zm-1.886 6.607c.307.131.702.263 1.316.438 1.535.437 3.069.262 3.508-1.313.438-1.531-.746-2.494-2.456-2.975l-1.14-.35-1.228 4.2z"
        fill="#96D9C9"
      />
      <path
        d="M223.537 35.745c.175.832-.307 1.663-1.141 1.882-.789.175-1.754.35-2.894.35-5.92.219-9.077-3.37-9.252-8.226-.176-5.819 3.859-9.188 9.033-9.363a12.47 12.47 0 012.675.175c.877.132 1.447 1.007 1.272 1.882v.043a1.572 1.572 0 01-1.798 1.226 11.357 11.357 0 00-1.842-.132c-3.07.088-5.394 2.013-5.262 5.82.131 3.412 2.236 5.512 5.657 5.38.57 0 1.184-.087 1.71-.218.833-.175 1.666.35 1.842 1.181z"
        fill="#F7CC7F"
      />
      <path
        d="M31.86 115.156a2.434 2.434 0 011.492-3.019c1.272-.438 2.455.219 2.938 1.488.482 1.268-.044 2.537-1.403 3.019-1.36.481-2.588-.175-3.026-1.488zm-.438-3.719l-3.99-9.494c-.351-.788.088-1.707.877-1.97l.965-.35c.789-.305 1.666.132 1.929.963l3.026 9.845-2.807 1.006z"
        fill="#466EB6"
      />
      <defs>
        <linearGradient
          id="SvgPublication0_linear"
          x1={141.962}
          y1={42.29}
          x2={141.962}
          y2={187.865}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="SvgPublication1_linear"
          x1={138.486}
          y1={128.973}
          x2={138.486}
          y2={151.199}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset={1} stopColor="#CCE1F7" />
        </linearGradient>
        <linearGradient
          id="SvgPublication2_linear"
          x1={170.981}
          y1={129.289}
          x2={170.981}
          y2={146.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C1D6FB" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SvgPublication3_linear"
          x1={160.521}
          y1={151.156}
          x2={160.521}
          y2={183.489}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication4_linear"
          x1={204.197}
          y1={155.925}
          x2={204.197}
          y2={178.677}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#789EE2" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication5_linear"
          x1={60.088}
          y1={102.473}
          x2={60.088}
          y2={137.44}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication6_linear"
          x1={43.017}
          y1={130.347}
          x2={59.593}
          y2={167.869}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication7_linear"
          x1={63.279}
          y1={130.55}
          x2={78.235}
          y2={150.041}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication8_linear"
          x1={32.499}
          y1={161.317}
          x2={66.297}
          y2={161.317}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#1F2349" />
          <stop offset={0.504} stopColor="#172441" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication9_linear"
          x1={24.944}
          y1={160.093}
          x2={39.808}
          y2={160.093}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication10_linear"
          x1={39.488}
          y1={125.26}
          x2={39.488}
          y2={153.066}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication11_linear"
          x1={25.93}
          y1={162.377}
          x2={71.283}
          y2={162.377}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication12_linear"
          x1={198.951}
          y1={80.908}
          x2={198.951}
          y2={119.845}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication13_linear"
          x1={201.922}
          y1={101.855}
          x2={204.421}
          y2={114.734}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication14_linear"
          x1={205.77}
          y1={82.736}
          x2={205.77}
          y2={91.004}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication15_linear"
          x1={237.318}
          y1={90.58}
          x2={237.318}
          y2={121.316}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgPublication16_linear"
          x1={200.899}
          y1={137.471}
          x2={235.537}
          y2={137.471}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgPublication17_linear"
          x1={199.103}
          y1={105.533}
          x2={215.5}
          y2={134.627}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgPublication18_linear"
          x1={241.485}
          y1={89.624}
          x2={241.485}
          y2={124.729}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D2DEF4" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgPublication;
