import React from 'react';

const PublicationIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M55.0001 34.9809V38.5027H52.3627L49.7254 33.2227L55.0001 34.9809Z"
          fill="black"
          fillOpacity="0.8"
        />
        <path
          d="M52.3627 34.9816V38.5034H37.5257C37.3349 38.5034 37.2312 38.6274 37.196 38.681L36.4136 39.5943H33.5872L32.8039 38.6809C32.7687 38.6273 32.665 38.5033 32.4742 38.5033H15V34.9815L35 31.0615L52.3627 34.9816Z"
          fill="#072252"
        />
        <path
          d="M55.0001 32.3439V34.9812H52.3627L50.6044 30.2568L55.0001 32.3439Z"
          fill="#ECECEC"
        />
        <path
          d="M52.3626 32.3427V34.98H37.5256C36.4716 34.98 35.5432 35.5189 34.9999 36.3347L30.6043 29.3018L52.3626 32.3427Z"
          fill="#FCFCFC"
        />
        <path
          d="M35 30.1826V36.3364C34.4567 35.5206 33.5284 34.9818 32.4743 34.9818H15V32.3444L35 30.1826Z"
          fill="white"
        />
        <path
          d="M55.0001 29.7064V32.3439H52.3627L50.6044 25.6572L55.0001 29.7064Z"
          fill="#5384EE"
        />
        <path
          d="M52.3626 28.8265V32.343H37.5256C36.4715 32.343 35.5432 32.8819 34.9999 33.6977L33.2417 23.5518L52.3626 28.8265Z"
          fill="#83B4FF"
        />
        <path
          d="M35 23.5518V33.6977C34.4567 32.8818 33.5284 32.343 32.4743 32.343H15V29.7061L35 23.5518Z"
          fill="#83B4FF"
        />
        <path
          d="M55 -0.40625V29.7062H52.3626L47.967 13.7709L52.3626 -0.40625H55Z"
          fill="#3F73E3"
        />
        <path
          d="M52.3627 -0.40625V29.7062H37.5258C36.4717 29.7062 35.5433 30.2452 35.0001 31.0609L26.2089 15.1261L35.0001 0.949375C35.5433 0.132656 36.4717 -0.40625 37.5258 -0.40625H52.3627Z"
          fill="#5384EE"
        />
        <path
          d="M32.474 -0.40625H15V29.7067H32.474C33.528 29.7067 34.4569 30.2449 34.9998 31.0613V0.949609C34.4569 0.132578 33.5286 -0.40625 32.474 -0.40625Z"
          fill="#3F73E3"
        />
        <path
          d="M30.4066 11.7934H19.5935C19.2292 11.7934 18.9341 11.4982 18.9341 11.134C18.9341 10.7698 19.2292 10.4746 19.5935 10.4746H30.4067C30.7709 10.4746 31.066 10.7698 31.066 11.134C31.066 11.4983 30.7707 11.7934 30.4066 11.7934Z"
          fill="#83B4FF"
        />
        <path
          d="M25.596 8.27676H19.5935C19.2292 8.27676 18.9341 7.9816 18.9341 7.61738C18.9341 7.25316 19.2292 6.95801 19.5935 6.95801H25.5961C25.9603 6.95801 26.2555 7.25316 26.2555 7.61738C26.2555 7.9816 25.9603 8.27676 25.596 8.27676Z"
          fill="#83B4FF"
        />
        <path
          d="M30.4066 18.8256H19.5935C19.2292 18.8256 18.9341 18.5304 18.9341 18.1662C18.9341 17.802 19.2292 17.5068 19.5935 17.5068H30.4067C30.7709 17.5068 31.066 17.802 31.066 18.1662C31.066 18.5304 30.7707 18.8256 30.4066 18.8256Z"
          fill="#83B4FF"
        />
        <path
          d="M30.4066 15.31H19.5935C19.2292 15.31 18.9341 15.0148 18.9341 14.6506C18.9341 14.2864 19.2292 13.9912 19.5935 13.9912H30.4067C30.7709 13.9912 31.066 14.2864 31.066 14.6506C31.066 15.0148 30.7707 15.31 30.4066 15.31Z"
          fill="#83B4FF"
        />
        <path
          d="M30.4066 4.76016H19.5935C19.2292 4.76016 18.9341 4.465 18.9341 4.10078C18.9341 3.73656 19.2292 3.44141 19.5935 3.44141H30.4067C30.7709 3.44141 31.066 3.73656 31.066 4.10078C31.066 4.465 30.7707 4.76016 30.4066 4.76016Z"
          fill="#83B4FF"
        />
        <path
          d="M30.4066 22.3432H19.5935C19.2292 22.3432 18.9341 22.048 18.9341 21.6838C18.9341 21.3196 19.2292 21.0244 19.5935 21.0244H30.4067C30.7709 21.0244 31.066 21.3196 31.066 21.6838C31.066 22.048 30.7707 22.3432 30.4066 22.3432Z"
          fill="#83B4FF"
        />
        <path
          d="M25.596 25.8588H19.5935C19.2292 25.8588 18.9341 25.5636 18.9341 25.1994C18.9341 24.8352 19.2292 24.54 19.5935 24.54H25.5961C25.9603 24.54 26.2555 24.8352 26.2555 25.1994C26.2555 25.5636 25.9603 25.8588 25.596 25.8588Z"
          fill="#83B4FF"
        />
        <path
          d="M50.4066 11.7934H39.5935C39.2292 11.7934 38.9341 11.4982 38.9341 11.134C38.9341 10.7698 39.2292 10.4746 39.5935 10.4746H50.4067C50.7709 10.4746 51.066 10.7698 51.066 11.134C51.066 11.4983 50.7707 11.7934 50.4066 11.7934Z"
          fill="#83B4FF"
        />
        <path
          d="M50.4066 8.27676H39.5935C39.2292 8.27676 38.9341 7.9816 38.9341 7.61738C38.9341 7.25316 39.2292 6.95801 39.5935 6.95801H50.4067C50.7709 6.95801 51.066 7.25316 51.066 7.61738C51.066 7.9816 50.7707 8.27676 50.4066 8.27676Z"
          fill="#83B4FF"
        />
        <path
          d="M50.4066 15.31H39.5935C39.2292 15.31 38.9341 15.0148 38.9341 14.6506C38.9341 14.2864 39.2292 13.9912 39.5935 13.9912H50.4067C50.7709 13.9912 51.066 14.2864 51.066 14.6506C51.066 15.0148 50.7707 15.31 50.4066 15.31Z"
          fill="#83B4FF"
        />
        <path
          d="M50.4066 4.76016H39.5935C39.2292 4.76016 38.9341 4.465 38.9341 4.10078C38.9341 3.73656 39.2292 3.44141 39.5935 3.44141H50.4067C50.7709 3.44141 51.066 3.73656 51.066 4.10078C51.066 4.465 50.7707 4.76016 50.4066 4.76016Z"
          fill="#83B4FF"
        />
        <path
          d="M45.596 18.8256H39.5934C39.2292 18.8256 38.934 18.5304 38.934 18.1662C38.934 17.802 39.2292 17.5068 39.5934 17.5068H45.596C45.9603 17.5068 46.2554 17.802 46.2554 18.1662C46.2554 18.5304 45.9603 18.8256 45.596 18.8256Z"
          fill="#83B4FF"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(15 -0.40625)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default PublicationIcon;
