import { formatDate, datesDuration } from '@lobox/utils';
import { displayDuration } from '../../utils';

export const convertCourseApiToForm = (item) => {
  const {
    institutionPageId,
    institutionName,
    title,
    titleLookupId,
    startDate,
    endDate,
    id,
    description,
  } = item;
  return {
    institution: { label: institutionName, value: institutionPageId },
    title: { label: title, value: titleLookupId },
    id,
    endDate,
    startDate,
    description,
  };
};

export const courseNormalizer = (item) => {
  const {
    institutionPageId,
    institutionName,
    title,
    startDate,
    endDate,
    id,
    description,
    pageCroppedImageUrl,
  } = item;
  const realData = convertCourseApiToForm(item);

  const formattedEndDate = endDate ? formatDate(endDate) : 'Present';
  const formattedStartDate = formatDate(startDate);
  const durationObj = datesDuration({
    startDate,
    endDate: endDate || new Date().toISOString(),
  });
  const duration = durationObj ? displayDuration(durationObj) : '';

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: title,
    firstTextAdditionalProps: { objectId: institutionPageId },
    thirdText: institutionName,
    secondText: `${formattedStartDate} - ${formattedEndDate}`,
    secondTextHelper: duration,
    longText: description,
    realData,
    objectId: institutionPageId,
  };
};
