import React from 'react';

const ConferenceIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipConference0)">
      <path
        d="M48.3336 27.9522C47.9919 27.9522 47.7149 27.6753 47.7149 27.3336V19.7976C47.7149 19.0725 47.4325 18.3908 46.9197 17.878L45.3128 16.2711C45.0711 16.0294 45.0711 15.6378 45.3128 15.396C45.5544 15.1545 45.9461 15.1544 46.1878 15.3961L47.7948 17.003C48.5413 17.7496 48.9523 18.742 48.9523 19.7975V27.3335C48.9523 27.6753 48.6753 27.9522 48.3336 27.9522Z"
        fill="#485363"
      />
      <path
        d="M20.6663 27.9522C20.3246 27.9522 20.0476 27.6752 20.0476 27.3335V19.7975C20.0476 18.742 20.4586 17.7496 21.2051 17.0031L22.8121 15.3961C23.0537 15.1544 23.4455 15.1545 23.6872 15.396C23.9288 15.6377 23.9288 16.0293 23.6872 16.2711L22.0801 17.8781C21.5674 18.3908 21.2851 19.0725 21.2851 19.7976V27.3336C21.285 27.6753 21.008 27.9522 20.6663 27.9522Z"
        fill="#485363"
      />
      <path
        d="M45.7499 17.0003C46.3942 17.0003 46.9165 16.478 46.9165 15.8336C46.9165 15.1893 46.3942 14.667 45.7499 14.667C45.1056 14.667 44.5832 15.1893 44.5832 15.8336C44.5832 16.478 45.1056 17.0003 45.7499 17.0003Z"
        fill="#ECECEC"
      />
      <path
        d="M23.2496 17.0003C23.8939 17.0003 24.4162 16.478 24.4162 15.8336C24.4162 15.1893 23.8939 14.667 23.2496 14.667C22.6052 14.667 22.0829 15.1893 22.0829 15.8336C22.0829 16.478 22.6052 17.0003 23.2496 17.0003Z"
        fill="#ECECEC"
      />
      <path
        d="M36.6667 16.667L41.6621 18.006C43.4836 18.4942 44.75 20.1449 44.75 22.0305V27.3336H24.25V22.0305C24.25 20.1448 25.5165 18.4941 27.3379 18.006L32.3334 16.667H36.6667Z"
        fill="#5384EE"
      />
      <path
        d="M35.3331 22.75L36.9999 27.3333H32.0933L33.6665 22.75H35.3331Z"
        fill="#ECECEC"
      />
      <path
        d="M36.5346 20.5283L34.5002 18.833L32.4658 20.5283L33.6668 22.7497H35.3335L36.5346 20.5283Z"
        fill="white"
      />
      <path
        d="M32.3332 16.667L29.7816 17.3509L30.7952 21.0112C30.8911 21.3577 31.3129 21.4895 31.5891 21.2593L34.4998 18.8337V16.667H32.3332Z"
        fill="#3F73E3"
      />
      <path
        d="M36.6667 16.667L39.2183 17.3509L38.2047 21.0112C38.1087 21.3577 37.687 21.4895 37.4108 21.2593L34.5 18.8337V16.667H36.6667Z"
        fill="#3F73E3"
      />
      <path
        d="M42.3149 18.2402C42.5422 18.7569 42.6669 19.3253 42.6669 19.9168V27.3335H44.7503V22.0304C44.7503 20.3793 43.7793 18.9084 42.3149 18.2402Z"
        fill="#3F73E3"
      />
      <path
        d="M34.4996 18.8334C33.303 18.8334 32.3329 17.8633 32.3329 16.6667V14H36.6663V16.6667C36.6663 17.8633 35.6962 18.8334 34.4996 18.8334Z"
        fill="#FFE5C2"
      />
      <path
        d="M32.3329 15.7013C33.0092 15.9483 33.7389 16.0833 34.4996 16.0833C35.2603 16.0833 35.99 15.9483 36.6663 15.7013V14H32.3329V15.7013Z"
        fill="#FED2A4"
      />
      <path
        d="M29.667 4.91699V9.75035C29.667 12.4198 31.8309 14.5837 34.5003 14.5837C37.1698 14.5837 39.3337 12.4198 39.3337 9.75035V4.91699H29.667Z"
        fill="#FFE5C2"
      />
      <path
        d="M37.6667 4.91699V11.417C37.6667 12.3243 37.4163 13.1729 36.9814 13.8983C38.3902 13.0537 39.3334 11.5124 39.3334 9.75035V4.91699H37.6667Z"
        fill="#FED2A4"
      />
      <path
        d="M37.8332 6.58328H39.3333V3.33331C39.3333 2.3208 38.5125 1.5 37.5 1.5H36.1667V4.91669C36.1666 5.83711 36.9127 6.58328 37.8332 6.58328Z"
        fill="#072252"
      />
      <path
        d="M34.5003 6.5833H29.667V3.33333C29.667 1.49242 31.1594 0 33.0003 0H34.667C35.4954 0 36.167 0.671535 36.167 1.50002V4.91672C36.167 5.83713 35.4208 6.5833 34.5003 6.5833Z"
        fill="#485363"
      />
      <path
        d="M54.1667 30.1672H14.8334C14.3731 30.1672 14 29.7941 14 29.3339V28.1673C14 27.7071 14.3731 27.334 14.8334 27.334H54.1667C54.6269 27.334 55 27.7071 55 28.1673V29.3339C55 29.7941 54.6269 30.1672 54.1667 30.1672Z"
        fill="#83B4FF"
      />
      <path
        d="M54.1669 27.333H51.8336C52.2938 27.333 52.6669 27.7061 52.6669 28.1663V29.333C52.6669 29.7932 52.2938 30.1663 51.8336 30.1663H54.1669C54.6271 30.1663 55.0002 29.7932 55.0002 29.333V28.1664C55.0002 27.7061 54.6271 27.333 54.1669 27.333Z"
        fill="#64A2FF"
      />
      <path
        d="M52.4999 33.0003H16.5C16.0398 33.0003 15.6667 32.6272 15.6667 32.167V30.167H53.3333V32.167C53.3333 32.6271 52.9602 33.0003 52.4999 33.0003Z"
        fill="#5384EE"
      />
      <path
        d="M50.8334 30.167V32.167C50.8334 32.6272 50.4604 33.0003 50.0002 33.0003H52.5002C52.9604 33.0003 53.3335 32.6272 53.3335 32.167V30.167H50.8334Z"
        fill="#3F73E3"
      />
      <path
        d="M50.8416 41H18.1582C17.7026 41 17.3333 40.6307 17.3333 40.1751V33H51.6666V40.1751C51.6666 40.6307 51.2973 41 50.8416 41Z"
        fill="#83B4FF"
      />
      <path
        d="M17.3333 33.0001V35.1668H48.9999C49.276 35.1668 49.4999 35.3906 49.4999 35.6668V41H50.8416C51.2973 41 51.6666 40.6307 51.6666 40.1751V33H17.3333V33.0001Z"
        fill="#83B4FF"
      />
      <path d="M20.6665 33H48.3332V41H20.6665V33Z" fill="#83B4FF" />
      <path d="M20.6665 33H48.3332V35.1667H20.6665V33Z" fill="#83B4FF" />
      <path
        d="M53.3334 0H43.6668C42.7463 0 42.0001 0.746168 42.0001 1.66667V8.33333C42.0001 9.25383 42.7463 10 43.6668 10H44.0885C44.3619 10 44.5834 10.2216 44.5834 10.495V11.6624C44.5834 12.1 45.1095 12.3223 45.4233 12.0175L47.3561 10.14C47.4485 10.0502 47.5722 10 47.701 10H53.3335C54.254 10 55.0002 9.25383 55.0002 8.33333V1.66667C55.0001 0.746168 54.2539 0 53.3334 0V0Z"
        fill="#5384EE"
      />
      <path
        d="M28.0482 27.3332V25.6665C28.0482 25.3248 28.3252 25.0479 28.6669 25.0479C29.0086 25.0479 29.2856 25.3248 29.2856 25.6665V27.3332H28.0482Z"
        fill="#3F73E3"
      />
      <path
        d="M39.7146 27.3332V25.6665C39.7146 25.3248 39.9916 25.0479 40.3333 25.0479C40.675 25.0479 40.9519 25.3248 40.9519 25.6665V27.3332H39.7146Z"
        fill="#3F73E3"
      />
      <g clipPath="url(#clipConference1)">
        <path
          d="M5.91278e-05 36C5.91278e-05 28.268 6.26807 22 14.0001 22C21.732 22 28.0001 28.268 28.0001 36C28.0001 43.732 21.732 50 14.0001 50C6.26807 50 5.91278e-05 43.732 5.91278e-05 36Z"
          fill="#3F73E3"
        />
        <path
          d="M2.00006 36C2.00006 29.3726 7.37264 24 14.0001 24C20.6275 24 26.0001 29.3726 26.0001 36C26.0001 42.6274 20.6275 48 14.0001 48C7.37264 48 2.00006 42.6274 2.00006 36Z"
          fill="white"
        />
        <path
          d="M8.37506 34.9688C8.12115 34.9688 7.90631 35.0664 7.73053 35.2617C7.53522 35.4375 7.43756 35.6523 7.43756 35.9062V36.8438C7.43756 37.0977 7.53522 37.3223 7.73053 37.5176C7.90631 37.6934 8.12115 37.7812 8.37506 37.7812H12.5939V42C12.5939 42.2539 12.6915 42.4688 12.8868 42.6445C13.0626 42.8398 13.2774 42.9375 13.5313 42.9375H14.4689C14.7228 42.9375 14.9474 42.8398 15.1427 42.6445C15.3185 42.4688 15.4063 42.2539 15.4063 42V37.7812H19.6251C19.879 37.7812 20.1036 37.6934 20.2989 37.5176C20.4747 37.3223 20.5626 37.0977 20.5626 36.8438V35.9062C20.5626 35.6523 20.4747 35.4375 20.2989 35.2617C20.1036 35.0664 19.879 34.9688 19.6251 34.9688H15.4063V30.75C15.4063 30.4961 15.3185 30.2812 15.1427 30.1055C14.9474 29.9102 14.7228 29.8125 14.4689 29.8125H13.5313C13.2774 29.8125 13.0626 29.9102 12.8868 30.1055C12.6915 30.2812 12.5939 30.4961 12.5939 30.75V34.9688H8.37506Z"
          fill="#3F73E3"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clipConference0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clipConference1">
        <rect
          width="29"
          height="29"
          fill="white"
          transform="translate(6.10352e-05 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ConferenceIcon;
