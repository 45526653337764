import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function AchievementIcon(props: any) {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      id="Layer_achievement_dark"
      data-name="Layer achievement_dark"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 70 65"
      width={70}
      height={65}
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-achive-1{fill:none;}.cls-achive-2{fill:#393939;}.cls-achive-3{clipPath:url(#clipPath);}.cls-achive-4{fill:#64a2ff;}.cls-achive-5{fill:#5384ee;}.cls-achive-6{fill:#3f73e3;}.cls-achive-7{fill:#fff;}'
          }
        </style>
        <clipPath id="clipPath">
          <rect className="cls-achive-1" y={15} width={50} height={50} />
        </clipPath>
      </defs>
      <circle className="cls-achive-2" cx={41.5} cy={28.5} r={28.5} />
      <g className="cls-achive-3">
        <path
          className="cls-achive-4"
          d="M29.38,41.69a1.79,1.79,0,0,1-1.79-1.79V21.12a1.8,1.8,0,0,1,1.79-1.8H47a3,3,0,0,1,2.93,3.79l-2.07,8a12,12,0,0,1-8.68,8.63l-.09,0-9.32,1.9a2.19,2.19,0,0,1-.36,0Zm1.8-18.78V37.7l7.12-1.45a8.4,8.4,0,0,0,6.05-6l1.89-7.3Z"
        />
        <path
          className="cls-achive-4"
          d="M20.61,41.69l-.35,0-9.32-1.9-.09,0a12,12,0,0,1-8.68-8.63l-2.07-8A3,3,0,0,1,3,19.32H20.61a1.8,1.8,0,0,1,1.8,1.8V39.9a1.79,1.79,0,0,1-1.8,1.79Zm-8.92-5.44,7.13,1.45V22.91H3.76l1.89,7.3A8.4,8.4,0,0,0,11.69,36.25Z"
        />
        <path
          className="cls-achive-5"
          d="M31.15,55.39c-4.66-1.54-7.91-6.33-8.08-11.84a12.73,12.73,0,0,0,.28-2.69v-6.1H22v6.1a12.75,12.75,0,0,1-9.21,12.25V56.8H32.28v-1Z"
        />
        <path
          className="cls-achive-6"
          d="M36,52.78A11.18,11.18,0,0,1,28,42V34.76H22v6.1a12.73,12.73,0,0,1-.28,2.69c.17,5.51,3.42,10.3,8.08,11.84l1.13.37v1h6.2V53.11Z"
        />
        <path
          className="cls-achive-5"
          d="M33.65,53.07H10.71a1.61,1.61,0,0,0-1.62,1.62v8.13H35.27V54.69A1.62,1.62,0,0,0,33.65,53.07Z"
        />
        <path
          className="cls-achive-5"
          d="M39.29,53.07H32.81a1.62,1.62,0,0,1,1.62,1.62v8.13h6.48V54.69A1.61,1.61,0,0,0,39.29,53.07Z"
        />
        <path
          className="cls-achive-5"
          d="M33.14,26.35V17.26H11.52V26A19.86,19.86,0,0,0,21.7,43.37a6.26,6.26,0,0,0,1.47.61A20.58,20.58,0,0,0,33.14,26.35Z"
        />
        <path
          className="cls-achive-5"
          d="M32,17.26V26A19.84,19.84,0,0,1,21.8,43.37l-.05,0a6.73,6.73,0,0,0,6.54,0A19.85,19.85,0,0,0,38.48,26V17.26Z"
        />
        <path
          className="cls-achive-5"
          d="M38.5,64.16v-1a1.8,1.8,0,0,0-1.79-1.8H7.63a1.8,1.8,0,0,0,0,3.59h30Z"
        />
        <path
          className="cls-achive-5"
          d="M42.37,61.35h-6.5a1.8,1.8,0,0,1,1.79,1.8v1.79h4.71a1.8,1.8,0,0,0,0-3.59Z"
        />
        <path
          className="cls-achive-5"
          d="M36.69,17.19V15.76l-1.39-.7h-25a2.13,2.13,0,1,0,0,4.26H34.56A2.13,2.13,0,0,0,36.69,17.19Z"
        />
        <path
          className="cls-achive-4"
          d="M39.66,15.06H35.3v2.13a2.13,2.13,0,0,1-2.13,2.13h6.49a2.13,2.13,0,1,0,0-4.26Z"
        />
        <path
          className="cls-achive-7"
          d="M34.43,58.15H15.57a.76.76,0,0,1-.76-.76.77.77,0,0,1,.76-.77H34.43a.77.77,0,0,1,.76.77A.76.76,0,0,1,34.43,58.15Z"
        />
        <path
          className="cls-achive-7"
          d="M25.58,24.56l1.13,3.08a.63.63,0,0,0,.56.41l3.29.12a.62.62,0,0,1,.36,1.11l-2.59,2a.61.61,0,0,0-.21.66l.9,3.16a.62.62,0,0,1-.95.68L25.35,34a.64.64,0,0,0-.7,0l-2.73,1.83a.61.61,0,0,1-.94-.68l.9-3.16a.61.61,0,0,0-.21-.66l-2.59-2a.62.62,0,0,1,.36-1.11l3.29-.12a.63.63,0,0,0,.56-.41l1.13-3.08a.61.61,0,0,1,1.16,0Z"
        />
      </g>
    </svg>
  ) : (
    <svg
      id="Layer_achievement_light"
      data-name="Layer achievement_light"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={70}
      height={65}
      viewBox="0 0 70 65"
      {...props}
    >
      <defs>
        <style>
          {
            '.cls-achive-1{fill:none;}.cls-achive-2,.cls-achive-5{fill:#5384ee;}.cls-achive-2{fill-opacity:0.1;}.cls-achive-3{clipPath:url(#clipPath);}.cls-achive-4{fill:#64a2ff;}.cls-achive-6{fill:#3f73e3;}.cls-achive-7{fill:#fff;}'
          }
        </style>
        <clipPath id="clipPath">
          <rect className="cls-achive-1" y={15} width={50} height={50} />
        </clipPath>
      </defs>
      <circle className="cls-achive-2" cx={41.5} cy={28.5} r={28.5} />
      <g className="cls-achive-3">
        <path
          className="cls-achive-4"
          d="M29.38,41.69a1.79,1.79,0,0,1-1.79-1.79V21.12a1.8,1.8,0,0,1,1.79-1.8H47a3,3,0,0,1,2.93,3.79l-2.07,8a12,12,0,0,1-8.68,8.63l-.09,0-9.32,1.9a2.19,2.19,0,0,1-.36,0Zm1.8-18.78V37.7l7.12-1.45a8.4,8.4,0,0,0,6.05-6l1.89-7.3Z"
        />
        <path
          className="cls-achive-4"
          d="M20.61,41.69l-.35,0-9.32-1.9-.09,0a12,12,0,0,1-8.68-8.63l-2.07-8A3,3,0,0,1,3,19.32H20.61a1.8,1.8,0,0,1,1.8,1.8V39.9a1.79,1.79,0,0,1-1.8,1.79Zm-8.92-5.44,7.13,1.45V22.91H3.76l1.89,7.3A8.4,8.4,0,0,0,11.69,36.25Z"
        />
        <path
          className="cls-achive-5"
          d="M31.15,55.39c-4.66-1.54-7.91-6.33-8.08-11.84a12.73,12.73,0,0,0,.28-2.69v-6.1H22v6.1a12.75,12.75,0,0,1-9.21,12.25V56.8H32.28v-1Z"
        />
        <path
          className="cls-achive-6"
          d="M36,52.78A11.18,11.18,0,0,1,28,42V34.76H22v6.1a12.73,12.73,0,0,1-.28,2.69c.17,5.51,3.42,10.3,8.08,11.84l1.13.37v1h6.2V53.11Z"
        />
        <path
          className="cls-achive-5"
          d="M33.65,53.07H10.71a1.61,1.61,0,0,0-1.62,1.62v8.13H35.27V54.69A1.62,1.62,0,0,0,33.65,53.07Z"
        />
        <path
          className="cls-achive-5"
          d="M39.29,53.07H32.81a1.62,1.62,0,0,1,1.62,1.62v8.13h6.48V54.69A1.61,1.61,0,0,0,39.29,53.07Z"
        />
        <path
          className="cls-achive-5"
          d="M33.14,26.35V17.26H11.52V26A19.86,19.86,0,0,0,21.7,43.37a6.26,6.26,0,0,0,1.47.61A20.58,20.58,0,0,0,33.14,26.35Z"
        />
        <path
          className="cls-achive-5"
          d="M32,17.26V26A19.84,19.84,0,0,1,21.8,43.37l-.05,0a6.73,6.73,0,0,0,6.54,0A19.85,19.85,0,0,0,38.48,26V17.26Z"
        />
        <path
          className="cls-achive-5"
          d="M38.5,64.16v-1a1.8,1.8,0,0,0-1.79-1.8H7.63a1.8,1.8,0,0,0,0,3.59h30Z"
        />
        <path
          className="cls-achive-5"
          d="M42.37,61.35h-6.5a1.8,1.8,0,0,1,1.79,1.8v1.79h4.71a1.8,1.8,0,0,0,0-3.59Z"
        />
        <path
          className="cls-achive-5"
          d="M36.69,17.19V15.76l-1.39-.7h-25a2.13,2.13,0,1,0,0,4.26H34.56A2.13,2.13,0,0,0,36.69,17.19Z"
        />
        <path
          className="cls-achive-4"
          d="M39.66,15.06H35.3v2.13a2.13,2.13,0,0,1-2.13,2.13h6.49a2.13,2.13,0,1,0,0-4.26Z"
        />
        <path
          className="cls-achive-7"
          d="M34.43,58.15H15.57a.76.76,0,0,1-.76-.76.77.77,0,0,1,.76-.77H34.43a.77.77,0,0,1,.76.77A.76.76,0,0,1,34.43,58.15Z"
        />
        <path
          className="cls-achive-7"
          d="M25.58,24.56l1.13,3.08a.63.63,0,0,0,.56.41l3.29.12a.62.62,0,0,1,.36,1.11l-2.59,2a.61.61,0,0,0-.21.66l.9,3.16a.62.62,0,0,1-.95.68L25.35,34a.64.64,0,0,0-.7,0l-2.73,1.83a.61.61,0,0,1-.94-.68l.9-3.16a.61.61,0,0,0-.21-.66l-2.59-2a.62.62,0,0,1,.36-1.11l3.29-.12a.63.63,0,0,0,.56-.41l1.13-3.08a.61.61,0,0,1,1.16,0Z"
        />
      </g>
    </svg>
  );
}

export default AchievementIcon;
