import React from 'react';

const LanguageIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M53.5285 10.6885H32.0782C31.4828 10.6885 31.0001 11.1712 31.0001 11.7666V27.8376C31.0001 28.433 31.4828 28.9157 32.0782 28.9157H34.4704C34.8642 28.9157 35.2234 29.1405 35.3954 29.4947L37.1236 33.052C37.2569 33.3264 37.6478 33.3264 37.7811 33.052L39.5093 29.4947C39.6813 29.1405 40.0406 28.9157 40.4343 28.9157H53.5285C54.1239 28.9157 54.6066 28.433 54.6066 27.8376V11.7666C54.6066 11.1712 54.1239 10.6885 53.5285 10.6885Z"
        fill="#5384EE"
      />
      <path
        d="M37.5044 29.4951L36.4498 31.6659L37.1234 33.0524C37.2567 33.3268 37.6476 33.3268 37.7809 33.0524L39.5091 29.495C39.6811 29.1409 40.0403 28.916 40.4341 28.916H38.4294C38.0356 28.9161 37.6764 29.1409 37.5044 29.4951Z"
        fill="#83B4FF"
      />
      <path
        d="M53.5283 10.6885H51.5237C52.1191 10.6885 52.6018 11.1712 52.6018 11.7666V27.8375C52.6018 28.4329 52.1191 28.9156 51.5237 28.9156H53.5283C54.1237 28.9156 54.6064 28.4329 54.6064 27.8375V11.7666C54.6064 11.1712 54.1237 10.6885 53.5283 10.6885Z"
        fill="#83B4FF"
      />
      <path
        d="M45.6536 23.9551C45.4341 23.9551 45.2274 23.8211 45.1453 23.6035L42.8141 17.4263L40.4608 23.6051C40.354 23.8855 40.0398 24.0263 39.7598 23.9194C39.4794 23.8126 39.3386 23.4987 39.4455 23.2184L42.1566 16.1C42.1581 16.096 42.1597 16.0918 42.1613 16.0877C42.2703 15.8212 42.5269 15.6487 42.815 15.6484H42.8157C43.1035 15.6484 43.36 15.8203 43.4694 16.0863C43.4715 16.0913 43.4734 16.0963 43.4753 16.1012L46.1617 23.2198C46.2677 23.5006 46.126 23.814 45.8453 23.9199C45.7822 23.9438 45.7173 23.9551 45.6536 23.9551Z"
        fill="white"
      />
      <path
        d="M44.8215 22.1822H40.7985C40.4984 22.1822 40.2552 21.939 40.2552 21.6389C40.2552 21.3389 40.4985 21.0957 40.7985 21.0957H44.8215C45.1216 21.0957 45.3647 21.3389 45.3647 21.6389C45.3647 21.939 45.1216 22.1822 44.8215 22.1822Z"
        fill="white"
      />
      <path
        d="M38.9314 3H21.8582C21.3842 3 21.0001 3.38419 21.0001 3.85809V16.6496C21.0001 17.1235 21.3842 17.5077 21.8582 17.5077H23.7622C24.0756 17.5077 24.3616 17.6867 24.4985 17.9686L25.874 20.8C25.9801 21.0184 26.2913 21.0184 26.3974 20.8L27.7729 17.9686C27.9099 17.6867 28.1958 17.5077 28.5091 17.5077H38.9314C39.4053 17.5077 39.7895 17.1235 39.7895 16.6496V3.85809C39.7895 3.38419 39.4053 3 38.9314 3Z"
        fill="white"
      />
      <path
        d="M38.9314 3H36.8985C37.3724 3 37.7567 3.38419 37.7567 3.85816V16.6497C37.7567 17.1236 37.3725 17.5079 36.8986 17.5079H38.9315C39.4054 17.5079 39.7896 17.1237 39.7896 16.6497V3.85816C39.7895 3.38419 39.4053 3 38.9314 3Z"
        fill="#ECECEC"
      />
      <path
        d="M25.7399 17.9686L25.1192 19.2464L25.8739 20.8001C25.98 21.0185 26.2912 21.0185 26.3973 20.8001L27.7728 17.9686C27.9098 17.6867 28.1957 17.5078 28.509 17.5078H26.4762C26.1628 17.5078 25.8769 17.6868 25.7399 17.9686Z"
        fill="#ECECEC"
      />
      <path
        d="M33.4787 8.19291H27.3116C27.0115 8.19291 26.7684 7.9497 26.7684 7.64968C26.7684 7.34959 27.0116 7.10645 27.3116 7.10645H33.4787C33.7788 7.10645 34.0219 7.34966 34.0219 7.64968C34.0219 7.9497 33.7788 8.19291 33.4787 8.19291Z"
        fill="#072252"
      />
      <path
        d="M30.3949 8.19321C30.0948 8.19321 29.8517 7.95 29.8517 7.64998V6.72487C29.8517 6.42478 30.0949 6.18164 30.3949 6.18164C30.6949 6.18164 30.9382 6.42485 30.9382 6.72487V7.64998C30.9382 7.95 30.6949 8.19321 30.3949 8.19321Z"
        fill="#072252"
      />
      <path
        d="M27.3109 14.3262C27.054 14.3262 26.8257 14.1433 26.7773 13.8816C26.7227 13.5865 26.9178 13.3032 27.2128 13.2487C30.0856 12.7182 31.5343 10.9268 31.7719 7.6111C31.7932 7.31171 32.053 7.08488 32.3526 7.10808C32.6519 7.12952 32.877 7.38947 32.8556 7.68879C32.725 9.51192 32.2579 10.9296 31.4274 12.0226C30.5169 13.221 29.1653 13.993 27.4102 14.3172C27.3768 14.3233 27.3436 14.3262 27.3109 14.3262Z"
        fill="#072252"
      />
      <path
        d="M32.4357 13.8466C32.418 13.8466 32.4002 13.8457 32.3822 13.844C30.8239 13.6917 28.7089 13.1758 28.3337 10.4655C28.2925 10.1684 28.5001 9.89401 28.7974 9.85294C29.0942 9.81139 29.3688 10.0193 29.4099 10.3166C29.6238 11.8613 30.5155 12.5699 32.4879 12.7627C32.7865 12.7919 33.0049 13.0577 32.9758 13.3563C32.9483 13.6367 32.7119 13.8466 32.4357 13.8466Z"
        fill="#072252"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M5.91278e-05 36C5.91278e-05 28.268 6.26807 22 14.0001 22C21.732 22 28.0001 28.268 28.0001 36C28.0001 43.732 21.732 50 14.0001 50C6.26807 50 5.91278e-05 43.732 5.91278e-05 36Z"
          fill="#3F73E3"
        />
        <path
          d="M2.00006 36C2.00006 29.3726 7.37264 24 14.0001 24C20.6275 24 26.0001 29.3726 26.0001 36C26.0001 42.6274 20.6275 48 14.0001 48C7.37264 48 2.00006 42.6274 2.00006 36Z"
          fill="white"
        />
        <path
          d="M8.37506 34.9688C8.12115 34.9688 7.90631 35.0664 7.73053 35.2617C7.53522 35.4375 7.43756 35.6523 7.43756 35.9062V36.8438C7.43756 37.0977 7.53522 37.3223 7.73053 37.5176C7.90631 37.6934 8.12115 37.7812 8.37506 37.7812H12.5939V42C12.5939 42.2539 12.6915 42.4688 12.8868 42.6445C13.0626 42.8398 13.2774 42.9375 13.5313 42.9375H14.4689C14.7228 42.9375 14.9474 42.8398 15.1427 42.6445C15.3185 42.4688 15.4063 42.2539 15.4063 42V37.7812H19.6251C19.879 37.7812 20.1036 37.6934 20.2989 37.5176C20.4747 37.3223 20.5626 37.0977 20.5626 36.8438V35.9062C20.5626 35.6523 20.4747 35.4375 20.2989 35.2617C20.1036 35.0664 19.879 34.9688 19.6251 34.9688H15.4063V30.75C15.4063 30.4961 15.3185 30.2812 15.1427 30.1055C14.9474 29.9102 14.7228 29.8125 14.4689 29.8125H13.5313C13.2774 29.8125 13.0626 29.9102 12.8868 30.1055C12.6915 30.2812 12.5939 30.4961 12.5939 30.75V34.9688H8.37506Z"
          fill="#3F73E3"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="29"
          height="29"
          fill="white"
          transform="translate(6.10352e-05 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LanguageIcon;
