import React from 'react';

const AdventureIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipAdventure0)">
      <g clipPath="url(#clipAdventure1)">
        <path
          d="M51.4733 4.94141L47.4935 12.9708L51.4733 20.9999H54.2501V4.94141H51.4733Z"
          fill="#5384EE"
        />
        <path
          d="M44.4855 4.94141H51.4733V20.9999H44.4855V4.94141Z"
          fill="#3F73E3"
        />
        <path
          d="M44.4855 0.776367H41.7088L43.7914 16.8345L46.5681 18.9171V2.85892C46.5681 1.7085 45.6356 0.776367 44.4855 0.776367Z"
          fill="#5384EE"
        />
        <path
          d="M41.7088 0.776367H31.2501V16.8345H43.7913V2.85892C43.7913 1.7085 42.8589 0.776367 41.7088 0.776367Z"
          fill="#3F73E3"
        />
        <path
          d="M44.4855 16.835H40.3204V18.9175C40.3204 20.0676 41.2529 21.0001 42.403 21.0001H44.4855C45.6356 21.0001 46.5681 20.0676 46.5681 18.9175C46.5681 17.7674 45.6356 16.835 44.4855 16.835Z"
          fill="#072252"
        />
        <path
          d="M31.2501 35.6699V43.9998C37.6012 43.9998 42.7501 42.1352 42.7501 39.8347C42.7501 37.5345 37.6012 35.6699 31.2501 35.6699Z"
          fill="#83B4FF"
        />
        <path
          d="M39.9733 39.8347C39.9733 37.5345 36.0679 35.6699 31.2501 35.6699C24.8989 35.6699 19.7501 37.5345 19.7501 39.8347C19.7501 42.1352 24.8989 43.9998 31.2501 43.9998C36.0679 43.9998 39.9733 42.1352 39.9733 39.8347Z"
          fill="#83B4FF"
        />
        <path
          d="M31.2501 -2C30.4832 -2 29.8617 -1.37846 29.8617 -0.611633V38.4465L31.2501 41.2233L32.6384 38.4465V-0.611633C32.6384 -1.37846 32.0169 -2 31.2501 -2Z"
          fill="#8493A8"
        />
      </g>
      <g clipPath="url(#clipAdventure2)">
        <path
          d="M5.91278e-05 36C5.91278e-05 28.268 6.26807 22 14.0001 22C21.732 22 28.0001 28.268 28.0001 36C28.0001 43.732 21.732 50 14.0001 50C6.26807 50 5.91278e-05 43.732 5.91278e-05 36Z"
          fill="#3F73E3"
        />
        <path
          d="M2.00006 36C2.00006 29.3726 7.37264 24 14.0001 24C20.6275 24 26.0001 29.3726 26.0001 36C26.0001 42.6274 20.6275 48 14.0001 48C7.37264 48 2.00006 42.6274 2.00006 36Z"
          fill="white"
        />
        <path
          d="M8.37506 34.9688C8.12115 34.9688 7.90631 35.0664 7.73053 35.2617C7.53522 35.4375 7.43756 35.6523 7.43756 35.9062V36.8438C7.43756 37.0977 7.53522 37.3223 7.73053 37.5176C7.90631 37.6934 8.12115 37.7812 8.37506 37.7812H12.5939V42C12.5939 42.2539 12.6915 42.4688 12.8868 42.6445C13.0626 42.8398 13.2774 42.9375 13.5313 42.9375H14.4689C14.7228 42.9375 14.9474 42.8398 15.1427 42.6445C15.3185 42.4688 15.4063 42.2539 15.4063 42V37.7812H19.6251C19.879 37.7812 20.1036 37.6934 20.2989 37.5176C20.4747 37.3223 20.5626 37.0977 20.5626 36.8438V35.9062C20.5626 35.6523 20.4747 35.4375 20.2989 35.2617C20.1036 35.0664 19.879 34.9688 19.6251 34.9688H15.4063V30.75C15.4063 30.4961 15.3185 30.2812 15.1427 30.1055C14.9474 29.9102 14.7228 29.8125 14.4689 29.8125H13.5313C13.2774 29.8125 13.0626 29.9102 12.8868 30.1055C12.6915 30.2812 12.5939 30.4961 12.5939 30.75V34.9688H8.37506Z"
          fill="#3F73E3"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clipAdventure0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clipAdventure1">
        <rect
          width="46"
          height="46"
          fill="white"
          transform="translate(14.0001 -2)"
        />
      </clipPath>
      <clipPath id="clipAdventure2">
        <rect
          width="29"
          height="29"
          fill="white"
          transform="translate(6.10352e-05 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AdventureIcon;
