import React from 'react';

const JobIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipJob)">
      <path
        d="M26.4824 3.07209C26.5446 3.07209 26.6079 3.06397 26.6712 3.04699C28.344 2.59623 30.5917 2.34776 33 2.34776C35.4083 2.34776 37.6559 2.59615 39.3288 3.04699C39.7149 3.15125 40.1123 2.92239 40.2163 2.53618C40.3205 2.15006 40.0918 1.7527 39.7056 1.64844C37.9133 1.16544 35.532 0.899414 32.9999 0.899414C30.4679 0.899414 28.0866 1.16544 26.2942 1.64844C25.9081 1.75254 25.6793 2.14998 25.7835 2.53618C25.8707 2.8593 26.1631 3.07209 26.4824 3.07209Z"
        fill="#072252"
      />
      <path
        d="M39.5172 1.62402C39.9171 1.62402 40.2414 1.94829 40.2414 2.3482V5.96889H38.7931V2.34811C38.7931 1.94821 39.1173 1.62402 39.5172 1.62402Z"
        fill="#072252"
      />
      <path
        d="M26.4828 1.62402C26.8827 1.62402 27.207 1.94829 27.207 2.3482V5.96889H25.7587V2.34811C25.7586 1.94821 26.0829 1.62402 26.4828 1.62402Z"
        fill="#072252"
      />
      <path
        d="M16.3449 37.0998H49.6551C51.2548 37.0998 52.5517 35.803 52.5517 34.2033V20.4512H13.4482V34.2033C13.4483 35.803 14.7451 37.0998 16.3449 37.0998Z"
        fill="#3F73E3"
      />
      <path
        d="M33.7242 19.0029H51.1035V34.2033C51.1035 35.0032 50.4551 35.6516 49.6552 35.6516H34.4484C34.0485 35.6516 33.7242 35.3273 33.7242 34.9274V19.0029Z"
        fill="#5384EE"
      />
      <path
        d="M32.2758 19.0029V34.9274C32.2758 35.3273 31.9516 35.6516 31.5517 35.6516H16.3448C15.5449 35.6516 14.8965 35.0032 14.8965 34.2033V19.0029H32.2758Z"
        fill="#5384EE"
      />
      <path
        d="M16.3449 22.6241H49.6551C52.0548 22.6241 54 20.6789 54 18.2793V6.68654C54 5.88666 53.3516 5.23828 52.5517 5.23828H13.4483C12.6484 5.23828 12 5.88666 12 6.68654V18.2793C12 20.6789 13.9452 22.6241 16.3449 22.6241Z"
        fill="#5384EE"
      />
      <path
        d="M16.3449 24.072C15.2891 24.072 14.3017 23.7835 13.4484 23.2881V21.5108C14.2175 22.2005 15.2306 22.6237 16.3449 22.6237H49.6551C50.7696 22.6237 51.7826 22.2005 52.5517 21.5107V23.288C51.6984 23.7834 50.7108 24.0719 49.6551 24.0719H16.3448V24.072H16.3449Z"
        fill="#3F73E3"
      />
      <path
        d="M31.5517 16.8311H34.4483C34.8482 16.8311 35.1724 17.1553 35.1724 17.5552V22.6242C35.1724 23.0241 34.8482 23.3484 34.4483 23.3484H31.5517C31.1518 23.3484 30.8276 23.0241 30.8276 22.6242V17.5552C30.8276 17.1553 31.1518 16.8311 31.5517 16.8311Z"
        fill="white"
      />
      <path
        d="M30.8276 25.5202H35.1724C35.5723 25.5202 35.8966 25.1959 35.8966 24.796V21.1753C35.8966 20.7754 35.5723 20.4512 35.1724 20.4512H30.8276C30.4277 20.4512 30.1034 20.7754 30.1034 21.1753V24.796C30.1035 25.1959 30.4277 25.5202 30.8276 25.5202Z"
        fill="white"
      />
      <path
        d="M31.5517 25.5201H34.4483V22.6236C34.4483 22.2237 34.124 21.8994 33.7241 21.8994H32.2758C31.8759 21.8994 31.5517 22.2237 31.5517 22.6236V25.5201H31.5517Z"
        fill="#ECECEC"
      />
      <path
        d="M33 14.6592C32.2002 14.6592 31.5518 14.0108 31.5518 13.211C31.5518 12.4111 32.2002 11.7627 33 11.7627C33.7999 11.7627 34.4483 12.4111 34.4483 13.211C34.4483 14.0108 33.7999 14.6592 33 14.6592Z"
        fill="white"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clipJob">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default JobIcon;
