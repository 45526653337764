import React from 'react';

const StudyIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M14.858 26.5907L15.3391 27.5841C15.3391 27.5841 22.9666 33.7778 29.4999 33.7778C36.0332 33.7778 43.6952 27.5841 43.6952 27.5841L44.1412 26.5907H44.1413C43.4081 24.3992 42.5075 21.3905 41.8758 18.2128L40.4798 17.4277H18.315L17.1236 18.2125C16.4918 21.3903 15.5912 24.3991 14.8579 26.5907H14.858Z"
        fill="#3F73E3"
      />
      <path
        d="M21.3363 17.7649L21.8482 17.4277H18.315L17.1236 18.2125C16.4918 21.3903 15.5912 24.3991 14.8579 26.5907H14.858L15.3391 27.5841C15.3391 27.5841 16.513 28.5373 18.334 29.6957C19.4012 26.1308 20.4896 22.0242 21.3363 17.7649Z"
        fill="#5384EE"
      />
      <path
        d="M54.5079 10.6778L30.7744 2.65674C29.9478 2.37744 29.0523 2.37744 28.2257 2.65674L4.49208 10.6778C3.87938 10.8849 3.82529 11.7305 4.40661 12.0138L27.7557 23.3974C28.8566 23.9341 30.1434 23.9341 31.2443 23.3974L54.5934 12.0138C55.1747 11.7305 55.1207 10.8849 54.5079 10.6778Z"
        fill="#5384EE"
      />
      <path
        d="M11.5897 12.013C11.0083 11.7296 11.0624 10.884 11.6752 10.6769L33.0915 3.43897L30.7743 2.65584C29.9477 2.37644 29.0522 2.37644 28.2256 2.65584L4.49208 10.6769C3.87938 10.884 3.82529 11.7296 4.40661 12.013L27.7557 23.3965C28.8566 23.9332 30.1434 23.9332 31.2443 23.3965L33.0916 22.4958L11.5897 12.013Z"
        fill="#3F73E3"
      />
      <path
        d="M49.2491 30.8361C48.824 30.8361 48.4795 30.4915 48.4795 30.0665V15.8332C48.4795 15.4678 48.2326 15.1482 47.8791 15.0558L30.5096 10.476C30.0987 10.3676 29.8533 9.94658 29.9617 9.53559C30.0701 9.1246 30.4908 8.87897 30.9021 8.98764L48.27 13.567C49.299 13.8357 50.0187 14.7678 50.0187 15.8331V30.0663C50.0186 30.4915 49.6741 30.8361 49.2491 30.8361Z"
        fill="white"
      />
      <path
        d="M50.5964 31.7773H47.9017L47.5813 36.7611C47.5548 37.1741 47.8732 37.5241 48.2756 37.5241H50.2225C50.6248 37.5241 50.9433 37.1741 50.9167 36.7611L50.5964 31.7773Z"
        fill="#83B4FF"
      />
      <path
        d="M49.3349 36.7611L49.6554 31.7773H47.9017L47.5813 36.7611C47.5548 37.1741 47.8732 37.524 48.2756 37.524H50.0292C49.6269 37.524 49.3084 37.1741 49.3349 36.7611Z"
        fill="#83B4FF"
      />
      <path
        d="M49.8728 28.1953H48.6254C48.0039 28.1953 47.4955 28.7038 47.4955 29.3253V31.5682C47.4955 31.7722 47.661 31.9376 47.865 31.9376H50.6333C50.8373 31.9376 51.0028 31.7722 51.0028 31.5682V29.3253C51.0027 28.7037 50.4943 28.1953 49.8728 28.1953Z"
        fill="#5384EE"
      />
      <path
        d="M49.4793 31.5682V29.3253C49.4793 28.8328 49.7992 28.4124 50.2411 28.2586C50.1254 28.2183 50.0018 28.1953 49.8728 28.1953H48.6254C48.0039 28.1953 47.4954 28.7038 47.4954 29.3253V31.5682C47.4954 31.7723 47.6609 31.9376 47.8649 31.9376H49.8488C49.6447 31.9376 49.4793 31.7723 49.4793 31.5682Z"
        fill="#5384EE"
      />
      <path
        d="M29.6587 7.59075C28.1789 7.50111 26.9509 8.6746 26.9509 10.135V10.1479C26.9542 10.8047 27.5044 11.3282 28.1613 11.3282H30.8389C31.4738 11.3282 32.0858 10.8369 32.046 10.0101C31.9845 8.72411 30.9438 7.66855 29.6587 7.59075Z"
        fill="#64A2FF"
      />
      <path
        d="M29.2598 10.1479C29.2598 10.1436 29.2598 10.1394 29.2598 10.135C29.2598 9.14466 29.8249 8.28692 30.6498 7.86488C30.3479 7.71029 30.0133 7.61227 29.6588 7.59075C28.179 7.50111 26.951 8.6746 26.951 10.135V10.1479C26.9543 10.8047 27.5045 11.3282 28.1613 11.3282H30.4702C29.8134 11.3282 29.2631 10.8047 29.2598 10.1479Z"
        fill="#072252"
      />
      <path
        d="M44.1414 26.5908C41.4666 28.7893 37.1171 31.25 32.4182 32.7642C30.5557 33.3646 28.4914 33.3798 26.5814 32.7642C21.8932 31.2533 17.5452 28.7984 14.8581 26.5908C14.6011 27.3591 14.3644 28.0274 14.1674 28.5666C13.8288 29.4928 14.1204 30.5341 14.8958 31.1435C19.1916 34.5198 23.7262 36.3646 26.5814 37.2848C27.5243 37.5887 28.5091 37.7407 29.4997 37.7407C30.4904 37.7407 31.4752 37.5887 32.4181 37.2848C35.2733 36.3646 39.8078 34.5198 44.1037 31.1435C44.879 30.5341 45.1707 29.4928 44.8321 28.5666C44.6351 28.0273 44.3985 27.359 44.1414 26.5908Z"
        fill="white"
      />
      <path
        d="M18.5073 29.113C17.1041 28.2793 15.8646 27.4178 14.858 26.5908C14.6009 27.3591 14.3644 28.0274 14.1672 28.5666C13.8287 29.4928 14.1203 30.5341 14.8957 31.1435C15.706 31.7803 16.5247 32.3625 17.3391 32.8945C17.7188 31.7183 18.1126 30.4513 18.5073 29.113Z"
        fill="#F1F4F9"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StudyIcon;
