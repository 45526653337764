import classes from './useHonor.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  Endpoints,
  formValidator,
  hereApiResponseNormalizer,
  linkValidation,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfileModals, useProfile } from '@shared/hooks';
import { HONOR } from '@shared/constants/profileModalsKeys';
import HonorSVG from '@shared/svg/SvgHonor';
import { DESC_LEN_VALIDATION } from '@shared/constants/highlights.user';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import type { Award } from '@lobox/utils';
import useShowSuccessToast from './useShowSuccessToast';

export const useHonorFormGroups = ({
  isEdit,
  isHighlight,
}: {
  isEdit?: boolean;
  isHighlight?: boolean;
} = {}) => {
  const { t } = useTranslation();
  const groups = [
    {
      name: 'title',
      label: t('title'),
      type: 'text',
      maxLength: 100,
      required: true,
      cp: 'asyncAutoComplete',
      visibleRightIcon: true,
      rightIconProps: { name: 'search' },
      url: Endpoints.App.Common.searchAward,
      normalizer: (data: any) =>
        data.map(({ id: value, title: label }: any) => ({
          label,
          value,
        })),
      // wrapStyle: classes.formItem,
    },
    {
      name: 'institution',
      cp: 'asyncAutoComplete',
      maxLength: 100,
      label: t('presented_by'),
      required: true,
      url: `${Endpoints.App.Common.suggestPlace}`,
      normalizer: hereApiResponseNormalizer,
      wrapStyle: classes.formItem,
      renderItem: ({ item }: any) => (
        <AvatarCard
          data={{
            title: item.label,
            image: item.image,
          }}
          avatarProps={{ isCompany: true, size: 'sm' }}
        />
      ),
    },
    {
      name: 'link',
      cp: 'input',
      label: t('link'),
      wrapStyle: classes.formItem,
    },
    {
      name: 'date',
      cp: 'datePicker',
      variant: 'input',
      required: true,
      wrapStyle: classes.formItem,
      label: t('issue_date'),
    },
    {
      name: 'description',
      visibleOptionalLabel: false,
      cp: 'richtext',
      label: t('tell_more_about_honor'),
      wrapStyle: classes.formItem,
      maxLength: DESCRIPTION_MAX_LENGTH,
    },
  ].reduce((prev: any, curr) => {
    if (curr.name === 'description' && isHighlight) {
      return prev;
    }
    return [...prev, curr];
  }, []);
  return React.useCallback(groups, []);
};

const useHonor = () => {
  const { t } = useTranslation();
  const { reFetchAboutSectionQuery } = useProfile();
  const { openConfirmDialog } = useOpenConfirm();
  const { close, modals } = useProfileModals();
  const showSuccessToast = useShowSuccessToast();

  const award = modals?.params?.[HONOR]?.realData || {};
  const isEdit = !!award?.id;
  const editUrl = `${Endpoints.App.User.Honor.get}/${award?.id}`;
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const onSuccess = (data: Award, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          description: data?.description,
          link: data.link,
          startDate: data.date,
          profileEntityId: data.id,
          subTitle: data.institutionName,
          title: data.awardTitle,
          type: HIGHLIGHT_TYPES.AWARD,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isDelete: !values,
      isEdit,
      shared: values?.share,
      variant: t('awards'),
    });
  };

  const { mutate } = useReactMutation({
    url: editUrl,
    headers: {},
    method: 'DELETE',
  });

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_awards_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };
  const groups = useHonorFormGroups();

  return {
    onSuccess,
    title: isEdit ? t('edit_awards') : t('add_awards'),
    method: isEdit ? 'PUT' : 'POST',
    image: <HonorSVG />,
    url: isEdit ? editUrl : Endpoints.App.User.Honor.get,
    initialValues: { ...award, share: true } as Award,
    visibleRequiredHint: true,
    visibleHeaderBorderBottom: false,
    validationSchema: () =>
      formValidator.object().shape({
        link: linkValidation,
        description: DESC_LEN_VALIDATION,
      }),
    transform: ({ institution, title, ...rest }: Award) => ({
      ...rest,
      awardTitle: title?.label,
      awardLookupId: title?.value,
      institutionName: institution?.label,
      institutionPageId: `${institution?.value}`?.includes('_temp')
        ? null
        : institution?.value,
    }),
    secondaryAction: isEdit
      ? {
          label: t('delete_award'),
          onClick: openConfirm,
        }
      : undefined,
    groups,
  };
};

export default useHonor;
