import classes from './HighlightCard.info.module.scss';

import React, { Fragment } from 'react';
import Image from '@lobox/uikit/Image';
import Typography from '@lobox/uikit/Typography';
import cnj from '@lobox/uikit/utils/cnj';
import useTheme from '@lobox/uikit/utils/useTheme';
import HighlightImage from '@shared/svg/Highlights';
import HighlightPattern from '@shared/svg/HighlightPattern.svg';
import type { FC } from 'react';
import type { HighlightCardProps } from './types';
import DesktopDivider from './images/Divider.desktop';
import MobileDivider from './images/Divider.mobile';
import InfoItem from './InfoItem.component';

const HighlightCardWithData: FC<HighlightCardProps> = (props) => {
  const { title, type, data, className } = props;

  const { isDark } = useTheme();

  return (
    <div className={cnj(classes.root, isDark && classes.darkRoot, className)}>
      <div className={classes.content}>
        <Typography font="700" color="brand" size={16}>
          {title}
        </Typography>

        <div className={classes.largeSpacing} />

        {data?.subtitle && (
          <>
            <Typography
              font="700"
              size={16}
              color="gray"
              className={cnj(data.lineThrough && classes.previousName)}
            >
              {data?.subtitle}
            </Typography>
            <div className={classes.halfSpacing} />
          </>
        )}

        <Typography font="700" color="smoke_coal" size={20}>
          {data?.title}
        </Typography>

        {!!data?.items?.length && <div className={classes.halfSpacing} />}

        {data?.items?.map((infoItem, index) => (
          <Fragment key={infoItem.value + infoItem.icon}>
            <InfoItem {...infoItem} />

            {index !== data?.items.length - 1 && (
              <div className={classes.smallSpacing} />
            )}
          </Fragment>
        ))}

        <div className={classes.largeSpacing} />

        <Typography
          lineNumber={1}
          color="fifthText"
          size={16}
          className={classes.value}
        >
          {data?.date}
        </Typography>
      </div>

      <div
        className={cnj(
          classes.imageContainer,
          isDark && classes.imageContainerDark
        )}
      >
        <div className={classes.desktopDivider}>
          <DesktopDivider />
        </div>

        <div className={classes.mobileDivider}>
          <MobileDivider />
        </div>

        <Image src={HighlightPattern} className={classes.pattern} />
        <HighlightImage type={type} className={classes.highlightImage} />
      </div>
    </div>
  );
};

export default HighlightCardWithData;
