import classes from './InfoItem.component.module.scss';

import React from 'react';
import Icon from '@lobox/uikit/Icon';
import Typography from '@lobox/uikit/Typography';
import type { FC } from 'react';
import type { HighlightCardInfoItem } from './types';

const InfoItem: FC<HighlightCardInfoItem> = (props) => {
  const { icon, value, link } = props;

  const renderContent = () => {
    if (link) {
      return (
        <a href={link} className={classes.link}>
          <Typography
            lineNumber={1}
            color="forthText"
            size={16}
            font="400"
            className={classes.value}
          >
            {value}
          </Typography>
        </a>
      );
    }

    return (
      <Typography
        lineNumber={1}
        color="forthText"
        size={16}
        font="400"
        className={classes.value}
      >
        {value}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Icon type="far" name={icon} size={16} color="forthText" />
      {renderContent()}
    </div>
  );
};

export default InfoItem;
