import useTheme from '@lobox/uikit/utils/useTheme';
import React from 'react';
import type { FC } from 'react';

const DividerDesktop: FC = () => {
  const { theme, isDark } = useTheme();

  return (
    <svg
      width="65"
      height="100%"
      viewBox="0 0 65 162"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.3608 0C22.5284 38.2043 -34.0554 129.203 36.9277 147.077C49.4008 150.218 57.9879 155.402 63.6137 162H0V0H64.3608Z"
        fill={isDark ? theme.colors.background8 : theme.colors.background2}
      />
    </svg>
  );
};
export default DividerDesktop;
