import classes from '../../../../../constants/highlights.user.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  db,
  Endpoints,
  formValidator,
  geoApi,
  hereApiResponseNormalizer,
  isEmptyObjectValues,
  lookupResponseNormalizer,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfileModals, useProfile } from '@shared/hooks';
import { SCHOOL } from '@shared/constants/profileModalsKeys';
import SchoolSvg from '@shared/svg/SvgSchool';
import { DESC_LEN_VALIDATION } from '@shared/constants/highlights.user';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import useShowSuccessToast from './useShowSuccessToast';
import type { School } from '@lobox/utils';
import cnj from '@lobox/uikit/utils/cnj';

interface ValuesTypes {
  [name: string]: any;
}

export const useSchoolFormGroups = ({
  isEdit,
  isHighlight,
}: {
  isEdit?: boolean;
  isHighlight?: boolean;
}) => {
  const { t } = useTranslation();
  const groups = ({ values }: ValuesTypes) =>
    [
      {
        name: 'school',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('school'),
        url: `${Endpoints.App.Common.suggestPlace}`,
        normalizer: hereApiResponseNormalizer,
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        renderItem: ({ item }: any) => (
          <AvatarCard
            data={{
              title: item.label,
              image: item.image,
            }}
            avatarProps={{ isCompany: true, size: 'sm' }}
          />
        ),
      },
      {
        name: 'major',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('major'),
        normalizer: lookupResponseNormalizer,
        url: Endpoints.App.Common.getMajors,
        wrapStyle: cnj(classes.halfWidth, classes.leftItem, classes.formItem),
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
      },
      {
        name: 'location',
        apiFunc: geoApi.suggestCity,
        label: t('location'),
        wrapStyle: cnj(classes.halfWidth, classes.rightItem, classes.formItem),
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        cp: 'asyncAutoComplete',
      },

      {
        name: 'degree',
        cp: 'dropdownSelect',
        label: t('degree'),
        wrapStyle: classes.formItem,
        options: db.DEGREE_OPTIONS,
      },
      {
        name: 'currentlyStudying',
        cp: 'checkBox',
        label: t('currently_studying_ath_this_school'),
        wrapStyle: classes.marginTop,
        visibleOptionalLabel: false,
      },
      {
        name: 'startDate',
        cp: 'datePicker',
        variant: 'input',
        wrapStyle: cnj(classes.halfWidth, classes.leftItem, classes.formItem),
        required: true,
        picker: 'month',
        label: t('start_date'),
      },
      {
        name: 'endDate',
        cp: 'datePicker',
        wrapStyle: cnj(classes.halfWidth, classes.rightItem, classes.formItem),
        variant: 'input',
        picker: 'month',
        visibleOptionalLabel: false,
        minDate: values?.startDate ? new Date(values?.startDate) : undefined,
        label: t('end_date'),
        textInputProps: {
          disabled: values.currentlyStudying,
        },
      },
      {
        name: 'description',
        visibleOptionalLabel: false,
        cp: 'richtext',
        label: t('tell_us_more_about_your_school'),
        wrapStyle: classes.formItem,
        maxLength: DESCRIPTION_MAX_LENGTH,
      },
    ].reduce((prev: any, curr) => {
      if (isHighlight && curr.name === 'description') {
        return prev;
      }
      if (curr.name === 'share' && isEdit) {
        return prev;
      }
      return [...prev, curr];
    }, []);
  return React.useCallback(groups, []);
};

export const schoolValidationSchema = () =>
  formValidator.object().shape({
    location: formValidator
      .object()
      .test(
        'value',
        'select_one_of_sug_locns',
        (val: any) => isEmptyObjectValues(val) || val?.value
      ),
    endDate: formValidator
      .date()
      .when(
        ['startDate', 'currentlyWorking'],
        (startDate, currentlyWorking, schema) => {
          if (currentlyWorking) {
            return schema.nullable();
          }
          return startDate
            ? schema.min(startDate, 'date_e_b_s').required()
            : schema.nullable();
        }
      ),
    description: DESC_LEN_VALIDATION,
  });

const useSchool = () => {
  const { t } = useTranslation();
  const { close, modals } = useProfileModals();
  const { reFetchAboutSectionQuery } = useProfile();
  const showSuccessToast = useShowSuccessToast();
  const { openConfirmDialog } = useOpenConfirm();
  const school = modals?.params?.[SCHOOL]?.realData || {};
  const isEdit = !!school?.id;
  const editUrl = `${Endpoints.App.User.Education.get}/${school?.id}`;
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const onSuccess = (data: School, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          ...data,
          profileEntityId: data.id,
          subTitle: data.majorName,
          title: data.schoolName,
          type: HIGHLIGHT_TYPES.SCHOOL,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isDelete: !values,
      isEdit,
      shared: values?.share,
      variant: SCHOOL,
    });
  };

  const groups = useSchoolFormGroups({ isEdit });

  const { mutate } = useReactMutation({
    url: editUrl,
    method: 'DELETE',
  });

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_school_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };

  return {
    title: isEdit ? t('edit_school') : t('add_school'),
    onSuccess,
    method: isEdit ? 'PUT' : 'POST',
    image: <SchoolSvg />,
    visibleHeaderBorderBottom: false,
    url: isEdit ? editUrl : Endpoints.App.User.Education.get,
    initialValues: {
      share: false,
      ...school,
    } as School,
    groups,
    transform: ({
      school: selectedSchool,
      degree,
      major,
      currentlyStudying,
      endDate,
      ...rest
    }: School) => ({
      ...rest,
      currentlyStudying,
      endDate: currentlyStudying ? undefined : endDate,
      schoolName: selectedSchool?.label,
      schoolPageId: `${selectedSchool?.value}`?.includes('_temp')
        ? null
        : selectedSchool?.value,
      degree: degree?.value,
      majorLookupId: major?.value,
      majorName: major?.label,
    }),
    visibleRequiredHint: true,
    secondaryAction: isEdit
      ? {
          label: t('delete_school'),
          onClick: openConfirm,
        }
      : undefined,
    validationSchema: schoolValidationSchema,
  };
};

export default useSchool;
