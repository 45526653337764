import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const SelfDevelopmentIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      width='72'
      height='65'
      viewBox='0 0 72 65'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='43' cy='28.5' r='28.5' fill='#393939' />
      <g clip-path='url(#clip0_3633_79113)'>
        <path
          d='M4.05176 50.5271V61.0081H11.5314V50.5271C11.5314 50.0159 11.1171 49.6016 10.6059 49.6016H4.97733C4.46612 49.6016 4.05176 50.0159 4.05176 50.5271Z'
          fill='#83B4FF'
        />
        <path
          d='M14.5244 46.0389V61.0076H22.0041V46.0389C22.0041 45.5276 21.5897 45.1133 21.0785 45.1133H15.45C14.9388 45.1134 14.5244 45.5277 14.5244 46.0389Z'
          fill='#83B4FF'
        />
        <path
          d='M24.9971 40.4295V61.008H32.4767V40.4295C32.4767 39.9183 32.0624 39.5039 31.5512 39.5039H25.9226C25.4115 39.504 24.9971 39.9184 24.9971 40.4295Z'
          fill='#83B4FF'
        />
        <path
          d='M35.4678 36.6892V61.0076H42.9474V36.6892C42.9474 36.178 42.5331 35.7637 42.0219 35.7637H36.3933C35.8822 35.7638 35.4678 36.1781 35.4678 36.6892Z'
          fill='#83B4FF'
        />
        <path
          d='M24.9971 51.0746V61.0073H32.4767V47.4414C30.1793 48.7511 27.6727 49.9747 24.9971 51.0746Z'
          fill='#5384EE'
        />
        <path
          d='M20.3119 52.8121C18.3626 53.4598 16.4278 54.0118 14.5244 54.4715V61.0074H22.0041V52.2266C21.446 52.4273 20.8819 52.6226 20.3119 52.8121Z'
          fill='#5384EE'
        />
        <path
          d='M4.05176 56.1034V61.009H11.5314V55.125C8.94393 55.6268 6.43531 55.9509 4.05176 56.1034Z'
          fill='#5384EE'
        />
        <path
          d='M35.4678 45.6118V61.0085H42.9474V39.3945C40.9946 41.5347 38.4624 43.6434 35.4678 45.6118Z'
          fill='#5384EE'
        />
        <path
          d='M0.5 63.0741V61.9334C0.5 61.4222 0.914359 61.0078 1.42557 61.0078H45.5744C46.0856 61.0078 46.5 61.4222 46.5 61.9334V63.0741C46.5 63.5853 46.0856 63.9997 45.5744 63.9997H1.42557C0.914359 63.9997 0.5 63.5853 0.5 63.0741H0.5Z'
          fill='#072252'
        />
        <path
          d='M41.4518 18H34.8977C34.3865 18 33.9722 18.4144 33.9722 18.9256V20.0663C33.9722 20.5775 34.3865 20.9919 34.8977 20.9919H37.8403L29.9867 28.8456H23.7811C23.3618 28.8456 22.9617 29.0216 22.6784 29.3306L13.8669 38.9431H4.88553C4.37432 38.9431 3.95996 39.3575 3.95996 39.8687V41.0094C3.95996 41.5206 4.37432 41.935 4.88553 41.935H14.525C14.9443 41.935 15.3444 41.759 15.6278 41.4499L24.4392 31.8375H30.6064C31.0031 31.8375 31.3836 31.6799 31.6642 31.3993L39.9559 23.1075V26.0501C39.9559 26.5613 40.3703 26.9757 40.8815 26.9757H42.0223C42.5335 26.9757 42.9478 26.5613 42.9478 26.0501V19.4959C42.9477 18.6698 42.278 18 41.4518 18V18Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_3633_79113'>
          <rect
            width='46'
            height='46'
            fill='white'
            transform='translate(0.5 18)'
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='url(#a)'>
        <path
          fill='#83B4FF'
          d='M4.052 50.527v10.481h7.48v-10.48a.925.925 0 0 0-.926-.926H4.977a.925.925 0 0 0-.925.925ZM14.524 46.039v14.969h7.48v-14.97a.926.926 0 0 0-.926-.925H15.45a.926.926 0 0 0-.926.926ZM24.997 40.43v20.578h7.48V40.429a.926.926 0 0 0-.926-.925h-5.628a.926.926 0 0 0-.926.925ZM35.468 36.69v24.318h7.48V36.689a.925.925 0 0 0-.926-.925h-5.629a.926.926 0 0 0-.925.925Z'
        />
        <path
          fill='#5384EE'
          d='M24.997 51.075v9.932h7.48V47.441a65.149 65.149 0 0 1-7.48 3.634ZM20.312 52.812c-1.95.648-3.884 1.2-5.788 1.66v6.535h7.48v-8.78c-.558.2-1.122.396-1.692.585ZM4.052 56.103v4.906h7.48v-5.884a59.316 59.316 0 0 1-7.48.978ZM35.468 45.612v15.396h7.48V39.394c-1.953 2.14-4.486 4.25-7.48 6.218Z'
        />
        <path
          fill='#072252'
          d='M.5 63.074v-1.14c0-.512.414-.926.926-.926h44.148c.512 0 .926.414.926.925v1.141a.926.926 0 0 1-.926.926H1.426a.926.926 0 0 1-.926-.926Z'
        />
        <path
          fill='#5384EE'
          d='M41.452 18h-6.554a.926.926 0 0 0-.926.926v1.14c0 .512.414.926.926.926h2.942l-7.853 7.854H23.78c-.42 0-.82.176-1.103.485l-8.811 9.612H4.886a.926.926 0 0 0-.926.926v1.14c0 .512.414.926.926.926h9.639c.42 0 .82-.176 1.103-.485l8.811-9.613h6.167c.397 0 .778-.157 1.058-.438l8.292-8.291v2.942c0 .511.414.926.925.926h1.141a.926.926 0 0 0 .926-.926v-6.554c0-.826-.67-1.496-1.496-1.496Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M.5 18h46v46H.5z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SelfDevelopmentIcon;
