import React from 'react';

const VolunteerIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_48_593517)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.879 26.6334L36.3163 30.028L42.3901 26.6334C43.1808 25.6611 43.8904 24.4402 44.1474 22.9733C45.332 16.2707 47.732 14.6921 45.6588 13.8959C43.6919 13.1416 41.3284 15.0246 41.3284 17.813V4.03332C41.3284 2.95484 40.4455 2.07198 39.3671 2.07198C38.2887 2.07198 37.4059 2.95484 37.4059 4.03332V1.55509C37.4059 0.47661 36.523 -0.40625 35.4446 -0.40625C34.3633 -0.40625 33.4804 0.47661 33.4804 1.55509V2.99398C33.4804 1.9155 32.5976 1.03264 31.5192 1.03264C30.4408 1.03264 29.558 1.9155 29.558 2.99398V7.49221C29.558 6.59538 28.8231 5.8578 27.9235 5.8578C27.0239 5.8578 26.2109 6.59538 26.2109 7.49221L26.2165 15.3068C26.2417 19.5368 26.3813 23.9792 28.879 26.6334Z"
        fill="#FFE5C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5542 7.49281C29.5542 6.59598 28.8194 5.8584 27.9198 5.8584C27.8918 5.8584 27.8639 5.86117 27.8359 5.86117V12.5945C27.8359 13.7233 29.5542 13.7233 29.5542 12.5945V12.4744V7.49281Z"
        fill="#FED2A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.48 11.9681V2.99399C33.48 1.99658 32.7284 1.16674 31.7617 1.04663V11.9681C31.7617 12.443 32.1473 12.8286 32.6222 12.8286C33.0944 12.8286 33.48 12.443 33.48 11.9681Z"
        fill="#FED2A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4046 11.4793V4.0335V1.55527V1.54408C37.3991 0.552211 36.6475 -0.271983 35.6836 -0.39209V11.4793C35.6836 11.9515 36.072 12.3399 36.5441 12.3399C37.0163 12.3399 37.4046 11.9515 37.4046 11.4793Z"
        fill="#FED2A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.6554 13.8958C45.024 13.6555 44.3507 13.6834 43.7277 13.9293C45.6611 14.734 43.3058 16.3572 42.138 22.9732C41.7831 24.9904 40.579 26.5439 39.4727 27.6195H41.4815C42.585 26.5438 43.7891 24.9904 44.144 22.9732C45.3285 16.2706 47.7285 14.692 45.6554 13.8958Z"
        fill="#FED2A4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9898 8.52319L23.186 5.35207L23.6218 4.92179C24.6974 3.80704 24.6974 1.98534 23.6218 0.873364C22.6468 -0.138025 21.0683 0.113387 20.0262 1.05501C19.8334 1.22823 19.7663 1.31484 19.7132 1.31484C19.663 1.31484 19.5931 1.22823 19.4003 1.05501C18.3582 0.113483 16.7824 -0.138025 15.8046 0.873364C14.7318 1.98534 14.7318 3.80695 15.8046 4.92179L16.2405 5.35207L19.4394 8.52319C19.5763 8.6322 19.6461 8.69086 19.7132 8.69364C19.7831 8.69086 19.8529 8.6322 19.9898 8.52319Z"
        fill="#5384EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.988 8.52251L23.1842 5.3514L23.62 4.92112C24.6956 3.80637 24.6956 1.98466 23.62 0.872689C23.511 0.758133 23.3937 0.660324 23.2708 0.57936C22.6897 0.190997 21.9773 0.137882 21.3011 0.333499C21.3626 0.411688 21.4212 0.495524 21.4744 0.57936C22.1672 1.68578 22.0471 3.19444 21.1112 4.16401L20.6753 4.59707L18.0742 7.1731L19.4376 8.52261C19.5745 8.63162 19.6443 8.69028 19.7114 8.69306C19.7813 8.69019 19.851 8.63152 19.988 8.52251Z"
        fill="#3F73E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3808 26.6338H43.2359C43.5349 26.6338 43.7807 26.8797 43.7807 27.1814V48.0465C43.7807 48.3482 43.5349 48.5941 43.2359 48.5941H27.3808C27.079 48.5941 26.8359 48.3482 26.8359 48.0465V27.1814C26.836 26.8797 27.079 26.6338 27.3808 26.6338Z"
        fill="#83B4FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8949 48.5941H43.2338C43.5328 48.5941 43.7787 48.3482 43.7787 48.0465V27.1814C43.7787 26.8797 43.5328 26.6338 43.2338 26.6338H42.3873H40.387H40.3814H38.8867C39.1885 26.6338 39.4343 26.8797 39.4343 27.1814V48.0465C39.4341 48.3454 39.1911 48.5885 38.8949 48.5941Z"
        fill="#6DA1EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.6432 45.3417L45.1246 40.8993L45.7364 40.2958C47.2423 38.7341 47.2423 36.1804 45.7364 34.6213C44.3674 33.2048 42.1575 33.5596 40.6963 34.8756C40.4253 35.1187 40.3303 35.2416 40.2577 35.2388C40.185 35.2416 40.09 35.1187 39.8191 34.8756C38.3579 33.5597 36.1479 33.2048 34.7818 34.6213C33.2759 36.1803 33.2759 38.734 34.7818 40.2958L35.3908 40.8993L39.8722 45.3417C40.0649 45.4954 40.1628 45.5791 40.2578 45.582C40.3554 45.5792 40.4504 45.4954 40.6432 45.3417Z"
        fill="#5384EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.7363 40.2957C47.2422 38.7339 47.2422 36.1803 45.7363 34.6212C45.1859 34.0512 44.4987 33.7691 43.7806 33.7187C43.3643 33.6908 42.9369 33.7411 42.5178 33.8585C42.5262 33.864 42.5318 33.8724 42.5402 33.878C44.0461 35.4398 44.0461 37.9934 42.5402 39.5525L38.2852 43.7685L39.8721 45.3415C40.0648 45.4952 40.1627 45.5789 40.2577 45.5818C40.3555 45.579 40.4504 45.4952 40.6433 45.3415L45.1247 40.8991L45.7363 40.2957Z"
        fill="#3F73E3"
      />
      <path
        d="M41.3284 17.0748V4.03403C41.3284 3.03662 40.5741 2.20678 39.6074 2.08667V17.8137C39.6074 17.8615 39.6123 17.9081 39.6199 17.9538C38.3093 18.8705 37.2341 20.0868 36.9193 21.0925C36.7981 21.4796 36.9868 21.9089 37.3698 22.0427C37.4515 22.0713 37.5343 22.0849 37.6157 22.0849C37.9358 22.0849 38.231 21.8753 38.3251 21.5525C38.5287 20.8551 39.8843 19.31 41.1829 18.5707C41.2738 18.519 41.3283 18.4207 41.3283 18.3161V17.0748H41.3284Z"
        fill="#FED2A4"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0_48_593517">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default VolunteerIcon;
