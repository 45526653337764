import useToast from '@lobox/uikit/Toast/useToast';
import { useTranslation } from '@lobox/utils';
import upperFirst from 'lodash/upperFirst';

interface ShowSuccessToastProps {
  isEdit?: boolean;
  shared?: boolean;
  variant: string;
  isDelete?: boolean;
}

const useShowSuccessToast = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const showSuccessToast = ({
    isEdit,
    shared,
    variant,
    isDelete,
  }: ShowSuccessToastProps) => {
    const action = isDelete
      ? t('deleted')
      : isEdit
        ? t('updated')
        : shared
          ? t('added_t_y_p_a_feed')
          : t('added_t_y_p');

    toast({
      type: 'success',
      icon: 'check-circle',
      message: `${upperFirst(variant?.toLowerCase())} ${action} ${t(
        'successfully'
      )} `,
    });
  };

  return showSuccessToast;
};

export default useShowSuccessToast;
