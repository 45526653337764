import React from 'react';

const TravelIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6026 6.50552L23.6027 6.50705C23.6612 6.4984 23.7207 6.49238 23.7815 6.49238L39.8026 7.25564L47.0056 6.49238V4.91658L39.9868 4.15332L30.5272 4.91658L23.6026 6.50552Z"
      fill="#3F73E3"
    />
    <path
      d="M47.0055 4.91676V3.31841C47.0055 2.20125 45.967 1.37404 44.8781 1.62388L30.5272 4.91676H47.0055Z"
      fill="#5384EE"
    />
    <path
      d="M23.7817 44.1873H48.0416C49.3802 44.1873 50.4653 43.0001 50.4653 41.0002V41.0003V8.91597C50.4653 7.57738 49.3801 6.49219 48.0416 6.49219H23.7817C23.1124 6.49219 22.5698 7.03478 22.5698 7.70408V42.2122C22.5698 43.37 23.1124 44.1873 23.7817 44.1873Z"
      fill="#5384EE"
    />
    <path
      d="M37.1486 29.4548C41.3721 29.0859 44.4969 25.363 44.1279 21.1395C43.759 16.916 40.0361 13.7912 35.8126 14.1601C31.5891 14.5291 28.4643 18.252 28.8332 22.4755C29.2021 26.699 32.925 29.8238 37.1486 29.4548Z"
      fill="white"
    />
    <path
      d="M36.492 30.1522C41.0967 30.1522 44.8429 26.406 44.8429 21.8012C44.8429 17.1965 41.0967 13.4502 36.492 13.4502C31.8872 13.4502 28.1411 17.1965 28.1411 21.8012C28.141 26.406 31.8872 30.1522 36.492 30.1522ZM36.492 28.8365C35.8352 28.8365 34.969 27.4676 34.5167 25.1506H38.4672C38.0149 27.4675 37.1487 28.8365 36.492 28.8365ZM38.7238 28.4715C38.8895 28.1759 39.0436 27.8436 39.184 27.4758C39.4464 26.7888 39.6551 26.0019 39.806 25.1506H42.6774C41.8337 26.7027 40.4218 27.9018 38.7238 28.4715ZM43.5271 21.8012C43.5271 22.5082 43.4212 23.1906 43.2263 23.8348H39.9871C40.0532 23.1778 40.0879 22.496 40.0879 21.8012C40.0879 21.1733 40.0593 20.5562 40.0052 19.9582H43.2806C43.4404 20.546 43.5271 21.1635 43.5271 21.8012ZM42.7752 18.6425H39.8385C39.6858 17.7178 39.4658 16.8646 39.184 16.1266C39.0436 15.7588 38.8895 15.4265 38.7238 15.1309C40.4904 15.7236 41.9455 16.9987 42.7752 18.6425ZM36.492 14.7659C37.1665 14.7659 38.0623 16.2098 38.5033 18.6425H34.4806C34.9216 16.2099 35.8173 14.7659 36.492 14.7659ZM38.6846 19.9582C38.7405 20.5342 38.7721 21.1495 38.7721 21.8012C38.7721 22.5256 38.7329 23.2045 38.6646 23.8348H34.3191C34.2508 23.2045 34.2116 22.5255 34.2116 21.8012C34.2116 21.1495 34.2431 20.5342 34.2991 19.9582H38.6846ZM34.2601 15.1309C34.0944 15.4266 33.9403 15.7588 33.7999 16.1266C33.5181 16.8646 33.2981 17.7177 33.1454 18.6425H30.2086C31.0383 16.9987 32.4935 15.7236 34.2601 15.1309ZM29.4567 21.8012C29.4567 21.1635 29.5435 20.546 29.7033 19.9582H32.9787C32.9246 20.5562 32.896 21.1733 32.896 21.8012C32.896 22.496 32.9307 23.1779 32.9968 23.8348H29.7576C29.5626 23.1907 29.4567 22.5082 29.4567 21.8012ZM33.1778 25.1506C33.3287 26.0019 33.5375 26.7888 33.7998 27.4758C33.9402 27.8436 34.0943 28.1759 34.26 28.4715C32.5619 27.9018 31.1501 26.7027 30.3063 25.1506H33.1778Z"
      fill="#83B4FF"
    />
    <path
      d="M28.7985 34.2337H44.2366C44.6 34.2337 44.8945 33.9391 44.8945 33.5758C44.8945 33.2125 44.5999 32.918 44.2366 32.918H28.7985C28.4352 32.918 28.1406 33.2125 28.1406 33.5758C28.1406 33.9391 28.4352 34.2337 28.7985 34.2337Z"
      fill="#83B4FF"
    />
    <path
      d="M31.7126 36.7363C31.3493 36.7363 31.0548 37.0309 31.0548 37.3942C31.0548 37.7575 31.3493 38.052 31.7126 38.052H41.3224C41.6858 38.052 41.9803 37.7575 41.9803 37.3942C41.9803 37.0309 41.6857 36.7363 41.3224 36.7363H31.7126Z"
      fill="#83B4FF"
    />
    <path
      d="M48.0416 43.4247H23.7817C23.1124 43.4247 22.5698 42.8821 22.5698 42.2128V43.7886C22.5698 44.4579 23.1124 45.0005 23.7817 45.0005H48.0416C49.3802 45.0005 50.4653 43.9153 50.4653 42.5768V41.001C50.4653 42.3396 49.3802 43.4247 48.0416 43.4247Z"
      fill="#3F73E3"
    />
    <path
      d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
      fill="#3F73E3"
    />
    <path
      d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
      fill="white"
    />
    <path
      d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
      fill="#3F73E3"
    />
  </svg>
);

export default TravelIcon;
