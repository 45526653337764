import React from 'react';

const LicenceIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)" filter="url(#filter0_d)">
        <path
          d="M40.7221 -0.40625H25.3897C25.061 -0.40625 24.7398 -0.334777 24.4467 -0.200441C24.3185 -0.14166 24.2357 -0.0139297 24.2357 0.127086V5.21204C24.2357 5.9871 23.6074 6.61544 22.8323 6.61544H17.7474C17.6063 6.61544 17.4785 6.69819 17.4198 6.82644C17.2855 7.11953 17.214 7.44082 17.214 7.76939V35.3301C17.214 36.5803 18.2275 37.5938 19.4777 37.5938H40.7221C41.9723 37.5938 42.9857 36.5803 42.9857 35.3301V1.85742C42.9857 0.607207 41.9722 -0.40625 40.7221 -0.40625Z"
          fill="#5384EE"
        />
        <path
          d="M44.8436 -0.40625H39.9828C41.2331 -0.40625 42.2465 0.607207 42.2465 1.85742V35.3301C42.2465 36.5803 41.2331 37.5938 39.9828 37.5938H44.8436C46.0938 37.5938 47.1073 36.5803 47.1073 35.3301V1.85742C47.1073 0.607207 46.0938 -0.40625 44.8436 -0.40625Z"
          fill="#3F73E3"
        />
        <path
          d="M17.877 6.16855L23.789 0.256512C23.9826 0.0629492 24.2076 -0.0919453 24.452 -0.203125V5.42815C24.452 6.20321 23.8237 6.83155 23.0486 6.83155H17.4173C17.5286 6.58707 17.6834 6.36212 17.877 6.16855Z"
          fill="#E28086"
        />
        <path
          opacity="0.5"
          d="M41.5347 6.8324H29.7626C29.3694 6.8324 29.0506 6.51363 29.0506 6.12042V4.25983C29.0506 3.86662 29.3694 3.54785 29.7626 3.54785H41.5347C41.928 3.54785 42.2467 3.86662 42.2467 4.25983V6.12042C42.2467 6.51363 41.928 6.8324 41.5347 6.8324Z"
          fill="#F1F4F9"
        />
        <g opacity="0.5">
          <path
            d="M36.2124 14.0453H21.5336C21.2169 14.0453 20.9602 13.7885 20.9602 13.4719C20.9602 13.1552 21.2168 12.8984 21.5336 12.8984H36.2124C36.5291 12.8984 36.7858 13.1552 36.7858 13.4719C36.7858 13.7885 36.5291 14.0453 36.2124 14.0453Z"
            fill="#F1F4F9"
          />
          <path
            d="M36.2124 18.0746H21.5336C21.2169 18.0746 20.9602 17.8178 20.9602 17.5011C20.9602 17.1845 21.2168 16.9277 21.5336 16.9277H36.2124C36.5291 16.9277 36.7858 17.1845 36.7858 17.5011C36.7858 17.8178 36.5291 18.0746 36.2124 18.0746Z"
            fill="#F1F4F9"
          />
          <path
            d="M36.2124 22.1029H21.5336C21.2169 22.1029 20.9602 21.8462 20.9602 21.5295C20.9602 21.2128 21.2168 20.9561 21.5336 20.9561H36.2124C36.5291 20.9561 36.7858 21.2128 36.7858 21.5295C36.7858 21.8462 36.5291 22.1029 36.2124 22.1029Z"
            fill="#F1F4F9"
          />
          <path
            d="M36.2124 26.1332H21.5336C21.2169 26.1332 20.9602 25.8764 20.9602 25.5597C20.9602 25.2431 21.2168 24.9863 21.5336 24.9863H36.2124C36.5291 24.9863 36.7858 25.2431 36.7858 25.5597C36.7858 25.8764 36.5291 26.1332 36.2124 26.1332Z"
            fill="#F1F4F9"
          />
          <path
            d="M28.8918 32.0375H21.5336C21.2169 32.0375 20.9602 31.7807 20.9602 31.464C20.9602 31.1473 21.2168 30.8906 21.5336 30.8906H28.8919C29.2086 30.8906 29.4653 31.1473 29.4653 31.464C29.4653 31.7807 29.2086 32.0375 28.8918 32.0375Z"
            fill="#F1F4F9"
          />
        </g>
        <path
          d="M49.5476 33.2649L47.4448 31.9703C47.2379 31.843 46.9769 31.843 46.77 31.9703L44.6672 33.2649C44.2383 33.5289 43.6861 33.2203 43.6861 32.7168V24.3887H50.5287V32.7168C50.5287 33.2203 49.9765 33.5289 49.5476 33.2649Z"
          fill="#6799E4"
        />
        <path
          d="M45.7347 27.4579C48.3982 26.0659 50.2108 23.3185 50.2108 20.1567C50.2108 16.9949 48.3983 14.2474 45.7347 12.8555C45.3008 13.4027 44.578 13.6334 43.9062 13.4374C42.8265 13.1224 41.7401 13.9117 41.7061 15.0359C41.6848 15.7423 41.2334 16.3636 40.5681 16.6022C39.5095 16.9819 39.0945 18.2591 39.7278 19.1886C40.1256 19.7726 40.1256 20.5406 39.7278 21.1246C39.0944 22.0541 39.5095 23.3313 40.5681 23.711C41.2334 23.9496 41.6848 24.571 41.7061 25.2773C41.7401 26.4015 42.8265 27.1908 43.9062 26.8758C44.578 26.6799 45.3008 26.9107 45.7347 27.4579Z"
          fill="#83B4FF"
        />
        <path
          d="M54.4868 21.1245C54.0889 20.5405 54.0889 19.7725 54.4868 19.1884C55.12 18.259 54.705 16.9818 53.6464 16.602C52.9812 16.3634 52.5298 15.7421 52.5084 15.0357C52.4744 13.9115 51.388 13.1222 50.3083 13.4373C49.6299 13.6352 48.8995 13.3979 48.4669 12.839C47.7786 11.9495 46.4358 11.9495 45.7475 12.839C45.6506 12.9643 45.5386 13.0734 45.4157 13.1651C48.0698 14.3741 49.9147 17.0498 49.9147 20.1565C49.9147 23.2633 48.0697 25.939 45.4157 27.148C45.5386 27.2397 45.6506 27.3488 45.7475 27.4741C46.4358 28.3636 47.7786 28.3636 48.4669 27.4741C48.8994 26.9151 49.6299 26.6778 50.3083 26.8758C51.388 27.1908 52.4744 26.4015 52.5084 25.2773C52.5298 24.5709 52.9812 23.9496 53.6464 23.711C54.705 23.3311 55.12 22.0539 54.4868 21.1245Z"
          fill="#83B4FF"
        />
        <path
          d="M47.1072 24.3885C49.4446 24.3885 51.3395 22.4936 51.3395 20.1562C51.3395 17.8187 49.4446 15.9238 47.1072 15.9238C44.7697 15.9238 42.8748 17.8187 42.8748 20.1562C42.8748 22.4936 44.7697 24.3885 47.1072 24.3885Z"
          fill="white"
        />
        <path
          d="M51.3396 20.1558C51.3396 18.4538 50.3349 16.9864 48.8863 16.3145C49.5404 17.4444 49.9148 18.7564 49.9148 20.1558C49.9148 21.5553 49.5403 22.8673 48.8863 23.9971C50.335 23.3252 51.3396 21.8578 51.3396 20.1558Z"
          fill="#FCFCFC"
        />
        <path
          d="M17.877 6.16855L23.789 0.256512C23.9826 0.0629492 24.2076 -0.0919453 24.452 -0.203125V5.42815C24.452 6.20321 23.8237 6.83155 23.0486 6.83155H17.4173C17.5286 6.58707 17.6834 6.36212 17.877 6.16855Z"
          fill="#072252"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="5"
        y="-8.40625"
        width="62"
        height="62"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.65625 0 0 0 0 0.697742 0 0 0 0 0.7875 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="38"
          height="38"
          fill="white"
          transform="translate(17 -0.40625)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LicenceIcon;
