import React from 'react';

const SportIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M41.2188 5.54688L35.7807 18.4623L35.7517 20.1775L34.2932 20.4512C34.1453 20.5535 33.9896 20.6458 33.8271 20.727L33.4993 20.8909C33.0255 21.1278 32.4928 20.9562 32.2179 20.5717L28.7828 20.5707C28.508 20.9559 27.9749 21.128 27.5007 20.8909L27.1729 20.727C27.0108 20.646 26.8554 20.554 26.7079 20.452L24.9312 20.4048L25.2193 18.4623L19.7813 5.54688H16.7188L21.874 19.4659L26.64 24.6875H35.1655L39.126 19.4659L44.2813 5.54688H41.2188Z"
        fill="#FFE5C2"
      />
      <path
        d="M35.7807 18.4619L35.6432 18.7882C35.36 19.4608 34.8865 20.0387 34.2931 20.4508C34.1157 22.4003 32.4957 23.9215 30.5 23.9215C28.5042 23.9215 26.8852 22.4011 26.7078 20.4516C26.1145 20.0395 25.6399 19.4609 25.3567 18.7883L25.2192 18.462L23.3438 19.0246L24.1835 24.0307C24.7195 25.4776 24.9938 27.0083 24.9938 28.5512V37.7027L25.9055 39.3514C27.0279 39.7489 28.6702 39.9996 30.5 39.9996C33.8827 39.9996 36.625 39.1426 36.625 38.0856V37.7027V28.5512C36.625 27.0083 36.8993 25.4776 37.4352 24.0307L39.126 19.4656L35.7807 18.4619Z"
        fill="#5384EE"
      />
      <path
        d="M30.4999 20.8594C31.1046 20.8594 31.6756 20.7564 32.2179 20.5718C32.1001 20.4084 32.0312 20.2095 32.0312 19.9836V18.5625H28.9687V19.9836C28.9687 20.2094 28.9005 20.4074 28.7827 20.5707C29.325 20.7553 29.8953 20.8594 30.4999 20.8594Z"
        fill="#FED2A4"
      />
      <path
        d="M33.5554 13.2031H29.8457C28.5772 13.2031 27.5488 14.2315 27.5488 15.5V16.1221L28.9687 19.0046C29.3239 19.1654 29.7093 19.2713 30.1168 19.3101C32.4 19.5279 34.321 17.7383 34.321 15.5V13.9688C34.3211 13.5459 33.9783 13.2031 33.5554 13.2031Z"
        fill="#FFE5C2"
      />
      <path
        d="M26.6646 13.2028V15.3351C26.6646 16.9196 27.5967 18.3767 28.9682 19.0048V12.4053L26.6646 13.2028Z"
        fill="#FED2A4"
      />
      <path
        d="M33.5555 10.9062L28.9684 10.9066L28.5498 12.1982L28.9684 14.0366C31.9606 14.7487 34.3211 13.9688 34.3211 13.9688V11.6719C34.3211 11.2491 33.9783 10.9062 33.5555 10.9062Z"
        fill="#485363"
      />
      <path
        d="M28.9681 10.9062C27.6996 10.9062 26.6646 11.9342 26.6646 13.2027C27.4517 13.5932 28.2316 13.8605 28.9681 14.0362V10.9062Z"
        fill="#072252"
      />
      <path
        d="M9.0625 6.3125H6.76563C6.34281 6.3125 6 5.96969 6 5.54688C6 5.12406 6.34281 4.78125 6.76563 4.78125H9.0625V6.3125Z"
        fill="#485363"
      />
      <path
        d="M54.2344 6.3125H12.8906V4.78125H54.2344C54.6572 4.78125 55 5.12406 55 5.54688C55 5.96969 54.6572 6.3125 54.2344 6.3125Z"
        fill="#485363"
      />
      <path
        d="M12.8905 9.4707H11.3594L9.82812 8.60938V2.48438C9.82812 1.63865 10.5136 0.953125 11.3594 0.953125H12.8905C13.7363 0.953125 14.4218 1.63865 14.4218 2.48438V8.60938L12.8905 9.4707Z"
        fill="#83B4FF"
      />
      <path
        d="M11.3594 10.1406H12.8906C13.7363 10.1406 14.4219 9.45501 14.4219 8.60938H10.5938L11.3594 10.1406Z"
        fill="#64A2FF"
      />
      <path
        d="M10.2749 9.375L11.3595 8.60938L11.3596 0.953125H9.82826L8.74365 2.48438V7.84375C8.74365 8.68948 9.42917 9.375 10.2749 9.375Z"
        fill="#83B4FF"
      />
      <path
        d="M11.3594 8.60938C10.5136 8.60938 9.82812 7.92376 9.82812 7.07813V0.953125C8.98249 0.953125 8.29688 1.63865 8.29688 2.48438V8.60938C8.29688 9.45501 8.98249 10.1406 9.82812 10.1406H11.3594V8.60938Z"
        fill="#64A2FF"
      />
      <path
        d="M25.9058 28.5519C25.9058 26.8214 25.6007 25.1215 25.0003 23.4998L23.3433 19.0254L21.8735 19.4663L23.5643 24.0314C24.1003 25.4784 24.3745 27.009 24.3745 28.552V37.7035V38.0863C24.3745 38.5709 24.9556 39.0149 25.9058 39.3522V28.5519Z"
        fill="#3F73E3"
      />
      <path
        d="M51.1718 9.4707H49.6406L48.1094 8.60938V2.48438C48.1094 1.63865 48.7949 0.953125 49.6406 0.953125H51.1718C52.0175 0.953125 52.703 1.63865 52.703 2.48438V8.60938L51.1718 9.4707Z"
        fill="#83B4FF"
      />
      <path
        d="M49.6406 10.1406H51.1719C52.0175 10.1406 52.7031 9.45501 52.7031 8.60938H48.875L49.6406 10.1406Z"
        fill="#64A2FF"
      />
      <path
        d="M48.5557 9.375L49.6403 8.60938L49.6404 0.953125H48.109L47.0244 2.48438V7.84375C47.0244 8.68948 47.7099 9.375 48.5557 9.375Z"
        fill="#83B4FF"
      />
      <path
        d="M49.6406 8.60938C48.7949 8.60938 48.1094 7.92376 48.1094 7.07813V0.953125C47.2637 0.953125 46.5781 1.63865 46.5781 2.48438V8.60938C46.5781 9.45501 47.2637 10.1406 48.1094 10.1406H49.6406V8.60938Z"
        fill="#64A2FF"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SportIcon;
