import React from 'react';

const GradutionIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipGraduation)">
      <path
        d="M29.8373 2.73985C29.943 2.81498 29.7419 3.51131 29.753 3.54572C29.7559 3.5548 31.6171 7.62943 31.6017 7.64323C33.1154 6.28672 33.6876 4.36305 33.2477 3.49212C32.8492 2.70337 31.556 2.4697 29.8373 2.73985Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M24.5391 11.0735C24.5391 11.0735 20.8203 7.42352 20.8225 7.29381C19.5852 8.51698 19.006 9.69663 19.4045 10.4854C19.7514 11.1721 20.9045 11.7157 22.2982 11.5948C22.2865 11.5958 24.2316 11.2428 24.5391 11.0735Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M26.9497 8.22364C29.2641 6.31784 30.5326 4.38988 30.1205 3.11534C30.1094 3.08093 30.0957 3.0476 30.0793 3.01519C29.6137 2.09346 27.1813 2.38429 24.6466 3.66491C22.1118 4.94546 20.4344 6.73079 20.9001 7.65253C21.3541 8.55121 23.6037 9.28756 26.069 8.08746C26.1745 8.03603 26.9497 8.22364 26.9497 8.22364Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M26.9494 8.22343L26.4283 7.19186C26.3753 7.0871 26.2482 7.04548 26.1427 7.09691C23.766 8.25395 21.5211 8.53156 20.956 7.74481L23.075 10.8169C24.5523 10.0035 25.8656 9.11587 26.9494 8.22343Z"
        fill="#FCFCFC"
      />
      <path
        d="M28.4691 11.8354C29.9953 11.0643 31.468 7.02707 31.468 7.02707L30.1206 3.11603C30.766 5.11168 27.2911 8.70925 22.1322 11.3156L22.1354 11.3218C22.1321 11.3155 26.4602 12.8503 28.4691 11.8354Z"
        fill="#FCFCFC"
      />
      <path
        d="M30.0456 8.15278C30.0456 8.15278 34.7146 24.1134 35.3962 25.687C35.9629 25.5915 38.8538 21.5444 39.7189 20.7977C38.6556 18.5481 37.7105 16.2417 36.8884 13.8878L33.3119 3.64738C33.6798 4.76673 32.3364 6.54522 30.0456 8.15278Z"
        fill="#3F73E3"
      />
      <path
        d="M48.4339 44.1428C50.7391 42.487 52.0303 40.6711 51.484 39.5898C51.4585 39.5393 51.4293 39.4911 51.3965 39.4451L45.6996 31.1106L34.7957 26.3674L48.4339 44.1428Z"
        fill="#3F73E3"
      />
      <path
        d="M39.0397 26.1403C39.0394 26.1443 39.039 26.1482 39.0386 26.1522C39.039 26.1482 39.0394 26.1443 39.0397 26.1403Z"
        fill="#5384EE"
        fillOpacity="0.2"
      />
      <path
        d="M37.6731 28.4729C37.6732 28.4728 33.3262 25.8851 31.3305 29.6213C32.3284 31.7621 33.2196 33.9536 34 36.188L37.5764 46.4285C37.5765 46.4284 37.5764 46.4284 37.5764 46.4283C37.594 46.4818 37.6154 46.5337 37.6408 46.5839C38.3657 48.0189 42.0523 47.6167 45.875 45.6856C46.8172 45.2095 47.6797 44.6851 48.4337 44.1435L37.7364 28.4937L37.6731 28.4729Z"
        fill="#5384EE"
      />
      <path
        d="M30.0456 8.15329C29.3284 8.6566 28.5183 9.14314 27.6388 9.58746C23.9501 11.451 20.388 11.8908 19.4916 10.6299L25.6127 19.5848C27.0197 21.6433 28.3154 23.7725 29.4953 25.9631C30.6098 25.7099 33.1088 26.5693 34.1686 26.161C34.4033 25.3259 35.0049 22.7256 35.3151 22.6733C34.6334 21.0996 34.0086 19.5004 33.4421 17.8785L30.0456 8.15329Z"
        fill="#5384EE"
      />
      <path
        d="M32.7603 24.9696C31.6905 25.3818 30.5887 25.717 29.4633 25.971L29.3384 25.9993C29.0759 26.0585 28.9833 26.3849 29.1757 26.5731L29.7022 27.0884C30.2085 27.584 30.5076 28.2533 30.5391 28.9611L30.558 29.3858C30.5675 29.5971 30.7657 29.7485 30.972 29.7019L31.3096 29.6257C32.3647 29.3875 33.3989 29.0779 34.4055 28.7006L32.7603 24.9696Z"
        fill="white"
      />
      <path
        d="M41.2708 23.1974C40.8558 22.7731 40.5845 22.2253 40.5012 21.632L40.3988 20.9024C40.3614 20.636 40.0437 20.5168 39.8403 20.6929L39.7435 20.7767C38.8712 21.5319 37.9477 22.2198 36.9811 22.8364L37.8571 24.4602L41.2708 23.1974Z"
        fill="#5384EE"
        fillOpacity="0.1"
      />
      <path
        d="M48.3706 22.2971L38.1409 23.5958L38.1104 26.1069L44.5938 26.6759L48.8578 26.1346C48.9039 26.1287 48.9247 26.0736 48.8939 26.0388L47.766 24.7644C47.5544 24.5253 47.5108 24.1811 47.6559 23.8968L48.4295 22.381C48.4507 22.3395 48.4168 22.2913 48.3706 22.2971Z"
        fill="white"
      />
      <path
        d="M37.673 28.4717L47.4691 31.6919C47.5133 31.7065 47.5558 31.6656 47.5429 31.6209L47.0717 29.9856C46.9833 29.6789 47.0916 29.3493 47.3448 29.1548L48.6944 28.118C48.7313 28.0897 48.7214 28.0316 48.6771 28.0171L38.881 24.7968L37.673 28.4717Z"
        fill="white"
      />
      <path
        d="M38.8934 26.8365C39.4464 25.1543 38.531 23.3423 36.8488 22.7893C35.1665 22.2363 33.3545 23.1517 32.8015 24.8339C32.2485 26.5162 33.164 28.3282 34.8462 28.8812C36.5284 29.4342 38.3404 28.5187 38.8934 26.8365Z"
        fill="#83B4FF"
      />
      <path
        d="M36.9667 26.2034C37.1699 25.5852 36.8335 24.9192 36.2152 24.716C35.5969 24.5127 34.931 24.8492 34.7277 25.4674C34.5245 26.0857 34.8609 26.7517 35.4792 26.9549C36.0975 27.1582 36.7634 26.8217 36.9667 26.2034Z"
        fill="white"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clipGraduation">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GradutionIcon;
