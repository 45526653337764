import classes from './usePublication.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  Endpoints,
  formValidator,
  hereApiResponseNormalizer,
  linkValidation,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfileModals, useProfile } from '@shared/hooks';
import { PUBLICATION } from '@shared/constants/profileModalsKeys';
import PublicationSvg from '@shared/svg/SvgPublication';
import { DESC_LEN_VALIDATION } from '@shared/constants/highlights.user';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';

import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import type { Publication } from '@lobox/utils';
import useShowSuccessToast from './useShowSuccessToast';

export const usePublicationFormGroups = ({
  isEdit,
  isHighlight,
}: {
  isEdit?: boolean;
  isHighlight?: boolean;
} = {}) => {
  const { t } = useTranslation();

  const groups = [
    {
      name: 'title',
      cp: 'input',
      maxLength: 100,
      label: t('title'),
      helperText: t('publication_title_helper'),
      required: true,
      wrapStyle: classes.formItem,
    },
    {
      name: 'publisher',
      cp: 'asyncAutoComplete',
      maxLength: 100,
      label: t('publisher'),
      required: true,
      url: `${Endpoints.App.Common.suggestPlace}`,
      normalizer: hereApiResponseNormalizer,
      wrapStyle: classes.formItem,
      renderItem: ({ item }: any) => (
        <AvatarCard
          data={{
            title: item.label,
            image: item.image,
          }}
          avatarProps={{ isCompany: true, size: 'sm' }}
        />
      ),
    },

    {
      name: 'link',
      cp: 'input',
      label: t('link'),
      wrapStyle: classes.formItem,
    },
    {
      name: 'date',
      cp: 'datePicker',
      variant: 'input',
      wrapStyle: classes.formItem,
      required: true,
      label: t('issue_date'),
    },
    {
      name: 'description',
      visibleOptionalLabel: false,
      cp: 'richtext',
      label: t('tell_more_about_publication'),
      wrapStyle: classes.formItem,
      maxLength: DESCRIPTION_MAX_LENGTH,
    },
  ].reduce((prev: any, curr) => {
    if (curr.name === 'description' && isHighlight) {
      return prev;
    }
    return [...prev, curr];
  }, []);
  return React.useCallback(groups, []);
};
const usePublication = () => {
  const { t } = useTranslation();
  const { close, modals } = useProfileModals();
  const { reFetchAboutSectionQuery } = useProfile();
  const showSuccessToast = useShowSuccessToast();
  const { openConfirmDialog } = useOpenConfirm();
  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const publication = modals?.params?.[PUBLICATION]?.realData || {};
  const isEdit = !!publication?.id;
  const editUrl = `${Endpoints.App.User.Publication.get}/${publication?.id}`;

  const onSuccess = (data: Publication, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          ...data,
          profileEntityId: data.id,
          subTitle: data.publisherName,
          title: data.title,
          type: HIGHLIGHT_TYPES.PUBLICATION,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isDelete: !values,
      isEdit,
      shared: values?.share,
      variant: PUBLICATION,
    });
  };

  const { mutate } = useReactMutation({
    url: editUrl,
    method: 'DELETE',
  });

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_public_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };
  const groups = usePublicationFormGroups();

  return {
    onSuccess,
    title: isEdit ? t('edit_publication') : t('add_publication'),
    method: isEdit ? 'PUT' : 'POST',
    image: <PublicationSvg />,
    url: isEdit ? editUrl : Endpoints.App.User.Publication.get,
    initialValues: { ...publication, share: true } as Publication,
    visibleRequiredHint: true,
    visibleHeaderBorderBottom: false,
    transform: ({ publisher, ...rest }: Publication) => ({
      ...rest,
      publisherName: publisher?.label,
      publisherPageId: `${publisher?.value}`?.includes('_temp')
        ? null
        : publisher?.value,
    }),
    validationSchema: () =>
      formValidator.object().shape({
        link: linkValidation,
        description: DESC_LEN_VALIDATION,
      }),
    secondaryAction: isEdit
      ? {
          label: t('delete_publication'),
          onClick: openConfirm,
        }
      : undefined,
    groups,
  };
};

export default usePublication;
