import React from 'react';

const InstrumentIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.4449 47.5026C34.7062 49.3071 39.7671 48.4228 41.5201 43.2685C43.2742 38.1114 39.7043 36.043 40.4945 33.5975C41.2858 31.149 43.0221 31.8425 43.9947 28.9828C45.1131 25.6945 42.474 22.5944 38.3941 21.1952C34.3122 19.7969 30.3351 20.6259 29.2187 23.9133C28.2451 26.776 30.0421 27.2911 29.1765 29.714C28.3108 32.137 24.2269 31.5803 22.4738 36.7344C20.7208 41.8886 24.1866 45.6991 29.4449 47.5026Z"
        fill="#5384EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9218 48.4759C37.3917 48.5722 40.3108 47.0639 41.5587 43.3783C43.3113 38.2024 39.7586 36.1215 40.5483 33.667C41.339 31.2096 43.0673 31.908 44.0391 29.0379C45.1566 25.7377 42.5319 22.622 38.4705 21.2122C36.5835 20.5572 34.7198 20.3858 33.1533 20.7199C34.1815 20.7681 35.2748 20.9856 36.3739 21.3672C40.4352 22.777 43.0598 25.8927 41.9425 29.1929C40.9706 32.0631 39.2422 31.3647 38.4515 33.8221C37.6629 36.2786 41.2155 38.3597 39.464 43.5325C38.4749 46.4483 36.4435 47.9994 33.9218 48.4759Z"
        fill="#3F73E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1044 28.2315C34.4825 27.6753 32.7199 28.5395 32.1662 30.1623C31.6146 31.7841 32.4835 33.5526 34.1054 34.1088C35.7303 34.6661 37.4929 33.8019 38.0435 32.1781C38.5971 30.5552 37.7282 28.7868 36.1044 28.2315Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.1288 28.2374C34.9869 27.8394 33.7712 28.1576 32.9652 28.9562C33.4264 28.893 33.9076 28.9371 34.3779 29.101C35.9922 29.662 36.8536 31.441 36.3003 33.0712C36.1394 33.5465 35.8783 33.9567 35.5455 34.2852C36.6649 34.1341 37.6616 33.3635 38.0511 32.2076C38.6044 30.5774 37.743 28.7984 36.1288 28.2374Z"
        fill="#3F73E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6909 40.0975L29.5129 38.3216C28.7134 38.049 27.838 38.4782 27.5648 39.2767C27.2925 40.0772 27.7246 40.9565 28.5241 41.229L33.702 43.0049C34.5025 43.2795 35.3778 42.8503 35.6501 42.0498C35.9234 41.2513 35.4914 40.3721 34.6909 40.0975Z"
        fill="#64A2FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.6923 40.1024L29.5205 38.3234C28.7685 38.0648 27.946 38.434 27.6278 39.1462C27.6734 39.1586 27.719 39.1709 27.7636 39.1862L32.9354 40.9653C33.7349 41.2403 34.1663 42.1206 33.8932 42.9199C33.8789 42.9667 33.8608 43.0103 33.8417 43.0519C34.6001 43.238 35.3952 42.8128 35.6501 42.0571C35.9232 41.2578 35.4919 40.3774 34.6923 40.1024Z"
        fill="#83B4FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9967 9.38143L43.1607 8.23837L41.1816 8.07305L34.3072 28.28C34.9698 28.0171 35.7265 27.9809 36.4536 28.2319C37.1826 28.482 37.7591 28.9754 38.1232 29.5904L44.9967 9.38143Z"
        fill="#64A2FF"
      />
      <path
        d="M47.7232 7.91056C48.0317 8.01474 48.1957 8.34846 48.0904 8.65787C47.9861 8.96442 47.6532 9.12765 47.3447 9.02348L45.588 8.41933C45.2794 8.31515 45.1145 7.97946 45.2207 7.67194C45.325 7.3654 45.66 7.20117 45.9665 7.30634L47.7232 7.91056ZM49.1277 5.4614C49.4363 5.56557 49.6002 5.89929 49.495 6.20878C49.3907 6.51533 49.0557 6.67956 48.7492 6.57442L46.9429 5.95489C46.6363 5.84975 46.4714 5.51412 46.5757 5.20757C46.6819 4.90006 47.0149 4.73679 47.3234 4.84097L49.1277 5.4614ZM42.7764 3.28137C43.0829 3.38651 43.2479 3.72221 43.1436 4.02876C43.0373 4.3362 42.7043 4.49946 42.3958 4.39536L40.5915 3.77486C40.283 3.67069 40.118 3.33499 40.2243 3.02755C40.3285 2.721 40.6615 2.55777 40.9701 2.66191L42.7764 3.28137ZM42.3459 6.06626C42.6543 6.1704 42.8183 6.50412 42.7131 6.81357C42.6088 7.12012 42.2738 7.28439 41.9673 7.17921L40.2105 6.57502C39.9019 6.47085 39.737 6.13515 39.8432 5.82764C39.9475 5.5212 40.2805 5.35793 40.589 5.462L42.3459 6.06626Z"
        fill="#485363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.74 1.5473L44.4963 0.43475C43.7485 0.176622 42.9269 0.579406 42.6718 1.32923C42.1465 2.87374 40.8699 7.82913 41.581 8.07305L45.3962 9.38151C46.1053 9.62639 48.1161 4.9212 48.6394 3.3777C48.8944 2.62773 48.4899 1.80442 47.74 1.5473Z"
        fill="#485363"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.7581 1.55877L44.5287 0.438169C43.7999 0.185344 43.0012 0.56399 42.7288 1.28535C42.7437 1.29047 42.7604 1.29462 42.7782 1.30078L46.0055 2.42242C46.7522 2.68145 47.1542 3.50838 46.8993 4.26103C46.5504 5.29129 45.5431 7.73339 44.7012 9.17336L45.4153 9.42114C46.1213 9.66779 48.1291 4.94643 48.6519 3.39745C48.9067 2.64477 48.5047 1.81776 47.7581 1.55877Z"
        fill="#072252"
      />
      <path
        d="M44.7206 6.91866C44.6174 7.22715 44.2824 7.39142 43.9749 7.2843C43.6684 7.1792 43.5044 6.84547 43.6077 6.53698L44.8799 2.79633C44.9842 2.48978 45.3191 2.32555 45.6257 2.43069C45.9332 2.5378 46.0972 2.87145 45.9939 3.17998L44.7206 6.91866Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0348 28.5348C37.4951 28.8075 37.8664 29.186 38.129 29.6302L45.0009 9.41351L43.6892 8.96318C41.47 15.4867 39.2539 22.0112 37.0348 28.5348Z"
        fill="#83B4FF"
      />
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InstrumentIcon;
