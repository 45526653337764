import React from 'react';

const AwardIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipAward0)">
      <g clipPath="url(#clipAward1)">
        <path
          d="M37.6825 22.0175C37.3377 22.0175 37 21.8992 36.7283 21.6772C36.3779 21.3908 36.1745 20.9621 36.1745 20.5096V4.73348C36.1745 3.9007 36.8496 3.22559 37.6824 3.22559H52.4559C53.2485 3.22559 53.9813 3.58505 54.4663 4.21193C54.9514 4.83873 55.1155 5.63829 54.9167 6.40553L53.1744 13.1272C52.2495 16.695 49.4557 19.4739 45.8831 20.3796C45.8598 20.3855 45.8364 20.3908 45.8128 20.3955L37.9828 21.9873C37.8832 22.0075 37.7826 22.0175 37.6825 22.0175ZM39.1905 6.24138V18.6643L45.1752 17.4477C47.6648 16.8039 49.6095 14.8613 50.2552 12.3704L51.8439 6.24146H39.1905V6.24138Z"
          fill="#64A2FF"
        />
        <path
          d="M30.3163 22.0175C30.2162 22.0175 30.1158 22.0075 30.0159 21.9873L22.1859 20.3955C22.1624 20.3908 22.139 20.3854 22.1158 20.3796C18.5431 19.4738 15.7492 16.695 14.8245 13.1271L13.0821 6.40553C12.8832 5.63837 13.0473 4.83881 13.5324 4.21201C14.0174 3.58513 14.7502 3.22559 15.5428 3.22559H30.3163C31.1491 3.22559 31.8242 3.9007 31.8242 4.73348V20.5096C31.8242 20.9621 31.6209 21.3908 31.2704 21.6772C30.9989 21.8992 30.6611 22.0175 30.3163 22.0175ZM22.8237 17.4477L28.8085 18.6643V6.24138H16.1551L17.7438 12.3703C18.3894 14.8613 20.3341 16.8038 22.8237 17.4477Z"
          fill="#64A2FF"
        />
        <path
          d="M39.163 33.5174C35.2495 32.2255 32.5218 28.2017 32.3771 23.5716C32.5348 22.84 32.6173 22.0835 32.6173 21.3121V16.1943H31.5175V21.3121C31.5175 26.0839 28.3604 30.28 23.7754 31.6022V34.703H40.1129V33.831L39.163 33.5174Z"
          fill="#5384EE"
        />
        <path
          d="M43.2738 31.3283C39.2515 30.1684 36.4818 26.4871 36.4818 22.3008V16.1943H31.5171V21.3121C31.5171 22.0836 31.4345 22.84 31.2768 23.5716C31.4215 28.2017 34.1492 32.2255 38.0627 33.5174L39.0127 33.831V34.703H44.2238V31.6022L43.2738 31.3283Z"
          fill="#3F73E3"
        />
        <path
          d="M41.2659 31.5693H21.9957C21.2442 31.5693 20.6349 32.1785 20.6349 32.9301V39.7624H42.6265V32.9301C42.6266 32.1785 42.0173 31.5693 41.2659 31.5693Z"
          fill="#5384EE"
        />
        <path
          d="M46.0045 31.5693H40.5601C41.3116 31.5693 41.9208 32.1786 41.9208 32.9301V39.7624H47.3652V32.9301C47.3652 32.1786 46.756 31.5693 46.0045 31.5693Z"
          fill="#5384EE"
        />
        <path
          d="M40.8414 9.1262V1.49023H22.6758V8.86197C22.6758 14.9097 25.9498 20.4836 31.2321 23.4285C31.625 23.6476 32.0375 23.8166 32.4606 23.9356C37.6485 20.8207 40.8414 15.2045 40.8414 9.1262Z"
          fill="#5384EE"
        />
        <path
          d="M39.8673 1.49023V8.86197C39.8673 14.9097 36.5932 20.4836 31.3109 23.4285C31.2977 23.4358 31.2846 23.4431 31.2714 23.4503C32.9844 24.3877 35.0601 24.3804 36.7676 23.4285C42.0499 20.4835 45.324 14.9097 45.324 8.86197V1.49023H39.8673Z"
          fill="#5384EE"
        />
        <path
          d="M45.3427 40.8901V40.0381C45.3427 39.2051 44.6677 38.5297 43.8347 38.5293H19.4129C18.5796 38.5293 17.9041 39.2048 17.9041 40.0381C17.9041 40.8714 18.5796 41.5469 19.4129 41.5469H44.6364L45.3427 40.8901Z"
          fill="#5384EE"
        />
        <path
          d="M48.5868 38.5293H43.1275C43.9608 38.5293 44.6363 39.2048 44.6363 40.0381V41.5469H48.5868C49.4201 41.5469 50.0956 40.8714 50.0956 40.0381C50.0956 39.2048 49.4201 38.5293 48.5868 38.5293Z"
          fill="#5384EE"
        />
        <path
          d="M43.8212 1.43301V0.229691L42.6519 -0.359375H21.6842C20.6943 -0.359375 19.8919 0.443054 19.8919 1.43293C19.8919 2.4228 20.6943 3.22523 21.6842 3.22523H42.0289C43.0187 3.22539 43.8212 2.42288 43.8212 1.43301Z"
          fill="#5384EE"
        />
        <path
          d="M46.316 -0.359375H42.6517V1.43293C42.6517 2.4228 41.8492 3.22523 40.8594 3.22523H46.3161C47.306 3.22523 48.1084 2.4228 48.1084 1.43293C48.1084 0.443054 47.3059 -0.359375 46.316 -0.359375Z"
          fill="#64A2FF"
        />
        <path
          d="M41.92 35.8417H26.0791C25.7245 35.8417 25.437 35.5543 25.437 35.1997C25.437 34.8451 25.7245 34.5576 26.0791 34.5576H41.92C42.2746 34.5576 42.562 34.8451 42.562 35.1997C42.562 35.5543 42.2746 35.8417 41.92 35.8417Z"
          fill="white"
        />
        <path
          d="M34.4902 7.62046L35.4372 10.2147C35.51 10.4143 35.6967 10.5499 35.909 10.5575L38.669 10.6565C39.1557 10.674 39.3557 11.2894 38.9722 11.5895L36.7975 13.2919C36.6302 13.4229 36.5589 13.6422 36.6173 13.8465L37.376 16.5019C37.5098 16.9702 36.9864 17.3505 36.5823 17.0786L34.2912 15.5365C34.1149 15.4178 33.8844 15.4178 33.7081 15.5365L31.417 17.0786C31.013 17.3505 30.4895 16.9702 30.6233 16.5019L31.382 13.8465C31.4404 13.6421 31.3691 13.4228 31.2018 13.2919L29.0271 11.5895C28.6436 11.2893 28.8435 10.674 29.3303 10.6565L32.0902 10.5575C32.3026 10.5499 32.4892 10.4144 32.5621 10.2147L33.5091 7.62046C33.6761 7.16297 34.3231 7.16297 34.4902 7.62046Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clipAward2)">
        <path
          d="M5.91278e-05 36C5.91278e-05 28.268 6.26807 22 14.0001 22C21.732 22 28.0001 28.268 28.0001 36C28.0001 43.732 21.732 50 14.0001 50C6.26807 50 5.91278e-05 43.732 5.91278e-05 36Z"
          fill="#3F73E3"
        />
        <path
          d="M2.00006 36C2.00006 29.3726 7.37264 24 14.0001 24C20.6275 24 26.0001 29.3726 26.0001 36C26.0001 42.6274 20.6275 48 14.0001 48C7.37264 48 2.00006 42.6274 2.00006 36Z"
          fill="white"
        />
        <path
          d="M8.37506 34.9688C8.12115 34.9688 7.90631 35.0664 7.73053 35.2617C7.53522 35.4375 7.43756 35.6523 7.43756 35.9062V36.8438C7.43756 37.0977 7.53522 37.3223 7.73053 37.5176C7.90631 37.6934 8.12115 37.7812 8.37506 37.7812H12.5939V42C12.5939 42.2539 12.6915 42.4688 12.8868 42.6445C13.0626 42.8398 13.2774 42.9375 13.5313 42.9375H14.4689C14.7228 42.9375 14.9474 42.8398 15.1427 42.6445C15.3185 42.4688 15.4063 42.2539 15.4063 42V37.7812H19.6251C19.879 37.7812 20.1036 37.6934 20.2989 37.5176C20.4747 37.3223 20.5626 37.0977 20.5626 36.8438V35.9062C20.5626 35.6523 20.4747 35.4375 20.2989 35.2617C20.1036 35.0664 19.879 34.9688 19.6251 34.9688H15.4063V30.75C15.4063 30.4961 15.3185 30.2812 15.1427 30.1055C14.9474 29.9102 14.7228 29.8125 14.4689 29.8125H13.5313C13.2774 29.8125 13.0626 29.9102 12.8868 30.1055C12.6915 30.2812 12.5939 30.4961 12.5939 30.75V34.9688H8.37506Z"
          fill="#3F73E3"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clipAward0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clipAward1">
        <rect
          width="42"
          height="42"
          fill="white"
          transform="translate(13.0001 -0.40625)"
        />
      </clipPath>
      <clipPath id="clipAward2">
        <rect
          width="29"
          height="29"
          fill="white"
          transform="translate(6.10352e-05 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AwardIcon;
