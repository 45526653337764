import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgCourse(props: any) {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      width={316}
      height={170}
      viewBox="0 0 316 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.2 166.772S-1.203 144.422.022 99.166c1.225-45.257 50.363-52.337 83.984-24.433 33.757 27.903 51.039 14.252 68.462-9.348 17.423-23.6 44.242-77 93.107-57.01 36.48 14.854 60.844 54.697 68.739 90.652 5.445 24.71-2.45 50.809-21.37 67.051-.681.556-1.225 1.111-1.906 1.527l-239.836-.833z"
        fill="url(#SvgCourse0_linear)"
      />
      <path d="M197.96 0H65.983v92.254H197.96V0z" fill="#fff" />
      <path
        d="M65.983 0v92.254H197.96V0H65.983zm126.672 86.688H71.382V5.658h121.273v81.03z"
        fill="url(#SvgCourse1_linear)"
      />
      <path
        d="M51.187 27.284c-2.606-.274-4.56-1.46-6.422-3.285.559 5.018-1.024 10.128-1.024 10.128s.28 16.334 6.98 14.327c6.795-2.008 2.42-12.593 2.42-12.593-1.675-2.372-1.954-6.022-1.954-8.577z"
        fill="#FED2A4"
      />
      <path
        d="M47.836 10.493s7.725-.82 9.121 2.282c1.396 3.102-.186 7.208.186 8.577.466 1.369.187 10.13-2.327 10.312-2.513.182-10.517-5.384-10.703-11.954-.186-6.57 1.768-9.125 3.723-9.217z"
        fill="#FFE5C2"
      />
      <path
        d="M54.164 29.017H55c.093-.182.187-.365.187-.547.186-.365.279-.639.465-1.004l.558-1.916c-.372 0-.744-.092-1.117-.183-1.023.456-1.954.913-2.978 1.369l-.558.274c.93.638 1.768 1.277 2.606 2.007z"
        fill="#fff"
      />
      <path
        d="M86.552 47.725s2.606-1.917 2.978-2.464c.372-.548 2.14-4.563 3.165-6.388 1.024-1.825 6.329-3.923 6.701-2.555.466 1.278-1.768 1.643-4.095 3.194 0 0 .279.274.465.365.28 0 3.91 4.836 1.21 6.479-2.699 1.734-6.143 1.825-8.47 3.832 0-.09-1.861-1.095-1.954-2.463z"
        fill="#FFE5C2"
      />
      <path
        d="M47.648 159.05s-.93 5.475-.372 7.026c.465 1.186 17.219 1.369 18.15.365.93-1.004-.652-2.737-1.49-3.194l-9.4-4.106-6.888-.091zM36.947 160.054H29.78s-3.816 5.019-3.723 6.296c.093 1.278 5.771 3.65 7.074 3.65s5.957-3.011 5.864-3.559c0-.547-2.048-6.387-2.048-6.387z"
        fill="#112B46"
      />
      <path
        d="M57.42 18.068s2.606-1.095 2.048-4.015c-.559-2.92-2.048-4.745-6.515-5.932-4.375-1.186-8.377-.82-8.935 2.738 0 0 2.047 6.205 7.166 5.931 5.12-.274 6.609-.274 6.236 1.278z"
        fill="#2C3A64"
      />
      <path
        d="M57.327 24.729s-.744-.73-2.513-.822c-2.885 0-4.467 2.738-4.746 2.738-1.769 0-3.724-4.015-3.724-4.015-1.768-1.186-1.21-2.829-.279-3.194.931-.456 1.49 1.004 1.583 1.825.186.822.744 1.278 1.21.639.465-.639-.559-.091.093-3.833.744-3.74-1.955-5.2-1.955-5.2l-2.978-3.195c-6.888 3.012-2.42 13.78-.466 16.243 3.63 4.654 9.029 9.034 12.38 7.026 2.047-1.369 1.395-8.212 1.395-8.212zm-4.188 2.007s1.582 0 2.513-.456c0 0-.838 2.281-2.513.456z"
        fill="#2C3A64"
      />
      <path
        d="M58.165 73.274l-20.383-.365s-3.071 5.019-3.816 19.345c-.465 9.855-.93 12.593-1.862 25.551-.651 8.851-3.909 42.614-3.909 42.614h10.797s4.56-38.599 4.84-40.698c.093-.73 2.792-21.444 2.792-21.444s-.465 18.706-.465 20.896c0 2.19-.466 40.151-.466 40.151h11.355s1.303-32.303 1.397-39.694c.279-11.863 1.396-42.432-.28-46.356z"
        fill="url(#SvgCourse2_linear)"
      />
      <path
        d="M62.446 36.592l-4.003 14.874s7.353 13.413 11.076 13.687c3.723.183 21.407-15.147 21.407-15.147l-2.978-5.02s-15.357 11.225-16.195 10.95c-.745-.273-6.608-18.888-9.307-19.344z"
        fill="url(#SvgCourse3_linear)"
      />
      <path
        d="M43.647 33.763s-9.493 1.278-11.727 2.464c0 0 6.422 10.129 4.468 16.608 0 0-1.769 22.265-1.676 27.192 0 0 11.914 4.29 25.41.183 0 0-.187-24.547-.187-28.38 0 0 1.117-10.22 2.606-15.238 0 0-5.025-1.004-10.145-2.008 0 0 .373.548-1.768 3.194-1.303 1.643-6.515-.73-6.98-4.015z"
        fill="url(#SvgCourse4_linear)"
      />
      <path
        d="M59.934 51.739s.279-2.464.744-5.566c-1.675 1.733-1.954 9.307-2.14 10.585-1.024 5.748-8.75 7.847-13.682 4.745-4.654-2.92-5.213-10.13-6.33-14.874-.558-2.555-2.233-4.471-4.28-6.205 1.581 3.559 3.35 8.577 2.14 12.319 0 0-1.769 22.265-1.675 27.192 0 0 11.913 4.29 25.409.183 0 0-.186-24.547-.186-28.38z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M35.177 87.692c.745.82 3.072 3.558 2.7 4.836-.373 1.186-2.327-1.004-3.072-1.734-.745-.73-.838 1.734-.651 3.103.186 1.368 2.978 3.558 2.699 4.562-.28.913-1.862-.365-3.63-2.92 0 0 2.792 3.103 2.14 3.559-.651.456-2.233-1.186-3.443-3.011 0 0 2.699 2.92 2.047 3.285-.651.365-2.513-1.734-3.164-2.738 0 0 2.234 2.373 1.675 2.829-.465.274-2.14-1.095-2.792-3.011-.652-1.916 1.303-8.213 1.21-9.4-.093-1.185-.465-2.645-.465-2.645l3.909-.913c-.186.091-.28 3.011.837 4.198z"
        fill="#FFE5C2"
      />
      <path
        d="M35.177 85.685l-5.398 1.368s-6.33-20.349-5.398-25.276c.93-4.836 3.257-23.087 7.539-25.642 0 0 5.491 1.552 5.77 8.122.186 3.741-1.396 8.486-1.396 8.486s-3.444 6.388-3.816 10.95c-.28 3.742 2.7 21.992 2.7 21.992z"
        fill="url(#SvgCourse5_linear)"
      />
      <path
        d="M52.024 33.763l-1.955 3.65-6.236-4.745-1.861 1.46 5.956 8.03 2.42-3.924 3.444 2.463.093-5.84-1.861-1.094z"
        fill="#fff"
      />
      <path
        d="M77.99 61.686s33.599-42.523 34.157-42.706c.558-.182-31.924 44.805-31.924 44.805s-.651.638-1.768-.183c-.652-.547-.838-1.369-.465-1.916z"
        fill="#2C3A64"
      />
      <path
        d="M97.721 41.246s-2.234.82-3.443.912c-1.117.091-.838-1.004-.094-1.46.745-.456 3.63-1.734 4.375-1.369.745.365.465 1.643 0 1.825 0 0 1.024.548.372 1.643 0 0 .559 1.186-.093 1.734 0 0 .373 1.368-.558 1.642-.931.274-3.35.365-3.63-.182-.372-.73.837-1.095 3.257-1.37 0 0-2.606.457-2.978.366-.651-.091-2.234-1.369 2.978-1.916 0 0-4.095 1.095-4.28.09-.373-1.368 4.094-1.915 4.094-1.915z"
        fill="#FFE5C2"
      />
      <path
        d="M143.27 92.95l.093 4.57s19.638 1.189 25.129-.64c5.491-1.827 17.125-16.908 18.056-19.376 1.21-3.016-3.35-10.328-7.818-3.656-4.467 6.672-12.658 15.72-13.123 15.995-.465.183-22.337 3.107-22.337 3.107z"
        fill="url(#SvgCourse6_linear)"
      />
      <path
        d="M147.551 94.87s-1.955-.64-2.42-.73c-.373-.092-5.026-2.468-5.678-2.194-.651.274-6.98 2.65-6.98 3.29 0 .64 1.582.457 5.026-1.188 0 0-4.468 1.737-3.909 2.376.558.64 4.653-1.462 4.653-1.462s-4.095 1.645-3.35 2.194c.651.548 4.467-1.554 4.467-1.554s-2.885 1.553-2.699 1.92c.279.365 1.861 0 3.909-1.006 0 0 .372-.092 1.117.365.745.457 6.329.366 6.329.366l-.465-2.376z"
        fill="#FFE5C2"
      />
      <path
        d="M129.773 96.425l6.608-11.06s1.582-.913 1.582 1.006l-7.632 10.51s-1.768 2.103-.558-.456z"
        fill="#292C3D"
      />
      <path
        d="M145.502 93.409s-2.14 0-2.606-.092c-.465-.091-5.957-4.021-6.608-3.656-.652.366-7.26 3.748-7.167 4.57.093.823 1.862.549 5.305-1.736 0 0-4.653 2.467-3.909 3.29.745.823 4.84-2.01 4.84-2.01s-4.188 2.284-3.351 3.107c.838.822 4.654-2.194 4.654-2.194s-2.978 2.194-2.606 2.65c.279.458 2.048-.09 4.095-1.462 0 0 .838.183 1.676.914 1.675 1.554 6.608.549 6.608.549l-.931-3.93z"
        fill="#FFE5C2"
      />
      <path
        d="M182.358 61.968c.652-.183 4.003-6.946 5.399-8.317.651 4.661 3.536 15.72 3.536 15.72-3.443 3.656-10.424 1.463-10.424 1.463 1.21-2.468 1.675-7.312 1.489-8.866z"
        fill="#FED2A4"
      />
      <path
        d="M185.616 45.333s-6.329 1.371-7.539 3.2c-1.21 1.827-1.21 6.58-1.861 7.768-.373.731-.745 1.371-.931 1.828-.186.366.279.64.838.823.279.091.465.365.558.64.372 1.188 1.396 4.021 2.513 4.478 1.303.548 7.725.274 9.68-5.21 1.861-5.575.837-14.715-3.258-13.527zM156.763 158.393l4.561-.731s.186 4.204.652 5.209c0 0-5.12 2.377-10.239.457 0 0 3.351-1.371 4.189-2.376.744-1.005.837-2.559.837-2.559z"
        fill="#FFE5C2"
      />
      <path
        d="M161.511 160.859s-3.444 2.377-8.749 2.011c0 0-6.515 3.382-6.887 4.296-.559 1.188 2.233 1.279 6.701 1.097 3.816-.092 3.537-.366 4.281-1.097.745-.731 1.676-1.554 1.676-1.554s.372.457.744 2.559l1.676-.274s-.187-2.011.651-3.107c.559-.732 1.21-2.56-.093-3.931z"
        fill="#112B46"
      />
      <path
        d="M175.1 157.295l3.443-.914s2.048 3.291 2.606 4.113c0 0-4.374 3.473-9.959 2.742 0 0 2.886-2.102 3.537-3.29.559-1.097.373-2.651.373-2.651z"
        fill="#FFE5C2"
      />
      <path
        d="M180.683 159.763s-3.164 2.65-8.563 2.742c0 0-6.143 4.021-6.515 4.844-.465 1.279 2.327 1.097 6.795.548 3.723-.457 3.443-.731 4.188-1.554.651-.822 1.582-1.645 1.582-1.645s.373.457 1.024 2.468l1.675-.366s-.372-2.01.28-3.199c.465-.731.93-2.559-.466-3.838z"
        fill="#112B46"
      />
      <path
        d="M190.829 95.968l-8.935 6.215s-28.946 5.484-32.669 8.226c-3.629 2.742 23.362 50.726 23.362 50.726l8.376-2.742s-20.197-42.5-18.335-44.328c1.861-1.828 31.644-4.479 33.506-5.027 1.861-.64-.745-7.129-5.305-13.07z"
        fill="#514553"
      />
      <path
        d="M190.829 95.968l-8.935 6.215s-28.946 5.484-32.669 8.226c-3.629 2.742 23.362 50.726 23.362 50.726l8.376-2.742s-20.197-42.5-18.335-44.328c1.861-1.828 31.644-4.479 33.506-5.027 1.861-.64-.745-7.129-5.305-13.07z"
        fill="url(#SvgCourse7_linear)"
      />
      <path
        d="M193.527 98.8l-11.634 1.006s-30.713 6.672-34.436 10.145c-3.351 3.107 6.236 50.726 6.236 50.726l9.121-2.285s-5.119-42.5-2.886-43.78c1.955-1.097 33.32-1.097 35.275-1.097 2.792-.182 4.84-8.317-1.676-14.715z"
        fill="url(#SvgCourse8_linear)"
      />
      <path
        d="M190.27 67.085s-4.747 2.103-9.215 3.474c0 0-5.398 10.51-5.77 13.526-.373 2.925-3.165 18.28-3.165 18.28s17.312 5.941 25.037 2.102c0 0-2.42-11.882-1.582-18.097.838-6.215.093-14.715-5.305-19.285z"
        fill="url(#SvgCourse9_linear)"
      />
      <path
        d="M188.222 85c0 5.3-3.444 7.86-7.074 11.241-2.14 2.011-4.746 4.022-5.956 7.038-1.862-.548-3.072-1.005-3.072-1.005s1.675-7.952 2.513-13.71c3.537-1.554 5.492-4.936 6.701-8.5.745-2.102 1.117-3.382 2.514-4.936 3.909.092 4.188 4.662 4.374 8.866v1.005z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M214.561 70.741l-11.634 45.882-4.84-1.188 10.052-46.248 6.422 1.554z"
        fill="url(#SvgCourse10_linear)"
      />
      <path
        d="M163.278 117.994h-1.955v50.086h1.955v-50.086z"
        fill="url(#SvgCourse11_linear)"
      />
      <path
        d="M199.111 117.994h-1.955v50.086h1.955v-50.086z"
        fill="url(#SvgCourse12_linear)"
      />
      <path
        d="M204.23 113.333h-48.119v6.58h48.119v-6.58z"
        fill="url(#SvgCourse13_linear)"
      />
      <path
        d="M179.845 50.725c1.675.092 2.978.549 3.909 2.194.373.731.652 1.737.559 2.56-.093.456-1.303 2.375-.093 2.467.558.091 1.023-1.097 1.303-1.463.744-.914 1.675-.64 2.233.366.652 1.188 0 2.01-.93 2.742-1.21 1.097-.559 1.37-.28 2.925.373 1.92-.558 3.838.838 5.392 1.024 1.097 2.234 1.463 3.723 1.28 1.675-.274 3.537-.549 4.375-2.102 1.675-3.108-.466-5.484-1.49-8.5-1.116-3.382 0-8.683-2.699-11.334-1.396-1.37-3.35-2.01-5.212-2.193-1.861-.183-3.537.091-5.305.457-1.396.274-2.699.822-4.095 1.096-.931.183-1.304-.182-1.49.823-.093.457.28 1.554.559 2.01.744 1.372 2.42 1.372 3.816 1.372.093-.092.186-.092.279-.092z"
        fill="#2C3A64"
      />
      <path
        d="M166.443 97.704h-1.675V170h1.675V97.704z"
        fill="url(#SvgCourse14_linear)"
      />
      <path
        d="M111.903 97.704h-1.675V170h1.675V97.704z"
        fill="url(#SvgCourse15_linear)"
      />
      <path
        d="M166.35 97.704h-56.122v1.92h56.122v-1.92z"
        fill="url(#SvgCourse16_linear)"
      />
      <path
        d="M143.919 91.763l.093 5.941s20.29.914 25.967-.914c5.678-1.828 17.684-16.908 18.708-19.376 1.21-3.016-3.444-10.328-8.098-3.656-4.653 6.672-13.03 15.72-13.495 15.995-.652.274-23.175 2.01-23.175 2.01z"
        fill="url(#SvgCourse17_linear)"
      />
      <path
        d="M251.701 157.834s-6.702 3.202-10.239 4.665c-2.978 1.19-2.326 3.843 1.024 4.849 3.351 1.006 6.143.366 6.888-.732.744-1.006 2.699-.64 3.35-.366.745.366 6.329-.915 6.702-2.195.279-1.281-1.117-6.953-1.117-6.953l-6.608.732z"
        fill="#112B46"
      />
      <path
        d="M286.416 97l-11.355.183s-27.735 10.154-31.365 12.807c-3.63 2.653 5.956 49.124 5.956 49.124l9.401-1.006s-2.234-41.623-.373-43.361c1.862-1.739 31.18-4.391 32.948-4.94 1.861-.549-.745-6.953-5.212-12.808z"
        fill="#73C4D1"
      />
      <path
        d="M286.416 97l-11.355.183s-27.735 10.154-31.365 12.807c-3.63 2.653 5.956 49.124 5.956 49.124l9.401-1.006s-2.234-41.623-.373-43.361c1.862-1.739 31.18-4.391 32.948-4.94 1.861-.549-.745-6.953-5.212-12.808z"
        fill="url(#SvgCourse18_linear)"
      />
      <path
        d="M309.405 72.208L297.864 117.4l-4.747-1.189 9.866-45.648 6.422 1.646z"
        fill="url(#SvgCourse19_linear)"
      />
      <path
        d="M258.773 118.681h-1.862v49.308h1.862v-49.308z"
        fill="url(#SvgCourse20_linear)"
      />
      <path
        d="M294.047 118.68h-1.861v49.308h1.861V118.68z"
        fill="url(#SvgCourse21_linear)"
      />
      <path
        d="M299.167 114.106h-47.374v6.496h47.374v-6.496z"
        fill="url(#SvgCourse22_linear)"
      />
      <path
        d="M283.438 52.266l-6.515 1.372s1.861 7.868 0 12.167c0 0 3.537 1.372 9.493-3.11 0 .091-3.723-3.019-2.978-10.429z"
        fill="#FED2A4"
      />
      <path
        d="M284.461 43.21s-7.26-2.653-9.215-1.738c-1.954 1.006-4.281 2.378-3.909 3.842.372 1.464.652 5.672.186 7.044-.465 1.372-1.303 2.287-1.117 2.744.187.458 1.303.092 1.676.64.279.55 2.047 4.3 2.978 4.849 1.024.549 6.608-1.372 9.215-7.41 2.606-5.946 1.768-8.965.186-9.971z"
        fill="#FFE5C2"
      />
      <path
        d="M243.603 158.474s-8.656 3.019-12.286 4.391c-2.979 1.189-2.42 3.842.93 4.849 3.351 1.006 6.143.366 6.888-.641.745-1.006 2.699-.64 3.351-.274.744.366 6.329-.915 6.701-2.196.372-1.28-.465-5.031-.465-5.031l-5.119-1.098z"
        fill="#112B46"
      />
      <path
        d="M287.812 47.692c-.186-.64-.558-1.372-.651-2.013-.093-.731.651-5.854-5.864-5.214h-.931c-1.023-.274-2.047-1.098-3.164-1.19-3.63-.456-5.771 2.928-9.121 3.111-.093.915 1.023 2.379 1.675 3.11.745.915 1.861 1.464 2.885 1.739 1.117.365 2.699.365 4.002.274.652-.091 1.862-.823 2.42-.274.559.548.094 2.744.187 3.476 0 .549-.093 1.006.558 1.19h.372c.652-.184.559-2.014.652-2.562.558-1.921 2.885-1.647 3.164.183.28 1.555-1.303 2.653-1.768 2.927-.093.274-.093.549-.093.732-.186 1.555 1.489 2.561 2.885 1.83 1.117-.641 1.955-1.373 2.699-2.837.745-1.372.466-3.018.093-4.482z"
        fill="#2C3A64"
      />
      <path
        d="M289.579 95.353l-12.658 2.47s-28.759 10.245-32.389 12.898c-3.63 2.653-4.281 48.484-4.281 48.484l9.4.915s4.002-43.544 6.236-44.825c1.862-1.098 32.855-1.098 34.716-1.098 2.885-.091 5.305-12.532-1.024-18.844z"
        fill="url(#SvgCourse23_linear)"
      />
      <path
        d="M237.739 91.785l1.117 5.855s24.571-4.209 27.177-6.13c2.606-1.92 18.522-18.753 16.195-23.51-2.327-4.665-5.305-3.568-7.26-.915-1.861 2.653-10.61 16.284-11.913 17.564-1.21 1.464-25.316 7.136-25.316 7.136z"
        fill="url(#SvgCourse24_linear)"
      />
      <path
        d="M286.323 62.33s-6.329 3.2-9.959 2.927c0 0-5.771 11.983-6.236 15.185-.372 3.202-4.84 20.675-4.84 20.675s19.267 6.586 28.202 1.372c0 0-2.606-12.716-1.769-19.486 1.024-6.769.28-15.642-5.398-20.674z"
        fill="url(#SvgCourse25_linear)"
      />
      <path
        d="M283.531 84.65c1.396-3.202 6.887-8.325 4.095-11.893-2.234-2.835-3.816-3.11-6.236-.365-2.327 2.561-4.375 5.305-6.981 7.592-1.396 1.19-2.885 2.105-4.374 3.202 0 6.587-4.747 18.022-4.747 18.022s9.959 3.11 15.45 3.202c-2.885-6.221-.093-13.082 2.793-19.76z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M244.721 93.25s-1.955.914-2.42.823c-.373-.092-5.026-2.47-5.678-2.196-.651.275-6.98 2.653-6.98 3.293 0 .64 1.582.458 5.026-1.189 0 0-4.468 1.738-3.91 2.379.559.64 4.654-1.464 4.654-1.464s-4.095 1.647-3.35 2.195c.651.55 4.467-1.555 4.467-1.555s-2.885 1.555-2.699 1.921c.279.366 1.861 0 3.909-1.006 0 0 .372-.091 1.117.366.745.457 6.329-1.19 6.329-1.19l-.465-2.378z"
        fill="#FFE5C2"
      />
      <path
        d="M226.57 96.359l6.143-11.252s1.583-1.007 1.583.915l-7.167 10.794c0-.091-1.675 2.196-.559-.457z"
        fill="#292C3D"
      />
      <path
        d="M242.208 92.7s-2.14.092-2.606.092c-.465 0-6.143-3.842-6.794-3.385-.652.366-7.074 4.025-6.981 4.848.093.824 1.862.458 5.305-1.92 0 0-4.56 2.652-3.723 3.384.745.823 4.747-2.195 4.747-2.195s-4.095 2.47-3.257 3.201c.837.732 4.56-2.378 4.56-2.378s-2.885 2.287-2.513 2.744c.373.458 2.048-.091 4.002-1.646 0 0 .838.091 1.676.823 1.768 1.464 6.608.274 6.608.274l-1.024-3.842z"
        fill="#FFE5C2"
      />
      <path
        d="M269.755 97.64h-1.675v72.361h1.675v-72.36z"
        fill="url(#SvgCourse26_linear)"
      />
      <path
        d="M215.309 97.64h-1.675V170h1.675V97.64z"
        fill="url(#SvgCourse27_linear)"
      />
      <path
        d="M269.756 97.64h-56.122v1.921h56.122V97.64z"
        fill="url(#SvgCourse28_linear)"
      />
      <path
        d="M238.576 91.785l1.675 5.947s25.037.731 27.829-1.19c2.792-1.92 21.872-21.04 19.452-25.706-2.42-4.665-5.584-3.567-7.632-.914-1.861 2.47-13.868 16.192-15.357 17.655-1.303 1.281-25.967 4.208-25.967 4.208z"
        fill="url(#SvgCourse29_linear)"
      />
      <defs>
        <linearGradient
          id="SvgCourse0_linear"
          x1={158}
          y1={3.984}
          x2={158}
          y2={167.605}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="SvgCourse1_linear"
          x1={131.972}
          y1={0}
          x2={131.972}
          y2={92.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse2_linear"
          x1={28.217}
          y1={116.705}
          x2={59.094}
          y2={116.705}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse3_linear"
          x1={56.925}
          y1={40.947}
          x2={63.587}
          y2={70.134}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse4_linear"
          x1={30.488}
          y1={41.117}
          x2={48.648}
          y2={85.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse5_linear"
          x1={23.66}
          y1={43.9}
          x2={51.027}
          y2={71.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse6_linear"
          x1={141.237}
          y1={75.208}
          x2={145.724}
          y2={103.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse7_linear"
          x1={148.923}
          y1={128.582}
          x2={196.781}
          y2={128.582}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse8_linear"
          x1={146.781}
          y1={129.768}
          x2={197.9}
          y2={129.768}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse9_linear"
          x1={170.95}
          y1={72.983}
          x2={185.311}
          y2={108.801}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse10_linear"
          x1={206.324}
          y1={69.188}
          x2={206.324}
          y2={116.623}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse11_linear"
          x1={162.301}
          y1={117.994}
          x2={162.301}
          y2={168.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse12_linear"
          x1={198.134}
          y1={117.994}
          x2={198.134}
          y2={168.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse13_linear"
          x1={180.17}
          y1={113.333}
          x2={180.17}
          y2={119.913}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse14_linear"
          x1={165.605}
          y1={97.704}
          x2={165.605}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse15_linear"
          x1={111.065}
          y1={97.704}
          x2={111.065}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse16_linear"
          x1={138.289}
          y1={97.704}
          x2={138.289}
          y2={99.623}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse17_linear"
          x1={141.817}
          y1={75.136}
          x2={146.202}
          y2={103.372}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse18_linear"
          x1={242.909}
          y1={128.086}
          x2={292.278}
          y2={128.086}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#343266" />
          <stop offset={0.179} stopColor="#3B3F6C" />
          <stop offset={0.504} stopColor="#2D3C5C" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse19_linear"
          x1={301.261}
          y1={70.561}
          x2={301.261}
          y2={117.399}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse20_linear"
          x1={257.842}
          y1={118.681}
          x2={257.842}
          y2={167.989}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse21_linear"
          x1={293.116}
          y1={118.68}
          x2={293.116}
          y2={167.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse22_linear"
          x1={275.48}
          y1={114.106}
          x2={275.48}
          y2={120.602}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse23_linear"
          x1={240.288}
          y1={127.767}
          x2={293.611}
          y2={127.767}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse24_linear"
          x1={235.649}
          y1={69.806}
          x2={242.143}
          y2={103.875}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse25_linear"
          x1={263.97}
          y1={68.759}
          x2={279.262}
          y2={108.157}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse26_linear"
          x1={268.918}
          y1={97.64}
          x2={268.918}
          y2={170.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse27_linear"
          x1={214.471}
          y1={97.64}
          x2={214.471}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse28_linear"
          x1={241.695}
          y1={97.64}
          x2={241.695}
          y2={99.561}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse29_linear"
          x1={236.278}
          y1={72.245}
          x2={241.337}
          y2={103.941}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={316}
      height={170}
      viewBox="0 0 316 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M51.2 166.772S-1.203 144.422.022 99.166c1.225-45.257 50.363-52.337 83.984-24.433 33.757 27.903 51.039 14.252 68.462-9.348 17.423-23.6 44.242-77 93.107-57.01 36.48 14.854 60.844 54.697 68.739 90.652 5.445 24.71-2.45 50.809-21.37 67.051-.681.556-1.225 1.111-1.906 1.527l-239.836-.833z"
        fill="url(#SvgCourse0_linear)"
      />
      <path d="M197.96 0H65.983v92.254H197.96V0z" fill="#fff" />
      <path
        d="M65.983 0v92.254H197.96V0H65.983zm126.672 86.688H71.382V5.658h121.273v81.03z"
        fill="url(#SvgCourse1_linear)"
      />
      <path
        d="M51.187 27.284c-2.606-.274-4.56-1.46-6.422-3.285.559 5.018-1.024 10.128-1.024 10.128s.28 16.334 6.98 14.327c6.795-2.008 2.42-12.593 2.42-12.593-1.675-2.372-1.954-6.022-1.954-8.577z"
        fill="#FED2A4"
      />
      <path
        d="M47.836 10.493s7.725-.82 9.121 2.282c1.396 3.102-.186 7.208.186 8.577.466 1.369.187 10.13-2.327 10.312-2.513.182-10.517-5.384-10.703-11.954-.186-6.57 1.768-9.125 3.723-9.217z"
        fill="#FFE5C2"
      />
      <path
        d="M54.164 29.017H55c.093-.182.187-.365.187-.547.186-.365.279-.639.465-1.004l.558-1.916c-.372 0-.744-.092-1.117-.183-1.023.456-1.954.913-2.978 1.369l-.558.274c.93.638 1.768 1.277 2.606 2.007z"
        fill="#fff"
      />
      <path
        d="M86.552 47.725s2.606-1.917 2.978-2.464c.372-.548 2.14-4.563 3.165-6.388 1.024-1.825 6.329-3.923 6.701-2.555.466 1.278-1.768 1.643-4.095 3.194 0 0 .279.274.465.365.28 0 3.91 4.836 1.21 6.479-2.699 1.734-6.143 1.825-8.47 3.832 0-.09-1.861-1.095-1.954-2.463z"
        fill="#FFE5C2"
      />
      <path
        d="M47.648 159.05s-.93 5.475-.372 7.026c.465 1.186 17.219 1.369 18.15.365.93-1.004-.652-2.737-1.49-3.194l-9.4-4.106-6.888-.091zM36.947 160.054H29.78s-3.816 5.019-3.723 6.296c.093 1.278 5.771 3.65 7.074 3.65s5.957-3.011 5.864-3.559c0-.547-2.048-6.387-2.048-6.387z"
        fill="#112B46"
      />
      <path
        d="M57.42 18.068s2.606-1.095 2.048-4.015c-.559-2.92-2.048-4.745-6.515-5.932-4.375-1.186-8.377-.82-8.935 2.738 0 0 2.047 6.205 7.166 5.931 5.12-.274 6.609-.274 6.236 1.278z"
        fill="#2C3A64"
      />
      <path
        d="M57.327 24.729s-.744-.73-2.513-.822c-2.885 0-4.467 2.738-4.746 2.738-1.769 0-3.724-4.015-3.724-4.015-1.768-1.186-1.21-2.829-.279-3.194.931-.456 1.49 1.004 1.583 1.825.186.822.744 1.278 1.21.639.465-.639-.559-.091.093-3.833.744-3.74-1.955-5.2-1.955-5.2l-2.978-3.195c-6.888 3.012-2.42 13.78-.466 16.243 3.63 4.654 9.029 9.034 12.38 7.026 2.047-1.369 1.395-8.212 1.395-8.212zm-4.188 2.007s1.582 0 2.513-.456c0 0-.838 2.281-2.513.456z"
        fill="#2C3A64"
      />
      <path
        d="M58.165 73.274l-20.383-.365s-3.071 5.019-3.816 19.345c-.465 9.855-.93 12.593-1.862 25.551-.651 8.851-3.909 42.614-3.909 42.614h10.797s4.56-38.599 4.84-40.698c.093-.73 2.792-21.444 2.792-21.444s-.465 18.706-.465 20.896c0 2.19-.466 40.151-.466 40.151h11.355s1.303-32.303 1.397-39.694c.279-11.863 1.396-42.432-.28-46.356z"
        fill="url(#SvgCourse2_linear)"
      />
      <path
        d="M62.446 36.592l-4.003 14.874s7.353 13.413 11.076 13.687c3.723.183 21.407-15.147 21.407-15.147l-2.978-5.02s-15.357 11.225-16.195 10.95c-.745-.273-6.608-18.888-9.307-19.344z"
        fill="url(#SvgCourse3_linear)"
      />
      <path
        d="M43.647 33.763s-9.493 1.278-11.727 2.464c0 0 6.422 10.129 4.468 16.608 0 0-1.769 22.265-1.676 27.192 0 0 11.914 4.29 25.41.183 0 0-.187-24.547-.187-28.38 0 0 1.117-10.22 2.606-15.238 0 0-5.025-1.004-10.145-2.008 0 0 .373.548-1.768 3.194-1.303 1.643-6.515-.73-6.98-4.015z"
        fill="url(#SvgCourse4_linear)"
      />
      <path
        d="M59.934 51.739s.279-2.464.744-5.566c-1.675 1.733-1.954 9.307-2.14 10.585-1.024 5.748-8.75 7.847-13.682 4.745-4.654-2.92-5.213-10.13-6.33-14.874-.558-2.555-2.233-4.471-4.28-6.205 1.581 3.559 3.35 8.577 2.14 12.319 0 0-1.769 22.265-1.675 27.192 0 0 11.913 4.29 25.409.183 0 0-.186-24.547-.186-28.38z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M35.177 87.692c.745.82 3.072 3.558 2.7 4.836-.373 1.186-2.327-1.004-3.072-1.734-.745-.73-.838 1.734-.651 3.103.186 1.368 2.978 3.558 2.699 4.562-.28.913-1.862-.365-3.63-2.92 0 0 2.792 3.103 2.14 3.559-.651.456-2.233-1.186-3.443-3.011 0 0 2.699 2.92 2.047 3.285-.651.365-2.513-1.734-3.164-2.738 0 0 2.234 2.373 1.675 2.829-.465.274-2.14-1.095-2.792-3.011-.652-1.916 1.303-8.213 1.21-9.4-.093-1.185-.465-2.645-.465-2.645l3.909-.913c-.186.091-.28 3.011.837 4.198z"
        fill="#FFE5C2"
      />
      <path
        d="M35.177 85.685l-5.398 1.368s-6.33-20.349-5.398-25.276c.93-4.836 3.257-23.087 7.539-25.642 0 0 5.491 1.552 5.77 8.122.186 3.741-1.396 8.486-1.396 8.486s-3.444 6.388-3.816 10.95c-.28 3.742 2.7 21.992 2.7 21.992z"
        fill="url(#SvgCourse5_linear)"
      />
      <path
        d="M52.024 33.763l-1.955 3.65-6.236-4.745-1.861 1.46 5.956 8.03 2.42-3.924 3.444 2.463.093-5.84-1.861-1.094z"
        fill="#fff"
      />
      <path
        d="M77.99 61.686s33.599-42.523 34.157-42.706c.558-.182-31.924 44.805-31.924 44.805s-.651.638-1.768-.183c-.652-.547-.838-1.369-.465-1.916z"
        fill="#2C3A64"
      />
      <path
        d="M97.721 41.246s-2.234.82-3.443.912c-1.117.091-.838-1.004-.094-1.46.745-.456 3.63-1.734 4.375-1.369.745.365.465 1.643 0 1.825 0 0 1.024.548.372 1.643 0 0 .559 1.186-.093 1.734 0 0 .373 1.368-.558 1.642-.931.274-3.35.365-3.63-.182-.372-.73.837-1.095 3.257-1.37 0 0-2.606.457-2.978.366-.651-.091-2.234-1.369 2.978-1.916 0 0-4.095 1.095-4.28.09-.373-1.368 4.094-1.915 4.094-1.915z"
        fill="#FFE5C2"
      />
      <path
        d="M143.27 92.95l.093 4.57s19.638 1.189 25.129-.64c5.491-1.827 17.125-16.908 18.056-19.376 1.21-3.016-3.35-10.328-7.818-3.656-4.467 6.672-12.658 15.72-13.123 15.995-.465.183-22.337 3.107-22.337 3.107z"
        fill="url(#SvgCourse6_linear)"
      />
      <path
        d="M147.551 94.87s-1.955-.64-2.42-.73c-.373-.092-5.026-2.468-5.678-2.194-.651.274-6.98 2.65-6.98 3.29 0 .64 1.582.457 5.026-1.188 0 0-4.468 1.737-3.909 2.376.558.64 4.653-1.462 4.653-1.462s-4.095 1.645-3.35 2.194c.651.548 4.467-1.554 4.467-1.554s-2.885 1.553-2.699 1.92c.279.365 1.861 0 3.909-1.006 0 0 .372-.092 1.117.365.745.457 6.329.366 6.329.366l-.465-2.376z"
        fill="#FFE5C2"
      />
      <path
        d="M129.773 96.425l6.608-11.06s1.582-.913 1.582 1.006l-7.632 10.51s-1.768 2.103-.558-.456z"
        fill="#292C3D"
      />
      <path
        d="M145.502 93.409s-2.14 0-2.606-.092c-.465-.091-5.957-4.021-6.608-3.656-.652.366-7.26 3.748-7.167 4.57.093.823 1.862.549 5.305-1.736 0 0-4.653 2.467-3.909 3.29.745.823 4.84-2.01 4.84-2.01s-4.188 2.284-3.351 3.107c.838.822 4.654-2.194 4.654-2.194s-2.978 2.194-2.606 2.65c.279.458 2.048-.09 4.095-1.462 0 0 .838.183 1.676.914 1.675 1.554 6.608.549 6.608.549l-.931-3.93z"
        fill="#FFE5C2"
      />
      <path
        d="M182.358 61.968c.652-.183 4.003-6.946 5.399-8.317.651 4.661 3.536 15.72 3.536 15.72-3.443 3.656-10.424 1.463-10.424 1.463 1.21-2.468 1.675-7.312 1.489-8.866z"
        fill="#FED2A4"
      />
      <path
        d="M185.616 45.333s-6.329 1.371-7.539 3.2c-1.21 1.827-1.21 6.58-1.861 7.768-.373.731-.745 1.371-.931 1.828-.186.366.279.64.838.823.279.091.465.365.558.64.372 1.188 1.396 4.021 2.513 4.478 1.303.548 7.725.274 9.68-5.21 1.861-5.575.837-14.715-3.258-13.527zM156.763 158.393l4.561-.731s.186 4.204.652 5.209c0 0-5.12 2.377-10.239.457 0 0 3.351-1.371 4.189-2.376.744-1.005.837-2.559.837-2.559z"
        fill="#FFE5C2"
      />
      <path
        d="M161.511 160.859s-3.444 2.377-8.749 2.011c0 0-6.515 3.382-6.887 4.296-.559 1.188 2.233 1.279 6.701 1.097 3.816-.092 3.537-.366 4.281-1.097.745-.731 1.676-1.554 1.676-1.554s.372.457.744 2.559l1.676-.274s-.187-2.011.651-3.107c.559-.732 1.21-2.56-.093-3.931z"
        fill="#112B46"
      />
      <path
        d="M175.1 157.295l3.443-.914s2.048 3.291 2.606 4.113c0 0-4.374 3.473-9.959 2.742 0 0 2.886-2.102 3.537-3.29.559-1.097.373-2.651.373-2.651z"
        fill="#FFE5C2"
      />
      <path
        d="M180.683 159.763s-3.164 2.65-8.563 2.742c0 0-6.143 4.021-6.515 4.844-.465 1.279 2.327 1.097 6.795.548 3.723-.457 3.443-.731 4.188-1.554.651-.822 1.582-1.645 1.582-1.645s.373.457 1.024 2.468l1.675-.366s-.372-2.01.28-3.199c.465-.731.93-2.559-.466-3.838z"
        fill="#112B46"
      />
      <path
        d="M190.829 95.968l-8.935 6.215s-28.946 5.484-32.669 8.226c-3.629 2.742 23.362 50.726 23.362 50.726l8.376-2.742s-20.197-42.5-18.335-44.328c1.861-1.828 31.644-4.479 33.506-5.027 1.861-.64-.745-7.129-5.305-13.07z"
        fill="#514553"
      />
      <path
        d="M190.829 95.968l-8.935 6.215s-28.946 5.484-32.669 8.226c-3.629 2.742 23.362 50.726 23.362 50.726l8.376-2.742s-20.197-42.5-18.335-44.328c1.861-1.828 31.644-4.479 33.506-5.027 1.861-.64-.745-7.129-5.305-13.07z"
        fill="url(#SvgCourse7_linear)"
      />
      <path
        d="M193.527 98.8l-11.634 1.006s-30.713 6.672-34.436 10.145c-3.351 3.107 6.236 50.726 6.236 50.726l9.121-2.285s-5.119-42.5-2.886-43.78c1.955-1.097 33.32-1.097 35.275-1.097 2.792-.182 4.84-8.317-1.676-14.715z"
        fill="url(#SvgCourse8_linear)"
      />
      <path
        d="M190.27 67.085s-4.747 2.103-9.215 3.474c0 0-5.398 10.51-5.77 13.526-.373 2.925-3.165 18.28-3.165 18.28s17.312 5.941 25.037 2.102c0 0-2.42-11.882-1.582-18.097.838-6.215.093-14.715-5.305-19.285z"
        fill="url(#SvgCourse9_linear)"
      />
      <path
        d="M188.222 85c0 5.3-3.444 7.86-7.074 11.241-2.14 2.011-4.746 4.022-5.956 7.038-1.862-.548-3.072-1.005-3.072-1.005s1.675-7.952 2.513-13.71c3.537-1.554 5.492-4.936 6.701-8.5.745-2.102 1.117-3.382 2.514-4.936 3.909.092 4.188 4.662 4.374 8.866v1.005z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M214.561 70.741l-11.634 45.882-4.84-1.188 10.052-46.248 6.422 1.554z"
        fill="url(#SvgCourse10_linear)"
      />
      <path
        d="M163.278 117.994h-1.955v50.086h1.955v-50.086z"
        fill="url(#SvgCourse11_linear)"
      />
      <path
        d="M199.111 117.994h-1.955v50.086h1.955v-50.086z"
        fill="url(#SvgCourse12_linear)"
      />
      <path
        d="M204.23 113.333h-48.119v6.58h48.119v-6.58z"
        fill="url(#SvgCourse13_linear)"
      />
      <path
        d="M179.845 50.725c1.675.092 2.978.549 3.909 2.194.373.731.652 1.737.559 2.56-.093.456-1.303 2.375-.093 2.467.558.091 1.023-1.097 1.303-1.463.744-.914 1.675-.64 2.233.366.652 1.188 0 2.01-.93 2.742-1.21 1.097-.559 1.37-.28 2.925.373 1.92-.558 3.838.838 5.392 1.024 1.097 2.234 1.463 3.723 1.28 1.675-.274 3.537-.549 4.375-2.102 1.675-3.108-.466-5.484-1.49-8.5-1.116-3.382 0-8.683-2.699-11.334-1.396-1.37-3.35-2.01-5.212-2.193-1.861-.183-3.537.091-5.305.457-1.396.274-2.699.822-4.095 1.096-.931.183-1.304-.182-1.49.823-.093.457.28 1.554.559 2.01.744 1.372 2.42 1.372 3.816 1.372.093-.092.186-.092.279-.092z"
        fill="#2C3A64"
      />
      <path
        d="M166.443 97.704h-1.675V170h1.675V97.704z"
        fill="url(#SvgCourse14_linear)"
      />
      <path
        d="M111.903 97.704h-1.675V170h1.675V97.704z"
        fill="url(#SvgCourse15_linear)"
      />
      <path
        d="M166.35 97.704h-56.122v1.92h56.122v-1.92z"
        fill="url(#SvgCourse16_linear)"
      />
      <path
        d="M143.919 91.763l.093 5.941s20.29.914 25.967-.914c5.678-1.828 17.684-16.908 18.708-19.376 1.21-3.016-3.444-10.328-8.098-3.656-4.653 6.672-13.03 15.72-13.495 15.995-.652.274-23.175 2.01-23.175 2.01z"
        fill="url(#SvgCourse17_linear)"
      />
      <path
        d="M251.701 157.834s-6.702 3.202-10.239 4.665c-2.978 1.19-2.326 3.843 1.024 4.849 3.351 1.006 6.143.366 6.888-.732.744-1.006 2.699-.64 3.35-.366.745.366 6.329-.915 6.702-2.195.279-1.281-1.117-6.953-1.117-6.953l-6.608.732z"
        fill="#112B46"
      />
      <path
        d="M286.416 97l-11.355.183s-27.735 10.154-31.365 12.807c-3.63 2.653 5.956 49.124 5.956 49.124l9.401-1.006s-2.234-41.623-.373-43.361c1.862-1.739 31.18-4.391 32.948-4.94 1.861-.549-.745-6.953-5.212-12.808z"
        fill="#73C4D1"
      />
      <path
        d="M286.416 97l-11.355.183s-27.735 10.154-31.365 12.807c-3.63 2.653 5.956 49.124 5.956 49.124l9.401-1.006s-2.234-41.623-.373-43.361c1.862-1.739 31.18-4.391 32.948-4.94 1.861-.549-.745-6.953-5.212-12.808z"
        fill="url(#SvgCourse18_linear)"
      />
      <path
        d="M309.405 72.208L297.864 117.4l-4.747-1.189 9.866-45.648 6.422 1.646z"
        fill="url(#SvgCourse19_linear)"
      />
      <path
        d="M258.773 118.681h-1.862v49.308h1.862v-49.308z"
        fill="url(#SvgCourse20_linear)"
      />
      <path
        d="M294.047 118.68h-1.861v49.308h1.861V118.68z"
        fill="url(#SvgCourse21_linear)"
      />
      <path
        d="M299.167 114.106h-47.374v6.496h47.374v-6.496z"
        fill="url(#SvgCourse22_linear)"
      />
      <path
        d="M283.438 52.266l-6.515 1.372s1.861 7.868 0 12.167c0 0 3.537 1.372 9.493-3.11 0 .091-3.723-3.019-2.978-10.429z"
        fill="#FED2A4"
      />
      <path
        d="M284.461 43.21s-7.26-2.653-9.215-1.738c-1.954 1.006-4.281 2.378-3.909 3.842.372 1.464.652 5.672.186 7.044-.465 1.372-1.303 2.287-1.117 2.744.187.458 1.303.092 1.676.64.279.55 2.047 4.3 2.978 4.849 1.024.549 6.608-1.372 9.215-7.41 2.606-5.946 1.768-8.965.186-9.971z"
        fill="#FFE5C2"
      />
      <path
        d="M243.603 158.474s-8.656 3.019-12.286 4.391c-2.979 1.189-2.42 3.842.93 4.849 3.351 1.006 6.143.366 6.888-.641.745-1.006 2.699-.64 3.351-.274.744.366 6.329-.915 6.701-2.196.372-1.28-.465-5.031-.465-5.031l-5.119-1.098z"
        fill="#112B46"
      />
      <path
        d="M287.812 47.692c-.186-.64-.558-1.372-.651-2.013-.093-.731.651-5.854-5.864-5.214h-.931c-1.023-.274-2.047-1.098-3.164-1.19-3.63-.456-5.771 2.928-9.121 3.111-.093.915 1.023 2.379 1.675 3.11.745.915 1.861 1.464 2.885 1.739 1.117.365 2.699.365 4.002.274.652-.091 1.862-.823 2.42-.274.559.548.094 2.744.187 3.476 0 .549-.093 1.006.558 1.19h.372c.652-.184.559-2.014.652-2.562.558-1.921 2.885-1.647 3.164.183.28 1.555-1.303 2.653-1.768 2.927-.093.274-.093.549-.093.732-.186 1.555 1.489 2.561 2.885 1.83 1.117-.641 1.955-1.373 2.699-2.837.745-1.372.466-3.018.093-4.482z"
        fill="#2C3A64"
      />
      <path
        d="M289.579 95.353l-12.658 2.47s-28.759 10.245-32.389 12.898c-3.63 2.653-4.281 48.484-4.281 48.484l9.4.915s4.002-43.544 6.236-44.825c1.862-1.098 32.855-1.098 34.716-1.098 2.885-.091 5.305-12.532-1.024-18.844z"
        fill="url(#SvgCourse23_linear)"
      />
      <path
        d="M237.739 91.785l1.117 5.855s24.571-4.209 27.177-6.13c2.606-1.92 18.522-18.753 16.195-23.51-2.327-4.665-5.305-3.568-7.26-.915-1.861 2.653-10.61 16.284-11.913 17.564-1.21 1.464-25.316 7.136-25.316 7.136z"
        fill="url(#SvgCourse24_linear)"
      />
      <path
        d="M286.323 62.33s-6.329 3.2-9.959 2.927c0 0-5.771 11.983-6.236 15.185-.372 3.202-4.84 20.675-4.84 20.675s19.267 6.586 28.202 1.372c0 0-2.606-12.716-1.769-19.486 1.024-6.769.28-15.642-5.398-20.674z"
        fill="url(#SvgCourse25_linear)"
      />
      <path
        d="M283.531 84.65c1.396-3.202 6.887-8.325 4.095-11.893-2.234-2.835-3.816-3.11-6.236-.365-2.327 2.561-4.375 5.305-6.981 7.592-1.396 1.19-2.885 2.105-4.374 3.202 0 6.587-4.747 18.022-4.747 18.022s9.959 3.11 15.45 3.202c-2.885-6.221-.093-13.082 2.793-19.76z"
        fill="#7CA5E0"
        fillOpacity={0.3}
      />
      <path
        d="M244.721 93.25s-1.955.914-2.42.823c-.373-.092-5.026-2.47-5.678-2.196-.651.275-6.98 2.653-6.98 3.293 0 .64 1.582.458 5.026-1.189 0 0-4.468 1.738-3.91 2.379.559.64 4.654-1.464 4.654-1.464s-4.095 1.647-3.35 2.195c.651.55 4.467-1.555 4.467-1.555s-2.885 1.555-2.699 1.921c.279.366 1.861 0 3.909-1.006 0 0 .372-.091 1.117.366.745.457 6.329-1.19 6.329-1.19l-.465-2.378z"
        fill="#FFE5C2"
      />
      <path
        d="M226.57 96.359l6.143-11.252s1.583-1.007 1.583.915l-7.167 10.794c0-.091-1.675 2.196-.559-.457z"
        fill="#292C3D"
      />
      <path
        d="M242.208 92.7s-2.14.092-2.606.092c-.465 0-6.143-3.842-6.794-3.385-.652.366-7.074 4.025-6.981 4.848.093.824 1.862.458 5.305-1.92 0 0-4.56 2.652-3.723 3.384.745.823 4.747-2.195 4.747-2.195s-4.095 2.47-3.257 3.201c.837.732 4.56-2.378 4.56-2.378s-2.885 2.287-2.513 2.744c.373.458 2.048-.091 4.002-1.646 0 0 .838.091 1.676.823 1.768 1.464 6.608.274 6.608.274l-1.024-3.842z"
        fill="#FFE5C2"
      />
      <path
        d="M269.755 97.64h-1.675v72.361h1.675v-72.36z"
        fill="url(#SvgCourse26_linear)"
      />
      <path
        d="M215.309 97.64h-1.675V170h1.675V97.64z"
        fill="url(#SvgCourse27_linear)"
      />
      <path
        d="M269.756 97.64h-56.122v1.921h56.122V97.64z"
        fill="url(#SvgCourse28_linear)"
      />
      <path
        d="M238.576 91.785l1.675 5.947s25.037.731 27.829-1.19c2.792-1.92 21.872-21.04 19.452-25.706-2.42-4.665-5.584-3.567-7.632-.914-1.861 2.47-13.868 16.192-15.357 17.655-1.303 1.281-25.967 4.208-25.967 4.208z"
        fill="url(#SvgCourse29_linear)"
      />
      <defs>
        <linearGradient
          id="SvgCourse0_linear"
          x1={158}
          y1={4.302}
          x2={158}
          y2={167.605}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="SvgCourse1_linear"
          x1={131.972}
          y1={0}
          x2={131.972}
          y2={92.254}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse2_linear"
          x1={28.217}
          y1={116.705}
          x2={59.094}
          y2={116.705}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse3_linear"
          x1={56.925}
          y1={40.947}
          x2={63.587}
          y2={70.134}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse4_linear"
          x1={30.488}
          y1={41.117}
          x2={48.648}
          y2={85.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse5_linear"
          x1={23.66}
          y1={43.9}
          x2={51.027}
          y2={71.683}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse6_linear"
          x1={141.237}
          y1={75.208}
          x2={145.724}
          y2={103.275}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse7_linear"
          x1={148.923}
          y1={128.582}
          x2={196.781}
          y2={128.582}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse8_linear"
          x1={146.781}
          y1={129.768}
          x2={197.9}
          y2={129.768}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse9_linear"
          x1={170.95}
          y1={72.983}
          x2={185.311}
          y2={108.801}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse10_linear"
          x1={206.324}
          y1={69.188}
          x2={206.324}
          y2={116.623}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse11_linear"
          x1={162.301}
          y1={117.994}
          x2={162.301}
          y2={168.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse12_linear"
          x1={198.134}
          y1={117.994}
          x2={198.134}
          y2={168.08}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse13_linear"
          x1={180.17}
          y1={113.333}
          x2={180.17}
          y2={119.913}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse14_linear"
          x1={165.605}
          y1={97.704}
          x2={165.605}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse15_linear"
          x1={111.065}
          y1={97.704}
          x2={111.065}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse16_linear"
          x1={138.289}
          y1={97.704}
          x2={138.289}
          y2={99.623}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse17_linear"
          x1={141.817}
          y1={75.136}
          x2={146.202}
          y2={103.372}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse18_linear"
          x1={242.909}
          y1={128.086}
          x2={292.278}
          y2={128.086}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#343266" />
          <stop offset={0.179} stopColor="#3B3F6C" />
          <stop offset={0.504} stopColor="#2D3C5C" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse19_linear"
          x1={301.261}
          y1={70.561}
          x2={301.261}
          y2={117.399}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse20_linear"
          x1={257.842}
          y1={118.681}
          x2={257.842}
          y2={167.989}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse21_linear"
          x1={293.116}
          y1={118.68}
          x2={293.116}
          y2={167.988}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse22_linear"
          x1={275.48}
          y1={114.106}
          x2={275.48}
          y2={120.602}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse23_linear"
          x1={240.288}
          y1={127.767}
          x2={293.611}
          y2={127.767}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgCourse24_linear"
          x1={235.649}
          y1={69.806}
          x2={242.143}
          y2={103.875}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse25_linear"
          x1={263.97}
          y1={68.759}
          x2={279.262}
          y2={108.157}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgCourse26_linear"
          x1={268.918}
          y1={97.64}
          x2={268.918}
          y2={170.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse27_linear"
          x1={214.471}
          y1={97.64}
          x2={214.471}
          y2={170}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse28_linear"
          x1={241.695}
          y1={97.64}
          x2={241.695}
          y2={99.561}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgCourse29_linear"
          x1={236.278}
          y1={72.245}
          x2={241.337}
          y2={103.941}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgCourse;
