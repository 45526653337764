import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const LifeStyleIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='url(#a)'>
        <path
          fill='#485363'
          d='M41.335 38.161h-5.08a.824.824 0 1 1 0-1.65h5.08v1.65ZM41.335 40.39v1.651h-5.08a.825.825 0 0 1 0-1.65h5.08ZM41.335 44.272v1.65h-5.08a.81.81 0 0 1-.434-.126.82.82 0 0 1-.173-1.258.82.82 0 0 1 .607-.266h5.08ZM41.335 32.627v1.65h-5.08a.824.824 0 1 1 0-1.65h5.08Z'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='M37.592 13.781c-5.388 0-9.45 4.421-9.799 9.8.112 1.285 2.076 4.555 2.966 4.635l5.495-2.524v-4.306a3.06 3.06 0 0 1 3.052-3.052h2.892l1.44-2.032-1.44-2.52h-4.606Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m27.701 27.796-.077 3.555c-.019 1.711 1.194 1.766 2.278 2.101l2.572.8 3.781-.8v-7.76h-6.353c-.819 0-2.195.633-2.2 2.104ZM42.198 13.78v4.552c0 .407.336.78.742.742l10.304-1c.404-.042.51-.41.743-.743l1.458-2.089c.234-.336-.336-.694-.742-.742L42.94 13.038c-.406-.051-.742.332-.742.742Z'
          clipRule='evenodd'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='m20.167 40.122-7.374 2.959-2.668 8.992c.752 1.312 1.433 2.585 2.028 3.555 1.264 2.2 2.514 4.274 4.844 5.812 8.547 5.647 19.257-1.334 19.257-11.184v-9.04l-4.398-3.698-4.488 5.764-.058 2.697c-.045 2.099-2.44 3.267-3.832.557l-2.886-5.614a32.09 32.09 0 0 0-.425-.8Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M25.682 41.216a2.108 2.108 0 0 0 2.111 2.108 2.108 2.108 0 1 0-.336-4.191 2.11 2.11 0 0 0-1.775 2.083Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='M33.078 21.048v4.642h3.177v-4.306a3.06 3.06 0 0 1 3.052-3.052h2.892v-2.031h-3.484c-3.51 0-5.637 3.068-5.637 4.747ZM33.078 48.143c-.65 9.22-2.592 11.664-6.642 14.952 5.413-1.385 9.818-6.52 9.818-12.838v-9.04h-3.176v6.926Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M27.793 23.58a2.111 2.111 0 0 0-2.111 2.112c0 1.132.895 2.06 2.018 2.105l.093.003a2.108 2.108 0 0 0 2.108-2.108 2.109 2.109 0 0 0-2.108-2.112Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M33.078 25.691h3.177v7.761h-3.177v-7.76Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m15.16 34.088-6.103 4.501-3.634 7.489c1.724 1.065 3.33 3.599 4.702 5.995l10.042-11.952c-1.683-3.087-3.73-5.787-5.006-6.033Z'
          clipRule='evenodd'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='m11.51 31.126-9.737 11.99a2.36 2.36 0 0 0 .342 3.305c1.004.816 2.393.573 3.308-.343l9.738-11.99a2.36 2.36 0 0 0-.343-3.304 2.357 2.357 0 0 0-3.307.342Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m27.534 35.547-.077 3.586a2.108 2.108 0 0 1 2.444 2.083h6.353V33.45h-6.353c-1.126 0-2.316.925-2.367 2.096Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M27.623 31.35a2.112 2.112 0 0 0-1.941 2.102 2.113 2.113 0 0 0 2.11 2.111 2.109 2.109 0 0 0 2.11-2.111 2.108 2.108 0 0 0-2.11-2.108c-.057 0-.111.003-.169.006Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M33.078 33.451V41.215h3.177V33.451h-3.177Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='M22.892 52.131a3.106 3.106 0 1 0 3.11 5.379 3.106 3.106 0 0 0-3.11-5.379ZM11.51 31.126l-1.634 2.012a2.358 2.358 0 0 1 2.959.004 2.35 2.35 0 0 1 .857 2.028 2.333 2.333 0 0 1-.515 1.28l.058.009 1.926-2.37a2.36 2.36 0 0 0-.343-3.305 2.357 2.357 0 0 0-3.307.342Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M13.234 36.458c1.098.27 2.736 2.269 4.223 4.751.25.41.493.835.73 1.27l1.98-2.358c-1.683-3.087-3.73-5.787-5.007-6.033l-1.926 2.37Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='m18.612 43.282 1.44 2.805c1.27 2.467 3.349 3.055 5.281 2.022-.64-.064-1.321-.534-1.855-1.574l-2.886-5.614a31.436 31.436 0 0 0-.425-.8l-1.98 2.358c.143.265.284.53.425.803Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M42.198 16.302v2.032c0 .406.336.78.742.742l10.304-1.002c.404-.041.51-.409.743-.742l1.458-2.089c.234-.336-.336-.694-.742-.742l-1.295-.163-.749 1.068c-.23.333-.355.896-.761.896h-9.7Z'
          clipRule='evenodd'
        />
        <path
          fill='#64A2FF'
          d='M19.725 14.878v-1.775a1.096 1.096 0 0 0-.39-.852 1.12 1.12 0 0 0-.924-.231l-5.548 1.032c-.6.11-1.07.67-1.07 1.274V19.623a2.522 2.522 0 0 0-1.101-.02c-1.23.24-2.192 1.245-2.192 2.288 0 .495.21.94.593 1.256.353.29.822.444 1.334.444.17 0 .345-.017.523-.051 1.227-.24 2.188-1.245 2.188-2.288v-4.604l5.242-.973v1.294a2.53 2.53 0 0 0-1.104-.02c-1.228.24-2.19 1.245-2.19 2.287 0 .495.211.942.594 1.257.353.29.821.444 1.332.444.17 0 .345-.017.521-.052 1.23-.24 2.192-1.244 2.192-2.287V14.878ZM55.165 50.446a1.092 1.092 0 0 0-.954-.18l-3.867 1.03c-.59.157-1.034.743-1.034 1.365v4.9a2.618 2.618 0 0 0-1.052-.217c-1.32 0-2.396.96-2.396 2.141 0 1.18 1.075 2.14 2.396 2.14 1.322 0 2.397-.96 2.397-2.14l-.003-.092.003-.051V55.12l3.901-1.038c.59-.157 1.035-.743 1.035-1.365v-1.371a1.12 1.12 0 0 0-.426-.901Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M.5 10h55v55H.5z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='url(#a)'>
        <path
          fill='#485363'
          d='M41.335 38.161h-5.08a.824.824 0 1 1 0-1.65h5.08v1.65ZM41.335 40.39v1.651h-5.08a.825.825 0 0 1 0-1.65h5.08ZM41.335 44.272v1.65h-5.08a.81.81 0 0 1-.434-.126.82.82 0 0 1-.173-1.258.82.82 0 0 1 .607-.266h5.08ZM41.335 32.627v1.65h-5.08a.824.824 0 1 1 0-1.65h5.08Z'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='M37.592 13.781c-5.388 0-9.45 4.421-9.799 9.8.112 1.285 2.076 4.555 2.966 4.635l5.495-2.524v-4.306a3.06 3.06 0 0 1 3.052-3.052h2.892l1.44-2.032-1.44-2.52h-4.606Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m27.701 27.796-.077 3.555c-.019 1.711 1.194 1.766 2.278 2.101l2.572.8 3.781-.8v-7.76h-6.353c-.819 0-2.195.633-2.2 2.104ZM42.198 13.78v4.552c0 .407.336.78.742.742l10.304-1c.404-.042.51-.41.743-.743l1.458-2.089c.234-.336-.336-.694-.742-.742L42.94 13.038c-.406-.051-.742.332-.742.742Z'
          clipRule='evenodd'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='m20.167 40.122-7.374 2.959-2.668 8.992c.752 1.312 1.433 2.585 2.028 3.555 1.264 2.2 2.514 4.274 4.844 5.812 8.547 5.647 19.257-1.334 19.257-11.184v-9.04l-4.398-3.698-4.488 5.764-.058 2.697c-.045 2.099-2.44 3.267-3.832.557l-2.886-5.614a32.09 32.09 0 0 0-.425-.8Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M25.682 41.216a2.108 2.108 0 0 0 2.111 2.108 2.108 2.108 0 1 0-.336-4.191 2.11 2.11 0 0 0-1.775 2.083Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='M33.078 21.048v4.642h3.177v-4.306a3.06 3.06 0 0 1 3.052-3.052h2.892v-2.031h-3.484c-3.51 0-5.637 3.068-5.637 4.747ZM33.078 48.143c-.65 9.22-2.592 11.664-6.642 14.952 5.413-1.385 9.818-6.52 9.818-12.838v-9.04h-3.176v6.926Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M27.793 23.58a2.111 2.111 0 0 0-2.111 2.112c0 1.132.895 2.06 2.018 2.105l.093.003a2.108 2.108 0 0 0 2.108-2.108 2.109 2.109 0 0 0-2.108-2.112Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M33.078 25.691h3.177v7.761h-3.177v-7.76Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m15.16 34.088-6.103 4.501-3.634 7.489c1.724 1.065 3.33 3.599 4.702 5.995l10.042-11.952c-1.683-3.087-3.73-5.787-5.006-6.033Z'
          clipRule='evenodd'
        />
        <path
          fill='#5384EE'
          fillRule='evenodd'
          d='m11.51 31.126-9.737 11.99a2.36 2.36 0 0 0 .342 3.305c1.004.816 2.393.573 3.308-.343l9.738-11.99a2.36 2.36 0 0 0-.343-3.304 2.357 2.357 0 0 0-3.307.342Z'
          clipRule='evenodd'
        />
        <path
          fill='#fff'
          fillRule='evenodd'
          d='m27.534 35.547-.077 3.586a2.108 2.108 0 0 1 2.444 2.083h6.353V33.45h-6.353c-1.126 0-2.316.925-2.367 2.096Z'
          clipRule='evenodd'
        />
        <path
          fill='#072252'
          fillRule='evenodd'
          d='M27.623 31.35a2.112 2.112 0 0 0-1.941 2.102 2.113 2.113 0 0 0 2.11 2.111 2.109 2.109 0 0 0 2.11-2.111 2.108 2.108 0 0 0-2.11-2.108c-.057 0-.111.003-.169.006Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M33.078 33.451V41.215h3.177V33.451h-3.177Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='M22.892 52.131a3.106 3.106 0 1 0 3.11 5.379 3.106 3.106 0 0 0-3.11-5.379ZM11.51 31.126l-1.634 2.012a2.358 2.358 0 0 1 2.959.004 2.35 2.35 0 0 1 .857 2.028 2.333 2.333 0 0 1-.515 1.28l.058.009 1.926-2.37a2.36 2.36 0 0 0-.343-3.305 2.357 2.357 0 0 0-3.307.342Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M13.234 36.458c1.098.27 2.736 2.269 4.223 4.751.25.41.493.835.73 1.27l1.98-2.358c-1.683-3.087-3.73-5.787-5.007-6.033l-1.926 2.37Z'
          clipRule='evenodd'
        />
        <path
          fill='#3F73E3'
          fillRule='evenodd'
          d='m18.612 43.282 1.44 2.805c1.27 2.467 3.349 3.055 5.281 2.022-.64-.064-1.321-.534-1.855-1.574l-2.886-5.614a31.436 31.436 0 0 0-.425-.8l-1.98 2.358c.143.265.284.53.425.803Z'
          clipRule='evenodd'
        />
        <path
          fill='#83B4FF'
          fillRule='evenodd'
          d='M42.198 16.302v2.032c0 .406.336.78.742.742l10.304-1.002c.404-.041.51-.409.743-.742l1.458-2.089c.234-.336-.336-.694-.742-.742l-1.295-.163-.749 1.068c-.23.333-.355.896-.761.896h-9.7Z'
          clipRule='evenodd'
        />
        <path
          fill='#64A2FF'
          d='M19.725 14.878v-1.775a1.096 1.096 0 0 0-.39-.852 1.12 1.12 0 0 0-.924-.231l-5.548 1.032c-.6.11-1.07.67-1.07 1.274V19.623a2.522 2.522 0 0 0-1.101-.02c-1.23.24-2.192 1.245-2.192 2.288 0 .495.21.94.593 1.256.353.29.822.444 1.334.444.17 0 .345-.017.523-.051 1.227-.24 2.188-1.245 2.188-2.288v-4.604l5.242-.973v1.294a2.53 2.53 0 0 0-1.104-.02c-1.228.24-2.19 1.245-2.19 2.287 0 .495.211.942.594 1.257.353.29.821.444 1.332.444.17 0 .345-.017.521-.052 1.23-.24 2.192-1.244 2.192-2.287V14.878ZM55.165 50.446a1.092 1.092 0 0 0-.954-.18l-3.867 1.03c-.59.157-1.034.743-1.034 1.365v4.9a2.618 2.618 0 0 0-1.052-.217c-1.32 0-2.396.96-2.396 2.141 0 1.18 1.075 2.14 2.396 2.14 1.322 0 2.397-.96 2.397-2.14l-.003-.092.003-.051V55.12l3.901-1.038c.59-.157 1.035-.743 1.035-1.365v-1.371a1.12 1.12 0 0 0-.426-.901Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' d='M.5 10h55v55H.5z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LifeStyleIcon;
