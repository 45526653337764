import React from 'react';

const PatentIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)">
        <path
          d="M22.7273 43.1387V44.2296L23.8181 48.5932H52.1818L53.2727 44.2296V43.1387H22.7273Z"
          fill="#485363"
        />
        <path
          d="M47.8181 43.1387V44.2296L46.7273 48.5932H52.1818L53.2727 44.2296V43.1387H47.8181Z"
          fill="#072252"
        />
        <path
          d="M38 0.59375C33.18 0.59375 29.2727 4.50106 29.2727 9.32103C29.2727 18.5938 33.6363 18.5938 33.6363 27.8665V28.9573H42.3636V27.8665C42.3636 18.5938 46.7272 18.5938 46.7272 9.32103C46.7273 4.50106 42.82 0.59375 38 0.59375Z"
          fill="#5384EE"
        />
        <path
          d="M38 0.59375C37.0472 0.59375 36.1311 0.748531 35.2727 1.03081C38.7566 2.17625 41.2727 5.45394 41.2727 9.32103C41.2727 18.5938 36.9091 18.5938 36.9091 27.8665V28.9573H42.3637V27.8665C42.3637 18.5938 46.7273 18.5938 46.7273 9.32103C46.7273 4.50106 42.82 0.59375 38 0.59375Z"
          fill="#3F73E3"
        />
        <path
          d="M47.5502 29.9235C47.0531 28.681 45.8497 27.8662 44.5115 27.8662H31.4885C30.1503 27.8662 28.947 28.681 28.4499 29.9235L27.091 33.3208V34.4116H48.9092V33.3208L47.5502 29.9235Z"
          fill="#5384EE"
        />
        <path
          d="M47.5502 29.9235C47.0531 28.681 45.8498 27.8662 44.5116 27.8662H39.057C40.3952 27.8662 41.5986 28.681 42.0956 29.9235L43.4545 33.3208V34.4116H48.9091V33.3208L47.5502 29.9235Z"
          fill="#5384EE"
        />
        <path
          d="M56.5454 33.3213H19.4546V44.2304H56.5454V33.3213Z"
          fill="white"
        />
        <path
          d="M56.5454 33.3213H51.0909V44.2304H56.5454V33.3213Z"
          fill="#CACACA"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="48"
          height="48"
          fill="white"
          transform="translate(14 0.59375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PatentIcon;
