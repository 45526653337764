import { formatDate, checkLinkPrefix } from '@lobox/utils';

export const convertPublicationApiToForm = (item) => {
  const {
    date,
    description,
    id,
    link,
    publisherName,
    publisherPageId,
    title,
    pageCroppedImageUrl,
  } = item;
  return {
    title,
    publisher: { label: publisherName, value: publisherPageId },
    id,
    date,
    startDate: date,
    description,
    link: checkLinkPrefix(link),
    pageCroppedImageUrl,
  };
};

export const publicationNormalizer = (item) => {
  const {
    date,
    description,
    id,
    publisherName,
    publisherPageId,
    title,
    pageCroppedImageUrl,
  } = item;
  const realData = convertPublicationApiToForm(item);

  const formattedDate = date ? formatDate(date) : undefined;

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: title,
    firstTextAdditionalProps: { objectId: publisherPageId },
    thirdText: publisherName,
    secondText: formattedDate,
    realData,
    longText: description,
    objectId: publisherPageId,
  };
};

export const convertAwardApiToForm = (item) => {
  const {
    date,
    description,
    id,
    link,
    institutionName,
    institutionPageId,
    awardName,
    awardLookupId,
    pageCroppedImageUrl,
  } = item;

  return {
    title: { label: awardName, value: awardLookupId },
    institution: { label: institutionName, value: institutionPageId },
    id,
    date,
    startDate: date,
    description,
    link: checkLinkPrefix(link),
    pageCroppedImageUrl,
  };
};
export const awardNormalizer = (item) => {
  const {
    date,
    description,
    id,
    institutionName,
    institutionPageId,
    awardName,
    pageCroppedImageUrl,
  } = item;

  const realData = convertAwardApiToForm(item);

  const formattedDate = date ? formatDate(date) : undefined;

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: awardName,
    firstTextAdditionalProps: { objectId: institutionPageId },
    thirdText: institutionName,
    secondText: formattedDate,
    realData,
    longText: description,
    objectId: institutionPageId,
  };
};

export const convertPatentApiToForm = (item) => {
  const {
    date,
    description,
    id,
    link,
    institutionName,
    institutionPageId,
    title,
    patentId,
    pageCroppedImageUrl,
  } = item;

  return {
    title,
    institution: { label: institutionName, value: institutionPageId },
    id,
    date,
    startDate: date,
    description,
    link: checkLinkPrefix(link),
    patentId,
    pageCroppedImageUrl,
  };
};
export const patentNormalizer = (item) => {
  const {
    date,
    description,
    id,
    institutionName,
    institutionPageId,
    title,
    pageCroppedImageUrl,
  } = item;

  const realData = convertPatentApiToForm(item);

  const formattedDate = date ? formatDate(date) : undefined;

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: title,
    firstTextAdditionalProps: { objectId: institutionPageId },
    thirdText: institutionName,
    secondText: formattedDate,
    realData,
    longText: description,
    objectId: institutionPageId,
  };
};
