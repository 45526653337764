const HIGHLIGHT_TYPES = {
  JOB: 'JOB',
  SCHOOL: 'SCHOOL',
  TRAVEL: 'TRAVEL',
  LICENSE: 'LICENSE',
  LANGUAGE: 'LANGUAGE',
  SIMPLE: 'SIMPLE',
  COURSE: 'COURSE',
  INSTRUMENT: 'INSTRUMENT',
  GET_FUNDED: 'GET_FUNDED',
  AWARD: 'AWARD',
  GET_PROMOTED: 'GET_PROMOTED',
  GRADUATION: 'GRADUATION',
  HOBBY: 'HOBBY',
  PATENT: 'PATENT',
  CERTIFICATION: 'CERTIFICATION',
  ENTREPRENEURSHIP: 'ENTREPRENEURSHIP',
  STUDY_ABROAD: 'STUDY_ABROAD',
  SPORT: 'SPORT',
  PUBLICATION: 'PUBLICATION',
  SKILL: 'SKILL',
  ADVENTURE: 'ADVENTURE',
  CONFERENCE_PARTICIPATION: 'CONFERENCE_PARTICIPATION',
  BUSSINES_GET_FUNDED: 'BUSSINES_GET_FUNDED',
  ACQUIRED: 'ACQUIRED',
  NEW_LOCATION: 'NEW_LOCATION',
  CHANGED_NAME: 'CHANGED_NAME',
  MARKET_VALUE: 'MARKET_VALUE',
  STOCK_VALUE_CHANGE: 'STOCK_VALUE_CHANGE',
  GET_INVESTED: 'GET_INVESTED',
  IPO: 'IPO',
  VOLUNTEERING: 'VOLUNTEERING',
  ANNIVERSARY: 'ANNIVERSARY',
  NEW_TEAM_MEMBER: 'NEW_TEAM_MEMBER',
};

export default HIGHLIGHT_TYPES;
