import React from 'react';

const SkillIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clipSkill0)">
      <path
        d="M46.5747 13.1971C45.4461 12.0975 43.6261 12.117 42.5177 13.2407L32.1788 23.7224C32.064 23.8388 31.9826 23.9838 31.9433 24.142C31.6545 25.3035 31.2814 26.3969 30.9817 27.2755C30.8771 27.5819 30.7784 27.8714 30.6917 28.1378C30.5851 28.4659 30.674 28.8255 30.9214 29.0666L39.283 37.2136C39.5305 37.4547 39.8939 37.5359 40.2219 37.4234C44.1337 36.0811 46.448 34.0521 48.9607 31.5046C49.3499 31.1102 49.744 30.703 50.1493 30.2843C50.5975 29.8212 51.0609 29.3424 51.5515 28.8451L53.5796 26.789C54.688 25.6653 54.6716 23.8564 53.5429 22.7567C53.1634 22.387 52.7059 22.1438 52.2233 22.0265C52.4409 21.0903 52.1786 20.067 51.44 19.3473C51.0605 18.9776 50.603 18.7343 50.1204 18.6171C50.338 17.6808 50.0757 16.6575 49.3371 15.9379C48.8063 15.4207 48.1225 15.151 47.4318 15.1275C47.4058 14.395 47.1031 13.712 46.5747 13.1971Z"
        fill="#FED2A4"
      />
      <g clipPath="url(#clipSkill1)">
        <path
          d="M36.7868 19.0654L31.238 24.6908C30.9489 24.984 30.4985 25.049 30.1382 24.8495C29.778 24.6501 29.5969 24.2355 29.696 23.8372C30.659 19.9689 30.2179 17.0425 28.3476 14.8908C27.6987 14.1438 27.7524 12.9673 28.4633 12.2651C29.1909 11.5507 30.1538 11.1554 31.1751 11.1519C32.1973 11.1484 33.1623 11.5378 33.8921 12.2484C33.8922 12.2485 33.8923 12.2486 33.8924 12.2486L34.1118 12.4625C35.8941 14.199 36.512 15.8317 37.029 18.2372C37.0931 18.536 37.0022 18.847 36.7868 19.0654Z"
          fill="#FFE5C2"
        />
        <path
          d="M44.3274 12.8924C43.1988 11.7928 41.3788 11.8123 40.2704 12.936L29.9315 23.4177C29.8167 23.5342 29.7353 23.6791 29.696 23.8373C29.4072 24.9988 29.0341 26.0922 28.7344 26.9708C28.6298 27.2773 28.5311 27.5667 28.4444 27.8331C28.3378 28.1612 28.4267 28.5208 28.6741 28.7619L37.0357 36.9089C37.2832 37.15 37.6466 37.2312 37.9746 37.1187C41.8864 35.7764 44.2007 33.7474 46.7135 31.1999C47.1026 30.8055 47.4967 30.3983 47.902 29.9796C48.3502 29.5165 48.8136 29.0378 49.3042 28.5404L51.3323 26.4843C52.4407 25.3606 52.4243 23.5518 51.2956 22.4521C50.9161 22.0823 50.4586 21.8391 49.9761 21.7218C50.1936 20.7856 49.9313 19.7623 49.1927 19.0426C48.8132 18.6729 48.3557 18.4296 47.8732 18.3124C48.0907 17.3761 47.8284 16.3528 47.0898 15.6332C46.559 15.116 45.8752 14.8464 45.1845 14.8228C45.1585 14.0903 44.8559 13.4073 44.3274 12.8924Z"
          fill="#FFE5C2"
        />
        <path
          d="M37.2965 8.69405L35.9278 10.0816C35.5736 10.4407 34.9938 10.447 34.6331 10.0955C34.2723 9.74398 34.2671 9.16776 34.6214 8.80859L35.99 7.42109C36.3442 7.06192 36.924 7.05569 37.2847 7.40718C37.6455 7.75867 37.6508 8.33484 37.2965 8.69405Z"
          fill="#83B4FF"
        />
        <path
          d="M27.7162 18.4069L26.3476 19.7945C25.9933 20.1536 25.4136 20.1598 25.0528 19.8084C24.6921 19.4569 24.6868 18.8807 25.0411 18.5215L26.4097 17.134C26.764 16.7748 27.3437 16.7686 27.7045 17.1201C28.0652 17.4716 28.0706 18.0477 27.7162 18.4069Z"
          fill="#83B4FF"
        />
        <path
          d="M26.1492 12.7966C25.9274 13.0214 25.5947 13.1259 25.2663 13.0421L23.3751 12.5599C22.8856 12.4352 22.5911 11.9393 22.7176 11.4525C22.844 10.9657 23.3435 10.6722 23.833 10.797L25.7242 11.2792C26.2138 11.404 26.5082 11.8999 26.3817 12.3866C26.3401 12.5469 26.258 12.6863 26.1492 12.7966Z"
          fill="#83B4FF"
        />
        <path
          d="M30.2537 8.63615C30.1449 8.74651 30.0063 8.83086 29.8459 8.87536C29.3587 9.01063 28.854 8.72796 28.7188 8.24396L28.1962 6.37427C28.0609 5.89032 28.3462 5.3881 28.8334 5.25291C29.3206 5.11763 29.8253 5.40031 29.9606 5.8843L30.4832 7.75399C30.574 8.07854 30.4754 8.41136 30.2537 8.63615Z"
          fill="#83B4FF"
        />
        <path
          d="M34.1877 37.0418L37.6282 40.3939L36.3451 41.6947L32.9047 38.3425C32.5441 37.9912 32.5389 37.4147 32.893 37.0557C33.2471 36.6967 33.8272 36.6904 34.1877 37.0418Z"
          fill="#73C3FF"
        />
        <path
          d="M37.2965 8.69405L35.9278 10.0816C35.5736 10.4407 34.9938 10.447 34.6331 10.0955C34.2723 9.74398 34.2671 9.16776 34.6214 8.80859L35.99 7.42109C36.3442 7.06192 36.924 7.05569 37.2847 7.40718C37.6455 7.75867 37.6508 8.33484 37.2965 8.69405Z"
          fill="#5384EE"
        />
        <path
          d="M27.7162 18.4069L26.3476 19.7945C25.9933 20.1536 25.4136 20.1598 25.0528 19.8084C24.6921 19.4569 24.6868 18.8807 25.0411 18.5215L26.4097 17.134C26.764 16.7748 27.3437 16.7686 27.7045 17.1201C28.0652 17.4716 28.0706 18.0477 27.7162 18.4069Z"
          fill="#5384EE"
        />
        <path
          d="M26.1492 12.7966C25.9274 13.0214 25.5947 13.1259 25.2663 13.0421L23.3751 12.5599C22.8856 12.4352 22.5911 11.9393 22.7176 11.4525C22.844 10.9657 23.3435 10.6722 23.833 10.797L25.7242 11.2792C26.2138 11.404 26.5082 11.8999 26.3817 12.3866C26.3401 12.5469 26.258 12.6863 26.1492 12.7966Z"
          fill="#5384EE"
        />
        <path
          d="M30.2537 8.63615C30.1449 8.74651 30.0063 8.83086 29.8459 8.87536C29.3587 9.01063 28.854 8.72796 28.7188 8.24396L28.1962 6.37427C28.0609 5.89032 28.3462 5.3881 28.8334 5.25291C29.3206 5.11763 29.8253 5.40031 29.9606 5.8843L30.4832 7.75399C30.574 8.07854 30.4754 8.41136 30.2537 8.63615Z"
          fill="#5384EE"
        />
        <path
          d="M34.1877 37.0418L37.6282 40.3939L36.3451 41.6947L32.9047 38.3425C32.5441 37.9912 32.5389 37.4147 32.893 37.0557C33.2471 36.6967 33.8272 36.6904 34.1877 37.0418Z"
          fill="#73C3FF"
        />
        <path
          d="M27.2602 26.1258L21.7858 31.6758C21.4317 32.0348 21.437 32.6113 21.7975 32.9627L32.9463 43.8253C33.3073 44.177 33.8869 44.1704 34.2493 43.8194L38.3551 39.6569C39.1333 38.8515 39.3534 37.7097 39.0116 36.7211C38.8724 36.3159 38.638 35.9362 38.3101 35.6167L28.555 26.1119C28.1944 25.7605 27.6143 25.7668 27.2602 26.1258Z"
          fill="#3F73E3"
        />
        <path
          d="M34.1441 36.9998L37.6281 40.3944L36.3451 41.6951L32.8611 38.3005C32.5005 37.9492 32.4952 37.3727 32.8494 37.0137C33.2035 36.6547 33.7836 36.6484 34.1441 36.9998Z"
          fill="#83B4FF"
        />
      </g>
      <g clipPath="url(#clipSkill2)">
        <path
          d="M5.91278e-05 36C5.91278e-05 28.268 6.26807 22 14.0001 22C21.732 22 28.0001 28.268 28.0001 36C28.0001 43.732 21.732 50 14.0001 50C6.26807 50 5.91278e-05 43.732 5.91278e-05 36Z"
          fill="#3F73E3"
        />
        <path
          d="M2.00006 36C2.00006 29.3726 7.37264 24 14.0001 24C20.6275 24 26.0001 29.3726 26.0001 36C26.0001 42.6274 20.6275 48 14.0001 48C7.37264 48 2.00006 42.6274 2.00006 36Z"
          fill="white"
        />
        <path
          d="M8.37506 34.9688C8.12115 34.9688 7.90631 35.0664 7.73053 35.2617C7.53522 35.4375 7.43756 35.6523 7.43756 35.9062V36.8438C7.43756 37.0977 7.53522 37.3223 7.73053 37.5176C7.90631 37.6934 8.12115 37.7812 8.37506 37.7812H12.5939V42C12.5939 42.2539 12.6915 42.4688 12.8868 42.6445C13.0626 42.8398 13.2774 42.9375 13.5313 42.9375H14.4689C14.7228 42.9375 14.9474 42.8398 15.1427 42.6445C15.3185 42.4688 15.4063 42.2539 15.4063 42V37.7812H19.6251C19.879 37.7812 20.1036 37.6934 20.2989 37.5176C20.4747 37.3223 20.5626 37.0977 20.5626 36.8438V35.9062C20.5626 35.6523 20.4747 35.4375 20.2989 35.2617C20.1036 35.0664 19.879 34.9688 19.6251 34.9688H15.4063V30.75C15.4063 30.4961 15.3185 30.2812 15.1427 30.1055C14.9474 29.9102 14.7228 29.8125 14.4689 29.8125H13.5313C13.2774 29.8125 13.0626 29.9102 12.8868 30.1055C12.6915 30.2812 12.5939 30.4961 12.5939 30.75V34.9688H8.37506Z"
          fill="#3F73E3"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clipSkill0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clipSkill1">
        <rect
          width="31.1243"
          height="31.0734"
          fill="white"
          transform="matrix(0.702241 -0.711939 0.716236 0.697858 11.0001 23.1582)"
        />
      </clipPath>
      <clipPath id="clipSkill2">
        <rect
          width="29"
          height="29"
          fill="white"
          transform="translate(6.10352e-05 21)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SkillIcon;
