import React from 'react';

const HobbyIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.5223 12.1372C52.6203 11.8278 53.4992 10.7308 53.9449 9.52726C54.4789 8.08529 54.2824 6.72068 53.6795 5.47467C53.013 4.09724 52.8701 3.58314 52.7835 2.76475C52.765 2.5901 52.579 2.54021 52.4756 2.68218C51.9913 3.34763 51.6104 3.72128 50.3443 4.58068C49.199 5.35807 48.3464 6.44145 48.0876 7.95717C47.8716 9.22224 48.0839 10.6118 48.8799 11.429L51.5223 12.1372Z"
      fill="#485363"
    />
    <path
      d="M53.9458 9.52697C53.5 10.7306 52.6208 11.8278 51.5228 12.1374L49.9989 11.7287C51.0539 11.3861 51.896 10.317 52.3294 9.14724C52.8635 7.70504 52.667 6.34035 52.0644 5.09457C51.7912 4.52906 51.6054 4.10969 51.4758 3.7547C51.9215 3.38819 52.18 3.09014 52.4763 2.6823C52.5795 2.54032 52.7652 2.58981 52.7842 2.76486C52.8709 3.58302 53.0137 4.09728 53.68 5.4743C54.2826 6.72088 54.4792 8.08549 53.9458 9.52697Z"
      fill="#072252"
    />
    <path
      d="M47.5911 13.7178L42.0803 37.033C41.9326 37.6662 42.3138 38.3023 42.9418 38.4706C43.5697 38.6389 44.2181 38.2788 44.4068 37.6566L51.2964 14.711L47.5911 13.7178Z"
      fill="#FFE5C2"
    />
    <path
      d="M51.296 14.7105L44.4067 37.6559C44.2491 38.1753 43.7727 38.5113 43.2542 38.5113C43.151 38.5113 43.0462 38.498 42.9414 38.47C42.8687 38.4502 42.7994 38.4246 42.7333 38.3932C42.7399 38.375 42.7457 38.3569 42.7515 38.3388L49.9545 14.3506L51.296 14.7105Z"
      fill="#FED2A4"
    />
    <path
      d="M51.7312 15.0145L47.1563 13.7881C46.6609 13.6553 46.3668 13.146 46.4997 12.6505L46.7667 11.6544C46.8996 11.1589 47.4089 10.8649 47.9043 10.9977L52.4792 12.224C52.9747 12.3569 53.2687 12.8662 53.1359 13.3616L52.8688 14.3578C52.736 14.8533 52.2267 15.1473 51.7312 15.0145Z"
      fill="#83B4FF"
    />
    <path
      d="M53.1357 13.3613L52.869 14.3577C52.7361 14.853 52.2267 15.1469 51.7314 15.014L50.4188 14.6623C50.7737 14.5863 51.0751 14.318 51.1758 13.9433L51.4623 12.8734C51.563 12.4977 51.435 12.1147 51.1642 11.8711L52.4793 12.2236C52.9747 12.3566 53.2686 12.8659 53.1357 13.3613Z"
      fill="#64A2FF"
    />
    <path
      d="M16.2791 11.7333C12.483 19.0717 13.5786 28.5693 18.4535 35.2166C21.5148 39.3909 26.2908 42.08 31.3922 42.8015C36.2157 43.4836 41.5646 42.4086 45.5739 39.5585C46.5272 38.8809 47.399 38.089 48.144 37.1862C50.4737 34.363 51.9841 29.9204 49.2191 26.8254C47.754 25.1852 45.3401 25.0321 43.4003 24.2838C41.026 23.3678 39.0467 22.0125 39.9587 19.173C40.5135 17.4456 42.0611 16.3019 42.8883 14.7296C45.7341 9.32018 41.1534 3.58018 35.8593 2.38174C32.8905 1.70972 29.6916 1.95444 26.8055 2.88447C22.1761 4.37632 18.5357 7.37132 16.2791 11.7333Z"
      fill="#5384EE"
    />
    <path
      d="M50.1556 33.7135C50.2853 32.1087 49.9385 30.503 48.8901 29.2902C48.4558 28.7875 47.9274 28.3672 47.3405 28.0551C46.4868 27.6011 45.5721 27.2766 44.6788 26.9126C43.4578 26.4147 42.1922 26.0367 40.9631 25.5628C38.4806 24.6051 36.737 21.9906 37.6897 19.0219C38.2701 17.2164 39.8882 16.021 40.7525 14.3773C43.2548 9.62045 40.263 4.61757 35.9544 2.4043C41.2082 3.64839 45.7174 9.35219 42.8883 14.7298C42.0611 16.3017 40.514 17.4459 39.9592 19.173C39.0469 22.0121 41.0266 23.3676 43.4009 24.2839C45.3402 25.0319 47.7541 25.1855 49.2195 26.825C51.006 28.8255 51.0076 31.3888 50.1556 33.7135Z"
      fill="#3F73E3"
    />
    <path
      d="M31.3994 12.5676C33.2281 12.5676 34.7106 11.0852 34.7106 9.25646C34.7106 7.42776 33.2281 5.94531 31.3994 5.94531C29.5707 5.94531 28.0883 7.42776 28.0883 9.25646C28.0883 11.0852 29.5707 12.5676 31.3994 12.5676Z"
      fill="#83B4FF"
    />
    <path
      d="M23.119 19.1897C24.9477 19.1897 26.4302 17.7072 26.4302 15.8785C26.4302 14.0498 24.9477 12.5674 23.119 12.5674C21.2903 12.5674 19.8079 14.0498 19.8079 15.8785C19.8079 17.7072 21.2903 19.1897 23.119 19.1897Z"
      fill="#83B4FF"
    />
    <path
      d="M22.3141 29.8381C24.1428 29.8381 25.6253 28.3557 25.6253 26.527C25.6253 24.6983 24.1428 23.2158 22.3141 23.2158C20.4854 23.2158 19.003 24.6983 19.003 26.527C19.003 28.3557 20.4854 29.8381 22.3141 29.8381Z"
      fill="#83B4FF"
    />
    <path
      d="M29.7414 37.6213C31.5701 37.6213 33.0525 36.1389 33.0525 34.3102C33.0525 32.4815 31.5701 30.999 29.7414 30.999C27.9127 30.999 26.4302 32.4815 26.4302 34.3102C26.4302 36.1389 27.9127 37.6213 29.7414 37.6213Z"
      fill="#83B4FF"
    />
    <path
      d="M40.6931 36.9006C42.5218 36.9006 44.0043 35.4182 44.0043 33.5895C44.0043 31.7608 42.5218 30.2783 40.6931 30.2783C38.8644 30.2783 37.382 31.7608 37.382 33.5895C37.382 35.4182 38.8644 36.9006 40.6931 36.9006Z"
      fill="#83B4FF"
    />
    <path
      d="M34.7105 9.25648C34.7105 11.0851 33.2278 12.5678 31.3992 12.5678C30.6554 12.5678 29.9693 12.3226 29.4162 11.909C29.5665 11.9296 29.7192 11.9404 29.8752 11.9404C31.7038 11.9404 33.1857 10.4577 33.1857 8.62907C33.1857 7.54433 32.664 6.58083 31.8582 5.97656C33.4697 6.2003 34.7105 7.58389 34.7105 9.25648Z"
      fill="#F1F4F9"
      fillOpacity="0.5"
    />
    <path
      d="M26.4299 15.8795C26.4299 17.7081 24.9481 19.19 23.1186 19.19C22.374 19.19 21.6879 18.9448 21.1348 18.5312C21.2851 18.5519 21.4386 18.5626 21.5946 18.5626C23.4232 18.5626 24.9059 17.0799 24.9059 15.2513C24.9059 14.1666 24.3842 13.204 23.5784 12.5996C25.1899 12.8233 26.4299 14.2069 26.4299 15.8795Z"
      fill="#F1F4F9"
      fillOpacity="0.5"
    />
    <path
      d="M25.6255 26.527C25.6255 28.3556 24.1428 29.8383 22.3142 29.8383C21.5704 29.8383 20.8835 29.5931 20.3304 29.1787C20.4807 29.1994 20.6343 29.2101 20.7903 29.2101C22.6188 29.2101 24.1016 27.7282 24.1016 25.8996C24.1016 24.814 23.5798 23.8513 22.7732 23.2471C24.3847 23.4708 25.6255 24.8544 25.6255 26.527Z"
      fill="#F1F4F9"
      fillOpacity="0.5"
    />
    <path
      d="M33.0533 34.3102C33.0533 36.1388 31.5706 37.6215 29.742 37.6215C28.9973 37.6215 28.3113 37.3763 27.7582 36.9619C27.9084 36.9826 28.0612 36.9933 28.2172 36.9933C30.0467 36.9933 31.5285 35.5114 31.5285 33.6828C31.5285 32.598 31.0067 31.6345 30.2011 31.0303C31.8125 31.254 33.0533 32.6376 33.0533 34.3102Z"
      fill="#F1F4F9"
      fillOpacity="0.5"
    />
    <path
      d="M44.0031 33.5887C44.0031 35.4173 42.5213 36.9 40.6926 36.9C39.9487 36.9 39.2635 36.6548 38.7104 36.2412C38.8598 36.2619 39.0126 36.2726 39.1677 36.2726C40.9963 36.2726 42.4791 34.7899 42.4791 32.9613C42.4791 31.8765 41.9573 30.9139 41.1515 30.3096C42.7631 30.5333 44.0031 31.9161 44.0031 33.5887Z"
      fill="#F1F4F9"
      fillOpacity="0.5"
    />
    <path
      d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
      fill="#3F73E3"
    />
    <path
      d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
      fill="white"
    />
    <path
      d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
      fill="#3F73E3"
    />
  </svg>
);

export default HobbyIcon;
