import useTheme from '@lobox/uikit/utils/useTheme';
import React from 'react';
import type { FC } from 'react';

const DividerMobile: FC = () => {
  const { theme, isDark } = useTheme();

  return (
    <svg
      preserveAspectRatio="none"
      width="100%"
      height="75"
      viewBox="0 0 375 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.15781 5.55125C53.7437 35.9925 133.3 71 217 71C294.362 71 316.52 48.0882 336.26 27.6767C347.033 16.5368 357.086 6.14171 375 0.622331V75H0V0H0.145349C2.68326 1.82705 5.35639 3.68053 8.15781 5.55125Z"
        fill={isDark ? theme.colors.background8 : theme.colors.background2}
      />
    </svg>
  );
};

export default DividerMobile;
