import classes from '../../../../../constants/highlights.user.module.scss';

import React from 'react';
import AvatarCard from '@lobox/uikit/AvatarCard';
import useOpenConfirm from '@lobox/uikit/Confirmation/useOpenConfirm';
import {
  Endpoints,
  formValidator,
  hereApiResponseNormalizer,
  lookupResponseNormalizer,
  postApi,
  useReactMutation,
  useTranslation,
} from '@lobox/utils';
import { useProfileModals, useProfile } from '@shared/hooks';
import { COURSE } from '@shared/constants/profileModalsKeys';
import CourseSvg from '@shared/svg/SvgCourse';
import { DESC_LEN_VALIDATION } from '@shared/constants/highlights.user';
import { DESCRIPTION_MAX_LENGTH } from '@shared/constants/enums';
import HIGHLIGHT_TYPES from '@shared/constants/highlightTypes';
import type { CourseType } from '@lobox/utils';
import useShowSuccessToast from './useShowSuccessToast';
import cnj from '@lobox/uikit/utils/cnj';

interface ValuesTypes {
  [name: string]: any;
}

export const useCourseFormGroups = ({
  isEdit,
  isHighlight,
}: {
  isEdit?: boolean;
  isHighlight?: boolean;
}) => {
  const { t } = useTranslation();
  const groups = ({ values }: ValuesTypes) =>
    [
      {
        name: 'title',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('course'),
        wrapStyle: classes.formItem,
        url: Endpoints.App.Common.getOccupations,
        normalizer: lookupResponseNormalizer,
        required: true,
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
      },
      {
        name: 'institution',
        cp: 'asyncAutoComplete',
        maxLength: 100,
        label: t('institution'),
        visibleRightIcon: true,
        rightIconProps: { name: 'search' },
        required: true,
        normalizer: hereApiResponseNormalizer,
        url: `${Endpoints.App.Common.suggestPlace}`,
        wrapStyle: classes.formItem,
        renderItem: ({ item }: any) => (
          <AvatarCard
            data={{
              title: item.label,
              image: item.image,
            }}
            avatarProps={{ isCompany: true, size: 'sm' }}
          />
        ),
      },
      {
        name: 'startDate',
        cp: 'datePicker',
        variant: 'input',
        wrapStyle: cnj(classes.halfWidth, classes.leftItem, classes.formItem),
        required: true,
        picker: 'month',
        label: t('start_date'),
      },
      {
        name: 'endDate',
        cp: 'datePicker',
        wrapStyle: cnj(classes.halfWidth, classes.rightItem, classes.formItem),
        variant: 'input',
        picker: 'month',
        visibleOptionalLabel: false,
        minDate: values?.startDate ? new Date(values?.startDate) : undefined,
        label: t('end_date'),
      },
      {
        name: 'description',
        cp: 'richtext',
        label: t('tell_us_more_about_your_school'),
        wrapStyle: classes.formItem,
        maxLength: DESCRIPTION_MAX_LENGTH,
        visibleOptionalLabel: false,
      },
    ].reduce((prev: any, curr) => {
      if (isHighlight && curr.name === 'description') {
        return prev;
      }
      if (curr.name === 'share' && isEdit) {
        return prev;
      }
      return [...prev, curr];
    }, []);
  return React.useCallback(groups, []);
};

export const courseValidationSchema = () =>
  formValidator.object().shape({
    endDate: formValidator
      .date()
      .when(
        ['startDate', 'currentlyWorking'],
        (startDate, currentlyWorking, schema) => {
          if (currentlyWorking) {
            return schema.nullable();
          }
          return startDate
            ? schema.min(startDate, 'date_e_b_s').required()
            : schema.nullable();
        }
      ),
    description: DESC_LEN_VALIDATION,
  });

const useCourse = () => {
  const { t } = useTranslation();
  const { modals, close } = useProfileModals();
  const showSuccessToast = useShowSuccessToast();
  const { reFetchAboutSectionQuery } = useProfile();
  const { openConfirmDialog } = useOpenConfirm();
  const course = modals?.params?.[COURSE]?.realData || {};
  const isEdit = !!course?.id;
  const editUrl = `${Endpoints.App.User.Course.get}/${course?.id}`;

  const { mutate: createPost } = useReactMutation({
    apiFunc: postApi.createPost,
  });

  const onSuccess = (data: CourseType, values: { share: boolean }) => {
    reFetchAboutSectionQuery();
    close();
    if (values?.share && !isEdit) {
      const post = {
        highlight: {
          ...data,
          profileEntityId: data.id,
          subTitle: data.institutionName,
          title: data.title,
          type: HIGHLIGHT_TYPES.COURSE,
        },
      };
      createPost({ postData: post });
    }
    showSuccessToast({
      isDelete: !values,
      isEdit,
      shared: values?.share,
      variant: COURSE,
    });
  };

  const { mutate } = useReactMutation({
    url: editUrl,
    headers: {},
    method: 'DELETE',
  });

  const groups = useCourseFormGroups({ isEdit });

  const openConfirm = () => {
    openConfirmDialog({
      title: t('delete'),
      message: t('delete_course_helper'),
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
      isAjaxCall: true,
      apiProps: {
        func: mutate,
        onSuccess,
      },
    });
  };

  return {
    onSuccess,
    title: isEdit ? t('edit_course') : t('add_course'),
    image: <CourseSvg />,
    method: isEdit ? 'PUT' : 'POST',
    url: isEdit ? editUrl : Endpoints.App.User.Course.get,
    initialValues: { ...course, share: true } as CourseType,
    groups,
    transform: ({ title, institution, ...rest }: CourseType) => ({
      ...rest,
      title: title?.label,
      titleLookupId: title?.value,
      institutionName: institution?.label,
      institutionPageId: `${institution?.value}`?.includes('_temp')
        ? null
        : institution?.value,
    }),
    visibleRequiredHint: true,
    visibleHeaderBorderBottom: false,
    secondaryAction: isEdit
      ? {
          label: t('delete_course'),
          onClick: openConfirm,
        }
      : undefined,
    validationSchema: courseValidationSchema,
  };
};

export default useCourse;
