import React from 'react';

const CourseIcon = (): JSX.Element => (
  <svg
    width="55"
    height="50"
    viewBox="0 0 55 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <g clipPath="url(#clip1)" filter="url(#filter0_d)">
        <path
          d="M43.6232 41.6259H14.5588C13.6145 41.6259 12.8491 40.8604 12.8491 39.9163V2.30341C12.8491 1.35925 13.6145 0.59375 14.5588 0.59375H43.6232C44.5675 0.59375 45.3329 1.35925 45.3329 2.30341V39.9163C45.3329 40.8605 44.5675 41.6259 43.6232 41.6259Z"
          fill="#3F73E3"
        />
        <path
          d="M39.3448 10.8522H25.6675C25.1949 10.8522 24.8126 10.4694 24.8126 9.99741C24.8126 9.52538 25.195 9.14258 25.6675 9.14258H39.3448C39.8174 9.14258 40.1997 9.52538 40.1997 9.99741C40.1997 10.4694 39.8173 10.8522 39.3448 10.8522Z"
          fill="#83B4FF"
        />
        <path
          d="M27.3771 14.2712H25.6675C25.1949 14.2712 24.8126 13.8884 24.8126 13.4164C24.8126 12.9443 25.195 12.5615 25.6675 12.5615H27.3771C27.8497 12.5615 28.2319 12.9443 28.2319 13.4164C28.2319 13.8884 27.8496 14.2712 27.3771 14.2712Z"
          fill="#83B4FF"
        />
        <path
          d="M31.5645 14.3034H29.8548C29.3823 14.3034 29 13.9206 29 13.4486C29 12.9766 29.3824 12.5938 29.8548 12.5938H31.5645C32.037 12.5938 32.4193 12.9766 32.4193 13.4486C32.4193 13.9206 32.0369 14.3034 31.5645 14.3034Z"
          fill="#83B4FF"
        />
        <path
          d="M39.3448 20.2546H25.6675C25.1949 20.2546 24.8126 19.8718 24.8126 19.3998C24.8126 18.9277 25.195 18.5449 25.6675 18.5449H39.3448C39.8174 18.5449 40.1997 18.9277 40.1997 19.3998C40.1997 19.8719 39.8173 20.2546 39.3448 20.2546Z"
          fill="#83B4FF"
        />
        <path
          d="M27.3771 23.6745H25.6675C25.1949 23.6745 24.8126 23.2917 24.8126 22.8197C24.8126 22.3476 25.195 21.9648 25.6675 21.9648H27.3771C27.8497 21.9648 28.2319 22.3476 28.2319 22.8197C28.2319 23.2917 27.8496 23.6745 27.3771 23.6745Z"
          fill="#83B4FF"
        />
        <path
          d="M31.5645 23.6735H29.8548C29.3823 23.6735 29 23.2907 29 22.8187C29 22.3467 29.3824 21.9639 29.8548 21.9639H31.5645C32.037 21.9639 32.4193 22.3467 32.4193 22.8187C32.4193 23.2907 32.0369 23.6735 31.5645 23.6735Z"
          fill="#83B4FF"
        />
        <path
          d="M39.3448 29.6579H25.6675C25.1949 29.6579 24.8126 29.2751 24.8126 28.8031C24.8126 28.331 25.195 27.9482 25.6675 27.9482H39.3448C39.8174 27.9482 40.1997 28.331 40.1997 28.8031C40.1997 29.2751 39.8173 29.6579 39.3448 29.6579Z"
          fill="#83B4FF"
        />
        <path
          d="M27.3771 33.0778H25.6675C25.1949 33.0778 24.8126 32.695 24.8126 32.223C24.8126 31.751 25.195 31.3682 25.6675 31.3682H27.3771C27.8497 31.3682 28.2319 31.751 28.2319 32.223C28.2319 32.695 27.8496 33.0778 27.3771 33.0778Z"
          fill="#83B4FF"
        />
        <path
          d="M31.5645 33.0729H29.8548C29.3823 33.0729 29 32.6901 29 32.2181C29 31.7461 29.3824 31.3633 29.8548 31.3633H31.5645C32.037 31.3633 32.4193 31.7461 32.4193 32.2181C32.4193 32.6901 32.0369 33.0729 31.5645 33.0729Z"
          fill="#83B4FF"
        />
        <path
          d="M20.5421 13.8445C21.7224 13.8445 22.6792 12.8877 22.6792 11.7074C22.6792 10.5271 21.7224 9.57031 20.5421 9.57031C19.3618 9.57031 18.405 10.5271 18.405 11.7074C18.405 12.8877 19.3618 13.8445 20.5421 13.8445Z"
          fill="white"
        />
        <path
          d="M20.5421 32.6501C21.7224 32.6501 22.6792 31.6933 22.6792 30.5131C22.6792 29.3328 21.7224 28.376 20.5421 28.376C19.3618 28.376 18.405 29.3328 18.405 30.5131C18.405 31.6933 19.3618 32.6501 20.5421 32.6501Z"
          fill="white"
        />
        <path
          d="M20.5421 23.2468C21.7224 23.2468 22.6792 22.29 22.6792 21.1097C22.6792 19.9295 21.7224 18.9727 20.5421 18.9727C19.3618 18.9727 18.405 19.9295 18.405 21.1097C18.405 22.29 19.3618 23.2468 20.5421 23.2468Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip2)">
        <path
          d="M54.5343 25.7428L53.4117 24.6201L46.208 32.3851L50.4179 32.1045L54.5343 27.9881C55.1543 27.368 55.1543 26.3628 54.5343 25.7428Z"
          fill="#5384EE"
        />
        <path
          d="M53.4119 24.62L52.8506 24.0588C52.2305 23.4387 51.2253 23.4388 50.6053 24.0588L46.4889 28.1751L47.0503 33.227L53.4119 26.8654C54.0319 26.2453 54.0319 25.2401 53.4119 24.62Z"
          fill="#072252"
        />
        <path
          d="M43.1215 32.6654L48.7348 33.7881L50.4187 32.1041L49.2961 30.9814L43.1215 32.6654Z"
          fill="#F7FCFF"
        />
        <path
          d="M49.2966 30.9814L46.4899 28.1748L44.806 29.8587L45.3673 34.9107L49.2966 30.9814Z"
          fill="#E1F5FE"
        />
        <path
          d="M32.0654 46.5283L29.4192 48.0519C29.2136 48.5772 29.0281 49.0514 29.0281 49.0514C28.902 49.3737 29.22 49.6918 29.5423 49.5657L31.9251 48.6333L32.0654 46.5283Z"
          fill="#072252"
        />
        <path
          d="M32.0655 46.5283L29.9605 46.6687C29.9605 46.6687 29.6744 47.3997 29.4192 48.0519L32.032 47.0295L32.0655 46.5283Z"
          fill="#485363"
        />
        <path
          d="M35.4336 43.1602L30.8027 47.5104L31.9253 48.633L35.1529 47.3701L35.4336 43.1602Z"
          fill="#FED2A4"
        />
        <path
          d="M34.0301 46.2474L35.4334 43.1602L31.2235 43.4408L29.9605 46.6684L30.8025 47.5104L34.0301 46.2474Z"
          fill="#FFE5C2"
        />
        <path
          d="M34.0306 46.2477L35.1533 47.3704L48.735 33.7887L47.6124 32.666L38.5762 37.2116L34.0306 46.2477Z"
          fill="#83B4FF"
        />
        <path
          d="M31.2225 43.4395L44.8022 29.8598L47.6084 32.666L34.0287 46.2456L31.2225 43.4395Z"
          fill="#5384EE"
        />
      </g>
      <path
        d="M-1.90735e-06 36C-1.90735e-06 28.268 6.26801 22 14 22C21.732 22 28 28.268 28 36C28 43.732 21.732 50 14 50C6.26801 50 -1.90735e-06 43.732 -1.90735e-06 36Z"
        fill="#3F73E3"
      />
      <path
        d="M2 36C2 29.3726 7.37258 24 14 24C20.6274 24 26 29.3726 26 36C26 42.6274 20.6274 48 14 48C7.37258 48 2 42.6274 2 36Z"
        fill="white"
      />
      <path
        d="M8.375 34.9688C8.12109 34.9688 7.90625 35.0664 7.73047 35.2617C7.53516 35.4375 7.4375 35.6523 7.4375 35.9062V36.8438C7.4375 37.0977 7.53516 37.3223 7.73047 37.5176C7.90625 37.6934 8.12109 37.7812 8.375 37.7812H12.5938V42C12.5938 42.2539 12.6914 42.4688 12.8867 42.6445C13.0625 42.8398 13.2773 42.9375 13.5312 42.9375H14.4688C14.7227 42.9375 14.9473 42.8398 15.1426 42.6445C15.3184 42.4688 15.4062 42.2539 15.4062 42V37.7812H19.625C19.8789 37.7812 20.1035 37.6934 20.2988 37.5176C20.4746 37.3223 20.5625 37.0977 20.5625 36.8438V35.9062C20.5625 35.6523 20.4746 35.4375 20.2988 35.2617C20.1035 35.0664 19.8789 34.9688 19.625 34.9688H15.4062V30.75C15.4062 30.4961 15.3184 30.2812 15.1426 30.1055C14.9473 29.9102 14.7227 29.8125 14.4688 29.8125H13.5312C13.2773 29.8125 13.0625 29.9102 12.8867 30.1055C12.6914 30.2812 12.5938 30.4961 12.5938 30.75V34.9688H8.375Z"
        fill="#3F73E3"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-3"
        y="-7.40625"
        width="77"
        height="77"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.65625 0 0 0 0 0.697742 0 0 0 0 0.7875 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="55" height="50" fill="white" />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="53"
          height="53"
          fill="white"
          transform="translate(9 0.59375)"
        />
      </clipPath>
      <clipPath id="clip2">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(29 23.5938)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CourseIcon;
