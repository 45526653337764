import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const WorkIcon = ({ props }): JSX.Element => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={67}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#393939' />
      <g clipPath='highlight_work_11223'>
        <path
          fill='#072252'
          d='M28.018 30.073a.73.73 0 0 1-.189-.025c-1.673-.45-3.92-.7-6.329-.7-2.408 0-4.656.25-6.329.7a.724.724 0 1 1-.376-1.399c1.792-.483 4.173-.749 6.705-.749s4.914.266 6.706.75a.724.724 0 0 1-.188 1.423Z'
        />
        <path
          fill='#072252'
          d='M14.983 28.623c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724ZM28.017 28.623c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724Z'
        />
        <path
          fill='#3F73E3'
          d='M38.155 64.1H4.845a2.897 2.897 0 0 1-2.897-2.897V47.451h39.104v13.752c0 1.6-1.297 2.897-2.897 2.897Z'
        />
        <path
          fill='#5384EE'
          d='M20.776 46.004H3.396v15.2c0 .8.649 1.449 1.449 1.449h15.207c.4 0 .724-.325.724-.725V46.004ZM22.225 46.004v15.924c0 .4.324.725.724.725h15.207c.8 0 1.448-.649 1.448-1.449v-15.2h-17.38Z'
        />
        <path
          fill='#5384EE'
          d='M38.155 49.624H4.845A4.345 4.345 0 0 1 .5 45.28V33.686c0-.8.648-1.448 1.448-1.448h39.104c.8 0 1.448.649 1.448 1.449v11.592c0 2.4-1.945 4.345-4.345 4.345Z'
        />
        <path
          fill='#3F73E3'
          d='M38.155 51.073a5.75 5.75 0 0 0 2.896-.784v-1.777a4.324 4.324 0 0 1-2.896 1.113H4.845a4.323 4.323 0 0 1-2.897-1.113v1.777a5.75 5.75 0 0 0 2.897.784h33.31Z'
        />
        <path
          fill='#fff'
          d='M22.948 43.83H20.05c-.4 0-.724.324-.724.724v5.07c0 .4.324.723.724.723h2.897c.4 0 .724-.324.724-.724v-5.069c0-.4-.324-.724-.724-.724Z'
        />
        <path
          fill='#fff'
          d='M23.673 52.52h-4.345a.724.724 0 0 1-.724-.724v-3.62c0-.4.324-.725.724-.725h4.345c.4 0 .724.324.724.724v3.621c0 .4-.324.724-.724.724Z'
        />
        <path
          fill='#ECECEC'
          d='M22.948 52.521h-2.896v-2.896c0-.4.324-.725.724-.725h1.448c.4 0 .724.325.724.725v2.896Z'
        />
        <path
          fill='#fff'
          d='M21.5 41.658a1.448 1.448 0 1 0 0-2.896 1.448 1.448 0 0 0 0 2.896Z'
        />
      </g>
      <defs>
        <clipPath id='highlight_work_11223b'>
          <path fill='#fff' d='M.5 25h42v42H.5z' />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={67}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <g clipPath='highlight_work_11223'>
        <path
          fill='#072252'
          d='M28.018 30.073a.73.73 0 0 1-.189-.025c-1.673-.45-3.92-.7-6.329-.7-2.408 0-4.656.25-6.329.7a.724.724 0 1 1-.376-1.399c1.792-.483 4.173-.749 6.705-.749s4.914.266 6.706.75a.724.724 0 0 1-.188 1.423Z'
        />
        <path
          fill='#072252'
          d='M14.983 28.623c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724ZM28.017 28.623c-.4 0-.724.324-.724.724v3.62h1.448v-3.62c0-.4-.324-.724-.724-.724Z'
        />
        <path
          fill='#3F73E3'
          d='M38.155 64.1H4.845a2.897 2.897 0 0 1-2.897-2.897V47.451h39.104v13.752c0 1.6-1.297 2.897-2.897 2.897Z'
        />
        <path
          fill='#5384EE'
          d='M20.776 46.004H3.396v15.2c0 .8.649 1.449 1.449 1.449h15.207c.4 0 .724-.325.724-.725V46.004ZM22.225 46.004v15.924c0 .4.324.725.724.725h15.207c.8 0 1.448-.649 1.448-1.449v-15.2h-17.38Z'
        />
        <path
          fill='#5384EE'
          d='M38.155 49.624H4.845A4.345 4.345 0 0 1 .5 45.28V33.686c0-.8.648-1.448 1.448-1.448h39.104c.8 0 1.448.649 1.448 1.449v11.592c0 2.4-1.945 4.345-4.345 4.345Z'
        />
        <path
          fill='#3F73E3'
          d='M38.155 51.073a5.75 5.75 0 0 0 2.896-.784v-1.777a4.324 4.324 0 0 1-2.896 1.113H4.845a4.323 4.323 0 0 1-2.897-1.113v1.777a5.75 5.75 0 0 0 2.897.784h33.31Z'
        />
        <path
          fill='#fff'
          d='M22.948 43.83H20.05c-.4 0-.724.324-.724.724v5.07c0 .4.324.723.724.723h2.897c.4 0 .724-.324.724-.724v-5.069c0-.4-.324-.724-.724-.724Z'
        />
        <path
          fill='#fff'
          d='M23.673 52.52h-4.345a.724.724 0 0 1-.724-.724v-3.62c0-.4.324-.725.724-.725h4.345c.4 0 .724.324.724.724v3.621c0 .4-.324.724-.724.724Z'
        />
        <path
          fill='#ECECEC'
          d='M22.948 52.521h-2.896v-2.896c0-.4.324-.725.724-.725h1.448c.4 0 .724.325.724.725v2.896Z'
        />
        <path
          fill='#fff'
          d='M21.5 41.658a1.448 1.448 0 1 0 0-2.896 1.448 1.448 0 0 0 0 2.896Z'
        />
      </g>
      <defs>
        <clipPath id='highlight_work_11223a'>
          <path fill='#fff' d='M.5 25h42v42H.5z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WorkIcon;
