import React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

const EducatioIcon: React.FC = ({ props }) => {
  const { isDark } = useTheme();

  return isDark ? (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#393939' />
      <path
        fill='#3F73E3'
        d='m11.357 53.145.482.994s7.627 6.194 14.16 6.194c6.534 0 14.196-6.194 14.196-6.194l.446-.994c-.733-2.191-1.634-5.2-2.266-8.378l-1.396-.785H14.815l-1.192.785c-.632 3.178-1.532 6.187-2.266 8.378Z'
      />
      <path
        fill='#5384EE'
        d='m17.836 44.32.512-.338h-3.533l-1.192.785c-.632 3.178-1.532 6.187-2.266 8.378l.482.994s1.173.953 2.995 2.111c1.067-3.565 2.155-7.671 3.002-11.93Z'
      />
      <path
        fill='#5384EE'
        d='m51.008 37.23-23.734-8.02a3.981 3.981 0 0 0-2.548 0L.992 37.23a.724.724 0 0 0-.085 1.337L24.256 49.95a3.98 3.98 0 0 0 3.488 0l23.35-11.383a.724.724 0 0 0-.086-1.337Z'
      />
      <path
        fill='#3F73E3'
        d='M8.09 38.567a.724.724 0 0 1 .085-1.336l21.416-7.238-2.317-.783a3.978 3.978 0 0 0-2.549 0L.992 37.23a.724.724 0 0 0-.085 1.337L24.256 49.95a3.98 3.98 0 0 0 3.488 0l1.848-.9L8.09 38.567Z'
      />
      <path
        fill='#fff'
        d='M45.748 57.39a.77.77 0 0 1-.769-.77V42.387a.804.804 0 0 0-.6-.777l-17.37-4.58a.77.77 0 1 1 .393-1.489l17.367 4.58a2.343 2.343 0 0 1 1.75 2.266V56.62a.77.77 0 0 1-.77.77Z'
      />
      <path
        fill='#83B4FF'
        d='M47.097 58.33h-2.695l-.32 4.984a.708.708 0 0 0 .694.763h1.947c.402 0 .72-.35.694-.763l-.32-4.984Z'
      />
      <path
        fill='#83B4FF'
        d='m45.835 63.314.32-4.984h-1.753l-.32 4.984a.708.708 0 0 0 .694.763h1.753a.708.708 0 0 1-.694-.763Z'
      />
      <path
        fill='#5384EE'
        d='M46.372 54.75h-1.247c-.621 0-1.13.508-1.13 1.13v2.243c0 .204.166.37.37.37h2.768a.37.37 0 0 0 .37-.37V55.88c0-.622-.51-1.13-1.13-1.13Z'
      />
      <path
        fill='#5384EE'
        d='M45.979 58.123V55.88c0-.492.32-.913.762-1.067a1.116 1.116 0 0 0-.368-.063h-1.248c-.621 0-1.13.508-1.13 1.13v2.243c0 .204.166.37.37.37h1.983a.37.37 0 0 1-.369-.37Z'
      />
      <path
        fill='#64A2FF'
        d='M26.159 34.144a2.55 2.55 0 0 0-2.708 2.544v.013c.003.656.554 1.18 1.21 1.18h2.678c.635 0 1.247-.491 1.207-1.318a2.562 2.562 0 0 0-2.387-2.42Z'
      />
      <path
        fill='#072252'
        d='M25.76 36.7v-.012c0-.99.565-1.848 1.39-2.27a2.503 2.503 0 0 0-.991-.274 2.55 2.55 0 0 0-2.708 2.544v.013c.003.656.554 1.18 1.21 1.18h2.31c-.657 0-1.208-.524-1.211-1.18Z'
      />
      <path
        fill='#fff'
        d='M40.642 53.145c-2.675 2.198-7.025 4.659-11.724 6.173a9.567 9.567 0 0 1-5.836 0c-4.689-1.511-9.036-3.966-11.724-6.173a76.8 76.8 0 0 1-.69 1.975 2.281 2.281 0 0 0 .728 2.577c4.296 3.377 8.83 5.221 11.686 6.142a9.512 9.512 0 0 0 2.918.455c.99 0 1.976-.152 2.919-.455 2.855-.92 7.39-2.765 11.685-6.142a2.281 2.281 0 0 0 .729-2.577 76.726 76.726 0 0 1-.691-1.975Z'
      />
      <path
        fill='#F1F4F9'
        d='M15.007 55.667c-1.403-.834-2.643-1.696-3.65-2.523a76.04 76.04 0 0 1-.69 1.976 2.281 2.281 0 0 0 .728 2.577c.81.637 1.63 1.22 2.444 1.751.38-1.176.773-2.443 1.168-3.781Z'
      />
    </svg>
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={72}
      height={65}
      fill='none'
      {...props}
    >
      <circle cx={43} cy={28.5} r={28.5} fill='#5384EE' fillOpacity={0.1} />
      <path
        fill='#3F73E3'
        d='m11.357 53.145.482.994s7.627 6.194 14.16 6.194c6.534 0 14.196-6.194 14.196-6.194l.446-.994c-.733-2.191-1.634-5.2-2.266-8.378l-1.396-.785H14.815l-1.192.785c-.632 3.178-1.532 6.187-2.266 8.378Z'
      />
      <path
        fill='#5384EE'
        d='m17.836 44.32.512-.338h-3.533l-1.192.785c-.632 3.178-1.532 6.187-2.266 8.378l.482.994s1.173.953 2.995 2.111c1.067-3.565 2.155-7.671 3.002-11.93Z'
      />
      <path
        fill='#5384EE'
        d='m51.008 37.23-23.734-8.02a3.981 3.981 0 0 0-2.548 0L.992 37.23a.724.724 0 0 0-.085 1.337L24.256 49.95a3.98 3.98 0 0 0 3.488 0l23.35-11.383a.724.724 0 0 0-.086-1.337Z'
      />
      <path
        fill='#3F73E3'
        d='M8.09 38.567a.724.724 0 0 1 .085-1.336l21.416-7.238-2.317-.783a3.978 3.978 0 0 0-2.549 0L.992 37.23a.724.724 0 0 0-.085 1.337L24.256 49.95a3.98 3.98 0 0 0 3.488 0l1.848-.9L8.09 38.567Z'
      />
      <path
        fill='#fff'
        d='M45.748 57.39a.77.77 0 0 1-.769-.77V42.387a.804.804 0 0 0-.6-.777l-17.37-4.58a.77.77 0 1 1 .393-1.489l17.367 4.58a2.343 2.343 0 0 1 1.75 2.266V56.62a.77.77 0 0 1-.77.77Z'
      />
      <path
        fill='#83B4FF'
        d='M47.097 58.33h-2.695l-.32 4.984a.708.708 0 0 0 .694.763h1.947c.402 0 .72-.35.694-.763l-.32-4.984Z'
      />
      <path
        fill='#83B4FF'
        d='m45.835 63.314.32-4.984h-1.753l-.32 4.984a.708.708 0 0 0 .694.763h1.753a.708.708 0 0 1-.694-.763Z'
      />
      <path
        fill='#5384EE'
        d='M46.372 54.75h-1.247c-.621 0-1.13.508-1.13 1.13v2.243c0 .204.166.37.37.37h2.768a.37.37 0 0 0 .37-.37V55.88c0-.622-.51-1.13-1.13-1.13Z'
      />
      <path
        fill='#5384EE'
        d='M45.979 58.123V55.88c0-.492.32-.913.762-1.067a1.116 1.116 0 0 0-.368-.063h-1.248c-.621 0-1.13.508-1.13 1.13v2.243c0 .204.166.37.37.37h1.983a.37.37 0 0 1-.369-.37Z'
      />
      <path
        fill='#64A2FF'
        d='M26.159 34.144a2.55 2.55 0 0 0-2.708 2.544v.013c.003.656.554 1.18 1.21 1.18h2.678c.635 0 1.247-.491 1.207-1.318a2.562 2.562 0 0 0-2.387-2.42Z'
      />
      <path
        fill='#072252'
        d='M25.76 36.7v-.012c0-.99.565-1.848 1.39-2.27a2.503 2.503 0 0 0-.991-.274 2.55 2.55 0 0 0-2.708 2.544v.013c.003.656.554 1.18 1.21 1.18h2.31c-.657 0-1.208-.524-1.211-1.18Z'
      />
      <path
        fill='#fff'
        d='M40.642 53.145c-2.675 2.198-7.025 4.659-11.724 6.173a9.567 9.567 0 0 1-5.836 0c-4.689-1.511-9.036-3.966-11.724-6.173a76.8 76.8 0 0 1-.69 1.975 2.281 2.281 0 0 0 .728 2.577c4.296 3.377 8.83 5.221 11.686 6.142a9.512 9.512 0 0 0 2.918.455c.99 0 1.976-.152 2.919-.455 2.855-.92 7.39-2.765 11.685-6.142a2.281 2.281 0 0 0 .729-2.577 76.726 76.726 0 0 1-.691-1.975Z'
      />
      <path
        fill='#F1F4F9'
        d='M15.007 55.667c-1.403-.834-2.643-1.696-3.65-2.523a76.04 76.04 0 0 1-.69 1.976 2.281 2.281 0 0 0 .728 2.577c.81.637 1.63 1.22 2.444 1.751.38-1.176.773-2.443 1.168-3.781Z'
      />
    </svg>
  );
};

export default EducatioIcon;
