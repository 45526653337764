import { formatDate, checkLinkPrefix } from '@lobox/utils';

export const convertLicenceApiToForm = (item) => {
  const {
    institutionPageId,
    institutionName,
    certificationLookupId,
    certificationName,
    startDate,
    id,
    description,
    certificateLink,
    verificationCode,
    pageCroppedImageUrl,
  } = item;
  return {
    institution: { label: institutionName, value: institutionPageId },
    title: { label: certificationName, value: certificationLookupId },
    id,
    startDate,
    description,
    certificateLink: checkLinkPrefix(certificateLink),
    verificationCode,
    pageCroppedImageUrl,
  };
};
export const licenceNormalizer = (item) => {
  const {
    institutionPageId,
    institutionName,
    certificationName,
    startDate,
    id,
    description,
    pageCroppedImageUrl,
  } = item;
  const realData = convertLicenceApiToForm(item);

  const formattedStartDate = formatDate(startDate);

  return {
    id,
    image: pageCroppedImageUrl,
    firstText: certificationName,
    firstTextAdditionalProps: { objectId: institutionPageId },
    thirdText: institutionName,
    secondText: formattedStartDate,
    realData,
    longText: description,
    objectId: institutionPageId,
  };
};
